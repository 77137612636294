import React from 'react';

export default (props) => (
  <svg width="83" height="86" viewBox="0 0 72 102" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M52.4855 40.4337C49.5352 31.8357 43.2131 29.9812 35.8795 30.2341C29.2203 30.4027 23.5725 32.51 21.128 40.2651C17.5034 38.4106 13.7944 37.1462 10.6755 34.9545C3.42624 29.8126 4.35346 19.7816 12.6143 16.747C28.7145 10.8464 45.0676 10.8464 61.1678 16.8313C68.5857 19.613 69.9344 29.2225 63.6966 34.1959C60.4091 36.7247 56.4473 38.3263 52.4855 40.4337Z"
      fill={props.color || '#9C9796'}
    />
    <path
      d="M27.5293 74.5692C27.5293 68.9215 27.445 63.358 27.5293 57.7103C27.6136 51.4726 30.2267 49.871 35.7058 52.4841C40.342 54.76 44.8939 57.1203 49.5301 59.2276C52.649 60.5763 54.0819 62.6837 53.9977 66.1397C53.9134 71.7875 53.9134 77.3509 53.9977 82.9986C53.9977 86.1175 52.8175 88.2248 49.7829 89.5735C44.8096 91.7652 40.0048 94.3783 35.1158 96.7385C30.3953 99.0145 27.6979 97.3286 27.5293 91.9338C27.3607 86.1175 27.5293 80.3855 27.5293 74.5692Z"
      fill={props.color || '#9C9796'}
    />
    <path
      d="M6.79344 14.4682C5.36043 10.2535 7.46778 7.21894 11.261 5.95453C17.1616 4.01576 23.2308 2.32988 29.3 1.57123C39.9211 0.222521 50.5421 1.14976 60.6574 5.02729C65.0407 6.71318 68.4125 9.3263 67.1481 14.6368C47.0018 6.54459 27.024 6.62888 6.79344 14.4682Z"
      fill={props.color || '#9C9796'}
    />
    <path
      d="M71.6181 74.5672C71.6181 76.0002 71.6181 77.3489 71.6181 78.7819C71.6181 85.104 70.9438 85.6941 64.6217 85.6098C61.0813 85.6098 59.3112 84.261 59.3955 80.6364C59.4797 76.4217 59.3955 72.207 59.3955 67.9923C59.3955 63.5247 62.3458 63.1875 65.8018 63.1875C69.1736 63.1875 71.7867 63.7776 71.6181 67.9923C71.5338 70.1839 71.6181 72.3756 71.6181 74.5672Z"
      fill={props.color || '#9C9796'}
    />
    <path
      d="M36.9758 34.9541C42.7921 35.0384 47.0911 37.5672 47.0911 41.1075C47.0911 44.4793 42.6235 47.261 36.9758 47.261C31.0752 47.3453 26.3547 44.6479 26.3547 41.1075C26.3547 37.5672 30.9066 34.8698 36.9758 34.9541Z"
      fill={props.color || '#9C9796'}
    />
    <path
      d="M8.65076 101.035C7.63923 100.192 6.2905 99.6021 5.78473 98.5063C-1.88604 82.4904 -1.97033 66.3902 5.78473 50.29C6.2905 49.3627 7.8078 48.857 8.81933 48.1826C8.73504 49.447 8.98792 50.7957 8.48216 51.8073C1.40145 66.8959 1.40145 81.9003 8.56646 96.9047C9.07222 97.9162 9.3251 99.012 9.74657 100.108C9.3251 100.529 8.98794 100.782 8.65076 101.035Z"
      fill={props.color || '#9C9796'}
    />
    <path
      d="M16.4036 94.7182C8.31137 91.515 7.8899 60.3262 16.4879 53.4141C15.3921 60.7477 13.5376 67.5755 13.5376 74.4033C13.5376 81.1469 15.3921 87.9747 16.4036 94.7182Z"
      fill={props.color || '#9C9796'}
    />
  </svg>
);
