import React from 'react';
import Action from 'components/datatable/Action';

export const roleListColumn = (filters, isEditAllow, isDeleteAllow, handleEdit, handleDelete, t) => {  
  return [
    {
      name: t('Serial No'),
      selector: (row) => row.serial_no,
      cell: (row, index) => {
        return (filters.pageNumber - 1) * filters.pageSize + index + 1;
      },
    },
    {
      name: t('Role'),
      alias: 'name',
      selector: (row) => row.name,
      sortable: true,
      sortFunction: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
    },
    {
      name: t('Description'),
      alias: 'description',
      selector: (row) => row.description,
      sortable: true,
      sortFunction: (a, b) => a.description.toLowerCase().localeCompare(b.description.toLowerCase()),
    },
    {
      name: t('Status'),
      alias: 'status',
      selector: (row) => (row.status ? 'Active' : 'Inactive'),
      sortFunction: (a, b) => {
        const statusA = a.status ? 1 : 0;
        const statusB = b.status ? 1 : 0;

        if (statusA < statusB) {
          return -1;
        }
        if (statusA > statusB) {
          return 1;
        }
        return 0;
      },
      sortable: true,
    },
    {
      name: t('Action'),
      button: true,
      cell: (row) => (
        <Action
          row={row}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          isEditAllow={isEditAllow}
          isDeleteAllow={isDeleteAllow}
        />
      ),
    },
  ];
};
