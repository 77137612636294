import React, {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {Link} from 'react-router-dom';
import {decryptData} from 'utils/rememberMe';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import {InputGroup, Form, Row, Col, Spinner} from 'react-bootstrap';
import {
  StyledPasswordEyeContainer,
  StyledErrorLabel,
  StyledLabel,
  StyledPrimaryButton,
} from 'styles/styled-components/StyledComponents';
import {REMEMBER} from 'constants/login';
import {EMAIL_REGEX} from 'constants/regularExpression';
import {useTranslation} from 'react-i18next';

const LoginForm = (props) => {
  const {isLoading, onSubmit} = props;
  const [formData, setFormData] = useState({email: '', password: '', remember: false});
  const [showPassword, setShowPassword] = useState(false);

  const {t} = useTranslation();

  const {
    setValue,
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    mode: 'onChange',
    defaultValues: formData,
  });

  useEffect(() => {
    const encryptedData = localStorage.getItem(REMEMBER);

    if (encryptedData) {
      const decryptedData = decryptData(encryptedData);
      const data = {
        email: decryptedData.email,
        password: decryptedData.password,
        remember: true,
      };
      setValue('email', data.email);
      setValue('password', data.password);
      setValue('remember', data.remember);
      setFormData(data);
    }
  }, []);

  return (
    <div className="login-form">
      <div className="w-100">
        <h2 className="mb-1">{t('Welcome')}!</h2>
        <span className="login-msg d-block mb-4">{t(`VALA member ! Let's Start...`)}</span>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3 position-relative" controlId="email">
            <StyledLabel>
              {t('Email')}
              <span className="required" />
            </StyledLabel>
            <Form.Control
              type="email"
              className="mb-3"
              placeholder={t('Enter your email')}
              autoComplete="off"
              defaultValue={formData.email}
              {...register('email', {
                required: t('Email is required'),
                pattern: {
                  value: EMAIL_REGEX,
                  message: t('Invalid email address'),
                },
              })}
            />
            {errors.email && <StyledErrorLabel>{errors.email.message}</StyledErrorLabel>}
          </Form.Group>

          <Form.Group className="mb-2 position-relative" controlId="password">
            <StyledLabel>
              {t('Password')}
              <span className="required" />
            </StyledLabel>
            <InputGroup className="mb-3">
              <Form.Control
                className="border-end-0"
                type={showPassword ? 'text' : 'password'}
                placeholder={t('Enter your password')}
                autoComplete="off"
                defaultValue={formData.password}
                {...register('password', {
                  required: t('Password is required'),
                  minLength: {
                    value: 6,
                    message: t('Password must be at least 6 characters'),
                  },
                })}
              />
              <StyledPasswordEyeContainer onClick={() => setShowPassword(!showPassword)}>
                <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
              </StyledPasswordEyeContainer>
            </InputGroup>
            {errors.password && <StyledErrorLabel>{errors.password.message}</StyledErrorLabel>}
          </Form.Group>
          <Row className="mb-5">
            <Col className="d-flex justify-content-between">
              <Form.Check
                type={'checkbox'}
                id={`login-check`}
                name={`remember`}
                label={t(`Remember me`)}
                {...register('remember')}
              />
              <Link to={`/auth/forgot-password`} className="login-link" variant="body2">
                {t('Forgot Password?')}
              </Link>
            </Col>
          </Row>
          <StyledPrimaryButton
            type="submit"
            variant="primary"
            className="w-100"
            disabled={isLoading || Object.keys(errors).length > 0}
          >
            {isLoading ? <Spinner animation="border" /> : t(`OTP`)}
          </StyledPrimaryButton>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
