export const ROLE_ACCESS = {
  CREATE: 'create',
  EDIT: 'edit',
  READ: 'read',
  DELETE: 'delete',
};

export const ADMIN_ROLE = 'admin';
export const TSN_USER = 'tsnUser';
export const TENANT = 'tenant';
export const SUB_TENANT = 'subTenant';

export const CHECKBOX_DISABLED = ['Dashboard', 'System Notification'];
