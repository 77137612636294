import React from 'react';
import Action from 'components/datatable/Action';
import SwitchButton from 'components/SwitchButton';
import { symbols } from 'utils/symbols';

export const notificationThresholdListColumn = (filters, handleEdit, t) => {
  return [
    {
      name: t('Serial No'),
      selector: (row) => row.serial_no,
      cell: (row, index) => {
        return (filters.pageNumber - 1) * filters.pageSize + index + 1;
      },
    },
    {
      name: t('Parameter'),
      alias: 'perameter',
      selector: (row) => row.perameter,
      sortable: true,
      sortFunction: (a, b) => a.perameter?.toLowerCase().localeCompare(b.perameter?.toLowerCase()),
    },
    {
      name: t('Threshold Value'),
      alias: 'thresholdValue',
      selector: (row) => {
        const symbol = symbols(row.condition)
        const unit = row.thresholdUnit ? row.thresholdUnit : ''
        return `${symbol} ${row.thresholdValue} ${unit}`
      },
    },
    {
      name: t('Severity Type'),
      alias: 'severityType',
      selector: (row) => row.severityType,
      sortable: true,
      sortFunction: (a, b) => a.severityType?.toLowerCase().localeCompare(b.severityType?.toLowerCase()),
    },
    {
      name: t('Notification Channel'),
      alias: 'notificationChannel',
      selector: (row) => row.notificationChannel,
    },
    {
      name: t('Notification Frequency'),
      alias: 'notificationFrequency',
      selector: (row) => row.notificationFrequency,
    },
    {
      name: t('Status'),
      alias: 'status',
      selector: (row) => (row.status ? 'Active' : 'Inactive'),
      sortFunction: (a, b) => {
        const statusA = a.status ? 1 : 0;
        const statusB = b.status ? 1 : 0;

        if (statusA < statusB) {
          return -1;
        }
        if (statusA > statusB) {
          return 1;
        }
        return 0;
      },
      sortable: true,
    },
    {
      name: t('Action'),
      button: true,
      cell: (row) => <Action row={row} handleEdit={handleEdit} />,
    },
  ];
};
