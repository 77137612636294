import api from 'api/axios';
import {formatFilter} from 'utils/formatFilter';

export const fetchStepper = async (tenantId) => {
  try {
    const response = await api.get(`${process.env.REACT_APP_API_URL}/tenant-step/${tenantId}`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const manageStepper = async (payload) => {
  try {
    const response = await api.post(`${process.env.REACT_APP_API_URL}/tenant-step`, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchTenants = async (filters) => {
  try {
    const filterParams = formatFilter(filters);
    // Make an API request with the filter values
    const response = await api.get(`${process.env.REACT_APP_API_URL}/tenant`, {
      params: filterParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchTenantById = async (tenantId) => {
  try {
    // Make an API request with the filter values
    const response = await api.get(`${process.env.REACT_APP_API_URL}/tenant/${tenantId}`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchTenantCsvContent = async (filters) => {
  try {
    const filterParams = formatFilter(filters);
    const response = await api.get(`${process.env.REACT_APP_API_URL}/tenant/export`, {
      params: filterParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addTenant = async (payload) => {
  try {
    const response = await api.post(`${process.env.REACT_APP_API_URL}/tenant`, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editTenant = async (id, payload) => {
  try {
    const response = await api.put(`${process.env.REACT_APP_API_URL}/tenant/${id}`, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteTenant = async (tenantId) => {
  try {
    const response = await api.delete(`${process.env.REACT_APP_API_URL}/tenant/${tenantId}`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

// Tenant Role API
export const fetchTenantRoles = async (tenantId, filters) => {
  try {
    const filterParams = formatFilter(filters);
    // Make an API request with the filter values
    const response = await api.get(`${process.env.REACT_APP_API_URL}/tenant/role/${tenantId}/all`, {
      params: filterParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchTenantRoleCsvContent = async (tenantId, filters) => {
  try {
    const filterParams = formatFilter(filters);
    const response = await api.get(`${process.env.REACT_APP_API_URL}/tenant/role/${tenantId}/export`, {
      params: filterParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addTenantRole = async (payload) => {
  try {
    const response = await api.post(`${process.env.REACT_APP_API_URL}/tenant/role`, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editTenantRole = async (id, payload) => {
  try {
    const response = await api.put(`${process.env.REACT_APP_API_URL}/tenant/role/${id}`, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteTenantRole = async (roleId, payload) => {
  try {
    const response = await api.delete(`${process.env.REACT_APP_API_URL}/tenant/role/${roleId}`, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

// Tenant User API

export const fetchTenantRolesByName = async (tenantId) => {
  try {
    const response = await api.get(`${process.env.REACT_APP_API_URL}/tenant/role/name?tenantId=${tenantId}`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchTenantUsers = async (tenantId, filters) => {
  try {
    const filterParams = formatFilter(filters);

    const response = await api.get(`${process.env.REACT_APP_API_URL}/tenant/user/${tenantId}/all`, {
      params: filterParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchTenantUserCsvContent = async (tenantId, filters) => {
  try {
    const filterParams = formatFilter(filters);

    const response = await api.get(`${process.env.REACT_APP_API_URL}/tenant/user/${tenantId}/export`, {
      params: filterParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addTenantUser = async (payload) => {
  try {
    const response = await api.post(`${process.env.REACT_APP_API_URL}/tenant/user`, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editTenantUser = async (id, payload) => {
  try {
    const response = await api.put(`${process.env.REACT_APP_API_URL}/tenant/user/${id}`, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteTenantUser = async (id, payload) => {
  try {
    const response = await api.delete(`${process.env.REACT_APP_API_URL}/tenant/user/${id}`, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

// Device APIS

export const fetchTenantDevices = async (tenantId, filters) => {
  try {
    const filterParams = formatFilter(filters);

    const response = await api.get(`${process.env.REACT_APP_API_URL}/device/${tenantId}/all`, {
      params: filterParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchTenantMasterDevices = async (tenantId) => {
  try {
    const response = await api.get(`${process.env.REACT_APP_API_URL}/device/master-device?tenantId=${tenantId}`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchTenantDeviceType = async () => {
  try {
    const response = await api.get(`${process.env.REACT_APP_API_URL}/notification/device/type`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchTenantSubDevices = async (deviceTypeId) => {
  try {
    const response = await api.get(`${process.env.REACT_APP_API_URL}/device/sub-type?deviceTypeId=${deviceTypeId}`);

    return response.data;
  } catch (error) {
    throw error;
  }
};


export const addTenantDevice = async (payload) => {
  try {
    const response = await api.post(`${process.env.REACT_APP_API_URL}/device`, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editTenantDevice = async (deviceId, payload) => {
  try {
    const response = await api.put(`/device/${deviceId}`, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteTenantDevice = async (id, payload) => {
  try {
    const response = await api.delete(`/device/${id}`, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getRegisteredDevice = async (deviceId, tenantId) => {
  try {
    const response = await api.get(`/device/${deviceId}?tenantId=${tenantId}`);

    return response.data;
  } catch (error) {
    throw error;
  }
}

export const getTagName = async () => {
  try {
    const response = await api.get(`/notification/device/tag`);

    return response.data;
  } catch (error) {
    throw error;
  }
}

export const fetchTenantDeviceCsvContent = async (tenantId, filters) => {
  try {
    const filterParams = formatFilter(filters);
    const response = await api.get(`/device/${tenantId}/export`, {
      params: filterParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

// Setting
export const fetchTenantRegisteredDevices = async (tenantId) => {
  try {
    const response = await api.get(`/device/${tenantId}/registered-device`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchRegisteredSubTenants = async (tenantId) => {
  try {
    const response = await api.get(`tenant/${tenantId}/registered-tenant`);

    return response.data;
  } catch (error) {
    throw error;
  }
}

export const addDeviceToSubTenant = async (tenantId, payload) => {
  try {
    const response = await api.post(`/device/${tenantId}/sub-tenant`, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editDeviceToSubTenant = async (tenantId, payload) => {
  try {
    const response = await api.put(`/device/${tenantId}/sub-tenant`, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};


export const fetchSubTenants = async (tenantId, filters) => {
  try {
    const filterParams = formatFilter(filters);
    
    const response = await api.get(`device/${tenantId}/sub-tenant`, {
      params: filterParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
}

export const fetchSubTenantDeviceCsvContent = async (tenantId, filters) => {
  try {
    const filterParams = formatFilter(filters);
    const response = await api.get(`/device/${tenantId}/sub-tenant/export`, {
      params: filterParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteSubTenant = async (id, payload) => {
  try {
    const response = await api.delete(`/device/${id}/sub-tenant`, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};