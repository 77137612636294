import React, {useState} from 'react';
import {toast} from 'react-toastify';
import {useForm} from 'react-hook-form';
import {Form, Spinner} from 'react-bootstrap';
import {forgotPassword} from 'features/auth/authApi';
import CustomToast from 'components/Toast/CustomToast';
import {StyledPrimaryButton, StyledErrorLabel, StyledLabel} from 'styles/styled-components/StyledComponents';
import {EMAIL_REGEX} from 'constants/regularExpression';
import {useTranslation} from 'react-i18next';

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    mode: 'onChange',
  });

  const {t} = useTranslation();

  const handleForgotPasswordSubmit = async (data) => {
    try {
      setIsLoading(true);
      const {email} = data;
      const {message} = await forgotPassword({email});
      toast(<CustomToast type="success" title="Success" message={message} />);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-form">
      <div className="w-100">
        <h2 className="mb-1">{t(`Forgot Password`)}</h2>
        <span className="login-msg d-block mb-4">
          {t(`Enter your email address / phone number and we'll send you a link to reset your password`)}
        </span>
        <form onSubmit={handleSubmit(handleForgotPasswordSubmit)}>
          <Form.Group className="mb-4 position-relative" controlId="email">
            <StyledLabel>
              {t('Email')}
              <span className="required" />
            </StyledLabel>
            <Form.Control
              type="email"
              name="email"
              className="mb-3"
              placeholder={t("Email")}
              autoComplete="off"
              {...register('email', {
                required: t('Email is required'),
                pattern: {
                  value: EMAIL_REGEX,
                  message: t('Invalid email address'),
                },
              })}
            />
            {errors.email && <StyledErrorLabel>{errors.email.message}</StyledErrorLabel>}
          </Form.Group>
          <StyledPrimaryButton
            type="submit"
            variant="primary"
            className="w-100"
            disabled={isLoading || Object.keys(errors).length > 0}
          >
            {isLoading ? <Spinner animation="border" /> : t(`Reset Password`)}
          </StyledPrimaryButton>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
