import * as echarts from 'echarts/core';

export const getCalendarLunarChartOptions = (data, colors, month) => {
  const initialValue = colors.length > 0 ? colors[0].startValue : 0;
  const lastValue = colors.length > 0 ? colors[colors.length - 1].startValue : 100;
  
  const lunarCalendar = {
    visualMap: {
      min: initialValue,
      max: lastValue,
      calculable: false,
      show: true,
      orient: 'horizontal',
      left: 'center',
      bottom: '20%',
    },
    calendar: [
      {
        left: 'center',
        top: 50,
        cellSize: [160, 60],
        yearLabel: {show: false},
        orient: 'vertical',
        dayLabel: {
          firstDay: 1,
          nameMap: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        },
        monthLabel: {
          show: false,
        },
        range: month,
      },
    ],
    series: [
      {
        type: 'scatter',
        coordinateSystem: 'calendar',
        symbolSize: 0,
        label: {
          show: true,
          formatter: function (params) {
            var d = echarts.number.parseDate(params.value[0]);
            return '{a|' + d.getDate() + '}\n\n{b|' + params.value[1].toFixed(2) + '}';
          },
          rich: {
            a: {
              align: 'left',
              verticalAlign: 'top',
              padding: [0, 0, 0, -50],
              fontSize: 12,
              fontWeight: 700,
            },
            b: {
              align: 'center',
              fontSize: 14,
              fontWeight: 700,
            }
          }
        },
        data: data.map((item) => [item.name, item.averageValue]),
        silent: true,
      },
      {
        type: 'scatter',
        coordinateSystem: 'calendar',
        symbolSize: 0,
        label: {
          show: true,
          formatter: function (params) {
            return '';
          },
          fontSize: 14,
          fontWeight: 700,
          color: '#a00',
        },
        data: data.map((item) => [item.name, item.averageValue]),
        silent: true,
      },
      {
        type: 'heatmap',
        coordinateSystem: 'calendar',
        data: data.map((item) => [item.name, item.averageValue]),
      },
    ],
  };

  if (colors.length > 0) {
    const pieces = colors.map((condition) => {
      return {
        min: condition.startValue,
        max: condition.endValue,
        color: condition.colorCode,
      };
    });
    pieces[pieces.length - 1].max = Infinity;

    lunarCalendar.visualMap.pieces = pieces;
    lunarCalendar.visualMap.selectedMode = false;
  } else {
    lunarCalendar.visualMap.color = ['red', 'yellow', 'green'];
  }

  return lunarCalendar;
};
