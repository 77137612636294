import React, {useState, useEffect, useRef, useMemo} from 'react';
import {Outlet} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import Sidebar from 'components/layout/Sidebar';
import Navbar from 'components/layout/Navbar';
import NotificationSideBar from 'components/layout/NotificationSideBar';
import {setDeviceType} from 'features/app/appSlice';
import {DESKTOP, I_PAD, I_PAD_LANDSCAPE, MOBILE, TABLET} from 'constants/app';

const Layout = () => {
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);
  const [mainContainerHeight, setMainContainerHeight] = useState(0);
  const mainContainerRef = useRef(null);
  const headerRef = useRef(null);
  const notificationFilterRef = useRef(null);

  const app = useSelector((state) => state.app);

  const dispatch = useDispatch();

  const handleResize = () => {
    const width = window.innerWidth;

    if (width <= 480) {
      dispatch(setDeviceType(MOBILE));
    } else if (width <= 768) {
      dispatch(setDeviceType(TABLET));
    } else if (width <= 1024) {
      dispatch(setDeviceType(I_PAD));
    } else if (width <= 1200) {
      dispatch(setDeviceType(I_PAD_LANDSCAPE));
    } else {
      dispatch(setDeviceType(DESKTOP));
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dispatch]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === mainContainerRef.current) {
          const notificationFilterHeight = notificationFilterRef.current
            ? notificationFilterRef.current.clientHeight
            : 0;
          const headerHeight = headerRef.current ? headerRef.current.clientHeight : 0;
          const adjustHeight = entry.contentRect.height - notificationFilterHeight - headerHeight - 150;
          setMainContainerHeight(adjustHeight);
        }
      }
    });

    if (mainContainerRef.current) {
      resizeObserver.observe(mainContainerRef.current);
      setMainContainerHeight(mainContainerRef.current.clientHeight);
    }

    return () => {
      if (mainContainerRef.current) {
        resizeObserver.unobserve(mainContainerRef.current);
      }
    };
  }, []);

  const memoizedMainContainerHeight = useMemo(() => mainContainerHeight, [mainContainerHeight]);

  const handleOpen = () => setIsNotificationVisible(!isNotificationVisible);

  return (
    <>
      <div className="d-flex h-100">
        <Sidebar
          isNotificationVisible={isNotificationVisible}
          mainContainerRef={mainContainerRef}
          setIsNotificationVisible={setIsNotificationVisible}
        />
        <div className="w-100 content overflow-hidden">
          <Navbar handleOpen={handleOpen} headerRef={headerRef} />
          <div className="content-wrapper d-flex flex-row">
            <div className={`w-100 body-wrapper ${isNotificationVisible ? 'show' : 'hide'}`}>
              <Outlet />
            </div>
            {isNotificationVisible && app.deviceType === DESKTOP ? (
              <NotificationSideBar
                isNotificationVisible={isNotificationVisible}
                mainContainerHeight={memoizedMainContainerHeight}
                notificationFilterRef={notificationFilterRef}
                setIsNotificationVisible={setIsNotificationVisible}
              />
            ) : null}
          </div>
        </div>
      </div>
      {app.deviceType != DESKTOP  ? (
        <NotificationSideBar
          isNotificationVisible={isNotificationVisible}
          mainContainerHeight={memoizedMainContainerHeight}
          notificationFilterRef={notificationFilterRef}
          setIsNotificationVisible={setIsNotificationVisible}
        />
      ) : null}
    </>
  );
};

export default React.memo(Layout);
