import React from 'react';
import {useTranslation} from 'react-i18next';
import { StyledErrorLabel } from 'styles/styled-components/StyledComponents';

const DeviceSensor = ({allDeviceTypes, deviceId, deviceType, handleDeviceType}) => {
  const {t} = useTranslation();

  return (
    <>
      <div className="row">
        <div className="col  mb-4">
          <div className="tanent-card-sec  mb-2">
            {allDeviceTypes && allDeviceTypes.map((device) => {
              return (
                <div
                  key={device._id}
                  className={`tanent-card me-3 ${device._id === deviceType?._id ? `active` : ''}`}
                  onClick={() => (!deviceId ? handleDeviceType(device) : null)}
                >
                  <img className="me-1" src={`/icons/${device.name}.svg`} alt="Noice Sensor" />
                  {device.displayName}
                </div>
              );
            })}
          </div>
          {!deviceType && <StyledErrorLabel>{t('Please select any device')}</StyledErrorLabel>}
        </div>
      </div>
    </>
  );
};

export default DeviceSensor;
