import React from 'react';
import ReactECharts from 'echarts-for-react'; // Please do not remove this, it has to import before ReactEChartsCore.
import * as echarts from 'echarts/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import LoadingSpinner from 'components/LoadingSpinner';
import NoRecordFound from 'components/NoRecordFound';
import {getHeatChartOptions} from 'utils/echarts/heatChartOptions';
import {EChartContainer} from 'styles/styled-components/StyledComponents';

const DashboardTrendChart = (props) => {
  const {
    groupType,
    chartTitle,
    last7DaysTrend,
    loading,
    allParameters,
    deviceParameterName,
    handleTrendSubDeviceType,
  } = props;
  return (
    <>
      <div className="row mb-4 mongo-chart">
        <div className="col-12 col-md-12">
          <EChartContainer height={'400px'} className='dark-container'>
            <div className="p-2">
              <h5 className='dark-text'>{chartTitle}</h5>
            </div>
            {loading ? (
              <LoadingSpinner />
            ) : last7DaysTrend ? (
              <>
                <ReactEChartsCore
                  echarts={echarts}
                  option={getHeatChartOptions(last7DaysTrend)}
                  notMerge={true}
                  lazyUpdate={true}
                  style={{height: '400px'}}
                />
              </>
            ) : (
              <>
                <NoRecordFound />
              </>
            )}
          </EChartContainer>
        </div>
      </div>
      <div className="row paramter-container">
        <div className="col mb-4">
          <div className="sub-tanent-card-sec mb-2 dark-container">
            {allParameters.map((allSubDeviceType) => {
              return (
                <div
                  key={allSubDeviceType._id}
                  className={`sub-tanent-card me-3 dark-text ${deviceParameterName === allSubDeviceType.name ? 'active' : ''}`}
                  onClick={() => handleTrendSubDeviceType(allSubDeviceType, groupType)}
                >
                  {allSubDeviceType.displayName === 'PM2_5' ? 'PM2.5' : allSubDeviceType.displayName}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardTrendChart;
