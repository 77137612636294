import React from 'react';
import {Form, Button} from 'react-bootstrap';
import {PrimaryButton, StyledLabel} from 'styles/styled-components/StyledComponents';
import downloadImg from 'assets/images/svg/download.svg';
import addImg from 'assets/images/svg/add-white.svg';
import resetFilterImg from 'assets/images/svg/refresh-icon.svg';
import {useTranslation} from 'react-i18next';

const UserListFilter = (props) => {
  const {
    filters,
    roleTypes,
    isTenantManagePage,
    setShowUserModal,
    handleFilterChange,
    handleFilter,
    handleClearFilter,
    handleDownloadCSV,
  } = props;

  const {t} = useTranslation();

  return (
    <>
      <div className="filter-card">
        <div className="row">
          <div className="col d-flex justify-content-between align-items-end flex-column flex-md-row">
            <div className="d-flex flex-wrap align-items-end mob-100 w-100">
              <Form.Group className="me-0 me-md-2 mb-3 mob-100 flex-fill" controlId="filterName">
                <StyledLabel>{t('User')}</StyledLabel>
                <Form.Control
                  type="text"
                  name="filterName"
                  placeholder={t('Enter User')}
                  autoComplete="off"
                  value={filters.filterName}
                  onChange={handleFilterChange}
                />
              </Form.Group>
              <Form.Group className="me-0 me-md-2 mb-3 mob-100 flex-fill" controlId="roleType">
                <StyledLabel>{t('Role Type')}</StyledLabel>
                <Form.Select name="roleType" value={filters.roleType} onChange={handleFilterChange}>
                  <option value="all">All</option>
                  {roleTypes.map((roleType) => (
                    <option key={roleType._id} value={roleType.name}>
                      {roleType.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="me-0 me-md-2 mb-3 mob-100 flex-fill" controlId="email">
                <StyledLabel>{t('Email ID')}</StyledLabel>
                <Form.Control
                  type="text"
                  name="email"
                  placeholder={t('Enter Email ID')}
                  autoComplete="off"
                  value={filters.email}
                  onChange={handleFilterChange}
                />
              </Form.Group>
              <Form.Group className="me-0 me-md-2 mb-3 mob-100 flex-fill" controlId="contact">
                <StyledLabel>{t('Phone Number')}</StyledLabel>
                <Form.Control
                  type="text"
                  name="contact"
                  placeholder={t('Enter Phone Number')}
                  autoComplete="off"
                  value={filters.contact}
                  onChange={handleFilterChange}
                />
              </Form.Group>
              <Form.Group className="me-0 me-md-2 mb-3 mob-100" controlId="status">
                <StyledLabel>{t('Status')}</StyledLabel>
                <Form.Select name="status" value={filters.status} onChange={handleFilterChange}>
                  <option value="all">All</option>
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </Form.Select>
              </Form.Group>
              <PrimaryButton variant="outline-primary" className="apply me-2 mb-3" onClick={handleFilter}>
                {t('Apply')}
              </PrimaryButton>
              <Button variant="outline-primary" className="mb-3" type="button" onClick={handleClearFilter}>
                <img src={resetFilterImg} alt="Filter" />
              </Button>
              <div className="ms-5">
                {isTenantManagePage && (
                  <Button
                    variant="primary"
                    className="mb-3 me-2 wrap-adjust effect-btn"
                    type="button"
                    onClick={() => setShowUserModal(true)}
                  >
                    <img className="me-1" src={addImg} alt="plus" />
                    {t('Add User')}
                  </Button>
                )}
                <Button variant="outline-primary" className="mb-3" type="button" onClick={handleDownloadCSV}>
                  <img src={downloadImg} alt="download" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserListFilter;
