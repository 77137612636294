import React from 'react';
import moment from 'moment';
import { DAILY, MONTHLY, REPORT_DATE_FORMATE, REPORT_DATE_TIME_FORMATE } from 'constants/reports';

export const group2_1ParametersColumn = (parameters, reportType) => {
  const dynamicColumns = [
    {
      name: 'Date',
      alias: 'date',
      selector: (row) => {
        return reportType === DAILY
          ? row.date
          : reportType === MONTHLY
          ? moment(row.date).format(REPORT_DATE_FORMATE)
          : moment(row.date).format(REPORT_DATE_TIME_FORMATE);
      },
    },
  ];

  parameters.forEach((param) => {
    let columnName = '';
    let unit = '';
    switch (param) {
      case 'Wind Speed':
        columnName = 'Wind Speed (m/s)';
        unit = 'm/s';
        break;
      case 'Pressure':
        columnName = 'Pressure (hPa)';
        unit = 'hPa';
        break;
      case 'Pressure OB':
        columnName = 'Pressure OB (hPa)';
        unit = 'hPa';
        break;
      case 'Temperature':
        columnName = 'Temperature (°C)';
        unit = '°C';
        break;
      case 'Wind Direction':
        columnName = 'Wind Direction (°)';
        unit = '°';
        break;
      default:
        break;
    }

    dynamicColumns.push({
      name: columnName,
      alias: param.toLowerCase().replace(' ', '_'),
      selector: (row) => {
        return <span>{row[`${param}_averageValue`]}</span>;
      },
      conditionalCellStyles: [
        {
          when: (row) => row[`${param}_colorCode`],
          style: (row) => {
            return {
              backgroundColor: row[`${param}_colorCode`],
              color: row[`${param}_colorCode`] === '#FFFF00' ? '#000000' : '#FFFFFF',
              '&:hover': {
                cursor: 'pointer',
              },
            };
          },
        },
      ],
    });
  });

  return dynamicColumns;
};
