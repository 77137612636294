import React from 'react';
import viewImg from 'assets/images/svg/view.svg';
import editImg from 'assets/images/svg/edit.svg';
import deleteImg from 'assets/images/svg/delete.svg';
import settingImg from 'assets/images/svg/setting.svg';
import {Image} from 'react-bootstrap';

const Action = React.memo((props) => {
  const {
    row,
    handleEdit,
    handleDelete,
    handleView = null,
    handleSetting = null,
    isEditAllow = false,
    isDeleteAllow = false,
  } = props;

  return (
    <div>
      {handleView && (
        <button className="action-btn" onClick={() => handleView(row)}>
          <Image src={viewImg} fluid />
        </button>
      )}
      <button className="action-btn" onClick={() => handleEdit(row)} disabled={isEditAllow}>
        <Image src={editImg} fluid />
      </button>
      {handleDelete && (
        <button className="action-btn" onClick={() => handleDelete(row)} disabled={isDeleteAllow}>
          <Image src={deleteImg} fluid />
        </button>
      )}
      {handleSetting && (
        <button className="action-btn" onClick={() => handleSetting(row)}>
          <Image src={settingImg} fluid />
        </button>
      )}
    </div>
  );
});

export default Action;
