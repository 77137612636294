import React from 'react';
import {Link} from 'react-router-dom';
import Action from 'components/datatable/Action';
import { SUBSCRIBER } from 'constants/reports';

export const reportListColumn = (filters, isEditAllow, isDeleteAllow, handleView, handleEdit, handleDelete, t) => {
  return [
    {
      name: t('Serial No'),
      selector: (row) => row.serial_no,
      cell: (row, index) => {
        return (filters.pageNumber - 1) * filters.pageSize + index + 1;
      },
    },
    {
      name: t('Report'),
      alias: 'report',
      selector: (row) => row.report,
      sortable: true,
      sortFunction: (a, b) => a.report?.toLowerCase().localeCompare(b.report?.toLowerCase()),
    },
    {
      name: t('Report Type'),
      alias: 'reportType',
      selector: (row) => row.reportType,
      sortable: true,
      sortFunction: (a, b) => a.reportType?.toLowerCase().localeCompare(b.reportType?.toLowerCase()),
    },
    {
      name: t('Area'),
      alias: 'area',
      selector: (row) => row.area,
      sortable: true,
      sortFunction: (a, b) => a.area?.toLowerCase().localeCompare(b.area?.toLowerCase()),
    },
    {
      name: t('Report Name'),
      alias: 'name',
      selector: (row) => row.name,
      sortable: true,
      sortFunction: (a, b) => a.name?.toLowerCase().localeCompare(b.name?.toLowerCase()),
    },
    {
      name: t('Manage Subscribers'),
      alias: 'manageSubscribers',
      selector: (row) => (
        <>
          <Link to={`/app/reports/${SUBSCRIBER}/${row._id}`}>
            {t('Subscribers')}
          </Link>
        </>
      ),
      sortable: false,
    },
    {
      name: t('Status'),
      alias: 'status',
      selector: (row) => (row.status ? 'Active' : 'Inactive'),
      sortFunction: (a, b) => {
        const statusA = a.status ? 1 : 0;
        const statusB = b.status ? 1 : 0;

        if (statusA < statusB) {
          return -1;
        }
        if (statusA > statusB) {
          return 1;
        }
        return 0;
      },
      sortable: true,
    },
    {
      name: t('Action'),
      button: true,
      cell: (row) => (
        <Action
          row={row}
          isEditAllow={isEditAllow}
          isDeleteAllow={isDeleteAllow}
          handleView={handleView}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
        />
      ),
    },
  ];
};
