import React, {useEffect, useState, useRef, useMemo} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import CustomToast from 'components/Toast/CustomToast';
import ManageDevices from 'components/tenantStepper/Device/ManageDevices';
import DeviceListFilter from './Device/DeviceListFilter';
import {useStateAsync} from 'hooks/useStateAsync';
import useGetTableList from 'hooks/table/useGetTableList';
import {PAGE_DISPLAY} from 'constants/datatable';
import CustomDataTable from 'components/datatable';
import {conditionalRowStyles} from 'utils/tableRowsColor';
import {deleteTenantDevice, fetchTenantDeviceCsvContent, fetchTenantDevices} from 'features/tenant/tenantApi';
import {deviceListColumn} from 'components/datatable/columns/deviceListColumn';
import {useCustomEffect} from 'hooks/useCustomEffect';
import {downloadFile} from 'utils/downloadFile';
import {SOMETHING_WENT_ERROR} from 'constants/apiNetwork';
import CustomModal from 'components/shared/CustomModal';
import DeleteModal from 'components/modal/DeleteModal';
import {useTranslation} from 'react-i18next';

const ManageDeviceTab = (props) => {
  const {tenantId} = props;
  const [isSubmit, setIsSubmit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [isShowAddDevice, setIsShowAddDevice] = useState(false);
  const [filters, setFilters] = useStateAsync({
    deviceId: '',
    deviceName: '',
    deviceType: '',
    manufacturerName: '',
    tagId: '',
    status: '',
    key: '',
    value: '',
    pageSize: PAGE_DISPLAY[0], // per page data
    pageNumber: 1, // current page number
  });
  const numberOfPagesRef = useRef(1);
  const applyFilterRef = useRef(false);

  const {t} = useTranslation();

  const {deviceId} = useParams();
  const navigate = useNavigate();

  const {tableData, isLoading, getTablelist, handleSort, handlePageChange, handleRowsPerPageChange} = useGetTableList(
    fetchTenantDevices,
    numberOfPagesRef,
    setFilters,
    applyFilterRef,
    tenantId,
  );

  useEffect(() => {
    if (deviceId) {
      setIsShowAddDevice(true);
    } else {
      setIsShowAddDevice(false);
    }
  }, [deviceId]);

  useEffect(() => {
    if (!isShowAddDevice) {
      navigate(`/app/tenants/manage/${tenantId}?tab=4`);
    }
  }, [isShowAddDevice]);

  useCustomEffect(() => {
    setIsShowAddDevice(false);
    getTablelist(filters);
  }, []);

  const handleEdit = (row) => {
    setIsShowAddDevice(true);
    navigate(`/app/tenants/manage/${tenantId}/${row._id}?tab=4`);
  };

  const handleDelete = (row) => {
    setShowDelete(true);
    setSelectedDevice(row);
  };

  const handleDeleteCloseModal = () => {
    setShowDelete(false);
    setSelectedDevice(null);
  };

  const handleDeleteDevice = async () => {
    try {
      setIsSubmit(true);
      const {_id} = selectedDevice;
      const tenant = {
        data: {tenantId: tenantId},
      };
      const {message} = await deleteTenantDevice(_id, tenant);
      toast(<CustomToast type="success" title="Success" message={message} />);
      await getTablelist(filters);
      handleDeleteCloseModal();
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setIsSubmit(false);
    }
  };

  const handleSetting = (row) => {
    navigate(`/app/tenants/setting/${tenantId}/${row._id}`, {state: {from: `/app/tenants/manage/${tenantId}/${row._id}?tab=4`}});
  };

  const handleFilter = () => {
    applyFilterRef.current = true;
    setFilters(
      (prevFilters) => ({
        deviceId: prevFilters.deviceId,
        deviceName: prevFilters.deviceName,
        deviceType: prevFilters.deviceType,
        manufacturerName: prevFilters.manufacturerName,
        tagId: prevFilters.tagId,
        status: prevFilters.status === 'all' ? '' : prevFilters.status,
        key: '',
        value: '',
        pageSize: prevFilters.pageSize,
        pageNumber: 1,
      }),
      (newValue) => {
        getTablelist(newValue);
      },
    );
  };

  const handleFilterChange = (event) => {
    const {name, value} = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleClearFilter = () => {
    applyFilterRef.current = false;
    setFilters(
      (prevFilters) => ({
        ...prevFilters,
        deviceId: '',
        deviceName: '',
        deviceType: '',
        manufacturerName: '',
        tagId: '',
        status: '',
        key: '',
        value: '',
        pageSize: prevFilters.pageSize,
        pageNumber: 1,
      }),
      (newValue) => {
        getTablelist(newValue);
      },
    );
  };

  const handleDownloadCSV = async () => {
    try {
      const downloadWithFilter = {};
      if (applyFilterRef.current) {
        downloadWithFilter['deviceId'] = filters.deviceId;
        downloadWithFilter['deviceName'] = filters.deviceName;
        downloadWithFilter['deviceType'] = filters.deviceType;
        downloadWithFilter['manufacturerName'] = filters.manufacturerName;
        downloadWithFilter['tagId'] = filters.tagId;
        downloadWithFilter['status'] = filters.status;
        downloadWithFilter['key'] = filters.key;
        downloadWithFilter['value'] = filters.value;
      }
      const csvData = await fetchTenantDeviceCsvContent(tenantId, downloadWithFilter);
      const currentDate = new Date().toLocaleString();
      downloadFile(csvData, `tsn-tenant-device-${currentDate}.csv`);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={t(SOMETHING_WENT_ERROR)} />);
    }
  };

  const columns = useMemo(
    () => deviceListColumn(filters, handleEdit, handleDelete, handleSetting, t),
    [filters, handleEdit, handleDelete, handleSetting, t],
  );

  return (
    <>
      {isShowAddDevice ? (
        <ManageDevices
          tenantId={tenantId}
          deviceId={deviceId}
          getTablelist={getTablelist}
          filters={filters}
          setIsShowAddDevice={setIsShowAddDevice}
        />
      ) : (
        <>
          <DeviceListFilter
            filters={filters}
            handleFilterChange={handleFilterChange}
            handleFilter={handleFilter}
            handleClearFilter={handleClearFilter}
            handleDownloadCSV={handleDownloadCSV}
            setIsShowAddDevice={setIsShowAddDevice}
          />
          <CustomDataTable
            columns={columns}
            data={tableData}
            loading={isLoading}
            handleSort={handleSort}
            pagination={true}
            numberOfPages={numberOfPagesRef.current}
            rowPerPage={filters.pageSize}
            conditionalRowStyles={conditionalRowStyles}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
          />
          {showDelete && (
            <CustomModal
              size="sm"
              show={showDelete}
              handleClose={handleDeleteCloseModal}
              body={<DeleteModal deletedItem={selectedDevice && selectedDevice.deviceId} />}
              buttons={[
                {label: t('Yes'), type: 'primary', disabled: isSubmit, onClickHandler: handleDeleteDevice},
                {label: t('No'), type: 'secondary', disabled: isSubmit, onClickHandler: handleDeleteCloseModal},
              ]}
            />
          )}
        </>
      )}
    </>
  );
};

export default ManageDeviceTab;
