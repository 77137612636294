import {
  AQI,
  CO,
  CO2,
  Humidity,
  Humidity_OB,
  NO,
  NO2,
  Noise_LEQ,
  Noise_Max,
  O3,
  PM1,
  PM10,
  PM2_5,
  Pressure,
  Pressure_OB,
  SO2,
  Temperature,
  Temperature_OB,
  rainFall,
  windDirection,
  windSpeed,
} from 'constants/parameters';

export const formatParameterName = (inputArray) => {
  // Convert the input string to an array by splitting on commas
  const inputArrayElements = inputArray.split(',');

  // Define a mapping of original elements to desired output elements
  const mapping = {
    AQI: AQI.label,
    NO2: O3.label,
    CO: CO.label,
    CO2: CO2.label,
    NO: NO.label,
    O3: SO2.label,
    SO2: PM1.label,
    PM2_5: PM2_5.label,
    PM10: PM10.label,
    PM1: PM1.label,
    DB: NO2.label,
    humidity: Humidity.label,
    humidityOB: Humidity_OB.label,
    noiseLEQ: Noise_LEQ.label,
    noiseMax: Noise_Max.label,
    pressure: Pressure.label,
    pressureOB: Pressure_OB.label,
    temperature: Temperature.label,
    tempratureOB: Temperature_OB.label,
    windDirection: windDirection.label,
    windSpeed: windSpeed.label,
    rainFall: rainFall.label,
  };

  // Map the original elements to the desired output using the mapping
  const outputArray = inputArrayElements.map((element) => mapping[element]);

  // Remove any undefined elements
  const finalOutput = outputArray.filter((element) => element !== undefined);

  return finalOutput;
};
