import api from 'api/axios';
import {formatFilter} from 'utils/formatFilter';

export const fetchReports = async (filters) => {
  try {
    const filterParams = formatFilter(filters);
    // Make an API request with the filter values
    const response = await api.get(`/report`, {
      params: filterParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteReport = async (reportId) => {
  try {
    const response = await api.delete(`/report/${reportId}`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchReportCsvContent = async (filters) => {
  try {
    const filterParams = formatFilter(filters);
    const response = await api.get(`/report/export`, {
      params: filterParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAllParameters = async (filters) => {
  try {
    const filterParams = formatFilter(filters);
    const response = await api.get(`/report/device-parameter`, {
      params: filterParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchActiveDevices = async () => {
  try {
    const response = await api.get(`/report/device-list`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchParatersReport = async (filterParams) => {
  try {
    const response = await api.get(`/report/chart/average`, {
      params: filterParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchParatersChart = async (filterParams) => {
  try {
    const response = await api.get(`/report/chart/bar`, {
      params: filterParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchReportDetails = async (filterParams) => {
  try {
    const response = await api.get(`/report/chart/detail`, {
      params: filterParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchRoseChart = async (filterParams) => {
  try {
    const response = await api.get(`/report/chart/rose`, {
      params: filterParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const downloadPDFReport = async (payload) => {
  try {
    const response = await api.post(`/report/chart/email`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createReports = async (payload) => {
  try {
    const response = await api.post(`/report`, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editReports = async (reportId, payload) => {
  try {
    const response = await api.put(`/report/${reportId}`, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchReportById = async (reportId) => {
  try {
    const response = await api.get(`/report/${reportId}`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchSubscriberList = async (filters) => {
  try {
    const filterParams = formatFilter(filters);

    const response = await api.get(`/report/user`, {
      params: filterParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addSubscriber = async (payload) => {
  try {
    const response = await api.post(`/report/user`, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editSubscriber = async (subscriberId, payload) => {
  try {
    const response = await api.put(`/report/user/${subscriberId}`, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteSubscriber = async (subscriberId, params) => {
  try {
    const response = await api.delete(`/report/user/${subscriberId}`, {
      params: params,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
