import React, {useState} from 'react';
import {Form, Button} from 'react-bootstrap';
import {PrimaryButton, StyledLabel} from 'styles/styled-components/StyledComponents';
import downloadImg from 'assets/images/svg/download.svg';
import addImg from 'assets/images/svg/add-white.svg';
import resetFilterImg from 'assets/images/svg/refresh-icon.svg';
import {useCustomEffect} from 'hooks/useCustomEffect';
import {getTagName} from 'features/tenant/tenantApi';
import {useTranslation} from 'react-i18next';

const DeviceListFilter = (props) => {
  const {filters, handleFilterChange, handleFilter, handleClearFilter, handleDownloadCSV, setIsShowAddDevice} = props;
  const [tags, setTags] = useState([]);
  const {t} = useTranslation();

  useCustomEffect(() => {
    getTags();
  }, []);

  const getTags = async () => {
    const {data} = await getTagName();
    const tagsData = data ? data : [];
    setTags(tagsData);
  };

  return (
    <>
      <div className="filter-card">
        <div className="row">
          <div className="col d-flex justify-content-between align-items-end flex-column flex-md-row">
            <div className="d-flex flex-wrap align-items-end mob-100 w-100">
              <Form.Group className="me-0 me-md-2 mb-3 mob-100 flex-fill" controlId="deviceId">
                <StyledLabel>{t('Device ID')}</StyledLabel>
                <Form.Control
                  type="text"
                  name="deviceId"
                  placeholder={t("Enter Device ID")}
                  autoComplete="off"
                  value={filters.deviceId}
                  onChange={handleFilterChange}
                />
              </Form.Group>
              <Form.Group className="me-0 me-md-2 mb-3 mob-100 flex-fill" controlId="deviceName">
                <StyledLabel>{t('Device Name')}</StyledLabel>
                <Form.Control
                  type="text"
                  name="deviceName"
                  placeholder={t("Enter Device Name")}
                  autoComplete="off"
                  value={filters.deviceName}
                  onChange={handleFilterChange}
                />
              </Form.Group>
              <Form.Group className="me-0 me-md-2 mb-3 mob-100 flex-fill" controlId="deviceType">
                <StyledLabel>{t('Device Type')}</StyledLabel>
                <Form.Control
                  type="text"
                  name="deviceType"
                  placeholder={t("Enter Device Type")}
                  autoComplete="off"
                  value={filters.deviceType}
                  onChange={handleFilterChange}
                />
              </Form.Group>
              <Form.Group className="me-0 me-md-2 mb-3 mob-100 flex-fill" controlId="manufacturerName">
                <StyledLabel>{t('Manufacturer Name')}</StyledLabel>
                <Form.Control
                  type="text"
                  name="manufacturerName"
                  placeholder={t("Enter Manufacturer Name")}
                  autoComplete="off"
                  value={filters.manufacturerName}
                  onChange={handleFilterChange}
                />
              </Form.Group>
              <Form.Group className="me-0 me-md-2 mb-3 mob-100 flex-fill" controlId="tag">
                <StyledLabel>{t('Tag')}</StyledLabel>
                <Form.Select name="tagId" value={filters.tagId} onChange={handleFilterChange}>
                  <option value={''}>{t('Select Tag')}</option>
                  {tags.map((tag) => (
                    <option key={tag._id} value={tag._id}>
                      {tag.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="me-0 me-md-2 mb-3 mob-100" controlId="status">
                <StyledLabel>{t('Status')}</StyledLabel>
                <Form.Select name="status" value={filters.status} onChange={handleFilterChange}>
                  <option value="all">All</option>
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </Form.Select>
              </Form.Group>
              <PrimaryButton variant="outline-primary" className="apply me-2 mb-3" onClick={handleFilter}>
                {t('Apply')}
              </PrimaryButton>
              <Button variant="outline-primary" className="mb-3" type="button" onClick={handleClearFilter}>
                <img src={resetFilterImg} alt="Filter" />
              </Button>
              <div className="ms-5 mb-3">
                <Button
                  variant="primary"
                  className="mb-3 me-2 mb-xxl-0 effect-btn add-device-btn"
                  type="button"
                  onClick={() => setIsShowAddDevice(true)}
                >
                  <img className="me-1" src={addImg} alt="plus" />
                  {t('Add New Device')}
                </Button>
                <Button variant="outline-primary" className="mb-3 mb-xxl-0" type="button" onClick={handleDownloadCSV}>
                  <img src={downloadImg} alt="download" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeviceListFilter;
