import React, {useEffect, useState, useRef} from 'react';
import {useForm} from 'react-hook-form';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import Loader from 'assets/images/loader.gif';
import CustomToast from 'components/Toast/CustomToast';
import {Form, Button, Row, Col} from 'react-bootstrap';
import SwitchButton from 'components/SwitchButton';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import StreetMap from 'components/map';
import {StyledErrorLabel} from 'styles/styled-components/StyledComponents';
import {
  addTenantDevice,
  editTenantDevice,
  fetchTenantDeviceType,
  fetchTenantMasterDevices,
  fetchTenantSubDevices,
  getRegisteredDevice,
  getTagName,
} from 'features/tenant/tenantApi';
import DeviceSensor from 'components/tenantStepper/Device/DeviceSensor';
import DeviceSensorType from 'components/tenantStepper/Device/DeviceSensorType';
import {convertMinutesToHoursMinutes} from 'utils/convertMinutesToHoursMinutes';
import CustomModal from 'components/shared/CustomModal';
import DiscardAlert from 'components/modal/DiscardAlert';
import {useTranslation} from 'react-i18next';

const ManageDevices = (props) => {
  const {tenantId, deviceId, filters, getTablelist, setIsShowAddDevice} = props;
  const [startDate, setStartDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [allDevices, setAllDevices] = useState([]);
  const [allDeviceTypes, setAllDeviceTypes] = useState([]);
  const [allSubDeviceTypes, setAllSubDeviceTypes] = useState([]);
  const [deviceType, setDeviceType] = useState(null);
  const [selectedSubDeviceType, setSelectedSubDeviceType] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [editDevice, setEditDevice] = useState(null);
  const [transmissionFrequency, setTransmissionFrequency] = useState('');
  const [isDiscard, setIsDiscard] = useState(false);
  const [tags, setTags] = useState([]);

  const {t} = useTranslation();

  const scrollTargetRef = useRef(null);

  const navigate = useNavigate();
  const {
    setValue,
    register,
    handleSubmit,
    reset,
    formState: {errors, isDirty},
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      deviceType: '',
      deviceServices: [],
      deviceId: '',
      manufacturerName: '',
      installationDate: editDevice ? new Date(editDevice.installationDate) : new Date(),
      transmissionFrequency: '',
      status: true,
      deviceName: '',
      tagId: '',
      location: {
        lat: '',
        long: '',
      },
    },
  });

  useEffect(() => {
    if (editDevice) {
      setStartDate(new Date(editDevice.installationDate));
    }
  }, [editDevice]);

  useEffect(() => {
    (async () => {
      await getDeviceTypes();
      await getTags();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (deviceId) {
        await getDeviceById(deviceId);
      } else {
        await getDevices();
      }
    })();
  }, [deviceId]);

  useEffect(() => {
    setValue('deviceServices', selectedSubDeviceType);
  }, [selectedSubDeviceType]);

  useEffect(() => {
    (async () => {
      if (deviceType) {
        await getSubDevices(deviceType);
      }
    })();
  }, [deviceType]);

  const getTags = async () => {
    try {
      const {data} = await getTagName();
      const tagsData = data ? data : [];
      setTags(tagsData);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    }
  };

  const getDeviceById = async (deviceId) => {
    try {
      setIsLoading(true);
      await getTags();
      const {data} = await getRegisteredDevice(deviceId, tenantId);
      const subDeviceIds = data.subDeviceType.map((subDevice) => subDevice._id);
      setEditDevice(data);
      handleSelectDevice(data.masterDevice);
      setAllDevices([data.masterDevice]);
      setDeviceType(data.deviceType);
      setSelectedSubDeviceType(subDeviceIds);
      setValue('installationDate', data.installationDate);
      setValue('deviceName', data.deviceName);
      setValue('status', data.status);
      setValue('tagId', data.tagId);
      const frequency = convertMinutesToHoursMinutes(data.deviceType.transmissionFrequency);
      setTransmissionFrequency(frequency);
      // transmissionFrequency
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setIsLoading(false);
    }
  };

  const getSubDevices = async (deviceType) => {
    try {
      const {data} = await fetchTenantSubDevices(deviceType._id);
      const subDevices = data ? data : [];
      let activeSubDevices = [];
      if (deviceId) {
        activeSubDevices = selectedSubDeviceType;
      } else {
        activeSubDevices = subDevices.filter((subDevice) => subDevice.isDefault).map((item) => item._id);
      }
      setSelectedSubDeviceType(activeSubDevices);
      setAllSubDeviceTypes(subDevices);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    }
  };

  const getDevices = async () => {
    try {
      const {data} = await fetchTenantMasterDevices(tenantId);
      const devices = data ? data : [];
      setAllDevices(devices);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    }
  };

  const getDeviceTypes = async () => {
    try {
      const {data} = await fetchTenantDeviceType();
      const deviceTypes = data ? data : [];
      setAllDeviceTypes(deviceTypes);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    }
  };

  const handleDeviceType = (deviceType) => {
    const frequency = convertMinutesToHoursMinutes(deviceType.transmissionFrequency);
    setTransmissionFrequency(frequency);
    setDeviceType(deviceType);
  };

  const handleSubDeviceType = (subDeviceType) => {
    const isAlreadySelected = selectedSubDeviceType.includes(subDeviceType._id);
    if (isAlreadySelected) {
      if (!subDeviceType.isDefault) {
        const subType = selectedSubDeviceType.filter(function (item) {
          return item !== subDeviceType._id;
        });
        setSelectedSubDeviceType(subType);
      }
    } else {
      setSelectedSubDeviceType((prevSubDevice) => [subDeviceType._id, ...prevSubDevice]);
    }
  };

  const handleSelectDevice = (device) => {
    setSelectedDevice(device);
    setValue('deviceId', device.deviceId);
    setValue('manufacturerName', device.manufacturerName);
    setValue('location.lat', device.location.lat);
    setValue('location.long', device.location.long);
    if (scrollTargetRef.current) {
      scrollTargetRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  };

  const handleSaveDevice = async (data) => {
    try {
      setIsLoading(true);
      const {installationDate} = data;
      const date = new Date(installationDate);
      const formPayload = {
        masterDeviceListId: selectedDevice._id,
        deviceTypeId: deviceType._id,
        deviceSubTypeId: data.deviceServices,
        installationDate: moment(date).format('yyyy-MM-DD'),
        status: data.status,
        deviceName: data.deviceName,
        tenantId: tenantId,
        tagId: data.tagId,
      };

      if (deviceId) {
        const {message} = await editTenantDevice(deviceId, formPayload);
        toast(<CustomToast type="success" title="Success" message={message} />);
        getTablelist(filters);
        setIsShowAddDevice(false);
        navigate(`/app/tenants/manage/${tenantId}`);
      } else {
        const {message} = await addTenantDevice(formPayload);
        toast(<CustomToast type="success" title="Success" message={message} />);
        setSelectedDevice(null);
        setDeviceType(null);
        setAllSubDeviceTypes([]);
        setSelectedSubDeviceType([]);
        reset((formValues) => ({
          deviceType: '',
          deviceServices: [],
          deviceId: '',
          manufacturerName: '',
          installationDate: new Date(),
          transmissionFrequency: '',
          status: true,
          deviceName: '',
          tagId: '',
          location: {
            lat: '',
            long: '',
          },
        }));
        await getDevices();
      }
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseButton = () => {
    const isFormChange = deviceId
      ? isDirty
      : Boolean(selectedDevice) || Boolean(deviceType) || Boolean(selectedSubDeviceType.length) || isDirty;

    if (isFormChange) {
      setIsDiscard(true);
    } else {
      handleCancel();
    }
  };

  const hanldeDiscardAllModal = () => {
    setIsDiscard(false);
    handleCancel();
  };

  const hanldeDiscard = () => {
    setIsDiscard(false);
  };

  const handleCancel = () => {
    setIsShowAddDevice(false);
    getTablelist(filters);
    navigate(`/app/tenants/manage/${tenantId}`);
  };

  const isDisabled = isLoading || Object.keys(errors).length > 0 || !deviceType || selectedSubDeviceType.length === 0;

  return (
    <>
      {isLoading ? (
        <div className="loader-warp">
          <img src={Loader} alt="loader" />
        </div>
      ) : (
        <>
          <div className="card">
            <div className="card-body">
              <div className="tanent-device-tab">
                {/* map section starts*/}
                <div className="row">
                  <div className="col">
                    <div className="map-wrap mb-4">
                      <StreetMap
                        devices={allDevices}
                        deviceId={deviceId}
                        selectedDevice={selectedDevice}
                        handleSelectDevice={handleSelectDevice}
                      />
                    </div>
                  </div>
                </div>
                {/* map section ends*/}
                <div ref={scrollTargetRef} />
                {/* tanent card section start*/}
                <DeviceSensor
                  allDeviceTypes={allDeviceTypes}
                  deviceId={deviceId}
                  deviceType={deviceType}
                  handleDeviceType={handleDeviceType}
                />
                {/* tanent card section ends*/}
                {/* sub tanent card section starts*/}
                {allSubDeviceTypes.length > 0 && (
                  <DeviceSensorType
                    allSubDeviceTypes={allSubDeviceTypes}
                    selectedSubDeviceType={selectedSubDeviceType}
                    deviceId={deviceId}
                    handleSubDeviceType={handleSubDeviceType}
                  />
                )}
                {/* sub tanent card section ends*/}
                {/* Add devices section starts*/}
                <div className="row user-form">
                  <div className="col">
                    <h4 className="section-subheading mb-3">{t('Add Devices')}</h4>
                    <Row className="">
                      <Col md="12" lg="6" className="">
                        <Row>
                          <Col>
                            <Form.Group className="mb-3 position-relative" controlId="deviceId">
                              <Form.Label>
                                {t('Device ID')}
                                <span className="required" />
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className="mb-2"
                                autoComplete="off"
                                placeholder={t("Enter Device ID")}
                                disabled
                                {...register('deviceId', {
                                  required: t('Device Id is required'),
                                })}
                              />
                              {errors.deviceId && <StyledErrorLabel>{errors.deviceId.message}</StyledErrorLabel>}
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Group className="mb-3 position-relative" controlId="manufacturerName">
                              <Form.Label>
                                {t('Manufacturer Name')}
                                <span className="required" />
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className="mb-2"
                                autoComplete="off"
                                placeholder={t("Enter Manufacturer Name")}
                                disabled
                                {...register('manufacturerName', {
                                  required: t('Manufacturer Name is required'),
                                })}
                              />
                              {errors.manufacturerName && (
                                <StyledErrorLabel>{errors.manufacturerName.message}</StyledErrorLabel>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="mb-3 position-relative">
                            <Form.Group className="" controlId="deviceName">
                              <Form.Label>
                                {t('Installation Date')}
                                <span className="required" />
                              </Form.Label>
                            </Form.Group>
                            <div className="d-flex justify-content-between w-100">
                              <DatePicker
                                className="me-2"
                                dateFormat={'yyyy-MM-dd'}
                                selected={startDate}
                                maxDate={new Date()}
                                onChange={(date) => {
                                  setStartDate(date);
                                  setValue('installationDate', date);
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Group className="mb-3 position-relative" controlId="transFrequency">
                              <Form.Label>
                                {t('Transmission Frequency')}
                                <span className="required" />
                              </Form.Label>
                              <div className="d-flex justify-content-between">
                                <Form.Control
                                  type="text"
                                  className="mb-2"
                                  autoComplete="off"
                                  defaultValue={transmissionFrequency}
                                  placeholder={t("Enter Transmission Frequency")}
                                  disabled
                                />
                                {errors.transmissionFrequency && (
                                  <StyledErrorLabel>{errors.transmissionFrequency.message}</StyledErrorLabel>
                                )}
                              </div>
                              {errors.transmissionFrequency && (
                                <StyledErrorLabel>{errors.transmissionFrequency.message}</StyledErrorLabel>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                      <Col md="12" lg="6" className="">
                        <Row>
                          <Col>
                            <Form.Group className="mb-3 position-relative" controlId="status">
                              <Form.Label>
                                {t('Status')}
                                <span className="required" />
                              </Form.Label>
                              <div className="d-flex">
                                <SwitchButton
                                  leftLabel={'Active'}
                                  rightLabel={'Inactive'}
                                  isDisableInactive={Boolean(editDevice)}
                                  value={editDevice ? editDevice.status : true}
                                  handleSetValue={(value) => setValue('status', value)}
                                />
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <Form.Group className="mb-3 position-relative" controlId="deviceName">
                              <Form.Label>
                                {t('Device Name')}
                                <span className="required" />
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className="mb-2"
                                autoComplete="off"
                                placeholder={t("Enter Device Name")}
                                {...register('deviceName', {
                                  required: t('Device Name is required'),
                                })}
                              />
                              {errors.deviceName && <StyledErrorLabel>{errors.deviceName.message}</StyledErrorLabel>}
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Group className="mb-3 position-relative" controlId="location">
                              <Form.Label>
                                {t('Location')}
                                <span className="required" />
                              </Form.Label>
                              <div className="d-flex justify-content-between mb-2">
                                <Form.Control
                                  className="me-2"
                                  type="text"
                                  autoComplete="off"
                                  placeholder={t("Latitude")}
                                  disabled
                                  {...register('location.lat', {
                                    required: t('Location is required'),
                                  })}
                                />
                                <Form.Control
                                  className="me-2"
                                  type="text"
                                  autoComplete="off"
                                  placeholder={t("Longitude")}
                                  disabled
                                  {...register('location.long', {
                                    required: t('Location is required'),
                                  })}
                                />
                              </div>
                              {errors.location?.lat && (
                                <StyledErrorLabel>{errors.location?.lat.message}</StyledErrorLabel>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Group className="mb-3 position-relative" controlId="transFrequency">
                              <Form.Label>
                                {t('Tag')}
                                <span className="required" />
                              </Form.Label>
                              <div className="d-flex justify-content-between">
                                <Form.Select
                                  className="me-2 mb-2"
                                  aria-label="tag"
                                  {...register('tagId', {
                                    required: t('Tag is required'),
                                  })}
                                >
                                  <option value={''}>{t('Select Tag')}</option>
                                  {tags.map((tag) => (
                                    <option key={tag._id} value={tag._id}>
                                      {tag.name}
                                    </option>
                                  ))}
                                </Form.Select>
                              </div>
                              {errors.tagId && <StyledErrorLabel>{errors.tagId.message}</StyledErrorLabel>}
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xxl-12 d-flex justify-content-lg-end">
                        <Button variant="outline-primary" className="me-2" type="button" onClick={handleCloseButton}>
                          {t('Cancel')}
                        </Button>
                        <Button
                          variant="primary"
                          className=""
                          type="button"
                          disabled={isDisabled}
                          onClick={handleSubmit(handleSaveDevice)}
                        >
                          {t('Submit')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Add devices section ends*/}
              </div>
            </div>
          </div>
          {isDiscard && (
            <CustomModal
              size="sm"
              show={isDiscard}
              handleClose={hanldeDiscard}
              body={<DiscardAlert />}
              buttons={[
                {label: t('Yes'), type: 'primary', onClickHandler: hanldeDiscardAllModal},
                {label: t('No'), type: 'secondary', onClickHandler: hanldeDiscard},
              ]}
            />
          )}
        </>
      )}
    </>
  );
};

export default ManageDevices;
