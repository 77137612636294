import React, {useEffect, useMemo, useState, useRef} from 'react';
import {useParams, useNavigate, useSearchParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Button} from 'react-bootstrap';
import html2PDF from 'jspdf-html2canvas';
import Loader from 'assets/images/loader.gif';
import CustomToast from 'components/Toast/CustomToast';
import reportlogoImg from 'assets/images/svg/vala-logo.svg';
import CustomDataTable from 'components/datatable';
import {conditionalRowStyles} from 'utils/tableRowsColor';
import {parametersColumn} from 'components/datatable/columns/parametersColumn';
import {
  downloadPDFReport,
  fetchParatersChart,
  fetchParatersReport,
  fetchReportDetails,
  fetchRoseChart,
} from 'features/reports/reportsApi';
import {group1ParametersColumn} from 'components/datatable/columns/group1ParametersColumn';
import {group2ParametersColumn} from 'components/datatable/columns/group2ParametersColumn';
import {group2_1ParametersColumn} from 'components/datatable/columns/group2_1ParametersColumn';
import FloatingColor from 'components/FloatingColor';
import {calculateWindRose} from 'utils/roseDataFormat';
import ChartColorContainer from 'pages/ReportsPage/ChartColorContainer';
import {DAILY, DEVICE_VALUE, GRAPH_PER_PAGE, MONTHLY, WEEKLY} from 'constants/reports';
import moment from 'moment';
import {REPORTS} from 'constants/routesName';
import ReportCharts from 'components/reports/ReportCharts';
import {decryptData} from 'utils/rememberMe';
import {createPdfOptions} from 'utils/createPdfOptions';
import {formatParameterName} from 'utils/formatParameterName';
import {GROUP_1_GASES, GROUP_2_METROLOGICALES_1, GROUP_2_METROLOGICALES_2} from 'constants/parameters';
import {getParameterInChunks} from 'utils/getParameterInChunks';
import DailyReports from 'components/reports/DailyReports';
import MonthlyReports from 'components/reports/MonthlyReports';
import WeeklyReports from 'components/reports/WeeklyReports';
import {useTranslation} from 'react-i18next';
import MonthlyCalendarChart from 'components/reports/MonthlyCalendarChart';

const ViewReport = () => {
  const [isLoading, setIsLoading] = useState({
    report: false,
    parameter: false,
    parametersChart: false,
    isDownloadPdf: false,
  });
  const [report, setReport] = useState(null);
  const [parameters, setParameters] = useState([]);
  const [parametersChart, setParametersChart] = useState([]);
  const [calendarChart, setcalendarChart] = useState([]);
  const [aqiAvarage, setAqiAvarage] = useState(null);
  const [rose, setRose] = useState([]);
  const [isLoadingRose, setIsLoadingRose] = useState(false);
  const [trendGroup1ParameterParametersChart, setTrendGroup1ParameterParametersChart] = useState([]);
  const [trendGroup2ParameterParametersChart, setTrendGroup2ParameterParametersChart] = useState([]);
  const [trendGroup2_1ParameterParametersChart, setTrendGroup2_1ParameterParametersChart] = useState([]);
  const [parameterNames, setParameterNames] = useState({
    gasParameter: [],
    meteroLogical1: [],
    meteroLogical2: [],
  });
  const [page, setPage] = useState({
    group1: 6,
    group2_1: 7,
    group2_2: 8,
  });

  const {t} = useTranslation();

  const pdfRef = useRef(null);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const {reportId} = useParams();

  const reportDetails = searchParams.get('report') ? searchParams.get('report') : null;

  useEffect(() => {
    (async () => {
      await getReportById(reportId);
    })();
  }, []);

  useEffect(() => {
    if (report) {
      getParameterReports();
      getParamertChart();
      getRoseGraphData();
    }
  }, [report]);

  useEffect(() => {
    if (parametersChart.length > 0) {
      const parameters = getParameterInChunks(parametersChart, GRAPH_PER_PAGE);
      setPage((prevSate) => ({
        ...prevSate,
        group1: parameters.length + 3,
        // group2_1: parameters.length + 4,
        // group2_2: parameters.length + 5,
      }));
    }
  }, [parametersChart]);

  useEffect(() => {
    if (trendGroup1ParameterParametersChart.length > 0) {
      const parameters = getParameterInChunks(trendGroup1ParameterParametersChart, 30);
      setPage((prevSate) => ({
        ...prevSate,
        // group1: parameters.length + 3,
        group2_1: parameters.length + page.group1,
        // group2_2: parameters.length + 5,
      }));
    }
  }, [trendGroup1ParameterParametersChart]);

  useEffect(() => {
    if (trendGroup2ParameterParametersChart.length > 0) {
      const parameters = getParameterInChunks(trendGroup2ParameterParametersChart, 35);
      setPage((prevSate) => ({
        ...prevSate,
        // group1: parameters.length + 3,
        // group2_1: parameters.length + page.group1,
        group2_2: parameters.length + parameters.length + page.group2_1,
      }));
    }
  }, [trendGroup2ParameterParametersChart]);

  const getReportById = async (reportId) => {
    try {
      setIsLoading((prevSate) => ({
        ...prevSate,
        report: true,
      }));
      let payload;
      if (reportId) {
        payload = {
          id: reportId,
        };
      } else if (reportDetails) {
        const decReportDetails = decryptReportData(reportDetails);
        if (!decReportDetails) {
          toast(<CustomToast type="error" title="Error" message={'URL is tempered or invalid URL'} />);
          navigate(REPORTS);
          return;
        }
        const parameter = decReportDetails.parameters;
        payload = {
          reportType: decReportDetails.reportType,
          area: decReportDetails.area,
          parameter: parameter,
        };
        if (decReportDetails.area === DEVICE_VALUE) {
          payload.deviceId = decReportDetails.device._id;
        }
      } else {
        toast(<CustomToast type="error" title="Error" message={'Invalid route'} />);
        navigate(REPORTS);
        return;
      }
      const {data} = await fetchReportDetails(payload);
      setReport(data);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setIsLoading((prevSate) => ({
        ...prevSate,
        report: false,
      }));
    }
  };

  const decryptReportData = (reportDetails) => {
    try {
      const data = JSON.parse(decryptData(reportDetails));
      return data;
    } catch (error) {
      return null;
    }
  };

  const getParameterReports = async () => {
    try {
      setIsLoading((prevSate) => ({
        ...prevSate,
        parameter: true,
      }));
      let payload;
      if (reportId) {
        payload = {
          id: reportId,
        };
      } else {
        payload = {
          parameter: report.parameter,
          area: report.area,
          reportType: report.reportType,
        };
        if (report.area === DEVICE_VALUE) {
          payload.deviceId = report.deviceId;
        }
      }
      const {data} = await fetchParatersReport(payload);
      const dailyOverview = data ? data.filter((parameter) => !['Wind Direction'].includes(parameter.displayName)) : [];
      setParameters(dailyOverview);
      const aqi = dailyOverview.find((parameter) => parameter.key === 'AQI');
      setAqiAvarage(aqi ? aqi.averageValue : null);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setIsLoading((prevSate) => ({
        ...prevSate,
        parameter: false,
      }));
    }
  };

  const getParamertChart = async () => {
    try {
      setIsLoading((prevSate) => ({
        ...prevSate,
        parametersChart: true,
      }));
      let payload;
      if (reportId) {
        payload = {
          id: reportId,
        };
      } else {
        payload = {
          parameter: report.parameter,
          area: report.area,
          reportType: report.reportType,
        };
        if (report.area === DEVICE_VALUE) {
          payload.deviceId = report.deviceId;
        }
      }

      const {data: parameterData} = await fetchParatersChart(payload);
      const data = parameterData ? parameterData : [];
      const allParameterData = data
        .filter((parameter) => !['Wind Direction'].includes(Object.keys(parameter)[0]))
        .sort((a, b) => Object.keys(a)[0].localeCompare(Object.keys(b)[0]));
      setParametersChart(allParameterData);
      // get AQI and PM2.5 for calendar
      const filteredCharts = allParameterData.filter(
        (item) => item.hasOwnProperty('AQI') || item.hasOwnProperty('PM2.5'),
      );
      setcalendarChart(filteredCharts);
      const group1Data = data.filter((params) => GROUP_1_GASES.includes(Object.keys(params)[0]));
      const group2Data = data.filter((params) => GROUP_2_METROLOGICALES_1.includes(Object.keys(params)[0]));
      const group2_1Data = data.filter((params) => GROUP_2_METROLOGICALES_2.includes(Object.keys(params)[0]));

      // get parameter name
      const gasParameterName = group1Data.map((group1) => Object.keys(group1)[0]);
      const meteroLogical1ParameterName = group2Data.map((group1) => Object.keys(group1)[0]);
      const meteroLogical2ParameterName = group2_1Data.map((group1) => Object.keys(group1)[0]);

      const gasesData = await formatParameterData(group1Data);
      const metroData = await formatParameterData(group2Data);
      const metro1Data = await formatParameterData(group2_1Data);

      setParameterNames((prevSate) => ({
        ...prevSate,
        gasParameter: gasParameterName.sort(),
        meteroLogical1: meteroLogical1ParameterName.sort(),
        meteroLogical2: meteroLogical2ParameterName.sort(),
      }));
      setTrendGroup1ParameterParametersChart(gasesData);
      setTrendGroup2ParameterParametersChart(metroData);
      setTrendGroup2_1ParameterParametersChart(metro1Data);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setIsLoading((prevSate) => ({
        ...prevSate,
        parametersChart: false,
      }));
    }
  };

  const formatParameterData = (formattedData) => {
    var resultArray = [];
    if (formattedData.length > 0) {
      var keys = Object.keys(formattedData[0]);
      for (let i = 0; i < formattedData[0][keys[0]].length; i++) {
        var mergedObject = {
          date: null,
        };

        formattedData.forEach((obj) => {
          var key = Object.keys(obj)[0];
          var item = obj[key][i];

          for (const prop in item) {
            if (!mergedObject.date) {
              mergedObject.date = item['name'];
            }
            mergedObject[`${key}_${prop}`] = item[prop];
          }
        });

        resultArray.push(mergedObject);
      }
    }

    return resultArray;
  };

  const getRoseGraphData = async () => {
    try {
      setIsLoadingRose(true);
      setRose([]);
      let payload;
      if (reportId) {
        payload = {
          id: reportId,
        };
      } else {
        payload = {
          reportType: report.reportType,
          area: report.area,
          parameter: report.parameter,
        };
        if (report.area === DEVICE_VALUE) {
          payload.deviceId = report.deviceId;
        }
      }
      const {data: roseData} = await fetchRoseChart(payload);
      let calculateWindRoseData = [];
      if (roseData) {
        calculateWindRoseData = calculateWindRose({
          direction: roseData.WindDirection,
          speed: roseData.WindSpeed,
        });
      }
      setRose(calculateWindRoseData);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setIsLoadingRose(false);
    }
  };

  const handleDownloadReport = async () => {
    try {
      toast(<CustomToast type="success" title="Preparing" message={'Preparing report for download.'} />);
      setIsLoading((prevSate) => ({
        ...prevSate,
        isDownloadPdf: true,
      }));
      const element = pdfRef.current;
      const pages = document.getElementsByClassName('page');
      const pdfOptions = createPdfOptions('generate.pdf');
      await html2PDF(pages, {
        ...pdfOptions,
        success: function (pdf) {
          const pdfBlob = pdf.output('blob');
          const fileName = `${report?.name ? `${report?.name}-` : ``}${report.reportType}-${moment(
            report?.startTime,
          ).format('DD-MMM-YYYY')}-to-${moment(report?.startTime).format('DD-MMM-YYYY')}`;
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(pdfBlob);
          downloadLink.download = fileName; //'report.pdf';
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
          toast(<CustomToast type="success" title="Success" message={'Report downloaded successfully.'} />);
        },
      });
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setIsLoading((prevSate) => ({
        ...prevSate,
        isDownloadPdf: false,
      }));
    }
  };

  const handleEmailReport = async () => {
    try {
      toast(<CustomToast type="success" title="Preparing" message={'Preparing report for email.'} />);
      setIsLoading((prevSate) => ({
        ...prevSate,
        isDownloadPdf: true,
      }));
      const element = pdfRef.current;
      const pages = document.getElementsByClassName('page');
      const pdfOptions = createPdfOptions('generate.pdf');
      await html2PDF(pages, {
        ...pdfOptions,
        success: async function (pdf) {
          const pdfBlob = pdf.output('blob');
          const fileName = `${report?.name ? `${report?.name}-` : ``}${report.reportType}-${moment(
            report?.startTime,
          ).format('DD-MMM-YYYY')}-to-${moment(report?.startTime).format('DD-MMM-YYYY')}`;
          const formData = new FormData();
          formData.append('pdf', pdfBlob, `${fileName}.pdf`);
          const {message} = await downloadPDFReport(formData);
          toast(<CustomToast type="success" title="Success" message={message} />);
        },
      });
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setIsLoading((prevSate) => ({
        ...prevSate,
        isDownloadPdf: false,
      }));
    }
  };

  const handleBufferReport = async () => {
    try {
      const pages = document.getElementsByClassName('page');
      const pdfOptions = createPdfOptions('generate.pdf');
      const pdf = await html2PDF(pages, {
        ...pdfOptions,
        success: async function (pdf) {
          console.log('Inside ------------>');
        },
      });
      let base64String = pdf.output('dataurlstring');
      return base64String;
    } catch (error) {
      console.error('Error generating PDF:', error);
      return null;
    }
  };

  const reportType = report?.reportType.charAt(0).toUpperCase() + report?.reportType.slice(1);
  const area = report?.area.charAt(0).toUpperCase() + report?.area.slice(1);
  const areaDetail = report?.areaDetail.charAt(0).toUpperCase() + report?.areaDetail.slice(1);

  const parameterColumns = useMemo(() => parametersColumn(), []);
  const trend1ParameterColumns = useMemo(
    () => group1ParametersColumn(parameterNames.gasParameter, reportType),
    [parameterNames.gasParameter, reportType],
  );
  const trend2ParameterColumns = useMemo(
    () => group2ParametersColumn(parameterNames.meteroLogical1, reportType),
    [parameterNames.meteroLogical1, reportType],
  );
  const trend2_1ParameterColumns = useMemo(
    () => group2_1ParametersColumn(parameterNames.meteroLogical2, reportType),
    [parameterNames.meteroLogical2, reportType],
  );

  const isDisabled =
    !isLoading.isDownloadPdf && !isLoading.report && !isLoading.parametersChart && !isLoading.parameter;

  window.handleBufferReport = handleBufferReport;
  window.isDisabled = isDisabled;

  const reportTitle = `${report?.reportType} report`;

  return (
    <>
      <div ref={pdfRef} className="container py-4">
        <div className="page page-1">
          {isLoading.report ? (
            <>
              <div className="loader-warp">
                <img src={Loader} alt="loader" />
              </div>
            </>
          ) : (
            <>
              <div className="report-head mb-5">
                <div className="row">
                  <div className="col-12 d-flex justify-content-between align-items-center">
                    <div className="report-logo">
                      <img src={reportlogoImg} alt="Logo" />
                    </div>
                    <h1>{`${t(reportTitle)} ${report?.name ? `- (${report.name})` : ''}`}</h1>
                    <div></div>
                  </div>
                </div>
              </div>
              <div className="report-parameter mb-4">
                <div className="row">
                  <div className="col-12 col-md-6 d-flex mb-3">
                    <span className="label">{t('Time Form')}: </span>
                    <span className="reading">{moment(report?.startTime).format('DD-MMM-YYYY hh:mm A')}</span>
                  </div>
                  <div className="col-12 col-md-6 d-flex mb-3">
                    <span className="label">{t('Area')}: </span>
                    <span className="reading">{area ? area : ''}</span>
                  </div>
                  <div className="col-12 col-md-6 d-flex mb-3">
                    <span className="label">{t('Time To')}: </span>
                    <span className="reading">{moment(report?.endTime).format('DD-MMM-YYYY hh:mm A')}</span>
                  </div>
                  <div className="col-12 col-md-6 d-flex mb-3">
                    <span className="label">{t('Area Details')}: </span>
                    <span className="reading">{areaDetail ? areaDetail : ''}</span>
                  </div>
                  <div className="col-12 col-md-12 d-flex mb-3">
                    <span className="label">{t('Parameter')}:</span>
                    <span className="reading"> {report ? formatParameterName(report.parameter).join(', ') : ''}</span>
                  </div>
                </div>
              </div>
            </>
          )}
          {aqiAvarage && (
            <div className="card rounded-1 mt-3">
              <div className="card-body">
                <div className="d-flex">
                  <span className="label">{t('Average AQI')} </span>
                  <span className="reading">: {aqiAvarage}</span>
                </div>
              </div>
            </div>
          )}
          <div className="card rounded-1 mt-3">
            <div className="card-body">
              <div>{`${reportType ? t(`${reportType} Overview`) : ''}`}</div>
              {isLoading.parameter ? (
                <>
                  <div className="loader-warp">
                    <img src={Loader} alt="loader" />
                  </div>
                </>
              ) : (
                <CustomDataTable
                  columns={parameterColumns}
                  data={parameters}
                  loading={false}
                  pagination={false}
                  conditionalRowStyles={conditionalRowStyles}
                />
              )}
            </div>
          </div>
        </div>
        {reportType === MONTHLY && (
          <>
            {isLoading.parametersChart ? (
              <>
                <div className="loader-warp">
                  <img src={Loader} alt="loader" />
                </div>
              </>
            ) : (
              <>{<MonthlyCalendarChart report={report} calendarChart={calendarChart} />}</>
            )}
          </>
        )}

        <div className="mb-4 mongo-chart">
          {isLoading.parametersChart ? (
            <>
              <div className="loader-warp">
                <img src={Loader} alt="loader" />
              </div>
            </>
          ) : (
            <>
              <ReportCharts
                parametersChart={parametersChart}
                reportType={reportType}
                rose={rose}
                isLoadingRose={isLoadingRose}
              />
              {trendGroup1ParameterParametersChart.length === 0 &&
                trendGroup2ParameterParametersChart.length === 0 &&
                trendGroup2_1ParameterParametersChart.length === 0 && (
                  <div className="mt-3">
                    <ChartColorContainer />
                  </div>
                )}
            </>
          )}
        </div>

        {reportType === DAILY && (
          <DailyReports
            page={page}
            reportType={reportType}
            isLoading={isLoading}
            trend1ParameterColumns={trend1ParameterColumns}
            trend2ParameterColumns={trend2ParameterColumns}
            trend2_1ParameterColumns={trend2_1ParameterColumns}
            trendGroup1ParameterParametersChart={trendGroup1ParameterParametersChart}
            trendGroup2ParameterParametersChart={trendGroup2ParameterParametersChart}
            trendGroup2_1ParameterParametersChart={trendGroup2_1ParameterParametersChart}
          />
        )}
        {reportType === WEEKLY && (
          <WeeklyReports
            page={page}
            reportType={reportType}
            isLoading={isLoading}
            trend1ParameterColumns={trend1ParameterColumns}
            trend2ParameterColumns={trend2ParameterColumns}
            trend2_1ParameterColumns={trend2_1ParameterColumns}
            trendGroup1ParameterParametersChart={trendGroup1ParameterParametersChart}
            trendGroup2ParameterParametersChart={trendGroup2ParameterParametersChart}
            trendGroup2_1ParameterParametersChart={trendGroup2_1ParameterParametersChart}
          />
        )}
        {reportType === MONTHLY && (
          <MonthlyReports
            page={page}
            reportType={reportType}
            isLoading={isLoading}
            trend1ParameterColumns={trend1ParameterColumns}
            trend2ParameterColumns={trend2ParameterColumns}
            trend2_1ParameterColumns={trend2_1ParameterColumns}
            trendGroup1ParameterParametersChart={trendGroup1ParameterParametersChart}
            trendGroup2ParameterParametersChart={trendGroup2ParameterParametersChart}
            trendGroup2_1ParameterParametersChart={trendGroup2_1ParameterParametersChart}
          />
        )}
      </div>
      <div className="container">
        <div className="row  mb-4">
          <div className="d-flex justify-content-end gap-2">
            <Button
              className="btn-primary d-flex align-items-center"
              variant=""
              type="button"
              disabled={!isDisabled}
              onClick={handleEmailReport}
            >
              {t('Send Email')}
            </Button>
            <Button
              className="btn-primary d-flex align-items-center"
              variant=""
              type="button"
              disabled={!isDisabled}
              onClick={handleDownloadReport}
            >
              {t('Download as PDF')}
            </Button>
          </div>
        </div>
      </div>
      <FloatingColor />
    </>
  );
};

export default ViewReport;
