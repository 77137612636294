import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import api from 'api/axios';

const initialState = {
  users: [],
  isLoading: false,
  error: null,
};

export const fetchUser = createAsyncThunk('user/fetch', async () => {
  try {
    const response = await api.get(process.env.REACT_APP_API_URL);

    return response;
  } catch (error) {
    return error;
  }
});

const userDetailSlice = createSlice({
  name: 'userDetail',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.users = action.payload.data;
    });
  },
});

export default userDetailSlice.reducer;
