import React, {useEffect, useState, useRef} from 'react';
import {Link, useLocation, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import CustomToast from 'components/Toast/CustomToast';
import Loader from 'assets/images/loader.gif';
import {useStateAsync} from 'hooks/useStateAsync';
import {PAGE_DISPLAY} from 'constants/datatable';
import useGetTableList from 'hooks/table/useGetTableList';
import leftarrowImg from 'assets/images/svg/left-arrow.svg';
import ManageReportForm from 'components/forms/ManageReportForm';
import {
  addSubscriber,
  deleteSubscriber,
  editSubscriber,
  fetchReportById,
  fetchSubscriberList,
} from 'features/reports/reportsApi';
import SubscriberList from './SubscriberList';
import ManageSubscriberModel from 'components/modal/ManageSubscriberModel';
import {SUBSCRIBER} from 'constants/reports';
import {useTranslation} from 'react-i18next';

const ManageReports = (props) => {
  const {action} = props;

  const {reportId} = useParams();

  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [showSubscriberModel, setShowSubscriberModel] = useState(false);
  const [selectedSubscriber, setSelectedSubscriber] = useState(null);
  const [filters, setFilters] = useStateAsync({
    reportId: reportId ? reportId : '',
    name: '',
    email: '',
    key: '',
    value: '',
    pageSize: PAGE_DISPLAY[0], // per page data
    pageNumber: 1, // current page number
  });

  const {t} = useTranslation();

  const numberOfPagesRef = useRef(1);
  const applyFilterRef = useRef(false);
  const scrollTargetRef = useRef(null);

  const location = useLocation();

  const reportDetails = location.state ? location.state.reportDetails : null;

  const {tableData, isLoading, getTablelist, handleSort, handlePageChange, handleRowsPerPageChange} = useGetTableList(
    fetchSubscriberList,
    numberOfPagesRef,
    setFilters,
    applyFilterRef,
  );

  useEffect(() => {
    (async () => {
      if (action) {
        scrollToSubscriber();
      }
    })();
  }, [action]);

  useEffect(() => {
    (async () => {
      if (reportId) {
        await getReportById(reportId);
      }
    })();
  }, [reportId]);

  const scrollToSubscriber = () => {
    if (scrollTargetRef.current) {
      scrollTargetRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  };

  const getReportById = async (reportId) => {
    try {
      setLoading(true);
      const {data} = await fetchReportById(reportId);
      setSelectedReport(data);
      setFilters(
        (prevFilters) => ({
          ...prevFilters,
          reportId: reportId,
        }),
        async (newValue) => {
          await getTablelist(newValue);
          scrollToSubscriber();
        },
      );
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = () => {
    const {name, value} = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleFilter = () => {
    applyFilterRef.current = true;
    setFilters(
      (prevFilters) => ({
        name: prevFilters.name,
        email: prevFilters.email,
        reportId: reportId,
        key: '',
        value: '',
        pageSize: prevFilters.pageSize,
        pageNumber: 1,
      }),
      (newValue) => {
        getTablelist(newValue);
      },
    );
  };

  const handleClearFilter = () => {
    applyFilterRef.current = false;
    setFilters(
      (prevFilters) => ({
        ...prevFilters,
        name: '',
        email: '',
        key: '',
        value: '',
        pageSize: prevFilters.pageSize,
        pageNumber: 1,
      }),
      (newValue) => {
        getTablelist(newValue);
      },
    );
  };

  const handleAddSubscriberModal = () => {
    setShowSubscriberModel(true);
  };

  const handleManageSubscriber = async (formData, action) => {
    try {
      setIsSubmit(true);
      if (action === 'add') {
        const {message} = await addSubscriber(formData);
        toast(<CustomToast type="success" title="Success" message={message} />);
      } else {
        const subscriberId = selectedSubscriber._id;
        const payload = formData[0];
        const {message} = await editSubscriber(subscriberId, payload);
        toast(<CustomToast type="success" title="Success" message={message} />);
        setSelectedSubscriber(null);
      }
      handleClearFilter();
      setShowSubscriberModel(false);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setIsSubmit(false);
    }
  };

  const handleSubscriptionDelete = async (subscriber) => {
    try {
      const {message} = await deleteSubscriber(subscriber._id, {reportId});
      getTablelist(filters);
      toast(<CustomToast type="success" title="Success" message={message} />);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    }
  };

  return (
    <>
      <div className="breadcrum-sec align-items-center breadcrum-sec d-flex justify-content-between flex-column flex-md-row">
        <div className="mob-100">
          <ul>
            <li>
              <Link to={`/app/dashboard`} className="breadcrum-link" variant="breadcrum">
                <img className="me-2" src={leftarrowImg} alt="Add" />
                {t('Dashboard')}
              </Link>
            </li>
            <li>
              <Link to={`/app/reports`} className="breadcrum-link" variant="breadcrum">
                <img className="me-2" src={leftarrowImg} alt="Add" />
                {t('Reports')}
              </Link>
            </li>
          </ul>
          <h2 className="page-title">{action === SUBSCRIBER ? t(`Subscribers`) : t(`Reports`)}</h2>
        </div>
      </div>
      <div className="card mb-3">
        <div className="card-body">
          <h4 className="section-subheading mb-3">{t('Manage Report')}</h4>
          {loading ? (
            <div className="loader-warp">
              <img src={Loader} alt="loader" />
            </div>
          ) : (
            <ManageReportForm
              reportId={reportId}
              reportDetails={reportDetails ? reportDetails : selectedReport}
              getReportById={getReportById}
            />
          )}
        </div>
      </div>

      {reportId && (
        <>
          <div className="card">
            <div className="card-body">
              <div ref={scrollTargetRef} />
              <SubscriberList
                isLoading={isLoading}
                subscribers={tableData}
                filters={filters}
                numberOfPagesRef={numberOfPagesRef}
                setSelectedSubscriber={setSelectedSubscriber}
                setShowSubscriberModel={setShowSubscriberModel}
                handlePageChange={handlePageChange}
                handleRowsPerPageChange={handleRowsPerPageChange}
                handleFilterChange={handleFilterChange}
                handleFilter={handleFilter}
                handleClearFilter={handleClearFilter}
                handleAddSubscriber={handleAddSubscriberModal}
                handleSubscriptionDelete={handleSubscriptionDelete}
              />
            </div>
          </div>
        </>
      )}
      {showSubscriberModel && (
        <ManageSubscriberModel
          reportId={reportId}
          show={showSubscriberModel}
          selectedSubscriber={selectedSubscriber}
          isSubmit={isSubmit}
          setSelectedSubscriber={setSelectedSubscriber}
          setShowSubscriberModel={setShowSubscriberModel}
          handleManageSubscriber={handleManageSubscriber}
        />
      )}
    </>
  );
};

export default ManageReports;
