import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Breadcrumbs from 'components/Breadcrumbs';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CustomToast from 'components/Toast/CustomToast';
import ManageNotificationForm from 'components/forms/ManageNotificationForm';
import NotificationThresholdSettings from 'components/forms/NotificationThresholdSettingsForm';
import { fetchNotificationsById } from 'features/notification/notificationApi';
import { useTranslation } from 'react-i18next';

const ManageNotification = () => {
  const [isNotificationUpdate, setIsNotificationUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const { notificationId } = useParams();
  const navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    if (notificationId) {
      getNotificationById(notificationId);
    }
  }, [notificationId]);

  const getNotificationById = async (notificationId) => {
    try {
      setIsLoading(true);
      const { data } = await fetchNotificationsById(notificationId);
      setSelectedNotification(data);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="breadcrum-sec align-items-center breadcrum-sec d-flex justify-content-between flex-column flex-md-row">
        <Breadcrumbs breadcrumbs={[
          {
            url: `/app/dashboard`,
            label: t('Dashboard'),
          },
          {
            url: `/app/notifications`,
            label: t('Manage Notifications'),
          },
        ]} title={t('Notification Setup')} />
        <div className="mob-100  d-flex">
          <Button
            className="btn-outline-primary d-flex align-items-center me-2"
            variant=""
            type="button"
            onClick={() => navigate(`/app/notifications`)}
          >
            {t('Back')}
          </Button>
        </div>
      </div>
      <div className="card mb-3">
        <div className="card-body">
          <h4 className="section-subheading mb-3">{t('Manage Notifications')}</h4>
          <ManageNotificationForm
            isLoading={isLoading}
            selectedNotification={selectedNotification}
            setIsLoading={setIsLoading}
            setIsNotificationUpdate={setIsNotificationUpdate}
            getNotificationById={getNotificationById}
          />
        </div>
      </div>
      {notificationId && (
        <div className="card">
          <div className="card-body">
            <NotificationThresholdSettings
              notificationId={notificationId}
              selectedNotification={selectedNotification}
              isNotificationUpdate={isNotificationUpdate}
              setIsNotificationUpdate={setIsNotificationUpdate}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ManageNotification;
