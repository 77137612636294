import {ADMIN_ROLE, TSN_USER} from 'constants/role';

export const ALL_IN_ONE_SENSOR = 'All In One Sensor';
export const ALL_IN_ONE_SENSOR_VALUE = 'allInOne';
export const TRAFFIC = 'Traffic';
export const TRAFFIC_VALUE = 'traffic';
export const PARKING = 'Parking';
export const PARKING_VALUE = 'parking';
export const PERFORMANCE_ISSUE = 'Performance Issue';
export const PERFORMANCE_ISSUE_VALUE = 'performance';

export const PERFORMANCE_ISSUE_PERMISSION = [ADMIN_ROLE, TSN_USER];
