import React, {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import CustomModal from 'components/shared/CustomModal';
import DiscardAlert from 'components/modal/DiscardAlert';
import ManageUserForm from 'components/forms/ManageUserForm';
import { useTranslation } from 'react-i18next';

const ManageUserModal = (props) => {
  const {show, isSubmit, selectedUser, roleTypes, handleManageUser, handleClose} = props;

  const [isDiscard, setIsDiscard] = useState(false);
  const { t } = useTranslation();

  const {
    setValue,
    setError,
    clearErrors,
    register,
    handleSubmit,
    formState: {errors, isDirty},
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      address: {
        addressLine1: '',
        addressLine2: '',
      },
      country: '',
      city: '',
      roleId: '',
      email: '',
      contact: {
        countryCode: '+51',
        number: '',
      },
      status: true,
      additionalRemark: '',
    },
  });

  const isDisabled = isSubmit || Object.keys(errors).length > 0;

  useEffect(() => {
    if (selectedUser) {
      setValue('name', selectedUser.name);
      setValue('address.addressLine1', selectedUser.address.addressLine1);
      setValue('address.addressLine2', selectedUser.address.addressLine2);
      setValue('country', selectedUser.country);
      setValue('city', selectedUser.city);
      setValue('roleId', selectedUser.roleId);
      setValue('email', selectedUser.email);
      setValue('contact.number', selectedUser.contact.number.toString());
      setValue('status', selectedUser.status);
      setValue('additionalRemark', selectedUser.additionalRemark);
    }
  }, [selectedUser]);

  const handleUser = async (formData) => {
    const action = selectedUser ? 'edit' : 'add';
    handleManageUser(formData, action);
  };

  const handleCloseButton = () => {
    if (isDirty) {
      setIsDiscard(true);
    } else {
      handleClose();
    }
  };

  const hanldeDiscardAllModal = () => {
    setIsDiscard(false);
    handleClose();
  };

  const hanldeDiscard = () => {
    setIsDiscard(false);
  };

  return (
    <>
      <CustomModal
        size="lg"
        show={show}
        handleClose={handleCloseButton}
        title={<h2>{`${selectedUser ? t('Edit') : t('Create')} ${'User'}`}</h2>}
        body={
          <ManageUserForm
            roleTypes={roleTypes}
            register={register}
            errors={errors}
            setValue={setValue}
            setError={setError}
            clearErrors={clearErrors}
            selectedUser={selectedUser}
          />
        }
        buttons={[
          {label: t('Discard'), type: 'primary', onClickHandler: handleCloseButton},
          {
            label: `${selectedUser ? t('Update') : t('Create')}`,
            type: 'primary',
            disabled: isDisabled,
            onClickHandler: handleSubmit(handleUser),
          },
        ]}
      />
      {isDiscard && (
        <CustomModal
          size="sm"
          show={isDiscard}
          handleClose={hanldeDiscard}
          body={<DiscardAlert />}
          buttons={[
            {label: 'Yes', type: 'primary', onClickHandler: hanldeDiscardAllModal},
            {label: 'No', type: 'secondary', onClickHandler: hanldeDiscard},
          ]}
        />
      )}
    </>
  );
};

export default ManageUserModal;
