export const LEVELS_OF_CONCERN_COLOR = '#F6F6F6';
export const GOOD_COLOR = '#008000';
export const MODERATE_COLOR = '#FFFF00';
export const UNHEALTHY_FOR_SENSITIVE_GROUPS_COLOR = '#FFA500';
export const UNHEALTHY_COLOR = '#FF0000';
export const VERY_UNHEALTHY_COLOR = '#800080';
export const HAZARDOUS_COLOR = '#800000';

export const CHARTS_COLOR = [
  {label: 'Levels of Concern', background: LEVELS_OF_CONCERN_COLOR, color: '#000000'},
  {label: 'Good', background: GOOD_COLOR, color: '#FFFFFF'},
  {label: 'Moderate', background: MODERATE_COLOR, color: '#000000'},
  {label: 'Unhealthy for Sensitive Groups', background: UNHEALTHY_FOR_SENSITIVE_GROUPS_COLOR, color: '#FFFFFF'},
  {label: 'Unhealthy', background: UNHEALTHY_COLOR, color: '#FFFFFF'},
  {label: 'Very Unhealthy', background: VERY_UNHEALTHY_COLOR, color: '#FFFFFF'},
  {label: 'Hazardous', background: HAZARDOUS_COLOR, color: '#FFFFFF'},
];
