export const getNumberOfPages = (rowCount, rowsPerPage) => {
  return Math.ceil(rowCount / rowsPerPage);
};

export const toPages = (pages) => {
  const results = [];

  for (let i = 1; i <= pages; i++) {
    results.push(i);
  }

  return results;
};
