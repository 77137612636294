import React from 'react';
import {toPages} from 'utils/pagination';
import {useTranslation} from 'react-i18next';

const Pagination = React.memo((props) => {
  const {
    rowsPerPage,
    numberOfPages,
    onChangePage,
    onChangeRowsPerPage, // available but not used here
    currentPage,
    paginationRowsPerPageOptions,
    handlePageChange,
    handleRowsPerPageChanges,
  } = props;
  const pageItems = toPages(numberOfPages);
  const nextDisabled = currentPage === pageItems.length;
  const previosDisabled = currentPage === 1;

  const {t} = useTranslation();

  const handleBackButtonClick = () => {
    onChangePage(currentPage - 1);
    handlePageChange(currentPage - 1);
  };

  const handleNextButtonClick = () => {
    onChangePage(currentPage + 1);
    handlePageChange(currentPage + 1);
  };

  const handlePageNumber = (e) => {
    onChangePage(Number(e.target.value));
    handlePageChange(Number(e.target.value));
  };

  const handleRowsPerPageChange = (e) => {
    onChangeRowsPerPage(Number(e.target.value));
    handleRowsPerPageChanges(Number(e.target.value));
  };

  return (
    <nav className="table-paginationOption d-flex justify-content-between align-items-center flex-column flex-md-row  mt-4">
      <div className="rows-per-page">
        <span className="d-inline me-2">{t('Display')}</span>
        <select className="me-2" value={rowsPerPage} onChange={handleRowsPerPageChange}>
          {paginationRowsPerPageOptions.map((page, index) => (
            <option key={index} value={page}>
              {page}
            </option>
          ))}
        </select>
        {t('Entries')}
      </div>
      <ul className="pagination">
        <li className="page-item">
          <button
            className="page-link"
            onClick={handleBackButtonClick}
            disabled={previosDisabled}
            aria-disabled={previosDisabled}
            aria-label="previous page"
          >
            <span>&lt;</span> {t('Previous')}
          </button>
        </li>
        {pageItems.map((page) => {
          const className = page === currentPage ? 'page-item active' : 'page-item';

          return (
            <li key={page} className={className}>
              <button className="page-link" onClick={handlePageNumber} value={page}>
                {page}
              </button>
            </li>
          );
        })}
        <li className="page-item">
          <button
            className="page-link"
            onClick={handleNextButtonClick}
            disabled={nextDisabled}
            aria-disabled={nextDisabled}
            aria-label="next page"
          >
            {t('Next')} <span>&gt;</span>
          </button>
        </li>
      </ul>
    </nav>
  );
});

export default Pagination;
