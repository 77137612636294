export const createPdfOptions = (filename) => {
  return {
    jsPDF: {
      format: 'a4',
    },
    imageQuality: 0.7,
    margin: {
      top: 10,
      right: 10,
      bottom: 10,
      left: 10,
    },
    autoResize: true,
    imageType: 'image/jpeg',
    output: filename,
  };
};
