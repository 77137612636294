import React from 'react';

export default (props) => (
  <svg
    width={props.width || '30'}
    height={props.height || '30'}
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="34" height="34" rx="9" fill={props.color || '#800000'} />
    <path
      d="M19.197 19.5092C17.741 20.3412 16.363 20.3282 14.946 19.5352C13.932 21.3942 12.918 23.2402 11.917 25.0732C14.413 26.9712 19.158 26.9972 22.252 25.0992C21.238 23.2532 20.224 21.3942 19.197 19.5092Z"
      fill="white"
    />
    <path
      d="M11.904 7.40652C8.79696 9.16151 6.46996 13.2045 6.89896 16.1035C8.96596 16.1035 11.046 16.1035 13.165 16.1035C13.308 14.7255 13.919 13.6465 15.141 12.8795C14.062 11.0465 12.996 9.25251 11.904 7.40652Z"
      fill="white"
    />
    <path
      d="M22.1349 7.39278C21.0819 9.17378 20.0159 10.9678 18.9109 12.8268C20.1199 13.5678 20.7959 14.6338 20.9519 16.0508C23.0579 16.0508 25.1379 16.0508 27.2049 16.0508C27.7379 13.2948 25.0599 8.71878 22.1349 7.39278Z"
      fill="white"
    />
    <path
      d="M17.039 14.2316C15.947 14.2446 15.024 15.2066 15.037 16.2856C15.063 17.3906 15.986 18.2746 17.091 18.2616C18.196 18.2486 19.119 17.3126 19.093 16.2336C19.067 15.1286 18.118 14.2186 17.039 14.2316Z"
      fill="white"
    />
  </svg>
);
