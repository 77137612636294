import React from 'react';
import ReactECharts from 'echarts-for-react'; // Please do not remove this, it has to import before ReactEChartsCore.
import * as echarts from 'echarts/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import LoadingSpinner from 'components/LoadingSpinner';
import NoRecordFound from 'components/NoRecordFound';
import {getParameterOption} from 'utils/echarts/barAndLineChartOptions';
import {EChartContainer} from 'styles/styled-components/StyledComponents';
import {useTranslation} from 'react-i18next';
import {filterDurationFormate} from 'utils/filterDurationFormate';
import {getBarGroupChartOptions} from 'utils/echarts/poc/barGroupChartOptions';

const DashboardParameterChart = (props) => {
  const {
    groupType,
    chartTitle,
    filterDuration,
    prevFilterDuration,
    customDate,
    loading,
    parameterChartData,
    allParameters,
    deviceParameterName,
    handleSubDeviceType,
  } = props;

  const {t} = useTranslation();

  return (
    <>
      <div className="row mongo-chart">
        <div className="col-12 col-md-12">
          <EChartContainer height={'500px'} className="dark-container">
            <div className="d-flex justify-content-between p-2">
              <div>
                <h5 className="dark-text">{chartTitle}</h5>
              </div>
              <div className="dark-text">{filterDurationFormate(filterDuration)}</div>
            </div>
            {loading ? (
              <LoadingSpinner />
            ) : groupType === 'stackLineChart' ? (
              <>
                {parameterChartData ? (
                  <ReactEChartsCore
                    echarts={echarts}
                    option={getBarGroupChartOptions(parameterChartData, filterDuration, customDate, prevFilterDuration)}
                    notMerge={true}
                    lazyUpdate={true}
                    style={{height: '450px'}}
                  />
                ) : (
                  <NoRecordFound />
                )}
              </>
            ) : parameterChartData.length > 0 ? (
              <>
                <ReactEChartsCore
                  echarts={echarts}
                  option={getParameterOption(
                    'bar',
                    parameterChartData,
                    filterDuration,
                    true,
                    customDate,
                    prevFilterDuration,
                  )}
                  notMerge={true}
                  lazyUpdate={true}
                  style={{height: '450px'}}
                />
              </>
            ) : (
              <>
                <NoRecordFound />
              </>
            )}
          </EChartContainer>
        </div>
      </div>
      <div className="row">
        <div className="col mb-4">
          <div className="sub-tanent-card-sec mb-2 dark-container">
            {allParameters.map((allSubDeviceType) => {
              return (
                <div
                  key={allSubDeviceType._id}
                  className={`sub-tanent-card me-3 dark-text ${
                    deviceParameterName === allSubDeviceType.name ? 'active' : ''
                  }`}
                  onClick={() => handleSubDeviceType(allSubDeviceType, groupType)}
                >
                  {allSubDeviceType.displayName === 'PM2_5' ? 'PM2.5' : allSubDeviceType.displayName}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardParameterChart;
