import reportAirQualityImg from 'assets/images/svg/report-air-quality.svg';
import reportTrafficImg from 'assets/images/svg/report-traffic.svg';
import reportparkingImg from 'assets/images/svg/report-parking.svg';
import reportDailyImg from 'assets/images/svg/report-daily.svg';
import reportweeklyImg from 'assets/images/svg/report-weekly.svg';
import reportmonthlyImg from 'assets/images/svg/report-monthly.svg';
import reportOverallImg from 'assets/images/svg/report-overall.svg';
import reportzoneImg from 'assets/images/svg/report-zone.svg';
import reportstreetImg from 'assets/images/svg/report-street.svg';
import reportdeviceImg from 'assets/images/svg/report-device.svg';

export const GRAPH_PER_PAGE = 6;
export const SUBSCRIBER_LIMIT = 5;

export const MANAGE = 'manage';
export const SUBSCRIBER = 'subscriber';

export const AIO_SENSOR = 'AIO Sensor';
export const AIO_SENSOR_VALUE = 'aioSensor';
export const TRAFFIC = 'Traffic';
export const TRAFFIC_VALUE = 'traffic';
export const PARKING = 'Parking';
export const PARKING_VALUE = 'parking';
export const DAILY = 'Daily';
export const DAILY_VALUE = 'daily';
export const WEEKLY = 'Weekly';
export const WEEKLY_VALUE = 'weekly';
export const MONTHLY = 'Monthly';
export const MONTHLY_VALUE = 'monthly';
export const CUSTOM = 'Custom';
export const CUSTOM_VALUE = 'custom';
export const OVERALL = 'Overall';
export const OVERALL_VALUE = 'overall';
export const ZONE = 'Zone';
export const ZONE_VALUE = 'zone';
export const STREET = 'Street';
export const STREET_VALUE = 'street';
export const DEVICE = 'Device';
export const DEVICE_VALUE = 'device';

export const REPORTS = [
  {
    label: AIO_SENSOR,
    value: AIO_SENSOR_VALUE,
    icon: reportAirQualityImg,
  },
  {
    label: TRAFFIC,
    value: TRAFFIC_VALUE,
    icon: reportTrafficImg,
  },
  {
    label: PARKING,
    value: PARKING_VALUE,
    icon: reportparkingImg,
  },
];

export const REPORTS_TYPES = [
  {
    label: DAILY,
    value: DAILY_VALUE,
    icon: reportDailyImg,
  },
  {
    label: WEEKLY,
    value: WEEKLY_VALUE,
    icon: reportweeklyImg,
  },
  {
    label: MONTHLY,
    value: MONTHLY_VALUE,
    icon: reportmonthlyImg,
  },
  // {
  //   label: CUSTOM,
  //   value: CUSTOM_VALUE,
  //   icon: reportmonthlyImg,
  // },
];

export const AREAS = [
  {
    label: OVERALL,
    value: OVERALL_VALUE,
    icon: reportOverallImg,
  },
  {
    label: ZONE,
    value: ZONE_VALUE,
    icon: reportzoneImg,
  },
  {
    label: STREET,
    value: STREET_VALUE,
    icon: reportstreetImg,
  },
  {
    label: DEVICE,
    value: DEVICE_VALUE,
    icon: reportdeviceImg,
  },
];

export const REPORT_DATE_FORMATE = 'DD-MMM-YY';
export const REPORT_DATE_TIME_FORMATE = 'DD-MMM-YY hh:mm A';
