import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit';
import {combineReducers} from 'redux';
import appReducer from 'features/app/appSlice';
import authReducer from 'features/auth/authSlice';
import userDetail from 'features/userDetailSlice';
import languageReducer from 'features/language/languageSlice';
import storage from 'redux-persist/lib/storage';
import {persistReducer} from 'redux-persist';
import logger from 'redux-logger';

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  user: userDetail,
  language: languageReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = [
  ...getDefaultMiddleware({
    serializableCheck: false,
  }),
];

if (process.env.REACT_APP_ENV === 'development') {
  middleware.push(logger);
}

const store = configureStore({
  reducer: persistedReducer,
  middleware,
});

export default store;
