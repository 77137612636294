import React from 'react';
import Action from 'components/datatable/Action';

export const deviceListColumn = (filters, handleEdit, handleDelete, handleSetting, t) => {
  return [
    {
      name: t('Serial No'),
      selector: (row) => row.serial_no,
      cell: (row, index) => {
        return (filters.pageNumber - 1) * filters.pageSize + index + 1;
      },
    },
    {
      name: t('Device ID'),
      alias: 'deviceId',
      selector: (row) => row.deviceId,
      sortable: true,
      sortFunction: (a, b) => a.deviceId?.toLowerCase().localeCompare(b.deviceId?.toLowerCase()),
    },
    {
      name: t('Device Name'),
      alias: 'deviceName',
      selector: (row) => row.deviceName,
      sortable: true,
      sortFunction: (a, b) => a.deviceName?.toLowerCase().localeCompare(b.deviceName?.toLowerCase()),
    },
    {
      name: t('Device Type'),
      alias: 'deviceType',
      selector: (row) => (
        <>
          <img className="me-1" src={`/icons/${row.deviceAlias}.svg`} alt={row.deviceAlias} />
          <span>{row.deviceType}</span>
        </>
      ),
      sortable: true,
      sortFunction: (a, b) => a.deviceType?.toLowerCase().localeCompare(b.deviceType?.toLowerCase()),
    },
    {
      name: t('Add Ons'),
      alias: 'addons',
      selector: (row) => (row.addons ? row.addons : 'N/A'),
    },
    {
      name: t('Tag'),
      alias: 'tagName',
      selector: (row) => (row.tagName ? row.tagName : 'N/A'),
      sortable: true,
      sortFunction: (a, b) => a.tagName?.toLowerCase().localeCompare(b.tagName?.toLowerCase()),
    },
    {
      name: t('Status'),
      alias: 'status',
      selector: (row) => (row.status ? 'Active' : 'Inactive'),
      sortFunction: (a, b) => {
        const statusA = a.status ? 1 : 0;
        const statusB = b.status ? 1 : 0;

        if (statusA < statusB) {
          return -1;
        }
        if (statusA > statusB) {
          return 1;
        }
        return 0;
      },
      sortable: true,
    },
    {
      name: t('Action'),
      button: true,
      cell: (row) => (
        <Action row={row} handleEdit={handleEdit} handleDelete={handleDelete} handleSetting={handleSetting} />
      ),
    },
  ];
};
