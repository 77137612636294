export const AQI = {
  label: 'AQI',
  value: 'AQI',
};

export const NO2 = {
  label: 'NO2',
  value: 'NO2',
};

export const CO = {
  label: 'CO',
  value: 'CO',
};

export const CO2 = {
  label: 'CO2',
  value: 'CO2',
};

export const NO = {
  label: 'NO',
  value: 'NO',
};

export const O3 = {
  label: 'O3',
  value: 'O3',
};

export const SO2 = {
  label: 'SO2',
  value: 'SO2',
};

export const PM2_5 = {
  label: 'PM2.5',
  value: 'PM2_5',
};

export const PM10 = {
  label: 'PM10',
  value: 'PM10',
};

export const PM1 = {
  label: 'PM1',
  value: 'PM1',
};

export const DB = {
  label: 'DB',
  value: 'DB',
};

export const Humidity = {
  label: 'Humidity',
  value: 'Humidity',
};

export const Humidity_OB = {
  label: 'Humidity OB',
  value: 'Humidity_OB',
};

export const Noise_LEQ = {
  label: 'Noise LEQ',
  value: 'Noise_LEQ',
};

export const Noise_Max = {
  label: 'Noise Max',
  value: 'Noise_Max',
};

export const Pressure = {
  label: 'Pressure',
  value: 'Pressure',
};

export const Pressure_OB = {
  label: 'Pressure OB',
  value: 'Pressure_OB',
};

export const Temperature = {
  label: 'Temperature',
  value: 'Temperature',
};

export const Temperature_OB = {
  label: 'Temperature',
  value: 'Temperature_OB',
};

export const windDirection = {
  label: 'Wind Direction',
  value: 'windDirection',
};

export const windSpeed = {
  label: 'Wind Speed',
  value: 'windSpeed',
};

export const rainFall = {
  label: 'Rain Fall',
  value: 'rainFall',
};

export const ALL_PARAMETERS = [
  AQI.label,
  NO2.label,
  CO.label,
  CO2.label,
  NO.label,
  O3.label,
  SO2.label,
  PM2_5.label,
  PM10.label,
  PM1.label,
  DB.label,
  Humidity.label,
  Humidity_OB.label,
  Noise_LEQ.label,
  Noise_Max.label,
  Pressure.label,
  Pressure_OB.label,
  Temperature.label,
  Temperature_OB.label,
  windDirection.label,
  windSpeed.label,
  rainFall.label,
];

export const GROUP_1_GASES = [
  AQI.label,
  NO2.label,
  CO.label,
  CO2.label,
  NO.label,
  O3.label,
  SO2.label,
  PM2_5.label,
  PM10.label,
  PM1.label,
];
export const GROUP_2_METROLOGICALES_1 = [Humidity.label, Humidity_OB.label, Noise_LEQ.label, Noise_Max.label];
export const GROUP_2_METROLOGICALES_2 = [Pressure.label, Pressure_OB.label, Temperature.label, windSpeed.label];
