import ManageReports from 'components/reports/ManageReports';
import { SUBSCRIBER } from 'constants/reports';
import React from 'react';

const Subscriber = () => {
  return (
    <>
      <ManageReports action={SUBSCRIBER} />
    </>
  );
};

export default Subscriber;
