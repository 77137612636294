import React, {useState} from 'react';
import ManageRoleContainer from 'pages/ManageRolesPage/ManageRoleContainer';

const ManageRoleTab = (props) => {
  const [showRoleModal, setShowRoleModal] = useState(false);
  const {tenantId} = props;

  return (
    <>
      <div className="card">
        <div className="card-body">
          <ManageRoleContainer
            showRoleModal={showRoleModal}
            setShowRoleModal={setShowRoleModal}
            isTenantManagePage={true}
            tenantId={tenantId}
          />
        </div>
      </div>
    </>
  );
};

export default ManageRoleTab;
