import moment from 'moment';

export const getLineGradientChartOptions = (
  comparisonChart,
  duration = null,
  startTime = null,
  prevFilterDuration = null,
) => {
  const formatTime = (params) => {
    if (duration) {
      const [quantity, unit] = duration.split(' ');
      const [preQuantity, prevUnit] = prevFilterDuration.split(' ');
      const format =
        quantity === 'custom'
          ? startTime
            ? 'DD-MMM-YYYY hh:mm A'
            : prevFilterDuration
            ? prevUnit === 'month'
              ? 'DD-MMM-YYYY'
              : prevUnit === 'day'
              ? 'DD-MMM-YYYY'
              : 'hh:mm A'
            : 'DD-MMM-YYYY'
          : unit === 'month'
          ? 'DD-MMM-YYYY'
          : unit === 'day'
          ? 'DD-MMM-YYYY'
          : 'hh:mm A';
      return moment(params).format(format);
    } else {
      return moment(params, 'HH:mm:ss').format('hh:mm A');
    }
  };

  const processData = (data) => ({
    dateList: data.map((item) => item.name),
    valueList: data.map((item) => item.value),
  });

  const {pm2_5, temperature} = comparisonChart;
  const {dateList: pm2_5DateList, valueList: pm2_5ValueList} = processData(pm2_5);
  const {dateList: temperatureDateList, valueList: temperatureValueList} = processData(temperature);

  return {
    visualMap: [
      {
        show: false,
        type: 'continuous',
        seriesIndex: 0,
        min: 0,
        max: pm2_5DateList.length - 1,
      },
      {
        show: false,
        type: 'continuous',
        seriesIndex: 1,
        // dimension: 0,
        min: 0,
        max: temperatureDateList.length - 1,
      },
    ],
    title: [
      {top: '2%', left: 'center', text: 'PM2.5'},
      {top: '50%', left: 'center', text: 'Temperature'},
    ],
    tooltip: {
      trigger: 'axis',
      // formatter: (params) => {
      //   console.log('params ==> ', params[0].value);
      //   return params[0].value;
      //   // formatTime(params[0].axisValue)
      // },
    },
    xAxis: [
      {
        type: 'category',
        axisLabel: {rotate: pm2_5DateList.length > 10 ? 45 : 0, formatter: formatTime},
        data: pm2_5DateList,
      },
      {
        type: 'category',
        axisLabel: {rotate: temperatureDateList.length > 10 ? 45 : 0, formatter: formatTime},
        data: temperatureDateList,
        gridIndex: 1,
      },
    ],
    yAxis: [{}, {gridIndex: 1}],
    grid: [
      {top: '10%', bottom: '70%'},
      {top: '60%', bottom: '20%'},
    ],
    series: [
      {type: 'line', showSymbol: false, data: pm2_5ValueList},
      {type: 'line', showSymbol: false, data: temperatureValueList, xAxisIndex: 1, yAxisIndex: 1},
    ],
  };
};
