import api from 'api/axios';
import {formatFilter} from 'utils/formatFilter';

export const fetchDashboardDevices = async (filters = {tenantId: ''}) => {
  try {
    const filterParams = formatFilter(filters);
    const response = await api.get(`/dashboard/device`, {
      params: filterParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getTenantName = async () => {
  try {
    const response = await api.get(`/dashboard/tenant-name`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchDashboardWidgets = async (type) => {
  try {
    const response = await api.get(`/dashboard/widget?type=${type}`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchOverallDashboardParameterAvg = async (filters) => {
  try {
    const filterParams = formatFilter(filters);
    const response = await api.get(`/dashboard/average`, {
      params: filterParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchOverallDashboardDeviceParameterAvg = async (filterParams) => {
  try {
    const response = await api.get(`/dashboard/device-details`, {
      params: filterParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchDashboardRoseGraph = async (filterParams) => {
  try {
    const response = await api.get(`/dashboard/graph/rose`, {
      params: filterParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchDeviceParameters = async (deviceTypeId) => {
  try {
    const response = await api.get(`/device/sub-type?deviceTypeId=${deviceTypeId}`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

// Charts APIs Start
export const fetchAqiAndNoiseChart = async (filterParams) => {
  try {
    const response = await api.get(`/dashboard/chart/aqi-noise`, {
      params: filterParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchParameterChart = async (filterParams) => {
  try {
    const response = await api.get(`/dashboard/chart/bar`, {
      params: filterParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchGaugeChart = async (filterParams) => {
  try {
    const response = await api.get(`/dashboard/chart/gauge`, {
      params: filterParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchHeatChart = async (filterParams) => {
  try {
    const response = await api.get(`/dashboard/chart/heatmap`, {
      params: filterParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchDeviceAllParameters = async (filterParams) => {
  try {
    const response = await api.get(`/dashboard/device-parameter`, {
      params: filterParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchZoneParameterChart = async (filterParams) => {
  try {
    const response = await api.get(`/dashboard/chart/bar-zone`, {
      params: filterParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
// Charts APIs End
