import React, {useEffect} from 'react';
import {toast} from 'react-toastify';
import {LANGUAGES} from 'constants/locales';
import {useDispatch, useSelector} from 'react-redux';
import CustomToast from 'components/Toast/CustomToast';
import {setLanguage} from 'features/language/languageSlice';
import {useTranslation} from 'react-i18next';
import {fetchLanguage, updateLanguage} from 'features/language/languageApi';
import LanguageDropdown from 'components/LanguageDropdown';

const LanguageSelector = () => {
  const {i18n} = useTranslation();
  const {selectedLanguage} = useSelector((state) => state.language);

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      await getLanguage();
    })();
  }, []);

  const getLanguage = async () => {
    const {data} = await fetchLanguage();
    const language = LANGUAGES.find((lang) => lang.value === data.language);
    dispatch(setLanguage(language));
    i18n.changeLanguage(language.value);
    document.documentElement.lang = language.value;
  };

  const handleLanguageChange = async (language) => {
    try {
      const payload = {
        language: language.value,
      };
      const {message} = await updateLanguage(payload);
      dispatch(setLanguage(language));
      i18n.changeLanguage(language.value);
      document.documentElement.lang = language.value;
      toast(<CustomToast type="success" title="Success" message={message} />);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    }
  };

  return (
    <>
      {/* <Dropdown as={ButtonGroup} align={{lg: 'end'}}>
        <Button variant="success">{selectedLanguage.label}</Button>
        <Dropdown.Toggle split variant="success" id="languages"></Dropdown.Toggle>
        <Dropdown.Menu>
          {LANGUAGES.map((language, index) => {
            return (
              <Dropdown.Item key={index} onClick={() => handleLanguageChange(language)}>
                {language.label}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown> */}
      <LanguageDropdown language={selectedLanguage} handleLanguageChange={handleLanguageChange} />
    </>
  );
};

export default React.memo(LanguageSelector);
