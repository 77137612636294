import React from 'react';
import Action from 'components/datatable/Action';

export const notificationListColumn = (filters, isEditAllow, isDeleteAllow, handleEdit, handleDelete, t) => {
  return [
    {
      name: t('Serial No'),
      selector: (row) => row.serial_no,
      cell: (row, index) => {
        return (filters.pageNumber - 1) * filters.pageSize + index + 1;
      },
    },
    {
      name: t('Notification Title'),
      alias: 'notificationTitle',
      selector: (row) => row.notificationTitle,
      sortable: true,
      sortFunction: (a, b) => a.notificationTitle?.toLowerCase().localeCompare(b.notificationTitle?.toLowerCase()),
    },

    {
      name: t('Notification Type'),
      alias: 'notificationType',
      selector: (row) => row.notificationType,
      sortable: true,
      sortFunction: (a, b) => a.notificationType?.toLowerCase().localeCompare(b.notificationType?.toLowerCase()),
    },
    {
      name: t('Device Type'),
      alias: 'deviceType',
      selector: (row) => (
        <>
          <img className="me-1" src={`/icons/${row.deviceAlias}.svg`} alt={row.deviceAlias} />
          <span>{row.deviceType}</span>
        </>
      ),
      sortable: true,
      sortFunction: (a, b) => a.deviceType?.toLowerCase().localeCompare(b.deviceType?.toLowerCase()),
    },
    {
      name: t('Tags'),
      alias: 'tags',
      selector: (row) => row.tags,
      sortable: true,
      sortFunction: (a, b) => a.tags?.toLowerCase().localeCompare(b.tags?.toLowerCase()),
    },
    {
      name: t('Status'),
      alias: 'status',
      selector: (row) => (row.status ? 'Active' : 'Inactive'),
      sortFunction: (a, b) => {
        const statusA = a.status ? 1 : 0;
        const statusB = b.status ? 1 : 0;

        if (statusA < statusB) {
          return -1;
        }
        if (statusA > statusB) {
          return 1;
        }
        return 0;
      },
      sortable: true,
    },
    {
      name: t('Action'),
      button: true,
      cell: (row) => (
        <Action
          row={row}
          isEditAllow={isEditAllow}
          isDeleteAllow={isDeleteAllow}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
        />
      ),
    },
  ];
};
