import React, {useState} from 'react';
import ManageUserContainer from 'pages/ManageUsersPage/ManageUserContainer';

const ManageUserTab = (props) => {
  const [showUserModal, setShowUserModal] = useState(false);
  const {tenantId} = props;
  return (
    <>
      <div className="card">
        <div className="card-body">
          <ManageUserContainer
            showUserModal={showUserModal}
            setShowUserModal={setShowUserModal}
            isTenantManagePage={true}
            tenantId={tenantId}
          />
        </div>
      </div>
    </>
  );
};

export default ManageUserTab;
