import React, {useState, useEffect} from 'react';
import {toast} from 'react-toastify';
import {Tabs, Tab} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {useNavigate, useParams, createSearchParams, useSearchParams} from 'react-router-dom';
import {addTenant, editTenant, fetchStepper, fetchTenantById, manageStepper} from 'features/tenant/tenantApi';
import CustomToast from 'components/Toast/CustomToast';
import {useCustomEffect} from 'hooks/useCustomEffect';
import ManageTenantTab from 'components/tenantStepper/ManageTenantTab';
import ManageRoleTab from 'components/tenantStepper/ManageRoleTab';
import ManageUserTab from 'components/tenantStepper/ManageUserTab';
import ManageDeviceTab from 'components/tenantStepper/ManageDeviceTab';
import ManageSettingTab from 'components/tenantStepper/ManageSettingTab';
import StepperHeader from 'components/StepperHeader';
import {
  ADD_DEVICES_TAB,
  ADD_ROLES_PERMISSIONS_TAB,
  ADD_SETTINGS_TAB,
  ADD_TENANT_TAB,
  ADD_USERS_TAB,
  TENANT_TABS,
} from 'constants/tenant';
import {useTranslation} from 'react-i18next';

const ManageTenant = () => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [currentTab, setCurrentTab] = useState(ADD_TENANT_TAB);
  const {tenantId, deviceId} = useParams();
  const [searchParams] = useSearchParams();

  const {t} = useTranslation();

  const navigate = useNavigate();

  const {
    setValue,
    register,
    getValues,
    trigger,
    clearErrors,
    setError,
    formState: {errors},
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      tenantName: '',
      name: '',
      email: '',
      contact: {
        countryCode: '+51',
        number: '',
      },
      additionalRemark: '',
    },
  });

  useEffect(() => {
    const tab = searchParams.get('tab');
    if (tab) {
      const name = TENANT_TABS[tab - 1];
      const tabName = name?.tabName;
      if (tabName) {
        setCurrentTab(tabName);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    (async () => {
      if (tenantId) {
        // const lastVisitedTab = await getStepper();
        // if (lastVisitedTab === ADD_TENANT_TAB) {
        getTenant();
        // }
      }
    })();
  }, []);

  useEffect(() => {
    if (selectedTenant) {
      setValue('tenantName', selectedTenant.tenantName);
      setValue('name', selectedTenant.name);
      setValue('email', selectedTenant.email);
      setValue('contact.number', selectedTenant.contact.number.toString());
      setValue('status', selectedTenant.status);
      setValue('additionalRemark', selectedTenant.additionalRemark);
    }
  }, [selectedTenant]);

  const getStepper = async () => {
    try {
      const {data: stepperDetails} = await fetchStepper(tenantId);
      const lastVisitedTab = stepperDetails.steps[stepperDetails.steps.length - 1];
      setCurrentTab(lastVisitedTab.key);

      return lastVisitedTab.key;
    } catch (error) {
      return ADD_TENANT_TAB;
    }
  };

  const getTenant = async () => {
    try {
      setIsLoading(true);
      const {data} = await fetchTenantById(tenantId);
      setSelectedTenant(data);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTabClick = (tab) => {
    const currentIndex = TENANT_TABS.findIndex((t) => t.tabName === tab);
    const tabIndex = currentIndex + 1
    navigate(`/app/tenants/manage/${tenantId}?tab=${tabIndex}`);
  };

  const handleSaveExitAndNext = async (action, selectedTab = null) => {
    try {
      const tab = selectedTab ? selectedTab : currentTab;
      setIsSubmit(true);
      // save last tab history
      if (tab !== ADD_TENANT_TAB) {
        await saveStepperDetails(tab);
      }

      switch (tab) {
        case ADD_TENANT_TAB:
          const result = await handleManageTenantFormTab();
          if (result) {
            await saveStepperDetails(tab, result.tenantId);
            handleAction(action, selectedTab ? selectedTab : ADD_ROLES_PERMISSIONS_TAB);
            if (action === 'next') {
              navigate({
                pathname: `/app/tenants/manage/${result.tenantId}`,
              });
            }
          }
          break;
        case ADD_ROLES_PERMISSIONS_TAB:
          handleAction(action, selectedTab ? selectedTab : ADD_USERS_TAB);
          break;
        case ADD_USERS_TAB:
          handleAction(action, selectedTab ? selectedTab : ADD_DEVICES_TAB);
          break;
        case ADD_DEVICES_TAB:
          handleAction(action, selectedTab ? selectedTab : ADD_SETTINGS_TAB);
          break;
        case ADD_SETTINGS_TAB:
          handleAction(action, selectedTab ? selectedTab : ADD_TENANT_TAB);
          break;
      }
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setIsSubmit(false);
    }
  };

  const handleAction = (action, nextStep) => {
    if (action === 'next' || action === 'previous') {
      setCurrentTab(nextStep);
    } else {
      navigate('/app/tenants');
    }
  };

  const saveStepperDetails = async (stepKey, id = null) => {
    const stepperData = {
      tenantId: id ? id : tenantId,
      stepKey,
    };
    const {data} = manageStepper(stepperData);
  };

  const handleManageTenantFormTab = async () => {
    try {
      const result = await trigger(['tenantName', 'name', 'email', 'contact']);
      if (result) {
        const formData = getValues();
        let data;
        if (tenantId) {
          const {data: result, message} = await editTenant(tenantId, formData);
          toast(<CustomToast type="success" title="Success" message={message} />);
          data = result;
        } else {
          const {data: result, message} = await addTenant(formData);
          toast(<CustomToast type="success" title="Success" message={message} />);
          data = result;
        }

        return data;
      }
      return result;
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
      return false;
    }
  };

  const currentIndex = TENANT_TABS.findIndex((tab) => tab.tabName === currentTab);

  return (
    <>
      <div className="tab-structure">
        <StepperHeader
          title={t('Manage Tenants')}
          isSubmit={isSubmit}
          currentTab={currentTab}
          handleSaveExitAndNext={handleSaveExitAndNext}
          handleAddButton={() => navigate('/app/tenants/manage')}
        />
        <Tabs
          defaultActiveKey={currentTab}
          activeKey={currentTab}
          // onSelect={(k) => setCurrentTab(k)}
          onSelect={(k) => handleTabClick(k)}
          transition={false}
          id="tenant-tab"
          className="mb-3"
        >
          {TENANT_TABS.map((tenantTab, index) => {
            return (
              <Tab
                key={tenantTab.tabName}
                eventKey={tenantTab.tabName}
                title={t(tenantTab.tabTitle)}
                disabled={tenantId ? false : true}
                tabClassName={index < currentIndex ? 'completed-tab' : 'pending-tab'}
              >
                {tenantTab.tabName === currentTab && (
                  <>
                    {tenantTab.tabName === ADD_TENANT_TAB && (
                      <ManageTenantTab
                        isLoading={isLoading}
                        selectedTenant={selectedTenant}
                        register={register}
                        errors={errors}
                        setValue={setValue}
                        clearErrors={clearErrors}
                        setError={setError}
                      />
                    )}
                    {tenantTab.tabName === ADD_ROLES_PERMISSIONS_TAB && <ManageRoleTab tenantId={tenantId} />}
                    {tenantTab.tabName === ADD_USERS_TAB && <ManageUserTab tenantId={tenantId} />}
                    {tenantTab.tabName === ADD_DEVICES_TAB && <ManageDeviceTab tenantId={tenantId} />}
                    {tenantTab.tabName === ADD_SETTINGS_TAB && <ManageSettingTab tenantId={tenantId} />}
                  </>
                )}
              </Tab>
            );
          })}
        </Tabs>
      </div>
    </>
  );
};

export default ManageTenant;
