import React, {useState} from 'react';
import {toast} from 'react-toastify';
import {useForm} from 'react-hook-form';
import OtpInput from 'react-otp-input';
import {Form, Spinner} from 'react-bootstrap';
import {OTP_LENGTH} from 'constants/login';
import CustomToast from 'components/Toast/CustomToast';
import {StyledPrimaryButton, StyledErrorLabel, StyledLinkButton} from 'styles/styled-components/StyledComponents';
import {resendOtp} from 'features/auth/authApi';
import {useTranslation} from 'react-i18next';

const VerifyForm = (props) => {
  const {user, isLoading, onSubmit} = props;
  const [otp, setOtp] = useState('');

  const {t} = useTranslation();

  const {
    handleSubmit,
    setError,
    clearErrors,
    formState: {errors},
  } = useForm({
    mode: 'onChange',
  });

  const handleVerifyOTP = () => {
    if (otp.split('').length === OTP_LENGTH) {
      handleSubmit(onSubmit(otp));
    } else {
      setError('otp', {
        message: t('Please enter the OTP.'),
      });
    }
  };

  const handleResentOTP = async () => {
    setOtp('');
    const {message} = await resendOtp({email: user.email});
    toast(<CustomToast type="success" title="Success" message={message} />);
  };

  const handleChangeOtp = (otp) => {
    clearErrors();
    setOtp(otp);
  };

  return (
    <div className="login-form">
      <div className='w-100'>
        <h2 className="mb-1">{t('Enter OTP')}</h2>
        <span className="login-msg d-block mb-4">{`${t('We have sent a OTP to')} ${user?.contact}`}</span>
        <form>
          <Form.Group className="mb-2 otp-fields">
            <OtpInput
              name="otp"
              value={otp}
              onChange={handleChangeOtp}
              numInputs={OTP_LENGTH}
              inputType={`tel`}
              renderInput={(props) => <input {...props} />}
              containerStyle={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            />
            {errors.otp && <StyledErrorLabel>{errors.otp.message}</StyledErrorLabel>}
          </Form.Group>
          <StyledLinkButton variant="link" onClick={handleResentOTP}>
            {t('Resend OTP')}
          </StyledLinkButton>

          <StyledPrimaryButton
            variant="primary"
            className="w-100 mt-4"
            disabled={isLoading || Object.keys(errors).length > 0}
            onClick={handleVerifyOTP}
          >
            {isLoading ? <Spinner animation="border" /> : t(`Verify OTP`)}
          </StyledPrimaryButton>
        </form>
      </div>
    </div>
  );
};

export default VerifyForm;
