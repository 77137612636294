import api from 'api/axios';
import {formatFilter} from 'utils/formatFilter';

export const fetchRoles = async (filters) => {
  try {
    const filterParams = formatFilter(filters);
    
    const response = await api.get(`${process.env.REACT_APP_API_URL}/user/role/name`, {
      params: filterParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchUsers = async (filters) => {
  try {
    const filterParams = formatFilter(filters);

    const response = await api.get(`${process.env.REACT_APP_API_URL}/user`, {
      params: filterParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchUserCsvContent = async (filters) => {
  try {
    const filterParams = formatFilter(filters);
    
    const response = await api.get(`${process.env.REACT_APP_API_URL}/user/export`, {
      params: filterParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addUser = async (payload) => {
  try {
    const response = await api.post(`${process.env.REACT_APP_API_URL}/user`, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editUser = async (id, payload) => {
  try {
    const response = await api.put(`${process.env.REACT_APP_API_URL}/user/${id}`, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteUser = async (id) => {
  try {
    const response = await api.delete(`${process.env.REACT_APP_API_URL}/user/${id}`);

    return response.data;
  } catch (error) {
    throw error;
  }
};
