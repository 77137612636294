export const MultiValueStyle = (props) => {
  return {
    ...props,
    backgroundColor: '#f6f6f9',
    borderRadius: '4px',
    height: '32px',
    padding: '4px 4px 4px 2px',
    margin: '0px 8px 0px 0px',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    color: '#35363a',
    border: '1px solid #ececec',
  };
};

export const MultiOptionStyle = (provided, state) => {
  return {
    ...provided,
    backgroundColor: '',
    color: '#35363a',
    fontSize: '12px',
    ':hover': {
      backgroundColor: '#5794f1',
    },
    padding: 0,
    paddingLeft: '12px',
  };
};

export const ValueContainerStyle = (props) => {
  return {
    ...props,
    padding: '8px 8px 8px 16px',
  };
};

export const SelectPlaceholderStyle = (props, state) => {
  return {
    ...props,
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    color: state.selectProps.error ? '#35363a' : '#BCBCBC',
  };
};

export const ControlStyle = (props, state) => {
  return {
    ...props,
    borderColor:
      state.selectProps.error && !state.selectProps.isDisabled && !state.hasValue ? '#FF3E55' : 'hsl(0,0%,80%)',
    boxShadow: state.isFocused && '0 0 0 1px #041E42',
    ':hover': {
      borderColor: '#041E42',
    },
  };
};

export const MultiValueRemoveStyle = (props) => {
  return {
    ...props,
    marginLeft: '8px',
  };
};

export const SelectContainerStyle = (props, state) => {
  return {
    ...props,
    marginBottom: '0.5rem !important',
  };
};
