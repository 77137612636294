import React, {useState, useMemo, useCallback, useEffect} from 'react';
import {GoogleMap, useJsApiLoader} from '@react-google-maps/api';
import {MapSection} from 'styles/styled-components/StyledComponents';
import {calculateCenterLocation, calculateZoneCenterLocation} from 'utils/calculateCenterLocation';
import DashboardMap from 'components/charts/DashboardMap';
import ParameterDetailsModal from 'components/modal/ParameterDetailsModal';
import {Spinner} from 'react-bootstrap';
import {DEFAULT_ZOOM, GOOGLE_MAP_TOOL_OPTIONS, MAP_CLUSTER, SELECTED_ZOOM, ZONE_CLUSTER} from 'constants/map';

const libraries = ['drawing'];

const DashboardMapContainer = (props) => {
  const {
    devices,
    zones,
    deviceId,
    selectedZone,
    showZoneColor,
    isZoneVisible,
    selectedDevice,
    selectedDeviceDetail,
    showDeviceDetailModal,
    isPolygonVisible,
    isDeviceLoading,
    isContainerLoading,
    viewDeviceId,
    setShowDeviceDetailModal,
    handleSelectDevice,
    handleMapZoneClick,
    isZoneCluster,
  } = props;
  const [map, setMap] = useState(null);
  const [clusteringLevel, setClusteringLevel] = useState(MAP_CLUSTER);

  // const getCenterOfMap = calculateCenterLocation(devices);
  const defaultLat = 25.6458504; //isNaN(getCenterOfMap.lat) ? '24.191836' : getCenterOfMap.lat;
  const defaultLng = -100.477145; //isNaN(getCenterOfMap.lng) ? '-102.694752' : getCenterOfMap.lng;

  const lat = selectedZone
    ? selectedZone[0].coordinates[0].lat
    : selectedDevice
    ? selectedDevice.masterDevice.location.lat
    : defaultLat;

  const lng = selectedZone
    ? selectedZone[0].coordinates[0].lng
    : selectedDevice
    ? selectedDevice.masterDevice.location.long
    : defaultLng;

  const center = useMemo(() => ({lat: parseFloat(lat), lng: parseFloat(lng)}), [lat, lng]);

  const onLoad = useCallback((mapInstance) => {
    setMap(mapInstance);
  }, []);

  useEffect(() => {
    if (viewDeviceId && selectedDevice && map) {
      map.panTo({
        lat: parseFloat(selectedDevice.masterDevice.location.lat),
        lng: parseFloat(selectedDevice.masterDevice.location.long),
      });
      map.setZoom(SELECTED_ZOOM);
    }
  }, [viewDeviceId, selectedDevice, map]);

  useEffect(() => {
    if (zones.length > 0 && selectedZone && map) {
      if (selectedZone.length > 0) {
        const {lat, lng} = calculateZoneCenterLocation(selectedZone[0].coordinates);
        map.panTo({
          lat: parseFloat(lat),
          lng: parseFloat(lng),
        });
        map.setZoom(SELECTED_ZOOM);
      }
    }
  }, [zones, selectedZone, map]);

  const {isLoaded} = useJsApiLoader({
    id: 'tns-map-device',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: libraries,
  });

  function handleZoomChanged() {
    const zoomLevel = this.getZoom();
    if (zoomLevel <= 10) {
      setClusteringLevel(MAP_CLUSTER);
    } else {
      setClusteringLevel(ZONE_CLUSTER);
    }
  }

  return (
    <>
      <MapSection>
        {isDeviceLoading && (
          <div className="map-loader-overlay">
            <div className="text-center">
              <span className="d-flex justify-content-center mt-2 mb-2">
                <Spinner animation="border" variant="light" />
              </span>
            </div>
          </div>
        )}
        {isLoaded && (
          <GoogleMap
            onLoad={onLoad}
            onZoomChanged={isZoneCluster ? handleZoomChanged : null}
            mapContainerClassName="map-container"
            center={center}
            zoom={selectedDevice ? SELECTED_ZOOM : DEFAULT_ZOOM}
            options={GOOGLE_MAP_TOOL_OPTIONS}
          >
            <DashboardMap
              devices={devices}
              zones={zones}
              deviceId={deviceId}
              selectedDevice={selectedDevice}
              selectedZone={selectedZone}
              showZoneColor={showZoneColor}
              isZoneVisible={isZoneVisible}
              isPolygonVisible={isPolygonVisible}
              clusteringLevel={clusteringLevel}
              handleSelectDevice={handleSelectDevice}
              handleMapZoneClick={handleMapZoneClick}
            />
            {showDeviceDetailModal && (
              <ParameterDetailsModal
                isContainerLoading={isContainerLoading}
                selectedDevice={selectedDevice}
                selectedDeviceDetail={selectedDeviceDetail}
                setShowDeviceDetailModal={setShowDeviceDetailModal}
              />
            )}
          </GoogleMap>
        )}
      </MapSection>
    </>
  );
};

export default React.memo(DashboardMapContainer);
