import React from 'react';
import {Container, Button, Row, Col} from 'react-bootstrap';
import NotAuthorizedIcon from 'assets/images/svg/NotAuthorized.svg';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

function NotAuthorized() {
  const navigate = useNavigate();
  const {t} = useTranslation();

  function handleGoBack() {
    navigate(-1);
  }

  return (
    <Container className="mt-5">
      <Row className="justify-content-center align-items-center text-center">
        <Col xs={10} sm={10} md={8} lg={6}>
          <div className="error-container d-flex align-items-center flex-column justify-content-center">
            <img className="mb-3 w-80" src={NotAuthorizedIcon} alt="404-error" />
            <p>{t(`You don’t have permission to access  this page.`)}</p>
            <p>{t(`If you believe you should have access, please contact an administrator or the support team  for assistance`)}</p>
            <Button variant="primary mt-3" onClick={handleGoBack}>
              {t('Go Back')}
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default NotAuthorized;
