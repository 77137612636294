import React from 'react';
import {getCalendarLunarChartOptions} from 'utils/echarts/poc/calendarLunarChartOptions';
import ReactECharts from 'echarts-for-react'; // Please do not remove this, it has to import before ReactEChartsCore.
import * as echarts from 'echarts/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import {EChartContainer} from 'styles/styled-components/StyledComponents';
import moment from 'moment/moment';

const MonthlyCalendarChart = (props) => {
  const {report, calendarChart} = props;

  return (
    <>
      <div className="mb-4 mongo-chart">
        <div className={`row page page-`}>
          {calendarChart.map((calendar, i) => {
            const parameterName = Object.keys(calendar)[0];
            const data = calendar[parameterName];
            const colors = calendar['colors'];
            const month = moment(report?.startTime).format('YYYY-MM');
            return (
              <div key={i} className={`col-12 col-md-12`}>
                <div className="card rounded-1 mt-3">
                  <div className="card-body">
                    <EChartContainer height={'500px'}>
                      <div className="bar-chart-sec">
                        <div className="p-2">
                          <div className="d-flex justify-content-between">
                            <div>
                              <h3 className="mb-4">{`Month of ${moment(report?.startTime).format(
                                'MMM-YYYY',
                              )} - ${parameterName}`}</h3>
                            </div>
                          </div>
                          <ReactEChartsCore
                            echarts={echarts}
                            option={getCalendarLunarChartOptions(data, colors, month)}
                            notMerge={true}
                            lazyUpdate={true}
                            style={{height: '500px'}}
                          />
                        </div>
                      </div>
                    </EChartContainer>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default React.memo(MonthlyCalendarChart);
