import React from 'react';
import {Spinner} from 'react-bootstrap';
import {LoaderContainer, LoaderInnerContainer} from 'styles/styled-components/StyledComponents';

const LoadingSpinner = () => {
  return (
    <>
      <LoaderContainer>
        <LoaderInnerContainer>
          <Spinner animation="border" />
        </LoaderInnerContainer>
      </LoaderContainer>
    </>
  );
};

export default LoadingSpinner;
