import React from 'react';
import moment from 'moment';
import { REPORT_DATE_TIME_FORMATE } from 'constants/reports';

export const parametersColumn = () => {
  return [
    {
      name: 'Parameters',
      alias: '',
      selector: (row) => (
        <>
          {row.displayName} {row.unit && `(${row.unit})`}
        </>
      ),
    },
    {
      name: 'Average',
      alias: 'average',
      selector: (row) => row.averageValue,
    },
    {
      name: 'Maximum',
      alias: 'maximum',
      selector: (row) => row.maxValue,
    },
    {
      name: 'Maximum Date',
      alias: 'maximumDate',
      selector: (row) => moment(row.maxAlertData).format(REPORT_DATE_TIME_FORMATE),
    },
    {
      name: 'Minimum',
      alias: 'minimum',
      selector: (row) => row.minValue,
    },
    {
      name: 'Minimum Date',
      alias: 'minimumDate',
      selector: (row) => moment(row.minAlertData).format(REPORT_DATE_TIME_FORMATE),
    },
  ];
};
