import React, {useEffect, useState, useRef} from 'react';
import {Form, Button} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import Loader from 'assets/images/loader.gif';
import resetFilterImg from 'assets/images/svg/refresh-icon.svg';
import {useParams} from 'react-router-dom';
import {
  EChartContainer,
  StyledLabel,
  PrimaryButton,
  StyledProgressBar,
} from 'styles/styled-components/StyledComponents';
import SwitchButton from 'components/SwitchButton';
import DashboardMapContainer from 'components/charts/DashboardMapContainer';
import CustomToast from 'components/Toast/CustomToast';
import {
  fetchAqiAndNoiseChart,
  fetchDashboardDevices,
  fetchDashboardRoseGraph,
  fetchGaugeChart,
  fetchHeatChart,
  fetchParameterChart,
  fetchZoneParameterChart,
} from 'features/dashboard/dashboardApi';
import {calculateWindRose} from 'utils/roseDataFormat';
import {getMinutesFromSelection} from 'utils/getMinutesFromSelection';
import {DATE_FILTER_OPTIONS, DEVICE_DEFAULT_DURATION} from 'constants/charts';
import ReactECharts from 'echarts-for-react'; // Please do not remove this, it has to import before ReactEChartsCore.
import * as echarts from 'echarts/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import {getParameterOption} from 'utils/echarts/barAndLineChartOptions';
import LoadingSpinner from 'components/LoadingSpinner';
import NoRecordFound from 'components/NoRecordFound';
import AQIIcon from 'svgs/AQIIcon';
import NoiseLevelIcon from 'svgs/NoiseLevelIcon';
import {WindRoseChart} from 'components/charts/WindRoseChart';
import {GROUP_1, GROUP_2} from 'constants/deviceDashboard';
import DashboardParameterChart from 'components/charts/DashboardParameterChart';
import DashboardTrendChart from 'components/charts/DashboardTrendChart';
import {useTranslation} from 'react-i18next';
import {stackedChartOptions} from 'utils/echarts/stackedChartOptions';
import ChartsPOC from 'pages/DeviceDashboardPOCPage/ChartsPOC';
import 'rc-time-picker/assets/index.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import TimePicker from 'rc-time-picker';
import moment, {months} from 'moment';
import {getLineGradientChartOptions} from 'utils/echarts/poc/lineGradientChartOptions';
import {filterDurationFormate} from 'utils/filterDurationFormate';

const DeviceDashboardContainer = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [devices, setDevices] = useState([]);
  const [zones, setZones] = useState([]);
  const [selectedZone, setSelectedZone] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [group1AllParameters, setGroup1AllParameters] = useState([]);
  const [group2AllParameters, setGroup2AllParameters] = useState([]);
  const [rose, setRose] = useState([]);
  const [deviceId, setDeviceId] = useState('');
  const [deviceParameterId, setDeviceParameterId] = useState('');
  const [deviceParameter2Id, setDeviceParameter2Id] = useState('');
  const [deviceZoneParameterId, setDeviceZoneParameterId] = useState('');
  const [deviceParameterIdTrendChart, setDeviceParameterIdTrendChart] = useState('');
  const [deviceParameter2IdTrendChart, setDeviceParameter2IdTrendChart] = useState('');
  const [filterDate, setFilterDate] = useState(null);
  const [filterDateUnit, setFilterDateUnit] = useState(null);
  const [filterDuration, setFilterDuration] = useState(DEVICE_DEFAULT_DURATION);
  const [prevFilterDuration, setPrevFilterDuration] = useState(DEVICE_DEFAULT_DURATION);
  const [noiseAQIData, setNoiseAQIData] = useState({aqi: null, noise: null});
  const [gaugeData, setGaugeData] = useState([]);
  const [last7DaysTrend, setLast7DaysTrend] = useState(null);
  const [last7DaysTrend2, setLast7DaysTrend2] = useState(null);
  const [last24HrsAQI, setLast24HrsAQI] = useState([]);
  const [last12HrsCO, setLast12HrsCO] = useState([]);
  const [parameterChartData, setParameterChartData] = useState([]);
  const [parameterZoneChartData, setParameterZoneChartData] = useState(null);
  const [parameter2ChartData, setParameter2ChartData] = useState([]);
  const [isZoneVisible, setIsZoneVisible] = useState(false);
  const [selectedZoneId, setSelectedZoneId] = useState('');
  const [comparisonChart, setComparisonChart] = useState({
    pm2_5: [],
    temperature: [],
  });
  const [isLoadingRose, setIsLoadingRose] = useState(false);
  const [parameterDisplayName, setParameterDisplayName] = useState({
    parameter: '',
    parameterUnit: '',
    parameter2: '',
    parameter2Unit: '',
    zoneParameter: '',
    zoneParameterUnit: '',
    trendParameter: '',
    trendParameterUnit: '',
    trendParameter2: '',
    trendParameter2Unit: '',
  });
  const [loading, setLoading] = useState({
    aqiNoise: false,
    parameter: false,
    parameter2: false,
    zoneParameter: false,
    gauge: false,
    heat: false,
    heat2: false,
    aqi24hrs: false,
    co12hrs: false,
    comparison: false,
  });
  const [showDateRange, setShowDateRange] = useState(false);
  const [dates, setDates] = useState({
    startDate: null,
    endDate: null,
  });

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [disabledTime, setDisabledTime] = useState({
    hours: [],
    minutes: [],
  });

  const pickerRef = useRef(null);
  const applyFilterButtonRef = useRef(null);

  const {theme} = props;

  const {t} = useTranslation();

  const navigate = useNavigate();
  const {viewDeviceId} = useParams();

  useEffect(() => {
    (async () => {
      await getDashboardDetails();
    })();
  }, [viewDeviceId]);

  useEffect(() => {
    (async () => {
      if (deviceId && filterDate && filterDate != 'custom') {
        const payload = {
          filterDate: filterDate,
        };
        if (selectedZoneId) {
          payload.zoneId = selectedZoneId;
        } else {
          payload.deviceId = deviceId;
        }
        await getRoseGraphData(payload);
      }
    })();
  }, [deviceId, filterDate, selectedZoneId]);

  useEffect(() => {
    if (deviceId && deviceParameterIdTrendChart) {
      const payload = {};
      if (selectedZoneId) {
        payload.zoneId = selectedZoneId;
      } else {
        payload.deviceId = deviceId;
      }
      const params = {...payload, ...{key: deviceParameterIdTrendChart}};
      getLast7DaysTrendChart(params);
    }
  }, [deviceId, deviceParameterIdTrendChart, selectedZoneId]);

  useEffect(() => {
    if (deviceId && deviceParameter2IdTrendChart) {
      const payload = {};
      if (selectedZoneId) {
        payload.zoneId = selectedZoneId;
      } else {
        payload.deviceId = deviceId;
      }
      const params = {...payload, ...{key: deviceParameter2IdTrendChart}};
      getLast7DaysTrend2Chart(params);
    }
  }, [deviceId, deviceParameter2IdTrendChart, selectedZoneId]);

  useEffect(() => {
    if (deviceId && filterDate && filterDate != 'custom') {
      const payload = {
        filterDate: filterDate,
      };
      if (selectedZoneId) {
        payload.zoneId = selectedZoneId;
      } else {
        payload.deviceId = deviceId;
      }
      const aqiNoiseParams = {...payload, ...{key: 'AQI,noiseLEQ'}};
      const parameterParams = {...payload, ...{key: deviceParameterId, unit: getTimeUnit()}};
      const parameter2Params = {...payload, ...{key: deviceParameter2Id, unit: getTimeUnit()}};
      getAqiAndNoiseChart(aqiNoiseParams);
      getParameterChartData(parameterParams);
      getParameter2ChartData(parameter2Params);
      // if (filterDate !== 'custom') {
      const comparisonParams = {...payload, ...{unit: getTimeUnit()}};
      getComparisonChartData(comparisonParams);
      // }
      if (isZoneVisible && selectedZoneId) {
        const zoneParameterParams = {...payload, ...{key: deviceZoneParameterId, unit: getTimeUnit()}};
        getZoneBarChartData(zoneParameterParams);
      }
    }
  }, [deviceId, filterDate, selectedZoneId]);

  useEffect(() => {
    if (isZoneVisible && filterDate !== 'custom') {
      const payload = {
        filterDate: filterDate,
      };
      if (selectedZoneId) {
        payload.zoneId = selectedZoneId;
        const zoneParameterParams = {...payload, ...{key: deviceZoneParameterId, unit: getTimeUnit()}};
        getZoneBarChartData(zoneParameterParams);
      }
    }
  }, [isZoneVisible, deviceZoneParameterId, filterDate]);

  useEffect(() => {
    if (deviceZoneParameterId && selectedZoneId) {
      const parameterParams = {zoneId: selectedZoneId, key: deviceZoneParameterId, filterDate, unit: getTimeUnit()};
      getZoneBarChartData(parameterParams);
    }
  }, [deviceZoneParameterId]);

  useEffect(() => {
    if (deviceParameterId) {
      const parameterParams = {deviceId, key: deviceParameterId, filterDate, unit: getTimeUnit()};
      getParameterChartData(parameterParams);
    }
  }, [deviceParameterId]);

  useEffect(() => {
    if (deviceParameter2Id) {
      const parameter2Params = {deviceId, key: deviceParameter2Id, filterDate, unit: getTimeUnit()};
      getParameter2ChartData(parameter2Params);
    }
  }, [deviceParameter2Id]);

  useEffect(() => {
    if (deviceId) {
      const payload = {deviceId: deviceId};
      const gaugeParams = {...payload, ...{key: 'AQI,CO,CO2,NO,NO2,O3,SO2,PM1,PM2_5,PM10'}};
      // const aqiLast24Params = {deviceId, key: 'AQI', filterDate: 1400, unit: 'hour'};
      // const coParams = {deviceId, key: 'CO', filterDate: 720, unit: 'hour'};
      getGaugeChart(gaugeParams);
      // getAQILast24HrsChart(aqiLast24Params);
      // getCOLast12HrsChart(coParams);
    }
  }, [deviceId]);

  useEffect(() => {
    (async () => {
      if (selectedDevice) {
        await getDeviceParameters(selectedDevice);
      }
    })();
  }, [selectedDevice]);

  const getTimeUnit = () => {
    const [duration, unit] = filterDuration.split(' ');
    if (duration == 1 && unit == 'hour') {
      return 'minute';
    }
    if (duration == 1 && unit == 'month') {
      return 'day';
    }
    return filterDateUnit;
  };

  const getDashboardDetails = async () => {
    try {
      setIsLoading(true);
      const {data: deviceData} = await fetchDashboardDevices();
      if (deviceData) {
        const selectedDevice = deviceData.device.find((device) => device._id === viewDeviceId);
        let defaultFilter = 'PM2_5';
        let displayName = 'PM2.5';
        let displayUnit = 'μg/m³';
        let defaultFilter2 = 'temperature';
        let displayName2 = 'Temperature';
        let displayUnit2 = '°C';
        if (selectedDevice) {
          const matchedParameters1 = selectedDevice.subDeviceType.find((param) => GROUP_1.includes(param.name));
          if (matchedParameters1) {
            const isPM2_5Exist = selectedDevice.subDeviceType.some((subDevice) => subDevice.name === 'PM2_5');
            if (!isPM2_5Exist) {
              defaultFilter = matchedParameters1 ? matchedParameters1.name : '';
              displayName = matchedParameters1 ? matchedParameters1.displayName : '';
              displayUnit = matchedParameters1 ? matchedParameters1.unit : '';
            }
          }

          const matchedParameters = selectedDevice.subDeviceType.find((param) => GROUP_2.includes(param.name));
          if (matchedParameters) {
            const isTemperatureExist = selectedDevice.subDeviceType.some(
              (subDevice) => subDevice.name === 'temperature',
            );
            if (!isTemperatureExist) {
              defaultFilter2 = matchedParameters ? matchedParameters.name : '';
              displayName2 = matchedParameters ? matchedParameters.displayName : '';
              displayUnit2 = matchedParameters ? matchedParameters.unit : '';
            }
          }
        } else {
          const matchedParameters1 = deviceData.device[0].subDeviceType.find((param) => GROUP_1.includes(param.name));
          if (matchedParameters1) {
            const isPM2_5Exist = deviceData.device[0].subDeviceType.some((subDevice) => subDevice.name === 'PM2_5');
            if (!isPM2_5Exist) {
              defaultFilter = matchedParameters1 ? matchedParameters1.name : '';
              displayName = matchedParameters1 ? matchedParameters1.displayName : '';
              displayUnit = matchedParameters1 ? matchedParameters1.unit : '';
            }
          }

          const matchedParameters = deviceData.device[0].subDeviceType.find((param) => GROUP_2.includes(param.name));
          if (matchedParameters) {
            const isTemperatureExist = deviceData.device[0].subDeviceType.some(
              (subDevice) => subDevice.name === 'temperature',
            );
            if (!isTemperatureExist) {
              defaultFilter2 = matchedParameters ? matchedParameters.name : '';
              displayName2 = matchedParameters ? matchedParameters.displayName : '';
              displayUnit2 = matchedParameters ? matchedParameters.unit : '';
            }
          }
        }

        setDeviceParameterId(defaultFilter);
        setDeviceParameter2Id(defaultFilter2);
        setDeviceZoneParameterId(defaultFilter);
        setDeviceParameterIdTrendChart(defaultFilter);
        setDeviceParameter2IdTrendChart(defaultFilter2);
        setParameterDisplayName({
          parameter: displayName,
          parameterUnit: displayUnit,
          parameter2: displayName2,
          parameter2Unit: displayUnit2,
          zoneParameter: displayName,
          zoneParameterUnit: displayUnit,
          trendParameter: displayName,
          trendParameterUnit: displayUnit,
          trendParameter2: displayName2,
          trendParameter2Unit: displayUnit2,
        });
        setIsZoneVisible(false);
        const {minutes, unit} = getMinutesFromSelection(DEVICE_DEFAULT_DURATION);
        setFilterDate(minutes);
        setFilterDateUnit(unit);
        setShowDateRange(false);
        setFilterDuration(DEVICE_DEFAULT_DURATION);
        resetCustomDateFilter();
        setDevices(deviceData.device);
        setZones(deviceData.zone);
        if (viewDeviceId && selectedDevice) {
          setSelectedDevice(selectedDevice);
          setDeviceId(selectedDevice.masterDevice.deviceId);
        } else {
          setSelectedDevice(deviceData.device[0]);
          setDeviceId(deviceData.device[0].masterDevice.deviceId);
        }
      }
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setIsLoading(false);
    }
  };

  const getAqiAndNoiseChart = async (params) => {
    try {
      setLoading((prevState) => ({
        ...prevState,
        aqiNoise: true,
      }));
      const dates = getDateTime();
      // const param = {deviceId, key: 'AQI,noiseLEQ', filterDate};
      const {data} = await fetchAqiAndNoiseChart({...params, ...dates});
      if (data) {
        setNoiseAQIData({
          aqi: data.find((item) => item.key === 'AQI'),
          noise: data.find((item) => item.key === 'noiseLEQ'),
        });
      } else {
        setNoiseAQIData({
          aqi: null,
          noise: null,
        });
      }
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setLoading((prevState) => ({
        ...prevState,
        aqiNoise: false,
      }));
    }
  };

  const getParameterChartData = async (params) => {
    try {
      setParameterChartData([]);
      setLoading((prevState) => ({
        ...prevState,
        parameter: true,
      }));
      const dates = getDateTime();
      // const param = {deviceId, key: deviceParameterId, filterDate, unit: filterDateUnit};
      const {data} = await fetchParameterChart({...params, ...dates});
      setParameterChartData(data ? data : []);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setLoading((prevState) => ({
        ...prevState,
        parameter: false,
      }));
    }
  };

  const getParameter2ChartData = async (params) => {
    try {
      setParameter2ChartData([]);
      setLoading((prevState) => ({
        ...prevState,
        parameter2: true,
      }));
      const dates = getDateTime();
      // const param = {deviceId, key: deviceParameter2Id, filterDate, unit: filterDateUnit};
      const {data} = await fetchParameterChart({...params, ...dates});
      setParameter2ChartData(data ? data : []);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setLoading((prevState) => ({
        ...prevState,
        parameter2: false,
      }));
    }
  };

  const getZoneBarChartData = async (params) => {
    try {
      if (deviceId && deviceZoneParameterId) {
        setParameterZoneChartData(null);
        setLoading((prevState) => ({
          ...prevState,
          zoneParameter: true,
        }));
        const dates = getDateTime();
        const {data} = await fetchZoneParameterChart({...params, ...dates});
        setParameterZoneChartData(Array.isArray(data) && data.length === 0 ? null : data ? data : null);
      }
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setLoading((prevState) => ({
        ...prevState,
        zoneParameter: false,
      }));
    }
  };

  const getComparisonChartData = async (params) => {
    try {
      setLoading((prevState) => ({
        ...prevState,
        comparison: true,
      }));
      const dates = getDateTime();
      const {data: aqiData} = await fetchParameterChart({...params, ...{key: 'PM2_5'}, ...dates});
      const {data: temperatureData} = await fetchParameterChart({...params, ...{key: 'temperature'}, ...dates});
      setComparisonChart((prevState) => ({
        ...prevState,
        pm2_5: aqiData ? aqiData : [],
        temperature: temperatureData ? temperatureData : [],
      }));
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setLoading((prevState) => ({
        ...prevState,
        comparison: false,
      }));
    }
  };

  const getGaugeChart = async (params) => {
    try {
      setLoading((prevState) => ({
        ...prevState,
        gauge: true,
      }));
      const {data} = await fetchGaugeChart(params);
      setGaugeData(data ? data : []);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setLoading((prevState) => ({
        ...prevState,
        gauge: false,
      }));
    }
  };

  const getLast7DaysTrendChart = async (params) => {
    try {
      setLast7DaysTrend(null);
      setLoading((prevState) => ({
        ...prevState,
        heat: true,
      }));
      // const param = {deviceId, key: deviceParameterIdTrendChart};
      const {data} = await fetchHeatChart(params);
      setLast7DaysTrend(data);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setLoading((prevState) => ({
        ...prevState,
        heat: false,
      }));
    }
  };

  const getLast7DaysTrend2Chart = async (params) => {
    try {
      setLast7DaysTrend2(null);
      setLoading((prevState) => ({
        ...prevState,
        heat2: true,
      }));
      // const param = {deviceId, key: deviceParameter2IdTrendChart};
      const {data} = await fetchHeatChart(params);
      setLast7DaysTrend2(data);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setLoading((prevState) => ({
        ...prevState,
        heat2: false,
      }));
    }
  };

  const getAQILast24HrsChart = async (params) => {
    try {
      if (deviceId) {
        setLast24HrsAQI([]);
        setLoading((prevState) => ({
          ...prevState,
          aqi24hrs: true,
        }));
        // const param = {deviceId, key: 'AQI', filterDate: 1400, unit: 'hour'};
        const {data} = await fetchParameterChart(params);
        setLast24HrsAQI(data ? data : []);
      }
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setLoading((prevState) => ({
        ...prevState,
        aqi24hrs: false,
      }));
    }
  };

  const getCOLast12HrsChart = async (params) => {
    try {
      if (deviceId) {
        setLast12HrsCO([]);
        setLoading((prevState) => ({
          ...prevState,
          co12hrs: true,
        }));
        // const param = {deviceId, key: 'CO', filterDate: 720, unit: 'hour'};
        const {data} = await fetchParameterChart(params);
        setLast12HrsCO(data ? data : []);
      }
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setLoading((prevState) => ({
        ...prevState,
        co12hrs: false,
      }));
    }
  };

  const getRoseGraphData = async (params) => {
    try {
      setIsLoadingRose(true);
      setRose([]);
      const dates = getDateTime();
      const {data: roseData} = await fetchDashboardRoseGraph({...params, ...dates});
      let calculateWindRoseData = [];
      if (roseData) {
        calculateWindRoseData = calculateWindRose({
          direction: roseData.WindDirection,
          speed: roseData.WindSpeed,
        });
      }
      setRose(calculateWindRoseData);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setIsLoadingRose(false);
    }
  };

  const getDeviceParameters = async (selectedDevice) => {
    try {
      const group1Parameters = selectedDevice.subDeviceType.filter((parameter) => GROUP_1.includes(parameter.name));
      const group2Parameters = selectedDevice.subDeviceType.filter(
        (parameter) => !['windDirection'].includes(parameter.name) && GROUP_2.includes(parameter.name),
      );
      setGroup1AllParameters(group1Parameters);
      setGroup2AllParameters(group2Parameters);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    }
  };

  const handleSubDeviceType = (subDeviceType, groupType) => {
    if (groupType === 'group1') {
      setDeviceParameterId(subDeviceType.name);
      setParameterDisplayName((prevState) => ({
        ...prevState,
        parameter: subDeviceType.displayName,
        parameterUnit: subDeviceType.unit ? subDeviceType.unit : null,
      }));
    } else if (groupType === 'stackLineChart') {
      setDeviceZoneParameterId(subDeviceType.name);
      setParameterDisplayName((prevState) => ({
        ...prevState,
        zoneParameter: subDeviceType.displayName,
        zoneParameterUnit: subDeviceType.unit ? subDeviceType.unit : null,
      }));
    } else {
      setDeviceParameter2Id(subDeviceType.name);
      setParameterDisplayName((prevState) => ({
        ...prevState,
        parameter2: subDeviceType.displayName,
        parameter2Unit: subDeviceType.unit ? subDeviceType.unit : null,
      }));
    }
  };

  const handleTrendSubDeviceType = (subDeviceType, groupType) => {
    if (groupType === 'group1') {
      setDeviceParameterIdTrendChart(subDeviceType.name);
      setParameterDisplayName((prevState) => ({
        ...prevState,
        trendParameter: subDeviceType.displayName,
        trendParameterUnit: subDeviceType.unit ? subDeviceType.unit : null,
      }));
    } else {
      setDeviceParameter2IdTrendChart(subDeviceType.name);
      setParameterDisplayName((prevState) => ({
        ...prevState,
        trendParameter2: subDeviceType.displayName,
        trendParameter2Unit: subDeviceType.unit ? subDeviceType.unit : null,
      }));
    }
  };

  const handleSelectDevice = async (device) => {
    // if (viewDeviceId !== device.masterDevice.deviceId) {
    //   navigate(`/app/device/dashboard`);
    // }
    let defaultFilter = 'PM2_5';
    let displayName = 'PM2.5';
    let displayUnit = 'μg/m³';
    let defaultFilter2 = 'temperature';
    let displayName2 = 'Temperature';
    let displayUnit2 = '°C';

    const matchedParameters1 = device.subDeviceType.find((param) => GROUP_1.includes(param.name));
    if (matchedParameters1) {
      const isPM2_5Exist = device.subDeviceType.some((subDevice) => subDevice.name === 'PM2_5');
      if (!isPM2_5Exist) {
        defaultFilter = matchedParameters1.name;
        displayName = matchedParameters1.displayName;
        displayUnit = matchedParameters1.unit;
      }
    }
    const matchedParameters = device.subDeviceType.find((param) => GROUP_2.includes(param.name));
    if (matchedParameters) {
      const isTemperatureExist = device.subDeviceType.some((subDevice) => subDevice.name === 'temperature');
      if (!isTemperatureExist) {
        defaultFilter2 = matchedParameters.name;
        displayName2 = matchedParameters.displayName;
        displayUnit2 = matchedParameters.unit;
      }
    }
    setIsZoneVisible(false);
    setSelectedZoneId(null);
    setSelectedZone(null);
    setDeviceParameterId(defaultFilter);
    setDeviceParameter2Id(defaultFilter2);
    setDeviceParameterIdTrendChart(defaultFilter);
    setDeviceParameter2IdTrendChart(defaultFilter2);
    setParameterDisplayName({
      parameter: displayName,
      parameterUnit: displayUnit,
      parameter2: displayName2,
      parameter2Unit: displayUnit2,
      trendParameter: displayName,
      trendParameterUnit: displayUnit,
      trendParameter2: displayName2,
      trendParameter2Unit: displayUnit2,
    });
    setSelectedDevice(device);
    setDeviceId(device.masterDevice.deviceId);
    const params = {deviceId: device.masterDevice.deviceId, filterDate: filterDate};
    const aqiNoiseParams = {deviceId: device.masterDevice.deviceId, key: 'AQI,noiseLEQ', filterDate};
    const parameterParams = {
      deviceId: device.masterDevice.deviceId,
      key: defaultFilter,
      filterDate,
      unit: getTimeUnit(),
    };
    const parameter2Params = {
      deviceId: device.masterDevice.deviceId,
      key: defaultFilter2,
      filterDate,
      unit: getTimeUnit(),
    };
    getAqiAndNoiseChart(aqiNoiseParams);
    getParameterChartData(parameterParams);
    getParameter2ChartData(parameter2Params);
    getRoseGraphData(params);
  };

  const handleFilterByDate = (event) => {
    const value = event.target.value;
    setPrevFilterDuration(filterDuration);
    setFilterDuration(value);
    if (value === 'custom') {
      setFilterDate(value);
      setShowDateRange(true);
    } else {
      setShowDateRange(false);
      resetCustomDateFilter();
      const {minutes, unit} = getMinutesFromSelection(value);
      setFilterDate(minutes);
      setFilterDateUnit(unit);
    }
  };

  const handleFilter = async () => {
    try {
      if (filterDate === 'custom') {
        if (!dates.startDate || !dates.endDate) {
          toast(<CustomToast type="error" title="Error" message={'Please fill date'} />);
          return;
        }
        if (!startTime && endTime) {
          toast(<CustomToast type="error" title="Error" message={'Please fill start time'} />);
          return;
        }
        if (startTime && !endTime) {
          toast(<CustomToast type="error" title="Error" message={'Please fill end time'} />);
          return;
        }
      }

      const aqiNoiseParams = {deviceId, key: 'AQI,noiseLEQ', filterDate};
      const parameterParams = {deviceId, key: deviceParameterId, filterDate, unit: getTimeUnit()};
      const parameter2Params = {deviceId, key: deviceParameter2Id, filterDate, unit: getTimeUnit()};
      const roseParams = {deviceId: deviceId, filterDate: filterDate};
      const comparisonParams = {deviceId, filterDate, unit: getTimeUnit()};

      getAqiAndNoiseChart(aqiNoiseParams);
      getRoseGraphData(roseParams);
      getParameterChartData(parameterParams);
      getParameter2ChartData(parameter2Params);
      // getAqiAndNoiseChart(aqiNoiseParams);
      // getParameterChartData(parameterParams);
      // getParameter2ChartData(parameter2Params);
      getComparisonChartData(comparisonParams);
      if (isZoneVisible) {
        const zoneParameterParams = {
          zoneId: selectedZoneId,
          key: deviceZoneParameterId,
          filterDate,
          unit: getTimeUnit(),
        };
        getZoneBarChartData(zoneParameterParams);
      }
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    }
  };

  const getDateTime = () => {
    let dateRange = null;
    if (dates.startDate && dates.endDate) {
      dateRange = {
        startDate: moment(dates.startDate).format('YYYY-MM-DD'),
        endDate: moment(dates.endDate).format('YYYY-MM-DD'),
      };
    }
    if (startTime && endTime) {
      dateRange['startTime'] = startTime.format('HH:mm');
      dateRange['endTime'] = endTime.format('HH:mm');
    }
    return dateRange;
  };

  const handleClearFilter = async () => {
    setShowDateRange(false);
    resetCustomDateFilter();
    setFilterDuration(DEVICE_DEFAULT_DURATION);
    setPrevFilterDuration(DEVICE_DEFAULT_DURATION);
    const {minutes} = getMinutesFromSelection(DEVICE_DEFAULT_DURATION);
    setFilterDate(minutes);
  };

  const resetCustomDateFilter = () => {
    setStartTime(null);
    setEndTime(null);
    const inputField = document.getElementById('date-range');
    if (inputField) {
      inputField.value = '';
      pickerRef.current.setStartDate(null);
      pickerRef.current.setEndDate(null);
    }
    setDates((prevState) => ({
      ...prevState,
      startDate: null,
      endDate: null,
    }));
  };

  const handleApply = (event, picker) => {
    const startDate = picker.startDate.format('MM/DD/YYYY');
    const endDate = picker.endDate.format('MM/DD/YYYY');
    picker.element.val(startDate + ' - ' + endDate);
    setDates((prevState) => ({
      ...prevState,
      startDate: startDate,
      endDate: endDate,
    }));
  };

  const handleCancel = (event, picker) => {
    picker.element.val('');
    setDates((prevState) => ({
      ...prevState,
      startDate: null,
      endDate: null,
    }));
  };

  const isBeforeTime = (time1, time2) => time1.minutes() + time1.hours() * 60 < time2.minutes() + time2.hours();

  const handleValueChange1 = (value) => {
    if (!value) {
      setStartTime(value);
      return;
    }
    setStartTime(value.clone());
    setEndTime((previousendTime) =>
      previousendTime && isBeforeTime(value, previousendTime)
        ? previousendTime.clone()
        : value.clone().add(15, 'minutes'),
    );
    // Disable past hours and times for endTime
    const hrs = value.hours();
    const mins = value.minutes();
    const disabledHrs = [];
    const disabledMins = [];
    const currentTime = moment();
    for (let i = 0; i < 24; i++) {
      // if (i < hrs || (i === hrs && currentTime.minutes() > mins)) {
      if (i < hrs) {
        disabledHrs.push(i);
      }
    }
    // Disable minutes for the selected hour if it's the current hour
    if (value.hours() === hrs) {
      for (let i = 0; i < 60; i += 15) {
        if (i <= mins) {
          disabledMins.push(i);
        }
      }
    }
    setDisabledTime((prevState) => ({
      ...prevState,
      hours: disabledHrs,
      minutes: disabledMins,
    }));
  };

  const handleValueChange2 = (value) => {
    if (!value) {
      setEndTime(value);
      return;
    }
    setEndTime(value.clone());
    const disabledMins = [];
    // Disable minutes for the selected hour if it's the current hour
    if (startTime.format('HH') === value.format('HH')) {
      for (let i = 0; i < 60; i += 15) {
        if (i <= startTime.format('mm')) {
          disabledMins.push(i);
        }
      }
    }
    setDisabledTime((prevState) => ({
      ...prevState,
      minutes: disabledMins,
    }));
  };

  const handleSwitchToggle = () => {
    if (isZoneVisible) {
      setSelectedZoneId('');
      setSelectedZone(null);
      setSelectedDevice(devices[0]);
    } else {
      setSelectedZoneId(zones[0]._id);
      setSelectedZone([zones[0]]);
      setSelectedDevice(null);
    }
    setIsZoneVisible(!isZoneVisible);
  };

  const handleMapZoneClick = async (selectedZoneId) => {
    const zoneId = selectedZoneId._id;
    setSelectedZoneId(zoneId);
    setSelectedDevice(null);
    setSelectedZone([selectedZoneId]);
    if (filterDate === 'custom') {
      const aqiNoiseParams = {zoneId, key: 'AQI,noiseLEQ', filterDate};
      getAqiAndNoiseChart(aqiNoiseParams);

      const comparisonParams = {zoneId, filterDate, unit: getTimeUnit()};
      getComparisonChartData(comparisonParams);

      const trandParams = {zoneId, key: deviceParameterIdTrendChart};
      getLast7DaysTrendChart(trandParams);

      const trand2Params = {zoneId, key: deviceParameter2IdTrendChart};
      getLast7DaysTrend2Chart(trand2Params);

      const roseParams = {zoneId, filterDate: filterDate};
      getRoseGraphData(roseParams);

      // const gaugeParams = {
      //   zoneId,
      //   key: 'AQI,CO,CO2,NO,NO2,O3,SO2,PM1,PM2_5,PM10',
      // };
      // getGaugeChart(gaugeParams);
    }
  };

  return (
    <>
      <div className={`${theme || ''}`}>
        {isLoading ? (
          <div className="loader-warp">
            <img src={Loader} alt="loader" />
          </div>
        ) : deviceId ? (
          <>
            <div className="filter-card mb-4">
              <div className="row">
                <div className="col d-flex justify-content-between align-items-end flex-column flex-md-row">
                  <div className="d-flex flex-wrap align-items-end mob-100 gap-2">
                    <Form.Group className="me-0 me-md-2 mb-xxl-0 mob-100" controlId="deviceId">
                      <StyledLabel>{t('Filter By Duration')}</StyledLabel>
                      <Form.Select name="status" className="" value={t(filterDuration)} onChange={handleFilterByDate}>
                        {DATE_FILTER_OPTIONS.map((date, index) => (
                          <option key={index} value={date.value}>
                            {t(date.label)}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    {showDateRange && (
                      <>
                        <Form.Group className="me-0 me-md-2 mb-xxl-0 mob-100" controlId="roleName">
                          <StyledLabel>{t('Filter By Date')}</StyledLabel>
                          <DateRangePicker
                            ref={pickerRef}
                            initialSettings={{
                              autoUpdateInput: false,
                              locale: {
                                cancelLabel: 'Clear',
                              },
                              // minDate: moment().subtract(1, 'months'),
                              maxDate: moment(),
                              maxSpan: {months: 1},
                            }}
                            onApply={handleApply}
                            onCancel={handleCancel}
                          >
                            <input type="text" id="date-range" className="form-control col-4" defaultValue="" />
                          </DateRangePicker>
                        </Form.Group>
                        <Form.Group className="me-0 d-grid me-md-2 mb-xxl-0 mob-100 time-picker" controlId="roleName">
                          <StyledLabel>{t('Start time')}</StyledLabel>
                          <TimePicker
                            value={startTime}
                            minuteStep={15}
                            showSecond={false}
                            onChange={handleValueChange1}
                          />
                        </Form.Group>

                        <Form.Group className="me-0 d-grid me-md-2 mb-xxl-0 mob-100 time-picker" controlId="roleName">
                          <StyledLabel>{t('End time')}</StyledLabel>

                          <TimePicker
                            value={endTime}
                            disabledHours={() => (disabledTime.hours.length > 0 ? disabledTime.hours : null)}
                            disabledMinutes={() => (disabledTime.minutes.length > 0 ? disabledTime.minutes : null)}
                            minuteStep={15}
                            showSecond={false}
                            onChange={handleValueChange2}
                          />
                        </Form.Group>
                        <PrimaryButton
                          ref={applyFilterButtonRef}
                          variant="outline-primary"
                          className="apply me-2 mb-xxl-0"
                          onClick={handleFilter}
                        >
                          {t('Apply')}
                        </PrimaryButton>
                        <Button
                          variant="outline-primary"
                          className="mb-xxl-0"
                          type="button"
                          onClick={handleClearFilter}
                        >
                          <img src={resetFilterImg} alt="Filter" />
                        </Button>
                      </>
                    )}
                  </div>
                  <div className="d-flex flex-wrap align-items-end mob-100 gap-2">
                    <Form.Group className="me-0 mb-3 mb-xxl-0" controlId="showZones">
                      <StyledLabel>{t('Zones view')}</StyledLabel>
                      <SwitchButton
                        leftLabel={'Enable'}
                        rightLabel={'Disable'}
                        isDisableInactive={true}
                        value={isZoneVisible}
                        handleSetValue={handleSwitchToggle}
                        isDisableActive={true}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-4">
                <Form.Group className="mb-3 position-relative" controlId="deviceId">
                  <StyledLabel>{t('Filter By Duration')}</StyledLabel>
                  <Form.Select
                    name="status"
                    className="mb-2"
                    defaultValue={t(DEVICE_DEFAULT_DURATION)}
                    onChange={handleFilterByDate}
                  >
                    {DATE_FILTER_OPTIONS.map((date, index) => (
                      <option key={index} value={date.value}>
                        {t(date.label)}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>
            </div> */}
            <div className="row mb-4 mongo-chart">
              <div className="col-12 col-md-8">
                <div className="chart-container">
                  <div style={{display: 'flex', height: '25rem'}}>
                    <DashboardMapContainer
                      devices={devices}
                      zones={zones}
                      deviceId={''}
                      selectedZone={selectedZone}
                      selectedDevice={selectedDevice}
                      isPolygonVisible={isZoneVisible}
                      viewDeviceId={viewDeviceId}
                      showZoneColor={false}
                      isZoneVisible={isZoneVisible}
                      handleSelectDevice={handleSelectDevice}
                      handleMapZoneClick={handleMapZoneClick}
                    />
                  </div>
                </div>
              </div>
              {/* AQI and Noise Level */}
              <div className="col-12 col-md-4">
                <div className="card rounded-2 parameter-card dark-container">
                  <div className="card-body p-4">
                    <div className="dashboard-reading-para">
                      <div className="reading-block position-relative pb-4">
                        <div className="row">
                          <div className="col-12 d-flex justify-content-between mb-4">
                            <h5 className="label dark-text">{t('AQI')}</h5>
                            <span className="duration dark-text">{filterDurationFormate(filterDuration)}</span>
                          </div>
                        </div>
                        {loading.aqiNoise ? (
                          <LoadingSpinner />
                        ) : noiseAQIData.aqi ? (
                          <>
                            <h2 className="value">{noiseAQIData.aqi.value}</h2>
                            <div className="para-icon position-absolute">
                              <AQIIcon color={noiseAQIData.aqi.colorCode} />
                            </div>
                          </>
                        ) : (
                          <NoRecordFound />
                        )}
                      </div>
                      <div className="reading-block position-relative mt-4">
                        <div className="row">
                          <div className="col-12 d-flex justify-content-between mb-4">
                            <h5 className="label dark-text">
                              {t('Noise Level')} {`(dB)`}
                            </h5>
                            <span className="duration dark-text">{filterDurationFormate(filterDuration)}</span>
                          </div>
                        </div>
                        {loading.aqiNoise ? (
                          <LoadingSpinner />
                        ) : noiseAQIData.noise ? (
                          <>
                            <h2 className="value">{noiseAQIData.noise.value}</h2>
                            <div className="para-icon position-absolute">
                              <NoiseLevelIcon color={noiseAQIData.noise.colorCode} />
                            </div>
                          </>
                        ) : (
                          <NoRecordFound />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Parameter for all Group 1 Reading Bar and Line Chart */}
            {/* {theme === 'dark' && filterDuration == '1 month' ? ( */}
            {isZoneVisible ? (
              <>
                <DashboardParameterChart
                  groupType={'stackLineChart'}
                  chartTitle={`${parameterDisplayName.zoneParameter} ${`${
                    parameterDisplayName.zoneParameterUnit ? `(${parameterDisplayName.zoneParameterUnit})` : ''
                  }`}`}
                  filterDuration={filterDuration}
                  prevFilterDuration={prevFilterDuration}
                  customDate={{startDate: dates.startDate, startDate: dates.endDate, startTime, endTime}}
                  loading={loading.zoneParameter}
                  parameterChartData={parameterZoneChartData}
                  allParameters={group1AllParameters}
                  deviceParameterName={deviceZoneParameterId}
                  handleSubDeviceType={handleSubDeviceType}
                />
                {/* <ChartsPOC group1AllParameters={group1AllParameters} /> */}
              </>
            ) : (
              <>
                {group1AllParameters.length > 0 && (
                  <DashboardParameterChart
                    groupType={'group1'}
                    chartTitle={`${t('AQI Reading')} - ${parameterDisplayName.parameter} ${`${
                      parameterDisplayName.parameterUnit ? `(${parameterDisplayName.parameterUnit})` : ''
                    }`}`}
                    filterDuration={filterDuration}
                    prevFilterDuration={prevFilterDuration}
                    customDate={{startDate: dates.startDate, startDate: dates.endDate, startTime, endTime}}
                    loading={loading.parameter}
                    parameterChartData={parameterChartData}
                    allParameters={group1AllParameters}
                    deviceParameterName={deviceParameterId}
                    handleSubDeviceType={handleSubDeviceType}
                  />
                )}

                {/* Parameter for all Group 2 Reading Bar and Line Chart */}
                {group2AllParameters.length > 0 && (
                  <DashboardParameterChart
                    groupType={'group2'}
                    chartTitle={`${t('Meteorological Reading')} - ${parameterDisplayName.parameter2} ${`${
                      parameterDisplayName.parameter2Unit ? `(${parameterDisplayName.parameter2Unit})` : ``
                    }`}`}
                    filterDuration={filterDuration}
                    prevFilterDuration={prevFilterDuration}
                    customDate={{startDate: dates.startDate, endDate: dates.endDate, startTime, endTime}}
                    loading={loading.parameter2}
                    parameterChartData={parameter2ChartData}
                    allParameters={group2AllParameters}
                    deviceParameterName={deviceParameter2Id}
                    handleSubDeviceType={handleSubDeviceType}
                  />
                )}
              </>
            )}

            {/* Group 1 hitmap */}
            {group1AllParameters.length > 0 && (
              <DashboardTrendChart
                groupType={'group1'}
                chartTitle={`${t('AQI Trend Last 7 Days')} - ${parameterDisplayName.trendParameter} ${`${
                  parameterDisplayName.trendParameterUnit ? `(${parameterDisplayName.trendParameterUnit})` : ``
                }`}`}
                last7DaysTrend={last7DaysTrend}
                loading={loading.heat}
                allParameters={group1AllParameters}
                deviceParameterName={deviceParameterIdTrendChart}
                handleTrendSubDeviceType={handleTrendSubDeviceType}
              />
            )}

            {/* Group 2 hitmap */}
            {group2AllParameters.length > 0 && (
              <DashboardTrendChart
                groupType={'group2'}
                chartTitle={`${t('Meteorological Trend Last 7 Days')} - ${parameterDisplayName.trendParameter2} ${`${
                  parameterDisplayName.trendParameter2Unit ? `(${parameterDisplayName.trendParameter2Unit})` : ``
                }`}`}
                last7DaysTrend={last7DaysTrend2}
                loading={loading.heat2}
                allParameters={group2AllParameters}
                deviceParameterName={deviceParameter2IdTrendChart}
                handleTrendSubDeviceType={handleTrendSubDeviceType}
              />
            )}

            {/* Compare Chart */}
            <div className="row mb-4 mongo-chart">
              <div className="col-12 col-md-12">
                <EChartContainer height={'600px'} className="dark-container">
                  <div className="d-flex justify-content-between p-2">
                    <div>
                      <h5 className="dark-text">{t('PM 2.5 and Temperature')}</h5>
                    </div>
                    <div className="dark-text">{filterDurationFormate(filterDuration)}</div>
                  </div>
                  {loading.comparison ? (
                    <LoadingSpinner />
                  ) : comparisonChart.pm2_5.length > 0 || comparisonChart.temperature.length > 0 ? (
                    <>
                      <ReactEChartsCore
                        echarts={echarts}
                        option={getLineGradientChartOptions(
                          comparisonChart,
                          filterDuration,
                          startTime,
                          prevFilterDuration,
                        )}
                        notMerge={true}
                        lazyUpdate={true}
                        style={{height: '550px'}}
                      />
                    </>
                  ) : (
                    <>
                      <NoRecordFound />
                    </>
                  )}
                </EChartContainer>
              </div>
            </div>

            {/* AQI Last 12 Hours */}
            {/* <div className="row mb-4 mongo-chart">
              <div className="col-12 col-md-12">
                <EChartContainer height={'400px'} className="dark-container">
                  <div className="p-2">
                    <h5 className="dark-text">{t('AQI Last 24 Hours')}</h5>
                  </div>
                  {loading.aqi24hrs ? (
                    <LoadingSpinner />
                  ) : last24HrsAQI.length > 0 ? (
                    <>
                      <ReactEChartsCore
                        echarts={echarts}
                        option={getParameterOption('bar', last24HrsAQI)}
                        notMerge={true}
                        lazyUpdate={true}
                        style={{height: '350px'}}
                      />
                    </>
                  ) : (
                    <>
                      <NoRecordFound />
                    </>
                  )}
                </EChartContainer>
              </div>
            </div> */}
            {/* CO Hourly Reading */}
            {/* <div className="row mb-4 mongo-chart">
              <div className="col-12 col-md-12">
                <EChartContainer height={'400px'} className="dark-container">
                  <div className="p-2">
                    <h5 className="dark-text">{t('CO Last 12 Hours')} (ppb)</h5>
                  </div>
                  {loading.co12hrs ? (
                    <LoadingSpinner />
                  ) : last12HrsCO.length > 0 ? (
                    <>
                      <ReactEChartsCore
                        echarts={echarts}
                        option={getParameterOption('bar', last12HrsCO)}
                        notMerge={true}
                        lazyUpdate={true}
                        style={{height: '350px'}}
                      />
                    </>
                  ) : (
                    <>
                      <NoRecordFound />
                    </>
                  )}
                </EChartContainer>
              </div>
            </div> */}
            <div className="row mb-4 mongo-chart">
              <div className="col-12 col-md-6">
                <div className="windchart-container dark-container">
                  <div className="bar-chart-sec">
                    <div className="p-2">
                      <div className="d-flex justify-content-between">
                        <div>
                          <h3 className="mb-4 dark-text">{t('Wind Rose')}</h3>
                        </div>
                        <div className="dark-text">{filterDurationFormate(filterDuration)}</div>
                      </div>

                      {isLoadingRose ? (
                        <LoadingSpinner />
                      ) : rose.length > 0 ? (
                        <>
                          <WindRoseChart
                            data={rose}
                            columns={['angle', '0-1', '1-2', '2-3', '3-4', '4-5', '5-6', '6-7', '7+']}
                            responsive={true} // esponsive default, value: false
                            legendGap={20} // width gap size in px between chart and legend
                            height={450}
                            width={450}
                          />
                        </>
                      ) : (
                        <NoRecordFound />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="windchart-container dark-container">
                  <div className="bar-chart-sec">
                    <div className="p-2">
                      <h3 className="mb-4 dark-text">{t('Real Time')}</h3>
                      {loading.gauge ? (
                        <LoadingSpinner />
                      ) : gaugeData.length > 0 ? (
                        <>
                          <div className="param-live-data custom-scrollbar">
                            {gaugeData.map((gauge, index) => {
                              const parameterPercentage = (gauge.value / gauge.hazardousValue) * 100;
                              return (
                                <div className="graph-set mb-3" key={index}>
                                  <div className="row">
                                    <div className="col-12 d-flex justify-content-between">
                                      <span className="progress-label mb-1 dark-text">{gauge.key}</span>
                                      <span className="progress-value mb-1 dark-text">{`${gauge.value} ${
                                        gauge.unit ? gauge.unit : ``
                                      }`}</span>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="yellow">
                                        <StyledProgressBar color={gauge.colorCode} now={parameterPercentage} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </>
                      ) : (
                        <NoRecordFound />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row mb-4 mongo-chart">
              <div className="col-12 col-md-12">
                <EChartContainer height={'400px'}>
                  <div className="p-2">
                    <h5>AQI Reading</h5>
                  </div>
                  <ReactEChartsCore
                    echarts={echarts}
                    option={stackedChartOptions()}
                    notMerge={true}
                    lazyUpdate={true}
                    style={{height: '350px'}}
                  />
                </EChartContainer>
              </div>
            </div> */}
          </>
        ) : (
          <>{t('There are no active Tenants/ Devices right now. Please action to view the dashboard.')}</>
        )}
      </div>
    </>
  );
};

export default React.memo(DeviceDashboardContainer);
