export const formatRoleFormData = (data) => {
    const permissions = data
      .filter((row) => {
        // Filter out rows where at least one permission is selected
        return Object.keys(row).some((key) => key !== 'id' && key !== 'name' && row[key]);
      })
      .map((row) => {
        // Create permission object for each selected permission
        const permissionKeys = Object.keys(row).filter((key) => {
          return key !== 'id' && key !== 'name' && row[key];
        });
        return {
          routeId: row.id,
          access: permissionKeys,
        };
      });

    const roleObject = {
      permission: permissions,
    };

    return roleObject;
}