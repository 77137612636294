import React from 'react';
import {useForm, useFieldArray} from 'react-hook-form';
import {Form} from 'react-bootstrap';
import CustomModal from 'components/shared/CustomModal';
import {StyledErrorLabel, StyledLabel} from 'styles/styled-components/StyledComponents';
import {SUBSCRIBER_LIMIT} from 'constants/reports';
import {useTranslation} from 'react-i18next';

const ManageSubscriberModel = (props) => {
  const {
    reportId,
    show,
    selectedSubscriber,
    isSubmit,
    setShowSubscriberModel,
    setSelectedSubscriber,
    handleManageSubscriber,
  } = props;
  const {t} = useTranslation();

  const defaultContacts = selectedSubscriber
    ? [{name: selectedSubscriber.name, email: selectedSubscriber.email}]
    : Array.from({length: SUBSCRIBER_LIMIT}, () => ({name: '', email: ''}));

  const {
    register,
    control,
    handleSubmit,
    formState: {errors},
    setError,
  } = useForm({
    defaultValues: {
      subscribers: defaultContacts,
    },
  });

  const {fields} = useFieldArray({
    control,
    name: 'subscribers',
  });

  const handleCloseButton = () => {
    setShowSubscriberModel(false);
    if (selectedSubscriber) {
      setSelectedSubscriber(null);
    }
  };

  const onSubmit = async (formData) => {
    let hasError = false;
    const uniqueEmails = new Set();
  
    // Check if all rows are empty
    const allRowsEmpty = formData.subscribers.every((subscriber) => !subscriber.name && !subscriber.email);
    // If all rows are empty, show error for the first row only
    if (allRowsEmpty || !formData.subscribers[0].name || !formData.subscribers[0].email) {
      setError(`subscribers.0.name`, {
        type: 'manual',
        message: t('Name is required'),
      });
      setError(`subscribers.0.email`, {
        type: 'manual',
        message: t('Email is required'),
      });
      hasError = true;
    }
  
    // Iterate through each row
    formData.subscribers.forEach((subscriber, index) => {
      const cleanedEmail = subscriber.email.trim();
      if (!subscriber.name && cleanedEmail) {
        setError(`subscribers.${index}.name`, {
          type: 'manual',
          message: t('Name is required'),
        });
        hasError = true;
      } else if (subscriber.name && !cleanedEmail) {
        setError(`subscribers.${index}.email`, {
          type: 'manual',
          message: t('Email is required'),
        });
        hasError = true;
      } else if (cleanedEmail) {
        // Check for duplicate emails
        if (uniqueEmails.has(cleanedEmail)) {
          setError(`subscribers.${index}.email`, {
            type: 'manual',
            message: t('Email must be unique'),
          });
          hasError = true;
        } else {
          uniqueEmails.add(cleanedEmail);
          formData.subscribers[index].email = cleanedEmail; // Update the email with cleaned value
        }
      }
    });
  
    if (!hasError) {
      const data = formData.subscribers
        .filter((subscriber) => subscriber.name && subscriber.email)
        .map((subscriber) => {
          return {
            ...subscriber,
            reportId: reportId,
          };
        });
      const action = selectedSubscriber ? 'edit' : 'add';
      handleManageSubscriber(data, action);
    }
  };

  return (
    <>
      <CustomModal
        size="lg"
        show={show}
        handleClose={handleCloseButton}
        title={<h2>{`${selectedSubscriber ? t('Edit') : t('Create')} ${t('Subscriber')}`}</h2>}
        body={
          <>
            {fields.map((item, index) => (
              <div className="row" key={item.id}>
                <div className="col-12 col-md-6">
                  <Form.Group className="mb-4 me-2 position-relative" controlId="name">
                    <StyledLabel>
                      {t('Name')}
                      {index === 0 && <span className="required" />}
                    </StyledLabel>
                    <Form.Control
                      type="text"
                      placeholder={t("Enter Name")}
                      autoComplete="off"
                      {...register(`subscribers.${index}.name`, {
                        pattern: {
                          value: /^[a-zA-Z ]+$/,
                          message: t('Please enter valid name'),
                        },
                      })}
                    />
                    {errors.subscribers && errors.subscribers[index] && errors.subscribers[index].name && (
                      <StyledErrorLabel>{errors.subscribers[index].name.message}</StyledErrorLabel>
                    )}
                  </Form.Group>
                </div>
                <div className="col-12 col-md-6">
                  <Form.Group className="mb-4 me-2 position-relative" controlId="email">
                    <StyledLabel>
                      {t('Email')}
                      {index === 0 && <span className="required" />}
                    </StyledLabel>
                    <Form.Control
                      type="text"
                      placeholder={t("Enter Email ID")}
                      autoComplete="off"
                      {...register(`subscribers.${index}.email`, {
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: t('Entered value does not match email format'),
                        },
                      })}
                    />
                    {errors.subscribers && errors.subscribers[index] && errors.subscribers[index].email && (
                      <StyledErrorLabel>{errors.subscribers[index].email.message}</StyledErrorLabel>
                    )}
                  </Form.Group>
                </div>
              </div>
            ))}
          </>
        }
        buttons={[
          {label: t('Discard'), type: 'primary', onClickHandler: handleCloseButton},
          {
            label: `${selectedSubscriber ? t('Update') : t('Create')}`,
            type: 'primary',
            disabled: isSubmit || Object.keys(errors).length > 0,
            onClickHandler: handleSubmit(onSubmit),
          },
        ]}
      />
    </>
  );
};

export default ManageSubscriberModel;
