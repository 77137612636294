import React from 'react';
import styled from 'styled-components';
import {ToastContainer} from 'react-toastify';

export const WrappedToastContainer = ({className, ...rest}) => (
  <div className={className}>
    <ToastContainer {...rest} />
  </div>
);

export default styled(WrappedToastContainer).attrs({
  // custom props
})`
  .Toastify__toast-icon.Toastify--animate-icon {
    display: none;
  }
  .Toastify__toast-container {
    margin: 0px !important;
    max-width: 100vw !important;
    width: 400px;
    padding: 0px;
  }
  .Toastify__toast {
    padding: 0px;
    margin: 0px;
    overflow: visible;
    width: 100%;
    margin-bottom: 10px;
  }
  .Toastify__toast-body {
    padding: 0px;
    margin: 0px;
    all: unset;
    width: 100%;
  }
  .custom-toast {
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    position: relative;
  }
  .toast-icon {
    width: 18%;
    padding: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px 0px 0px 5px;
  }
  .toast-icon.success-block {
    background-color: #4fce66;
  }
  .toast-icon.warning-block {
    background-color: #ffc727;
  }
  .toast-icon.error-block {
    background-color: #ff2727;
  }
  .toast-content {
    width: 82%;
    padding: 5px 10px;
    height: 100%;
    display: flex;
    align-items: center;
    flex-flow: column;
    justify-content: start;
    overflow: hidden;
  }
  .toast-content .toast-title {
    font-size: 17px;
    color: #041e42;
    font-weight: 700;
    width: 100%;
    line-height: 24px;
    margin-bottom: 0px;
  }
  .toast-content .toast-message {
    font-size: 13px;
    color: #c1c6c8;
    font-weight: 500;
    width: 100%;
    line-height: 16px;
  }
  .toast-close {
    position: absolute;
    right: -9px;
    width: auto;
    padding: 0;
    top: -9px;
    height: auto;
  }
  .toast-close .close-button {
    all: unset;
    cursor: pointer;

  }
  .toast-close .close-button:hover img{
    filter: brightness(1);
  }
`;
