import React from 'react';
import Action from 'components/datatable/Action';

export const subTenantListColumn = (filters, handleEdit, handleDelete, t) => {
  return [
    {
      name: t('Serial No'),
      selector: (row) => row.serial_no,
      cell: (row, index) => {
        return (filters.pageNumber - 1) * filters.pageSize + index + 1;
      },
    },
    {
      name: t('Sub Tenant Name'),
      alias: 'subTenantName',
      selector: (row) => row.subTenantName,
      sortable: true,
      sortFunction: (a, b) => a.subTenantName?.toLowerCase().localeCompare(b.subTenantName?.toLowerCase()),
    },
    {
      name: t('Device Type'),
      alias: 'deviceType',
      selector: (row) => (
        <>
          <img className="me-1" src={`/icons/${row.deviceAlias}.svg`} alt="Noice Sensor" />
          <span>{row.deviceType}</span>
        </>
      ),
    },
    {
      name: t('Add Ons'),
      alias: 'addons',
      selector: (row) => (row.addons ? row.addons : 'N/A'),
    },
    {
      name: t('Action'),
      button: true,
      cell: (row) => <Action row={row} handleEdit={handleEdit} handleDelete={handleDelete} />,
    },
  ];
};
