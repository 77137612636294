import api from 'api/axios';
import {formatFilter} from 'utils/formatFilter';

export const fetchModules = async (filters) => {
  try {
    const filterParams = formatFilter(filters);
    const response = await api.get(`${process.env.REACT_APP_API_URL}/role/route`, {
      params: filterParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchRoles = async (filters) => {
  try {
    const filterParams = formatFilter(filters);
    // Make an API request with the filter values
    const response = await api.get(`${process.env.REACT_APP_API_URL}/role`, {
      params: filterParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchRoleCsvContent = async (filters) => {
  try {
    const filterParams = formatFilter(filters);
    const response = await api.get(`${process.env.REACT_APP_API_URL}/role/export`, {
      params: filterParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addRole = async (payload) => {
  try {
    const response = await api.post(`${process.env.REACT_APP_API_URL}/role`, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editRole = async (id, payload) => {
  try {
    const response = await api.put(`${process.env.REACT_APP_API_URL}/role/${id}`, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteRole = async (roleId) => {
  try {
    const response = await api.delete(`${process.env.REACT_APP_API_URL}/role/${roleId}`);

    return response.data;
  } catch (error) {
    throw error;
  }
};
