import React from 'react';
import {useTranslation} from 'react-i18next';
import DiscardImg from 'assets/images/svg/discard-icon.svg';

const DiscardAlert = () => {
  const {t} = useTranslation();
  return (
    <>
      <div className="d-flex flex-column text-center">
        <img className="mb-2 delete-larg m-auto" src={DiscardImg} alt="Delete" />
        <span className="sub-heading d-block mb-2">{t('Discard Changes')}</span>
        <p>{t('Changes will not be saved. Do you want to discard?')}</p>
      </div>
    </>
  );
};

export default DiscardAlert;
