import React from 'react';
import {useTranslation} from 'react-i18next';
import GroupTypeTables from 'components/reports/GroupTypeTables';
import ChartColorContainer from 'pages/ReportsPage/ChartColorContainer';

const WeeklyReports = (props) => {
  const {
    page,
    reportType,
    isLoading,
    trend1ParameterColumns,
    trend2ParameterColumns,
    trend2_1ParameterColumns,
    trendGroup1ParameterParametersChart,
    trendGroup2ParameterParametersChart,
    trendGroup2_1ParameterParametersChart,
  } = props;
  const {t} = useTranslation();
  return (
    <>
      {trendGroup1ParameterParametersChart.length > 0 && (
        <>
          <div className={`${trendGroup1ParameterParametersChart.length > 0 ? `page page-${page.group1}` : ``}`}>
            <GroupTypeTables
              reportType={reportType}
              isLoading={isLoading.parametersChart}
              columns={trend1ParameterColumns}
              data={trendGroup1ParameterParametersChart}
              trend={t('Daily')}
            />
            {trendGroup2ParameterParametersChart.length === 0 && trendGroup2_1ParameterParametersChart.length === 0 && (
              <div className="mt-3">
                <ChartColorContainer />
              </div>
            )}
          </div>
        </>
      )}

      {trendGroup2ParameterParametersChart.length > 0 && (
        <>
          <div className={`${trendGroup2ParameterParametersChart.length > 0 ? `page page-${page.group2_1}` : ``}`}>
            <GroupTypeTables
              reportType={reportType}
              isLoading={isLoading.parametersChart}
              columns={trend2ParameterColumns}
              data={trendGroup2ParameterParametersChart}
              trend={t('Daily')}
            />
            {trendGroup2_1ParameterParametersChart.length === 0 && (
              <div className="mt-3">
                <ChartColorContainer />
              </div>
            )}
          </div>
        </>
      )}

      {trendGroup2_1ParameterParametersChart.length > 0 && (
        <>
          <div className={`${trendGroup2_1ParameterParametersChart.length > 0 ? `page page-${page.group2_2}` : ``}`}>
            <GroupTypeTables
              reportType={reportType}
              isLoading={isLoading.parametersChart}
              columns={trend2_1ParameterColumns}
              data={trendGroup2_1ParameterParametersChart}
              trend={t('Daily')}
            />
            <div className="mt-3">
              <ChartColorContainer />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default WeeklyReports;
