import React, {useState, useEffect} from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {useLocation} from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import resetFilterImg from 'assets/images/svg/refresh-icon.svg';
import FiltersmallImg from 'assets/images/svg/filter-small.svg';
import {Form, Button, Spinner} from 'react-bootstrap';
import {useStateAsync} from 'hooks/useStateAsync';
import {PAGE_DISPLAY} from 'constants/datatable';
import CustomToast from 'components/Toast/CustomToast';
import {toast} from 'react-toastify';
import {usePermission} from 'hooks/usePermission';
import NotificationCard from 'components/notification/NotificationCard';
import {fetchSystemNotification, fetchTenantList} from 'features/notification/notificationApi';
import {useTranslation} from 'react-i18next';

const NotificationSideBar = (props) => {
  const [startDate, setStartDate] = useState('');
  const [filterShow, setFilterShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [notificationLoading, setNotificationLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [systemNotifications, setSystemNotifications] = useState([]);
  const [tenants, setTenants] = useState([]);

  const location = useLocation();
  const currentRoute = location.pathname.replace('/', '');

  const isSystemNotificationAllow = usePermission(`app/system-notification`, 'read');

  const scrollRef = React.useRef(null);
  const {t} = useTranslation();

  const {isNotificationVisible, mainContainerHeight, notificationFilterRef, setIsNotificationVisible} = props;

  const [filters, setFilters] = useStateAsync({
    issue: '',
    tenantId: '',
    severity: '',
    date: '',
    pageSize:
      currentRoute.startsWith('app/dashboard') ||
      currentRoute.startsWith('app/device/dashboard') ||
      currentRoute.startsWith('app/device/dashboard/')
        ? PAGE_DISPLAY[1]
        : PAGE_DISPLAY[0], // per page data
    pageNumber: 1, // current page number
  });

  useEffect(() => {
    if (!isSystemNotificationAllow) {
      fetchNotificationData(filters);
      const intervalId = setInterval(() => autoCallNotifications(), 600000); // 10 mints

      return () => clearInterval(intervalId);
    }
  }, []);

  useEffect(() => {
    if (!isSystemNotificationAllow) {
      getTenantList();
    }
  }, []);

  const getTenantList = async () => {
    try {
      const {data} = await fetchTenantList();
      setTenants(data);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    }
  };

  const autoCallNotifications = () => {
    // setFilters(
    //   (prevFilters) => ({
    //     ...prevFilters,
    //     pageSize: prevFilters.pageSize,
    //     pageNumber: 1,
    //   }),
    //   async (newValue) => {
    //     setIsLoading(true);
    //     setSystemNotifications([]);
    //     scrollToTop();
    //     await fetchNotificationData(newValue, true);
    //     setIsLoading(false);
    //   },
    // );
  };

  const fetchNotificationData = async (filters, isFilter = false) => {
    try {
      setNotificationLoading(true);
      const {data} = await fetchSystemNotification(filters);
      const notidicationData = data ? data.data : [];
      let finalNotificationData = [];
      if (notidicationData.length === 0) {
        finalNotificationData = [];
      } else {
        if (systemNotifications.length === 0 || isFilter) {
          finalNotificationData = [...notidicationData];
        } else {
          finalNotificationData = [...systemNotifications, ...notidicationData];
        }
      }
      setSystemNotifications(finalNotificationData);
      if (notidicationData.length > 0) {
        setTotalPage(data.numberOfPages);
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setNotificationLoading(false);
    }
  };

  const handleFilterChange = (event, isDate = null) => {
    let name;
    let value;
    if (isDate) {
      name = 'date';
      value = moment(event).format('yyyy-MM-DD');
      setStartDate(event);
    } else {
      name = event.target.name;
      value = event.target.value;
    }
    setFilters(
      (prevFilters) => {
        if (name === 'issue' && value === 'Device Performance Issue') {
          return {
            ...prevFilters,
            [name]: value,
            severity: '',
          };
        } else {
          return {
            ...prevFilters,
            [name]: value,
          };
        }
      },
      async (newValue) => {
        setIsLoading(true);
        setSystemNotifications([]);
        scrollToTop();
        await fetchNotificationData(newValue, true);
        setIsLoading(false);
      },
    );
  };

  const handleClearFilter = () => {
    setStartDate('');
    setFilters(
      (prevFilters) => ({
        ...prevFilters,
        issue: '',
        tenantId: '',
        severity: '',
        date: '',
        pageSize: prevFilters.pageSize,
        pageNumber: 1,
      }),
      async (newValue) => {
        setIsLoading(true);
        setSystemNotifications([]);
        scrollToTop();
        await fetchNotificationData(newValue, true);
        setIsLoading(false);
      },
    );
  };

  const handleToggleFilter = () => {
    if (filterShow) {
      notificationFilterRef.current = null;
    }
    setFilterShow(!filterShow);
  };

  const loadMore = async () => {
    if (!notificationLoading && totalPage !== filters.pageNumber) {
      setFilters(
        (prevFilters) => ({
          ...prevFilters,
          issue: prevFilters.issue,
          tenantId: prevFilters.tenantId,
          severity: prevFilters.severity,
          date: prevFilters.date,
          pageSize: prevFilters.pageSize,
          pageNumber: prevFilters.pageNumber + 1,
        }),
        (newValue) => {
          fetchNotificationData(newValue);
        },
      );
    }
  };

  const scrollToTop = () => {
    const scrollContainer = scrollRef.current;
    if (scrollContainer) {
      scrollContainer.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  return (
    <>
      <div className={`notification-container ${isNotificationVisible ? 'notification__collapsed' : ''}`}>
        <div className="noti-cancel" onClick={() => setIsNotificationVisible(false)}>
          X
        </div>
        <div className="row mb-2">
          <div className="col-12 d-flex justify-content-between align-items-center">
            <h4 className="mb-3">{t('Notifications')}</h4>
            <div className="d-flex">
              {filterShow && (
                <Button
                  variant="outline-primary"
                  className="mb-3 btn-filter me-2"
                  type="button"
                  onClick={handleClearFilter}
                >
                  <img src={resetFilterImg} alt="Reset" />
                </Button>
              )}

              <Button variant="outline-primary" className="mb-3 btn-filter" type="button" onClick={handleToggleFilter}>
                <img src={FiltersmallImg} alt="Filter" />
              </Button>
            </div>
          </div>
        </div>
        {filterShow && (
          <div className="filter-container" ref={notificationFilterRef}>
            <div className="row">
              <div className="col-12 col-md-6">
                <Form.Group className="mb-3" controlId="issue">
                  <Form.Select name="issue" value={filters.issue} onChange={handleFilterChange}>
                    <option value="">All</option>
                    <option value={'Sensor Reading Issue'}>{t('Sensor Reading Issue')}</option>
                    <option value={'Device Performance Issue'}>{t('Device Performance Issue')}</option>
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="col-12 col-md-6">
                <DatePicker
                  className="mb-3"
                  placeholderText="YYYY-MM-DD"
                  dateFormat={'yyyy-MM-dd'}
                  selected={startDate}
                  maxDate={new Date()}
                  onChange={handleFilterChange}
                />
              </div>
              <div className="col-12 col-md-6">
                <Form.Group className="mb-3" controlId="tenantId">
                  <Form.Select name="tenantId" value={filters.tenantId} onChange={handleFilterChange}>
                    <option value={''}>{t('Tenant Name')}</option>
                    {tenants.map((tenant) => (
                      <option key={tenant._id} value={tenant._id}>
                        {tenant.tenantName}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="col-12 col-md-6">
                <Form.Group className="mb-3" controlId="severity">
                  <Form.Select
                    name="severity"
                    disabled={filters.issue === 'Device Performance Issue' ? true : false}
                    value={filters.severity}
                    onChange={handleFilterChange}
                  >
                    <option value={''}>{t('Select Severity')}</option>
                    <option value="low">{t('Low')}</option>
                    <option value="medium">{t('Medium')}</option>
                    <option value="high">{t('High')}</option>
                  </Form.Select>
                </Form.Group>
              </div>
            </div>
          </div>
        )}
        <div
          id="notificationContainer"
          className="custom-scrollbar"
          ref={scrollRef}
          style={{
            height: `${mainContainerHeight}px`,
            overflowY: 'scroll',
            overflowX: 'hidden',
          }}
        >
          {isLoading && (
            <>
              <div className="text-center">
                <span className="d-flex justify-content-center mt-2 mb-2" key={0}>
                  <Spinner animation="border" />
                </span>
              </div>
              <div className="text-center">{t('Waiting for notifications...')}</div>
            </>
          )}
          {systemNotifications.length > 0 ? (
            <InfiniteScroll
              pageStart={0}
              loadMore={loadMore}
              hasMore={hasMore}
              loader={
                notificationLoading ? (
                  <span className="d-flex justify-content-center mt-2 mb-2" key={0}>
                    <Spinner animation="border" />
                  </span>
                ) : (
                  <div key={`loadHolder${0}`} />
                )
              }
              isReverse={false}
              initialLoad={false}
              useWindow={false}
              threshold={10}
            >
              {systemNotifications.map((notification, index) => (
                <NotificationCard key={index} notification={notification} />
              ))}
            </InfiniteScroll>
          ) : (
            !isLoading && (
              <>
                <div className="text-center">{t('No Notifications Found!!!')}</div>
              </>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default React.memo(NotificationSideBar);
