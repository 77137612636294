import {createSlice} from '@reduxjs/toolkit';
import {login, loginVerify} from 'features/auth/authApi';

const initialState = {
  user: null,
  isAuthorized: false,
  isVerify: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetLogin: (state) => {
      state.isAuthorized = false;
      state.isVerify = false;
    },
    logout: (state, action) => {
      state.user = null;
      state.isAuthorized = false;
      state.isVerify = false;
    },
    forgotPasswordLogin: (state, action)  => {
      state.isAuthorized = true;
      state.isVerify = true;
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.isAuthorized = true;
        state.isVerify = false;
      })
      .addCase(loginVerify.fulfilled, (state, action) => {
        state.isAuthorized = true;
        state.isVerify = true;
        state.user = action.payload.data;
      });
  },
});

export const {logout, resetLogin, forgotPasswordLogin} = authSlice.actions;

export default authSlice.reducer;
