import React, {useState, useRef, useMemo} from 'react';
import {useStateAsync} from 'hooks/useStateAsync';
import {useNavigate} from 'react-router-dom';
import PageInnerHeader from 'components/PageInnerHeader';
import {toast} from 'react-toastify';
import {downloadFile} from 'utils/downloadFile';
import CustomToast from 'components/Toast/CustomToast';
import {conditionalRowStyles} from 'utils/tableRowsColor';
import {PAGE_DISPLAY} from 'constants/datatable';
import {SOMETHING_WENT_ERROR} from 'constants/apiNetwork';
import {useCustomEffect} from 'hooks/useCustomEffect';
import useGetTableList from 'hooks/table/useGetTableList';
import {deleteReport, fetchReportCsvContent, fetchReports} from 'features/reports/reportsApi';
import ReportFilter from 'components/reports/ReportFilter';
import CustomDataTable from 'components/datatable';
import CustomModal from 'components/shared/CustomModal';
import DeleteModal from 'components/modal/DeleteModal';
import {reportListColumn} from 'components/datatable/columns/reportListColumn';
import {usePermission} from 'hooks/usePermission';
import {useTranslation} from 'react-i18next';

const ReportsPage = () => {
  const [showDelete, setShowDelete] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [filters, setFilters] = useStateAsync({
    report: '',
    reportType: '',
    area: '',
    name: '',
    key: '',
    value: '',
    pageSize: PAGE_DISPLAY[0], // per page data
    pageNumber: 1, // current page number
  });

  const numberOfPagesRef = useRef(1);
  const applyFilterRef = useRef(false);

  const {t} = useTranslation();

  const navigate = useNavigate();

  const isCreateAllow = usePermission(`app/reports`, 'create');
  const isEditAllow = usePermission(`app/reports`, 'edit');
  const isDeleteAllow = usePermission(`app/reports`, 'delete');

  const {tableData, isLoading, getTablelist, handleSort, handlePageChange, handleRowsPerPageChange} = useGetTableList(
    fetchReports,
    numberOfPagesRef,
    setFilters,
    applyFilterRef,
  );

  useCustomEffect(() => {
    getTablelist(filters);
  }, []);

  const handleView = (row) => {
    window.open(`/app/reports/view/${row._id}`, '_blank');
  };

  const handleEdit = (row) => {
    navigate(`/app/reports/manage/${row._id}`);
  };

  const handleDelete = (row) => {
    setSelectedReport(row);
    setShowDelete(true);
  };

  const handleDeleteCloseModal = () => {
    setShowDelete(false);
    setSelectedReport(null);
  };

  const handleDeleteReport = async () => {
    try {
      const {_id} = selectedReport;
      const {message} = await deleteReport(_id);
      toast(<CustomToast type="success" title="Success" message={message} />);
      await getTablelist(filters);
      handleDeleteCloseModal();
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    }
  };

  const handleFilter = () => {
    applyFilterRef.current = true;
    setFilters(
      (prevFilters) => ({
        report: prevFilters.report,
        reportType: prevFilters.reportType,
        area: prevFilters.area,
        name: prevFilters.name,
        // status: prevFilters.status === 'all' ? '' : prevFilters.status,
        key: '',
        value: '',
        pageSize: prevFilters.pageSize,
        pageNumber: 1,
      }),
      (newValue) => {
        getTablelist(newValue);
      },
    );
  };

  const handleFilterChange = (event) => {
    const {name, value} = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleClearFilter = () => {
    applyFilterRef.current = false;
    setFilters(
      (prevFilters) => ({
        ...prevFilters,
        report: '',
        reportType: '',
        area: '',
        name: '',
        key: '',
        value: '',
        pageSize: prevFilters.pageSize,
        pageNumber: 1,
      }),
      (newValue) => {
        getTablelist(newValue);
      },
    );
  };

  const handleDownloadCSV = async () => {
    try {
      const downloadWithFilter = {};
      if (applyFilterRef.current) {
        downloadWithFilter['report'] = filters.report;
        downloadWithFilter['reportType'] = filters.reportType;
        downloadWithFilter['area'] = filters.area;
        downloadWithFilter['name'] = filters.name;
        downloadWithFilter['key'] = filters.key;
        downloadWithFilter['value'] = filters.value;
        // if (filters.status !== 'all') {
        //   downloadWithFilter['status'] = filters.status;
        // }
      }
      const csvData = await fetchReportCsvContent(downloadWithFilter);
      const currentDate = new Date().toLocaleString();
      downloadFile(csvData, `tsn-reports-${currentDate}.csv`);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={t(SOMETHING_WENT_ERROR)} />);
    }
  };

  const columns = useMemo(
    () => reportListColumn(filters, isEditAllow, isDeleteAllow, handleView, handleEdit, handleDelete, t),
    [filters, isEditAllow, isDeleteAllow, handleView, handleEdit, handleDelete, t],
  );

  return (
    <>
      <PageInnerHeader
        title={t('Reports')}
        buttonLabel={t('Create New Report')}
        isCreateAllow={isCreateAllow}
        handleAddButton={() => navigate('/app/reports/generate')}
      />
      <ReportFilter
        filters={filters}
        handleFilterChange={handleFilterChange}
        handleFilter={handleFilter}
        handleClearFilter={handleClearFilter}
        handleDownloadCSV={handleDownloadCSV}
      />
      <CustomDataTable
        columns={columns}
        data={tableData}
        loading={isLoading}
        handleSort={handleSort}
        pagination={true}
        numberOfPages={numberOfPagesRef.current}
        rowPerPage={filters.pageSize}
        conditionalRowStyles={conditionalRowStyles}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
      />
      {showDelete && (
        <CustomModal
          size="sm"
          show={showDelete}
          handleClose={handleDeleteCloseModal}
          body={<DeleteModal deletedItem={selectedReport && selectedReport.name} />}
          buttons={[
            {label: t('Yes'), type: 'primary', onClickHandler: handleDeleteReport},
            {label: t('No'), type: 'secondary', onClickHandler: handleDeleteCloseModal},
          ]}
        />
      )}
    </>
  );
};

export default ReportsPage;
