import React from 'react';
import {LANGUAGES} from 'constants/locales';
import {Dropdown} from 'react-bootstrap';

const LanguageDropdown = (props) => {
  const {language, handleLanguageChange} = props;
  return (
    <div className="lang_switch_btn">
      <Dropdown align={{lg: 'end'}} className='dropdown btn-group'>
        <Dropdown.Toggle id="languages" variant="success">
          {language.label}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {LANGUAGES.map((language, index) => {
            return (
              <Dropdown.Item key={index} onClick={() => handleLanguageChange(language)}>
                {language.label}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default LanguageDropdown;
