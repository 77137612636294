import React from 'react';
import {Form, Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import resetFilterImg from 'assets/images/svg/refresh-icon.svg';
import {PrimaryButton, StyledLabel} from 'styles/styled-components/StyledComponents';

const SubscriberFilter = (props) => {
  const {filters, handleFilterChange, handleFilter, handleClearFilter, handleAddSubscriber} = props;
  const {t} = useTranslation();
  return (
    <>
      <div className="filter-card">
        <div className="row">
          <div className="col d-flex justify-content-between align-items-end flex-column flex-md-row">
            <div className="d-flex flex-wrap align-items-end mob-100 w-100">
              <Form.Group className="me-0 me-md-2 mb-3 mob-100 flex-fill" controlId="report">
                <StyledLabel>{t('Name')}</StyledLabel>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder={t("Enter Name")}
                  autoComplete="off"
                  value={filters.name}
                  onChange={handleFilterChange}
                />
              </Form.Group>
              <Form.Group className="me-0 me-md-2 mb-3 mob-100 flex-fill" controlId="reportType">
                <StyledLabel>{t('Email')}</StyledLabel>
                <Form.Control
                  type="text"
                  name="email"
                  placeholder={t("Enter Email ID")}
                  autoComplete="off"
                  value={filters.email}
                  onChange={handleFilterChange}
                />
              </Form.Group>
              <PrimaryButton variant="outline-primary" className="apply me-2 mb-3" onClick={handleFilter}>
                {t('Apply')}
              </PrimaryButton>
              <Button variant="outline-primary" className="mb-3" type="button" onClick={handleClearFilter}>
                <img src={resetFilterImg} alt="Filter" />
              </Button>
              <div className="ms-5">
                <Button variant="primary" className="mb-3" type="button" onClick={handleAddSubscriber}>
                  {t('Add Subscriber')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriberFilter;
