import React from 'react';
import {Link} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import plusImg from 'assets/images/svg/plus.svg';
import leftarrowImg from 'assets/images/svg/left-arrow.svg';
import { ADD_SETTINGS_TAB } from 'constants/tenant';
import {useTranslation} from 'react-i18next';

const StepperHeader = (props) => {
  const {title, isSubmit, currentTab, handleSaveExitAndNext} = props;

  const {t} = useTranslation();

  return (
    <>
      <div className="breadcrum-sec align-items-center breadcrum-sec d-flex justify-content-between flex-column flex-md-row">
        <div className="mob-100">
          <ul>
            <li>
              <Link to={`/app/dashboard`} className="breadcrum-link" variant="breadcrum">
                <img className="me-2" src={leftarrowImg} alt="Add" />
                {t('Dashboard')}
              </Link>
            </li>
            <li>
              <Link to={`/app/tenants`} className="breadcrum-link" variant="breadcrum">
                <img className="me-2" src={leftarrowImg} alt="Add" />
                {t('Manage Tenants')}
              </Link>
            </li>
          </ul>
          <h2 className="page-title">{title}</h2>
        </div>
        <div className="mob-100  d-flex">
          <Button
            className="btn-outline-primary d-flex align-items-center me-2"
            variant=""
            type="button"
            disabled={isSubmit}
            onClick={() => handleSaveExitAndNext('save')}
          >
            {t('Save & Exit')}
          </Button>
          <Button
            className="btn-primary d-flex align-items-center"
            variant=""
            type="button"
            disabled={isSubmit}
            onClick={() => handleSaveExitAndNext(currentTab === ADD_SETTINGS_TAB ? 'finalSave' : 'next')}
          >
            {currentTab === ADD_SETTINGS_TAB ? t('Save') : t('Next')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default StepperHeader;
