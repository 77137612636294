import api from 'api/axios';
import {formatFilter} from 'utils/formatFilter';

export const fetchNotificationType = async () => {
  try {
    const response = await api.get(`/notification/type`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchNotifications = async (filters) => {
  try {
    const filterParams = formatFilter(filters);
    // Make an API request with the filter values
    const response = await api.get(`/notification`, {
      params: filterParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addNotification = async (payload) => {
  try {
    const response = await api.post(`/notification`, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editNotification = async (id, payload) => {
  try {
    const response = await api.put(`/notification/${id}`, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteNotification = async (notificationId) => {
  try {
    const response = await api.delete(`/notification/${notificationId}`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchNotificationCsvContent = async (filters) => {
  try {
    const filterParams = formatFilter(filters);

    const response = await api.get(`notification/export`, {
      params: filterParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchNotificationsById = async (id) => {
  try {
    const response = await api.get(`/notification/${id}`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addNotificationThreshold = async (payload) => {
  try {
    const response = await api.post(`/notification/threshold`, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editNotificationThreshold = async (id, payload) => {
  try {
    const response = await api.put(`/notification/threshold/${id}`, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchNotificationThreshold = async (filters) => {
  try {
    const filterParams = formatFilter(filters);
    // Make an API request with the filter values
    const response = await api.get(`/notification/threshold`, {
      params: filterParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchNotificationsThresholdById = async (notificationId, thresholdId) => {
  try {
    const response = await api.get(`/notification/threshold/${notificationId}?deviceSubTypeId=${thresholdId}`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateNotificationThresholdStatus = async (id, payload) => {
  try {
    const response = await api.put(`/notification/threshold/status/${id}`, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchNotificationsThresholdByFrequency = async (notificationId) => {
  try {
    const response = await api.get(`/notification/frequency?notificationId=${notificationId}`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchSystemNotification = async (filters) => {
  try {
    const filterParams = formatFilter(filters);
    
    const response = await api.get(`/system-notification`, {
      params: filterParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchTenantList = async () => {
  try {
    const response = await api.get(`/system-notification/tenant/name`);

    return response.data;
  } catch (error) {
    throw error;
  }
};
