import React from 'react';

export default (props) => (
  <svg width="83" height="86" viewBox="0 0 49 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M47.0858 34.762H1.6663C1.24278 34.3495 1.0075 34.1204 0.583984 33.708V2.66698C1.0075 2.25457 1.24278 2.02545 1.6663 1.61304H47.0858C47.5093 2.02545 47.7446 2.25457 48.1681 2.66698V33.708C47.7446 34.1204 47.5093 34.3495 47.0858 34.762Z"
      fill={props.color || 'url(#paint0_linear_6999_2881)'}
    />
    <path
      d="M42.1543 31.5909H6.59802C5.00749 31.5909 3.72754 30.3353 3.72754 28.7957V8.00082C3.72754 6.45197 5.0169 5.20557 6.59802 5.20557H42.1637C43.7543 5.20557 45.0342 6.46114 45.0342 8.00082V28.7957C45.0248 30.3445 43.7449 31.5909 42.1543 31.5909Z"
      fill="white"
    />
    <mask
      id="mask0_6999_2881"
      style={{maskType: 'luminance'}}
      maskUnits="userSpaceOnUse"
      x="18"
      y="17"
      width="5"
      height="6"
    >
      <path
        d="M22.1171 22.7285L21.6935 22.5636C21.6183 22.5361 21.5335 22.4169 21.4959 22.3344C20.6112 20.6115 19.7454 18.8885 18.8701 17.1655L19.2936 17.3305C20.1689 19.0535 21.0442 20.7764 21.9194 22.4994C21.9571 22.5819 22.0418 22.701 22.1171 22.7285Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_6999_2881)">
      <path
        d="M22.1171 22.7285L21.6935 22.5636C21.6183 22.5361 21.5335 22.4169 21.4959 22.3344C20.6112 20.6115 19.7454 18.8885 18.8701 17.1655L19.2936 17.3305C20.1689 19.0535 21.0442 20.7764 21.9194 22.4994C21.9571 22.5819 22.0418 22.701 22.1171 22.7285Z"
        fill={props.color || '#858585'}
      />
    </g>
    <mask
      id="mask1_6999_2881"
      style={{maskType: 'luminance'}}
      maskUnits="userSpaceOnUse"
      x="18"
      y="16"
      width="2"
      height="2"
    >
      <path
        d="M19.2842 17.3306L18.8607 17.1656C18.8136 17.074 18.7666 16.9915 18.7007 16.854L19.1242 17.019C19.1901 17.1564 19.2371 17.2481 19.2842 17.3306Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1_6999_2881)">
      <path
        d="M19.2842 17.3306L18.8607 17.1656C18.8136 17.074 18.7666 16.9915 18.7007 16.854L19.1242 17.019C19.1901 17.1564 19.2371 17.2481 19.2842 17.3306Z"
        fill={props.color || '#858585'}
      />
    </g>
    <mask
      id="mask2_6999_2881"
      style={{maskType: 'luminance'}}
      maskUnits="userSpaceOnUse"
      x="14"
      y="13"
      width="6"
      height="10"
    >
      <path
        d="M14.7575 22.7377L14.334 22.5727C15.8022 19.695 17.2233 16.8631 18.6915 13.967L19.115 14.132C17.6468 17.0281 16.2257 19.86 14.7575 22.7377Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask2_6999_2881)">
      <path
        d="M14.7575 22.7377L14.334 22.5727C15.8022 19.695 17.2233 16.8631 18.6915 13.967L19.115 14.132C17.6468 17.0281 16.2257 19.86 14.7575 22.7377Z"
        fill={props.color || '#858585'}
      />
    </g>
    <path
      d="M15.4163 22.7377C15.2092 22.7377 14.9927 22.7468 14.748 22.7468C16.2162 19.8691 17.6374 17.0372 19.1055 14.1411C20.5361 17.0372 21.9384 19.8691 23.3689 22.756C23.143 22.756 22.9266 22.756 22.7195 22.756C22.5219 22.756 22.3242 22.756 22.136 22.7377C22.0513 22.7285 21.9572 22.6002 21.9007 22.4994C21.016 20.7764 20.1502 19.0534 19.2749 17.3304C19.2279 17.2388 19.1808 17.1563 19.1149 17.0188C19.0491 17.138 19.002 17.2205 18.9455 17.3121C18.0609 19.0259 17.1762 20.7397 16.3009 22.4535C16.1974 22.646 16.0939 22.7468 15.8774 22.7468C15.868 22.7468 15.8492 22.7468 15.8398 22.7468C15.708 22.7377 15.5668 22.7377 15.4163 22.7377Z"
      fill={props.color || '#E0E0E0'}
    />
    <mask
      id="mask3_6999_2881"
      style={{maskType: 'luminance'}}
      maskUnits="userSpaceOnUse"
      x="36"
      y="17"
      width="4"
      height="6"
    >
      <path
        d="M39.9708 22.7561L39.5473 22.5912C39.4626 22.5637 39.3779 22.4445 39.3309 22.362C38.4462 20.6391 37.5803 18.9161 36.7051 17.1931L37.1286 17.3581C38.0039 19.0811 38.8791 20.804 39.7544 22.527C39.8014 22.6095 39.8861 22.7286 39.9708 22.7561Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask3_6999_2881)">
      <path
        d="M39.9708 22.7561L39.5473 22.5912C39.4626 22.5637 39.3779 22.4445 39.3309 22.362C38.4462 20.6391 37.5803 18.9161 36.7051 17.1931L37.1286 17.3581C38.0039 19.0811 38.8791 20.804 39.7544 22.527C39.8014 22.6095 39.8861 22.7286 39.9708 22.7561Z"
        fill={props.color || '#858585'}
      />
    </g>
    <mask
      id="mask4_6999_2881"
      style={{maskType: 'luminance'}}
      maskUnits="userSpaceOnUse"
      x="36"
      y="16"
      width="2"
      height="2"
    >
      <path
        d="M37.1192 17.358L36.6956 17.1931C36.6486 17.1014 36.6015 17.0098 36.5356 16.8906L36.9592 17.0556C37.0344 17.1747 37.0815 17.2664 37.1192 17.358Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask4_6999_2881)">
      <path
        d="M37.1192 17.358L36.6956 17.1931C36.6486 17.1014 36.6015 17.0098 36.5356 16.8906L36.9592 17.0556C37.0344 17.1747 37.0815 17.2664 37.1192 17.358Z"
        fill={props.color || '#858585'}
      />
    </g>
    <mask
      id="mask5_6999_2881"
      style={{maskType: 'luminance'}}
      maskUnits="userSpaceOnUse"
      x="32"
      y="13"
      width="5"
      height="10"
    >
      <path
        d="M32.6017 22.7653L32.1782 22.6003C33.6276 19.7318 35.0675 16.8999 36.5357 13.9946L36.9592 14.1596C35.491 17.0648 34.0511 19.8967 32.6017 22.7653Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask5_6999_2881)">
      <path
        d="M32.6017 22.7653L32.1782 22.6003C33.6276 19.7318 35.0675 16.8999 36.5357 13.9946L36.9592 14.1596C35.491 17.0648 34.0511 19.8967 32.6017 22.7653Z"
        fill={props.color || '#858585'}
      />
    </g>
    <path
      d="M32.6016 22.7654C34.0509 19.8968 35.4909 17.0649 36.959 14.1597C38.3331 16.9458 39.6789 19.6585 41.0342 22.4079V22.7837C40.8648 22.7837 40.6954 22.7837 40.5354 22.7837C40.3566 22.7837 40.1872 22.7837 40.0083 22.7654C39.9142 22.7562 39.8107 22.6279 39.7542 22.5271C38.8696 20.8041 38.0037 19.0811 37.1285 17.3582C37.0814 17.2665 37.0343 17.1749 36.9685 17.0557C36.9026 17.1657 36.8555 17.2574 36.7991 17.349C35.9144 19.072 35.0203 20.795 34.1356 22.5179C34.0509 22.6829 33.9568 22.7745 33.7592 22.7745C33.7498 22.7745 33.7309 22.7745 33.7215 22.7745C33.5803 22.7654 33.4392 22.7654 33.298 22.7654C33.0815 22.7562 32.8557 22.7654 32.6016 22.7654Z"
      fill={props.color || '#E0E0E0'}
    />
    <mask
      id="mask6_6999_2881"
      style={{maskType: 'luminance'}}
      maskUnits="userSpaceOnUse"
      x="25"
      y="21"
      width="6"
      height="1"
    >
      <path
        d="M26.3429 21.5007L25.9194 21.3357C27.4064 21.3357 28.8652 21.3449 30.3522 21.3449L30.7757 21.5098C29.2887 21.5098 27.83 21.5098 26.3429 21.5007Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask6_6999_2881)">
      <path
        d="M26.3429 21.5007L25.9194 21.3357C27.4064 21.3357 28.8652 21.3449 30.3522 21.3449L30.7757 21.5098C29.2887 21.5098 27.83 21.5098 26.3429 21.5007Z"
        fill={props.color || '#949494'}
      />
    </g>
    <mask
      id="mask7_6999_2881"
      style={{maskType: 'luminance'}}
      maskUnits="userSpaceOnUse"
      x="24"
      y="13"
      width="2"
      height="10"
    >
      <path
        d="M25.0632 22.7195L24.6396 22.5545C24.6585 19.7134 24.6679 16.8632 24.6867 13.9946L25.1102 14.1596C25.0914 17.0282 25.082 19.8784 25.0632 22.7195Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask7_6999_2881)">
      <path
        d="M25.0632 22.7195L24.6396 22.5545C24.6585 19.7134 24.6679 16.8632 24.6867 13.9946L25.1102 14.1596C25.0914 17.0282 25.082 19.8784 25.0632 22.7195Z"
        fill={props.color || '#858585'}
      />
    </g>
    <mask
      id="mask8_6999_2881"
      style={{maskType: 'luminance'}}
      maskUnits="userSpaceOnUse"
      x="24"
      y="14"
      width="3"
      height="1"
    >
      <path
        d="M25.11 14.1689L24.6865 14.0039C25.1006 14.0039 25.5147 14.0039 25.9571 14.0039L26.3806 14.1689C25.9382 14.1689 25.5241 14.1689 25.11 14.1689Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask8_6999_2881)">
      <path
        d="M25.11 14.1689L24.6865 14.0039C25.1006 14.0039 25.5147 14.0039 25.9571 14.0039L26.3806 14.1689C25.9382 14.1689 25.5241 14.1689 25.11 14.1689Z"
        fill={props.color || '#949494'}
      />
    </g>
    <path
      d="M26.3806 14.1688C26.3712 16.6158 26.3524 19.0445 26.3429 21.5006C27.8299 21.5006 29.2887 21.5098 30.7757 21.5098C30.7757 21.9222 30.7757 22.3071 30.7663 22.7287C28.8746 22.7287 26.9829 22.7196 25.063 22.7196C25.0818 19.8785 25.0912 17.0282 25.11 14.1597C25.5241 14.1688 25.9382 14.1688 26.3806 14.1688Z"
      fill={props.color || '#E0E0E0'}
    />
    <mask
      id="mask9_6999_2881"
      style={{maskType: 'luminance'}}
      maskUnits="userSpaceOnUse"
      x="8"
      y="14"
      width="2"
      height="1"
    >
      <path
        d="M9.02593 14.0404L9.44944 14.2054C9.44003 14.1962 9.42121 14.1962 9.41179 14.1962L8.98828 14.0312C9.0071 14.0312 9.01652 14.0313 9.02593 14.0404Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask9_6999_2881)">
      <path
        d="M9.42107 14.1962L8.99756 14.0312C9.00697 14.0312 9.01638 14.0313 9.02579 14.0404L9.44931 14.2054C9.4399 14.1962 9.43048 14.1962 9.42107 14.1962Z"
        fill={props.color || '#999999'}
      />
      <path
        d="M9.44939 14.1962L9.02588 14.0312C9.02588 14.0312 9.02588 14.0312 9.03529 14.0312L9.44939 14.1962Z"
        fill={props.color || '#9E9E9E'}
      />
    </g>
    <mask
      id="mask10_6999_2881"
      style={{maskType: 'luminance'}}
      maskUnits="userSpaceOnUse"
      x="7"
      y="14"
      width="3"
      height="1"
    >
      <path
        d="M8.14129 14.1872L7.71777 14.0222C7.94365 14.0222 8.16011 14.0222 8.35775 14.0222C8.57421 14.0222 8.78126 14.0222 8.98831 14.0314L9.41183 14.1963C9.20478 14.1872 8.99773 14.1872 8.78126 14.1872C8.58362 14.1872 8.36716 14.1872 8.14129 14.1872Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask10_6999_2881)">
      <path
        d="M8.14129 14.1872L7.71777 14.0222C7.94365 14.0222 8.16011 14.0222 8.35775 14.0222C8.57421 14.0222 8.78126 14.0222 8.98831 14.0314L9.41183 14.1963C9.20478 14.1872 8.99773 14.1872 8.78126 14.1872C8.58362 14.1872 8.36716 14.1872 8.14129 14.1872Z"
        fill={props.color || '#949494'}
      />
    </g>
    <mask
      id="mask11_6999_2881"
      style={{maskType: 'luminance'}}
      maskUnits="userSpaceOnUse"
      x="7"
      y="14"
      width="6"
      height="9"
    >
      <path
        d="M12.4047 22.7928L11.9811 22.6278C10.5506 19.7318 9.15772 16.909 7.71777 14.0129L8.14129 14.1779C9.57182 17.074 10.9741 19.9059 12.4047 22.7928Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask11_6999_2881)">
      <path
        d="M12.4047 22.7928L11.9811 22.6278C10.5506 19.7318 9.15772 16.909 7.71777 14.0129L8.14129 14.1779C9.57182 17.074 10.9741 19.9059 12.4047 22.7928Z"
        fill={props.color || '#858585'}
      />
    </g>
    <path
      d="M13.0822 21.1615C13.1105 21.2257 13.1481 21.3265 13.1199 21.3815C12.894 21.8397 12.6587 22.2888 12.3952 22.7929C10.9647 19.8968 9.57178 17.074 8.13184 14.178C8.35771 14.178 8.57417 14.178 8.77181 14.178C8.98827 14.178 9.19533 14.178 9.40238 14.1871C9.47767 14.1963 9.57178 14.2788 9.60943 14.3613C10.7859 16.625 11.934 18.8978 13.0822 21.1615Z"
      fill={props.color || '#E0E0E0'}
    />
    <path
      d="M15.5009 1.63142L5.43066 1.65891L8.04704 0.0275879H15.5009V1.63142Z"
      fill="url(#paint1_linear_6999_2881)"
    />
    <path
      d="M17.1196 0.0109863L17.1196 1.61306L27.8581 1.58557V0.0109863H17.1196Z"
      fill="url(#paint2_linear_6999_2881)"
    />
    <path
      d="M29.439 5.06323e-06V1.62216L40.1209 1.63133L37.0802 5.06323e-06H29.439Z"
      fill="url(#paint3_linear_6999_2881)"
    />
    <path d="M9.44338 0.370117H8.17285L8.17229 1.35992H9.44281L9.44338 0.370117Z" fill={props.color || '#58595B'} />
    <path d="M12.1538 0.370117H10.8833L10.8827 1.35992H12.1533L12.1538 0.370117Z" fill={props.color || '#58595B'} />
    <path d="M14.8174 0.370117H13.5469L13.5463 1.35992H14.8168L14.8174 0.370117Z" fill={props.color || '#58595B'} />
    <path d="M20.3985 0.370117H19.1279L19.1274 1.35992H20.3979L20.3985 0.370117Z" fill={props.color || '#58595B'} />
    <path d="M23.1651 0.370117H21.8945L21.894 1.35992H23.1645L23.1651 0.370117Z" fill={props.color || '#58595B'} />
    <path d="M25.857 0.370117H24.5864L24.5859 1.35992H25.8564L25.857 0.370117Z" fill={props.color || '#58595B'} />
    <path d="M31.5322 0.370117H30.2617L30.2612 1.35992H31.5317L31.5322 0.370117Z" fill={props.color || '#58595B'} />
    <path d="M34.3179 0.370117H33.0474L33.0468 1.35992H34.3173L34.3179 0.370117Z" fill={props.color || '#58595B'} />
    <path d="M36.9907 0.370117H35.7202L35.7197 1.35992H36.9902L36.9907 0.370117Z" fill={props.color || '#58595B'} />
    <defs>
      <linearGradient
        id="paint0_linear_6999_2881"
        x1="0.581697"
        y1="18.1868"
        x2="48.1683"
        y2="18.1868"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.color || '#D8D9DA'} />
        <stop offset="0.2686" stopColor={props.color || '#E8E9E9'} />
        <stop offset="0.5642" stopColor={props.color || 'white'} />
        <stop offset="0.7766" stopColor={props.color || '#FDFDFD'} />
        <stop offset="0.8531" stopColor={props.color || '#F6F7F7'} />
        <stop offset="0.9077" stopColor={props.color || '#EBEBEC'} />
        <stop offset="0.9517" stopColor={props.color || '#DADBDC'} />
        <stop offset="0.9891" stopColor={props.color || '#C4C6C7'} />
        <stop offset="1" stopColor={props.color || '#BCBEC0'} />
      </linearGradient>
      <linearGradient
        id="paint1_linear_6999_2881"
        x1="5.47198"
        y1="0.684469"
        x2="15.5183"
        y2="1.07023"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.color || '#D8D9DA'} />
        <stop offset="0.2686" stopColor={props.color || '#E8E9E9'} />
        <stop offset="0.5642" stopColor={props.color || 'white'} />
        <stop offset="0.7766" stopColor={props.color || '#FDFDFD'} />
        <stop offset="0.8531" stopColor={props.color || '#F6F7F7'} />
        <stop offset="0.9077" stopColor={props.color || '#EBEBEC'} />
        <stop offset="0.9517" stopColor={props.color || '#DADBDC'} />
        <stop offset="0.9891" stopColor={props.color || '#C4C6C7'} />
        <stop offset="1" stopColor={props.color || '#BCBEC0'} />
      </linearGradient>
      <linearGradient
        id="paint2_linear_6999_2881"
        x1="17.1538"
        y1="0.625617"
        x2="27.8815"
        y2="1.03754"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.color || '#D8D9DA'} />
        <stop offset="0.2686" stopColor={props.color || '#E8E9E9'} />
        <stop offset="0.5642" stopColor={props.color || 'white'} />
        <stop offset="0.7766" stopColor={props.color || '#FDFDFD'} />
        <stop offset="0.8531" stopColor={props.color || '#F6F7F7'} />
        <stop offset="0.9077" stopColor={props.color || '#EBEBEC'} />
        <stop offset="0.9517" stopColor={props.color || '#DADBDC'} />
        <stop offset="0.9891" stopColor={props.color || '#C4C6C7'} />
        <stop offset="1" stopColor={props.color || '#BCBEC0'} />
      </linearGradient>
      <linearGradient
        id="paint3_linear_6999_2881"
        x1="29.4746"
        y1="0.675452"
        x2="40.144"
        y2="1.08514"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.color || '#D8D9DA'} />
        <stop offset="0.2686" stopColor={props.color || '#E8E9E9'} />
        <stop offset="0.5642" stopColor={props.color || 'white'} />
        <stop offset="0.7766" stopColor={props.color || '#FDFDFD'} />
        <stop offset="0.8531" stopColor={props.color || '#F6F7F7'} />
        <stop offset="0.9077" stopColor={props.color || '#EBEBEC'} />
        <stop offset="0.9517" stopColor={props.color || '#DADBDC'} />
        <stop offset="0.9891" stopColor={props.color || '#C4C6C7'} />
        <stop offset="1" stopColor={props.color || '#BCBEC0'} />
      </linearGradient>
    </defs>
  </svg>
);
