import React from 'react';
import moment from 'moment';
import {Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import handmarkerImg from 'assets/images/svg/hand-marker.svg';
import devicecrossImg from 'assets/images/svg/device-cross.svg';
import {ParameterCircleColor, StyledProgressBar} from 'styles/styled-components/StyledComponents';
import LoadingSpinner from 'components/LoadingSpinner';
import DeviceNotWorking from 'svgs/DeviceNotWorking';
import {MEXICO_TIME} from 'constants/app';

const ParameterDetailsModal = (props) => {
  const {isContainerLoading, selectedDevice, selectedDeviceDetail, setShowDeviceDetailModal} = props;
  const aqiParameter = selectedDeviceDetail.find((parameter) => parameter.key === 'AQI');
  const aqiPercentage = aqiParameter ? (aqiParameter.averageValue / aqiParameter.hazardousValue) * 100 : 0;
  const aqiColor = aqiParameter ? (aqiParameter.colorCode ? aqiParameter.colorCode : '#9C9796') : '#9C9796';

  const navigate = useNavigate();
  const {t} = useTranslation();

  const handleViewDetail = () => {
    const deviceId = selectedDevice._id;
    navigate(`/app/device/dashboard/${deviceId}`, {state: {from: 'overall-dashbaord'}});
  };

  return (
    <>
      <div className="map-devicepara-con">
        <div className="map-devicepara-head d-flex align-items-center justify-content-between position-relative">
          <div className="d-flex align-items-center">
            <div className="dev-img me-2">
              <img src={handmarkerImg} alt="Marker" />
            </div>
            <div className="dev-spec">
              <h5>{selectedDevice.deviceName}</h5>
              {/* <span className="d-flex">
                {selectedDevice.masterDevice.deviceId}
                | <span className="dev-time "> 12.15pm</span>
              </span> */}
            </div>
          </div>
          {/* <div className="dev-status me-2">{selectedDevice.status ? `Active` : `Inactive`}</div> */}
          <div className="dev-cancel d-flex">
            <img src={devicecrossImg} alt="cancel" onClick={() => setShowDeviceDetailModal(false)} />
          </div>
        </div>
        <div className="map-devicepara-body p-3">
          {isContainerLoading ? (
            <>
              <LoadingSpinner />
            </>
          ) : (
            <>
              {selectedDevice.name === 'Hazardous Device Value' || selectedDevice.name === 'Device Not Working' ? (
                <>
                  <div className="row">
                    <div className="col-12 mb-4 d-flex justify-content-center">
                      <DeviceNotWorking />
                    </div>
                    <div className="col-12 mb-2 d-flex justify-content-center">
                      {selectedDevice.name === 'Hazardous Device Value' ? t(`2X Hazardous Detected at`) : t('Device Not Working Since')}
                    </div>
                    <span className="col-12 mb-2 d-flex justify-content-center">
                      {selectedDevice.lastReceiveDataDate
                        ? moment(selectedDevice.lastReceiveDataDate, 'DD/MM/YYYY HH:mm', true).isValid()
                          ? `${moment(selectedDevice.lastReceiveDataDate, 'DD/MM/YYYY HH:mm').subtract(MEXICO_TIME, "hours").format('DD-MMM-YYYY hh:mm A')}`
                          : `${moment(selectedDevice.lastReceiveDataDate).subtract(MEXICO_TIME, "hours").format('DD-MMM-YYYY hh:mm A')}`
                        : `${moment(selectedDevice.endDate).subtract(MEXICO_TIME, "hours").format('DD-MMM-YYYY hh:mm A')}`}
                    </span>
                  </div>
                </>
              ) : selectedDeviceDetail.length === 0 ? (
                <>{t('No Record Found!!!')}</>
              ) : (
                <>
                  <div className="row">
                    <div className="col-12 mb-4">
                      <h5 className="progress-label">AQI</h5>
                      <StyledProgressBar color={aqiColor} now={aqiPercentage} key={1} />
                    </div>
                  </div>
                  <div className="custom-scrollbar p-container">
                    <div className="row">
                      {selectedDeviceDetail.map((deviceDetail, index) => {
                        return (
                          <div key={index} className="col-12 col-md-6 col-lg-6">
                            <div className="dev-paramere-info d-flex align-items-center mb-3">
                              <ParameterCircleColor className="staus me-2" color={deviceDetail.colorCode} />
                              <div className="info">
                                <span className="value  d-block">
                                  {deviceDetail.averageValue} {deviceDetail.unit}
                                </span>
                                <span className="parameter d-block">{deviceDetail.displayName}</span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>

        <div className="map-devicepara-fotter p-3">
          <Button variant="primary" className="w-100" type="button" onClick={handleViewDetail}>
            {t('View Details')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default ParameterDetailsModal;
