import React, {useEffect, useRef} from 'react';

export const useCustomEffect = (callback, dependencies) => {
  const effectRun = useRef(false);

  useEffect(() => {
    if (!effectRun.current) {
      callback();
    }
    return () => {
      effectRun.current = true;
    };
  }, dependencies);
};
