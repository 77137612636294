export const getCheckedElement = (accesses, data) => {
  const allAccessChecked = accesses.reduce(
    (result, access) => {
      if (data.every((row) => row[access])) {
        result.push(access);
      }
      return result;
    },
    [],
  );

  return allAccessChecked;
};
