import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import { PrimaryButton, SecondaryButton } from 'styles/styled-components/StyledComponents';
import {ModalButtonTypes} from 'constants/modal';

const CustomModal = (props) => {
  const {show, handleClose, title, body, buttons, size} = props;
  const modalButtonsLength = buttons.length;
  return (
    <>
      <Modal size={size} scrollable={true} show={show} onHide={handleClose} backdrop="static" keyboard={true}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
        <Modal.Footer>
          {buttons.map((button, index) => {
            const {type, label, disabled, onClickHandler} = button;
            if (type === ModalButtonTypes.Secondary)
              return (
                <SecondaryButton
                  variant="primary"
                  key={index}
                  style={{marginRight: modalButtonsLength > 2 ? 8 : 0}}
                  onClick={onClickHandler}
                  disabled={disabled}
                >
                  {label}
                </SecondaryButton>
              );
            else if (type === ModalButtonTypes.Primary)
              return (
                <PrimaryButton variant="outline-primary" key={index} onClick={onClickHandler} disabled={disabled}>
                  {label}
                </PrimaryButton>
              );
            else return null;
          })}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default React.memo(CustomModal);
