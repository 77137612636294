import React, {useState, useEffect, useRef} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useForm} from 'react-hook-form';
import {PAGE_DISPLAY} from 'constants/datatable';
import {useNavigate, useParams} from 'react-router-dom';
import leftarrowImg from 'assets/images/svg/left-arrow.svg';
import {useCustomEffect} from 'hooks/useCustomEffect';
import CustomToast from 'components/Toast/CustomToast';
import {editDeviceToSubTenant, fetchSubTenants} from 'features/tenant/tenantApi';
import {
  fetchTenantDeviceType,
  fetchTenantSubDevices,
  fetchTenantRegisteredDevices,
  getRegisteredDevice,
  fetchRegisteredSubTenants,
  addDeviceToSubTenant,
} from 'features/tenant/tenantApi';
import StreetMap from 'components/map';
import {useStateAsync} from 'hooks/useStateAsync';
import useGetTableList from 'hooks/table/useGetTableList';
import DeviceSensor from 'components/tenantStepper/Device/DeviceSensor';
import DeviceSensorType from 'components/tenantStepper/Device/DeviceSensorType';
import {StyledErrorLabel, StyledLabel} from 'styles/styled-components/StyledComponents';
import {Button, Form} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import SubTenantContainer from './SubTenants/SubTenantContainer';

const ManageTenantSetting = () => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [subTenants, setSubTenants] = useState([]);
  const [allDeviceTypes, setAllDeviceTypes] = useState([]);
  const [allDevices, setAllDevices] = useState([]);
  const [deviceType, setDeviceType] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [allSubDeviceTypes, setAllSubDeviceTypes] = useState([]);
  const [selectedSubDeviceType, setSelectedSubDeviceType] = useState([]);
  const [deviceDetils, setDeviceDetils] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedSubTenant, setSelectedSubTenant] = useState(null);

  const scrollTargetRef = useRef(null);
  const {t} = useTranslation();

  const {tenantId, deviceId} = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const previousPage = location.state ? location.state.from : `/app/tenants`;

  const [filters, setFilters] = useStateAsync({
    deviceId: deviceId ? deviceId : '',
    filterName: '',
    status: '',
    key: '',
    value: '',
    pageSize: PAGE_DISPLAY[0], // per page data
    pageNumber: 1, // current page number
  });

  const numberOfPagesRef = useRef(1);
  const applyFilterRef = useRef(false);

  const {tableData, isLoading, getTablelist, handleSort, handlePageChange, handleRowsPerPageChange} = useGetTableList(
    fetchSubTenants,
    numberOfPagesRef,
    setFilters,
    applyFilterRef,
    tenantId,
  );

  const {
    getValues,
    setValue,
    register,
    handleSubmit,
    formState: {errors, isDirty},
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      tenantId: tenantId,
      subTenantId: '',
      deviceId: '',
      deviceSubTypeId: [],
    },
  });

  useCustomEffect(() => {
    getDeviceTypes();
    getSubTenants();
  }, []);

  useEffect(() => {
    (async () => {
      if (tenantId && deviceId) {
        await getDeviceById(tenantId, deviceId);
        setValue('deviceId', deviceId);
        setValue('tenantId', tenantId);
      } else {
        await getDevices();
      }
    })();
  }, [deviceId]);

  useEffect(() => {
    setValue('deviceSubTypeId', selectedSubDeviceType);
  }, [selectedSubDeviceType]);

  const getDevices = async () => {
    try {
      const {data} = await fetchTenantRegisteredDevices(tenantId);
      const devices = data.map((device) => device.masterDevice);
      setDeviceDetils(data);
      setAllDevices(devices);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    }
  };

  const getSubDevices = async (deviceType) => {
    try {
      const {data} = await fetchTenantSubDevices(deviceType._id);
      const subDevices = data ? data : [];
      let activeSubDevices = [];
      if (deviceId) {
        activeSubDevices = selectedSubDeviceType;
      } else {
        activeSubDevices = subDevices.filter((subDevice) => subDevice.isDefault).map((item) => item._id);
      }
      if (deviceId) {
        setSelectedSubDeviceType(activeSubDevices);
      }
      setAllSubDeviceTypes(subDevices);
      setValue('deviceSubTypeId', activeSubDevices);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    }
  };

  const getSubTenants = async () => {
    try {
      const {data} = await fetchRegisteredSubTenants(tenantId);
      setSubTenants(data);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    }
  };

  const getDeviceById = async (tenantId, deviceId) => {
    try {
      const {data} = await getRegisteredDevice(deviceId, tenantId);
      const subDeviceIds = data.subDeviceType.map((subDevice) => subDevice._id);
      setDeviceType(data.deviceType);
      setSelectedSubDeviceType(subDeviceIds);
      setAllDevices([data.masterDevice]);
      setSelectedDevice(data.masterDevice);
      setAllSubDeviceTypes(data.subDeviceType);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    }
  };

  const handleSelectDevice = (device) => {
    const selectedDeviceIndex = deviceDetils.findIndex((devices) => devices.masterDevice._id === device._id);
    const {_id, deviceType, subDeviceType, masterDevice} = deviceDetils[selectedDeviceIndex];
    const subDeviceIds = subDeviceType.map((subDevice) => subDevice._id);
    setDeviceType(deviceType);
    setSelectedDevice(masterDevice);
    setSelectedSubDeviceType(subDeviceIds);
    setAllSubDeviceTypes(subDeviceType);
    setValue('deviceId', _id);
  };

  const handleSubDeviceType = (subDeviceType) => {
    const isAlreadySelected = selectedSubDeviceType.includes(subDeviceType._id);
    if (isAlreadySelected) {
      if (!subDeviceType.isDefault) {
        const subType = selectedSubDeviceType.filter(function (item) {
          return item !== subDeviceType._id;
        });
        setSelectedSubDeviceType(subType);
      }
    } else {
      setSelectedSubDeviceType((prevSubDevice) => [subDeviceType._id, ...prevSubDevice]);
    }
  };

  const getDeviceTypes = async () => {
    try {
      const {data} = await fetchTenantDeviceType();
      setAllDeviceTypes(data);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    }
  };

  const handleAssignSubtenantToDevice = async (formData) => {
    try {
      setIsSubmit(true);
      if (isEdit && selectedSubTenant) {
        const {message} = await editDeviceToSubTenant(selectedSubTenant._id, formData);
        toast(<CustomToast type="success" title="Success" message={message} />);
        await getDevices();
      } else {
        const {message} = await addDeviceToSubTenant(tenantId, formData);
        toast(<CustomToast type="success" title="Success" message={message} />);
      }
      // reset form
      if (!deviceId) {
        setSelectedSubTenant(null);
        setDeviceType(null);
        setAllSubDeviceTypes([]);
        setSelectedSubDeviceType([]);
      }
      setIsEdit(false);
      setValue('subTenantId', '');
      getTablelist(filters);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setIsSubmit(false);
    }
  };

  const handleEditSubTenant = async (device) => {
    setIsEdit(true);
    const {_id, deviceId, masterDevice, deviceTypeResult, deviceSubType, tenantSubDeviceType, subTenantId} = device;
    setSelectedSubTenant(device);
    const subDeviceIds = tenantSubDeviceType.map((tenantSubDevicetype) => tenantSubDevicetype._id);
    setAllDevices([masterDevice]);
    setDeviceType(deviceTypeResult);
    setAllSubDeviceTypes(deviceSubType);
    setSelectedSubDeviceType(subDeviceIds);
    setValue('deviceId', deviceId);
    setValue('subTenantId', subTenantId);
    if (scrollTargetRef.current) {
      scrollTargetRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  };

  const isDisabled = isLoading || Object.keys(errors).length > 0 || !deviceType || selectedSubDeviceType.length === 0;

  return (
    <>
      <div className="breadcrum-sec align-items-center breadcrum-sec d-flex justify-content-between flex-column flex-md-row">
        <div className="mob-100">
          <ul>
            <li>
              <Link to={`/app/dashboard`} className="breadcrum-link" variant="breadcrum">
                <img className="me-2" src={leftarrowImg} alt="Add" />
                {t('Dashboard')}
              </Link>
            </li>
          </ul>
          <h2 className="page-title">{'Manage Tenants'}</h2>
        </div>
        <Button onClick={() => navigate(previousPage)}>{t('Save & Exit')}</Button>
      </div>
      <div className="card">
        <div className="card-body" ref={scrollTargetRef}>
          <h4 className="section-subheading mb-3">{t('Sub Tenant Configuration')}</h4>
          <div className="row">
            <div className="col">
              <div className="map-wrap mb-4">
                <StreetMap
                  devices={allDevices}
                  deviceId={deviceId}
                  selectedDevice={selectedDevice}
                  handleSelectDevice={handleSelectDevice}
                />
              </div>
            </div>
          </div>
          <h4 className="section-subheading mb-3">{t('Sensor Settings')}</h4>
          <DeviceSensor
            allDeviceTypes={allDeviceTypes}
            deviceId={deviceId}
            deviceType={deviceType}
            handleDeviceType={() => null}
          />
          <div className="row">
            <div className="col-12 col-lg-6">
              <Form.Group className="mb-4 position-relative" controlId="roletype">
                <StyledLabel>{t('Sub Tenant')}</StyledLabel>
                <Form.Select
                  name="status"
                  className="mb-2"
                  {...register('subTenantId', {
                    required: t('Sub Tenant is required'),
                  })}
                >
                  <option value="">{t('Select Sub Tenant')}</option>
                  {subTenants.map((subTenant) => (
                    <option key={subTenant._id} value={subTenant._id}>
                      {subTenant.tenantName}
                    </option>
                  ))}
                </Form.Select>
                {errors.subTenantId && <StyledErrorLabel>{errors.subTenantId.message}</StyledErrorLabel>}
              </Form.Group>
            </div>
          </div>
          {allSubDeviceTypes.length > 0 && (
            <DeviceSensorType
              allSubDeviceTypes={allSubDeviceTypes}
              selectedSubDeviceType={selectedSubDeviceType}
              // deviceId={deviceId}
              handleSubDeviceType={handleSubDeviceType}
            />
          )}
          <div className="row">
            <div className="col-12 d-flex justify-content-end">
              <Button className="me-2 btn-outline-primary" onClick={() => navigate(previousPage)}>
                {t('Cancel')}
              </Button>
              <Button disabled={isDisabled} onClick={handleSubmit(handleAssignSubtenantToDevice)}>
                {isEdit ? t('Update') : t('Submit')}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <SubTenantContainer
        tenantId={tenantId}
        deviceId={deviceId}
        filters={filters}
        setFilters={setFilters}
        applyFilterRef={applyFilterRef}
        numberOfPagesRef={numberOfPagesRef}
        handleEditSubTenant={handleEditSubTenant}
        tableData={tableData}
        isLoading={isLoading}
        getTablelist={getTablelist}
        handleSort={handleSort}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
      />
    </>
  );
};

export default ManageTenantSetting;
