import React from 'react';
import moment from 'moment';
import {DAILY, MONTHLY, REPORT_DATE_FORMATE, REPORT_DATE_TIME_FORMATE} from 'constants/reports';

export const group1ParametersColumn = (parameters, reportType) => {
  const dynamicColumns = [
    {
      name: 'Date',
      alias: 'date',
      selector: (row) => {
        return reportType === DAILY
          ? row.date
          : reportType === MONTHLY
          ? moment(row.date).format(REPORT_DATE_FORMATE)
          : moment(row.date).format(REPORT_DATE_TIME_FORMATE);
      },
    },
  ];

  parameters.forEach((param) => {
    dynamicColumns.push({
      name: `${param} ${
        param != 'AQI'
          ? `(${
              param === 'CO' ||
              param === 'CO2' ||
              param === 'NO' ||
              param === 'NO2' ||
              param === 'O3' ||
              param === 'SO2'
                ? 'ppb'
                : 'μg/m³'
            })`
          : ''
      } `,
      alias: param.toLowerCase(),
      selector: (row) => {
        return <span>{row[`${param}_averageValue`]}</span>;
      },
      conditionalCellStyles: [
        {
          when: (row) => row[`${param}_colorCode`],
          style: (row) => {
            return {
              backgroundColor: row[`${param}_colorCode`],
              color: row[`${param}_colorCode`] === '#FFFF00' ? '#000000' : '#FFFFFF',
              '&:hover': {
                cursor: 'pointer',
              },
            };
          },
        },
      ],
    });
  });

  return dynamicColumns;
};
