import React from 'react';
import {useTranslation} from 'react-i18next';
import Loader from 'assets/images/loader.gif';
import NoRecordFound from 'components/NoRecordFound';
import {WindRoseChart} from 'components/charts/WindRoseChart';

const ReportRoseGraph = (props) => {
  const {rose, isLoadingRose, isLast, isLastPage} = props;
  const {t} = useTranslation();
  return (
    <>
      <div className={`col-12 col-md-6 ${rose.length > 0 && isLast ? `page page-${isLastPage}` : ``}`}>
        <div className="card rounded-1 mt-3">
          <div className="card-body">
            <div className="windchart-container">
              <div className="bar-chart-sec">
                <div className="p-2">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h3 className="mb-4">{t('Wind Rose')}</h3>
                    </div>
                  </div>

                  {isLoadingRose ? (
                    <div className="loader-warp">
                      <img src={Loader} alt="loader" />
                    </div>
                  ) : rose.length > 0 ? (
                    <>
                      <WindRoseChart
                        data={rose}
                        columns={['angle', '0-1', '1-2', '2-3', '3-4', '4-5', '5-6', '6-7', '7+']}
                        responsive={true} // esponsive default, value: false
                        legendGap={20} // width gap size in px between chart and legend
                        height={450}
                        width={450}
                      />
                    </>
                  ) : (
                    <NoRecordFound />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(ReportRoseGraph);
