import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {logout} from 'features/auth/authSlice';
import {resetLanguage} from 'features/language/languageSlice';

export const logoutUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    dispatch(resetLanguage());
    localStorage.removeItem('auth');
    navigate('/auth/login');
  };

  return handleLogout;
};
