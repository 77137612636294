import React, {useEffect, useState, useRef} from 'react';
import {useSelector} from 'react-redux';
import {Form, Button, Carousel, Spinner, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {toast} from 'react-toastify';
import Loader from 'assets/images/loader.gif';
import SwitchButton from 'components/SwitchButton';
import handmarkerImg from 'assets/images/svg/hand-marker.svg';
import resetFilterImg from 'assets/images/svg/refresh-icon.svg';
import {ParameterAvgColor, PrimaryButton, StyledLabel} from 'styles/styled-components/StyledComponents';
import DashboardMapContainer from 'components/charts/DashboardMapContainer';
import CustomToast from 'components/Toast/CustomToast';
import {
  fetchDashboardDevices,
  fetchDeviceAllParameters,
  fetchOverallDashboardDeviceParameterAvg,
  fetchOverallDashboardParameterAvg,
  getTenantName,
} from 'features/dashboard/dashboardApi';
import {getMinutesFromSelection} from 'utils/getMinutesFromSelection';
import {DATE_FILTER_OPTIONS, DEFAULT_MINUTES, OVERALL_DEFAULT_DURATION} from 'constants/charts';
import tracfficIconImg from 'assets/images/svg/traffic-icon.svg';
import parkingIconImg from 'assets/images/svg/parking-icon.svg';
import {
  ALL_IN_ONE_SENSOR,
  ALL_IN_ONE_SENSOR_VALUE,
  PARKING,
  PERFORMANCE_ISSUE,
  PERFORMANCE_ISSUE_PERMISSION,
  PERFORMANCE_ISSUE_VALUE,
  TRAFFIC,
} from 'constants/overviewDashboard';
import {I_PAD, I_PAD_LANDSCAPE, MOBILE, TABLET} from 'constants/app';
import {useTranslation} from 'react-i18next';
import {AQI} from 'constants/parameters';
import 'rc-time-picker/assets/index.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import TimePicker from 'rc-time-picker';
import moment, {months} from 'moment';

const CHUNK_SIZE = 6;

const OverviewDashboardContainer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDeviceLoading, setIsDeviceLoading] = useState(false);
  const [isContainerLoading, setIsContainerLoading] = useState(false);
  const [isParameterLoading, setIsParameterLoading] = useState(false);
  const [tenants, setTenants] = useState([]);
  const [tenantZones, setTenantZones] = useState([]);
  const [selectedTenants, setSelectedTenants] = useState(null);
  const [selectedZoneId, setSelectedZoneId] = useState('');
  const [selectedZone, setSelectedZone] = useState(null);
  const [devices, setDevices] = useState([]);
  const [allTenantZones, setAllTenantZones] = useState([]);
  const [allZones, setAllZones] = useState([]);
  const [zones, setZones] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [showDeviceDetailModal, setShowDeviceDetailModal] = useState(false);
  const [selectedDeviceDetail, setSelectedDeviceDetail] = useState([]);
  const [parametersAverage, setParametersAverage] = useState([]);
  const [selectedCityInsights, setSelectedCityInsights] = useState(ALL_IN_ONE_SENSOR_VALUE);
  const [isZoneVisible, setIsZoneVisible] = useState(false);
  const [duration, setDuration] = useState(OVERALL_DEFAULT_DURATION);
  const [parameter, setParameter] = useState(AQI.value);
  const [allParameters, setAllParameters] = useState([]);
  const [filterDate, setFilterDate] = useState(DEFAULT_MINUTES);
  const [showDateRange, setShowDateRange] = useState(false);
  const [dates, setDates] = useState({
    startDate: null,
    endDate: null,
  });

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [disabledTime, setDisabledTime] = useState({
    hours: [],
    minutes: [],
  });

  const pickerRef = useRef(null);
  const scrollTargetRef = useRef(null);
  const applyFilterButtonRef = useRef(null);
  const applyFilterRef = useRef({
    isDeviceModalOpen: false,
    deviceId: null,
    duration: OVERALL_DEFAULT_DURATION,
  });

  const {t} = useTranslation();

  const {user} = useSelector((state) => state.auth);
  const app = useSelector((state) => state.app);

  useEffect(() => {
    (async () => {
      await fetchTenantDetails();
      await fetchAllParameters();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (selectedTenants) {
        const tenantZones = selectedTenants.lookupMapZonesResult;
        setTenantZones(tenantZones);
        await getTenantZones(selectedTenants._id);
      }
    })();
  }, [selectedTenants]);

  const getTenantZones = async (tenantId) => {
    try {
      const payload = {
        tenantId: tenantId,
        // filterDate: filterDate,
      };
      const {data} = await fetchDashboardDevices(payload);
      setAllTenantZones(data.zone);
      // if (data) {
      //   const zone = data.zone.find((zone) => zone._id === selectedZoneId);
      //   setSelectedZone(zone ? [zone] : null);
      // } else {
      //   setSelectedZone(null);
      // }
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => handleAutoRefresh(), 600000); // 10 mints

    return () => clearInterval(intervalId);
  }, []);

  const handleAutoRefresh = async () => {
    try {
      if (applyFilterButtonRef.current) {
        const startDate = applyFilterRef.current.startDate ? applyFilterRef.current.startDate : null;
        const endDate = applyFilterRef.current.endDate ? applyFilterRef.current.endDate : null;
        const startTime = applyFilterRef.current.startTime ? applyFilterRef.current.startTime : null;
        const endTime = applyFilterRef.current.endTime ? applyFilterRef.current.endTime : null;

        if (applyFilterRef.current.duration === 'custom' && !startDate && !endDate) {
          applyFilterRef.current.duration = DEFAULT_MINUTES;
          setFilterDate(DEFAULT_MINUTES);
          setDuration(OVERALL_DEFAULT_DURATION);
          resetCustomDateFilter();
        } else {
          applyFilterButtonRef.current.click();
        }

        if (applyFilterRef.current.isDeviceModalOpen && applyFilterRef.current.deviceId) {
          setIsContainerLoading(true);
          const {data} = await fetchOverallDashboardDeviceParameterAvg({
            deviceId: applyFilterRef.current.deviceId,
            filterDate: applyFilterRef.current.duration,
            startDate: startDate,
            endDate: endDate,
            startTime: startTime,
            endTime: endTime,
          });
          const sortedData = await sortingParameters(data);
          setSelectedDeviceDetail(sortedData);
          setIsContainerLoading(false);
        }
      }
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    }
  };

  const fetchTenantDetails = async () => {
    try {
      setIsLoading(true);
      const {data: tenant} = await getTenantName();
      if (tenant) {
        const {minutes} = getMinutesFromSelection(duration);
        const params = {
          tenantId: tenant[0]._id,
          filterDate: minutes,
          zoneId: selectedZoneId,
        };
        getTenantParameterAvg(params);
        const payload = {
          tenantId: tenant[0]._id,
          insight: selectedCityInsights,
          filterDate: minutes,
          parameter: parameter,
        };
        await getDevices(payload);
        setTenants(tenant);
        setSelectedTenants(tenant[0]);
      } else {
        setTenants([]);
        setSelectedTenants(null);
      }
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAllParameters = async () => {
    try {
      const {data} = await fetchDeviceAllParameters();
      const parameters = data
        ? data.filter((item) => item.name !== 'windDirection').sort((a, b) => a.name.localeCompare(b.name))
        : [];
      setAllParameters(parameters);
      setParameter(AQI.value);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    }
  };

  const handleTenantChange = (event) => {
    const selectedTenant = tenants.find((tenant) => tenant._id === event.target.value);
    if (selectedTenant) {
      setSelectedZone(null);
      setSelectedTenants(selectedTenant);
    }
  };

  const handleZoneChange = (event) => {
    const zone = event.target.value;
    if (zone === 'all') {
      setSelectedZone(null);
      setSelectedZoneId('');
    } else {
      setSelectedZoneId(event.target.value);
    }
  };

  const getDevices = async (payload) => {
    try {
      setIsDeviceLoading(true);
      const zoneId = payload.zoneId ? payload.zoneId : selectedZoneId;
      const {data: deviceData} = await fetchDashboardDevices(payload);
      if (zoneId) {
        if (deviceData) {
          const zone = deviceData.zone.find((deviceZone) => deviceZone._id === zoneId);
          setSelectedZone(zone ? [zone] : null);
        } else {
          setSelectedZone(null);
        }
      }
      setDevices(deviceData ? deviceData.device : []);
      const deviceZone = deviceData ? deviceData.zone : [];
      if (payload.zoneId && deviceZone.length > 0) {
        deviceZone.forEach((deviceZoneItem) => {
          const zoneIndex = allZones.findIndex((zone) => zone._id === deviceZoneItem._id);
          if (zoneIndex !== -1) {
            allZones[zoneIndex] = {...allZones[zoneIndex], ...deviceZoneItem};
          }
        });
        setZones(allZones);
      } else {
        setZones(deviceData ? deviceData.zone : []);
        if (allZones.length === 0) {
          setAllZones(deviceData ? deviceData.zone : []);
        }
      }
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setIsDeviceLoading(false);
    }
  };

  const getTenantParameterAvg = async (payload) => {
    try {
      setIsParameterLoading(true);
      // const {minutes} = getMinutesFromSelection(duration);
      // setFilterDate(minutes);
      const {data: parameterAvgData} = await fetchOverallDashboardParameterAvg(payload);
      const parameterAvg = sortingParameters(parameterAvgData);
      const chunks = [];
      const chunk_size =
        app.deviceType === MOBILE ? 2 : [I_PAD, I_PAD_LANDSCAPE, TABLET].includes(app.deviceType) ? 4 : CHUNK_SIZE;
      for (let i = 0; i < parameterAvg.length; i += chunk_size) {
        chunks.push(parameterAvg.slice(i, i + chunk_size));
      }
      setParametersAverage(chunks);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setIsParameterLoading(false);
    }
  };

  const handleSelectDevice = async (device) => {
    try {
      setIsContainerLoading(true);
      setShowDeviceDetailModal(false);
      const deviceId = device._id;
      setSelectedDevice(device);
      setShowDeviceDetailModal(true);
      applyFilterRef.current.isDeviceModalOpen = true;
      applyFilterRef.current.deviceId = deviceId;
      applyFilterRef.current.duration = filterDate;
      applyFilterRef.current.startDate = dates.startDate ? moment(dates.startDate).format('YYYY-MM-DD') : null;
      applyFilterRef.current.endDate = dates.endDate ? moment(dates.endDate).format('YYYY-MM-DD') : null;
      applyFilterRef.current.startTime = startTime ? startTime.format('HH:mm') : null;
      applyFilterRef.current.endTime = endTime ? endTime.format('HH:mm') : null;
      if (device.name !== 'Hazardous Device Value' && device.name !== 'Device Not Working') {
        let dateRange = null;
        if (dates.startDate && dates.endDate) {
          dateRange = {
            startDate: moment(dates.startDate).format('YYYY-MM-DD'),
            endDate: moment(dates.endDate).format('YYYY-MM-DD'),
          };
        }
        if (startTime && endTime) {
          dateRange['startTime'] = startTime.format('HH:mm');
          dateRange['endTime'] = endTime.format('HH:mm');
        }
        const payload = {deviceId: deviceId, filterDate: filterDate, ...dateRange};
        const {data} = await fetchOverallDashboardDeviceParameterAvg(payload);
        const sortedData = await sortingParameters(data);
        setSelectedDeviceDetail(sortedData);
      }
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setIsContainerLoading(false);
    }
  };

  const handleFilterByDate = (event) => {
    const value = event.target.value;
    const {minutes} = getMinutesFromSelection(value);
    applyFilterRef.current.duration = minutes;
    setDuration(value);
    if (value === 'custom') {
      setFilterDate(value);
      setShowDateRange(true);
    } else {
      setShowDateRange(false);
      resetCustomDateFilter();
      setFilterDate(minutes);
    }
  };

  const handleFilterByParameter = async (event) => {
    setParameter(event.target.value);
    const dates = getDateTime();
    const payload = {
      tenantId: selectedTenants._id,
      insight: selectedCityInsights,
      zoneId: selectedZoneId,
      filterDate: filterDate,
      parameter: event.target.value,
      ...dates,
    };
    await getDevices(payload);
  };

  const handleFilter = async () => {
    try {
      let dateRange = null;
      if (filterDate === 'custom') {
        if (!dates.startDate || !dates.endDate) {
          toast(<CustomToast type="error" title="Error" message={'Please fill date'} />);
          return;
        }
        if (!startTime && endTime) {
          toast(<CustomToast type="error" title="Error" message={'Please fill start time'} />);
          return;
        }
        if (startTime && !endTime) {
          toast(<CustomToast type="error" title="Error" message={'Please fill end time'} />);
          return;
        }
        if (dates.startDate && dates.endDate) {
          dateRange = {
            startDate: moment(dates.startDate).format('YYYY-MM-DD'),
            endDate: moment(dates.endDate).format('YYYY-MM-DD'),
          };
          applyFilterRef.current.startDate = dates.startDate ? moment(dates.startDate).format('YYYY-MM-DD') : null;
          applyFilterRef.current.endDate = dates.endDate ? moment(dates.endDate).format('YYYY-MM-DD') : null;
        }
        if (startTime && endTime) {
          dateRange['startTime'] = startTime.format('HH:mm');
          dateRange['endTime'] = endTime.format('HH:mm');
          applyFilterRef.current.startTime = startTime ? startTime.format('HH:mm') : null;
          applyFilterRef.current.endTime = endTime ? endTime.format('HH:mm') : null;
        }
      }
      setIsDeviceLoading(true);
      setDevices([]);
      setZones([]);
      setParametersAverage([]);
      scrollToMap();
      const params = {
        tenantId: selectedTenants._id,
        filterDate: filterDate,
        zoneId: selectedZoneId,
        ...dateRange,
      };
      getTenantParameterAvg(params);

      const payload = {
        tenantId: selectedTenants._id,
        insight: selectedCityInsights,
        zoneId: selectedZoneId,
        filterDate: filterDate,
        parameter: parameter,
        ...dateRange,
      };
      await getDevices(payload);
      if (showDeviceDetailModal) {
        setIsContainerLoading(true);
        const {data: deviceDetailData} = await fetchOverallDashboardDeviceParameterAvg({
          deviceId: selectedDevice._id,
          filterDate: filterDate,
          ...dateRange,
        });
        const sortedData = await sortingParameters(deviceDetailData);
        setSelectedDeviceDetail(sortedData);
        setIsContainerLoading(false);
      }
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setIsDeviceLoading(false);
    }
  };

  const handleClearFilter = async () => {
    const {minutes} = getMinutesFromSelection(OVERALL_DEFAULT_DURATION);
    setShowDateRange(false);
    setFilterDate(minutes);
    setSelectedZone(null);
    setSelectedZoneId('');
    setDuration(OVERALL_DEFAULT_DURATION);
    setIsZoneVisible(false);
    setParameter(AQI.value);
    setSelectedTenants(tenants.length > 0 ? tenants[0] : []);
    resetCustomDateFilter();
    const params = {
      tenantId: tenants[0]._id,
      filterDate: minutes,
    };
    getTenantParameterAvg(params);
    const payload = {
      tenantId: tenants[0]._id,
      insight: selectedCityInsights,
      filterDate: minutes,
      parameter: AQI.value,
    };
    await getDevices(payload);
  };

  const resetCustomDateFilter = () => {
    setStartTime(null);
    setEndTime(null);
    const inputField = document.getElementById('date-range');
    if (inputField) {
      inputField.value = '';
      pickerRef.current.setStartDate(null);
      pickerRef.current.setEndDate(null);
    }
    setDates((prevState) => ({
      ...prevState,
      startDate: null,
      endDate: null,
    }));
    applyFilterRef.current.startDate = null;
    applyFilterRef.current.endDate = null;
    applyFilterRef.current.startTime = null;
    applyFilterRef.current.endTime = null;
  };

  const handleCityInsights = async (cityInsights) => {
    setShowDeviceDetailModal(false);
    setSelectedDevice(null);
    setSelectedCityInsights(cityInsights);
    const dates = getDateTime();
    const params = {
      tenantId: selectedTenants._id,
      filterDate: filterDate,
      zoneId: selectedZoneId,
      ...dates,
    };
    getTenantParameterAvg(params);
    const payload = {
      tenantId: selectedTenants._id,
      insight: cityInsights,
      zoneId: selectedZoneId,
      filterDate: filterDate,
      parameter: parameter,
      ...dates,
    };
    await getDevices(payload);
  };

  const getDateTime = () => {
    let dateRange = null;
    if (dates.startDate && dates.endDate) {
      dateRange = {
        startDate: moment(dates.startDate).format('YYYY-MM-DD'),
        endDate: moment(dates.endDate).format('YYYY-MM-DD'),
      };
    }
    if (startTime && endTime) {
      dateRange['startTime'] = startTime.format('HH:mm');
      dateRange['endTime'] = endTime.format('HH:mm');
    }
    return dateRange;
  };

  const handleSwitchToggle = () => {
    setIsZoneVisible(!isZoneVisible);
    scrollToMap();
  };

  const scrollToMap = () => {
    if (scrollTargetRef.current) {
      scrollTargetRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  };

  const sortingParameters = (data) => {
    return data ? data.filter((item) => item.key !== 'windDirection').sort((a, b) => a.key.localeCompare(b.key)) : [];
  };

  const handleMapZoneClick = async (zone) => {
    const zoneId = zone._id;
    setSelectedZoneId(zoneId);
    const dates = getDateTime();
    const params = {
      tenantId: selectedTenants._id,
      filterDate: filterDate,
      zoneId: zoneId,
      ...dates,
    };
    getTenantParameterAvg(params);
    const payload = {
      tenantId: selectedTenants._id,
      insight: selectedCityInsights,
      zoneId: zoneId,
      filterDate: filterDate,
      parameter: parameter,
      ...dates,
    };
    await getDevices(payload);
  };

  const handleApply = (event, picker) => {
    const startDate = picker.startDate.format('MM/DD/YYYY');
    const endDate = picker.endDate.format('MM/DD/YYYY');
    picker.element.val(startDate + ' - ' + endDate);
    setDates((prevState) => ({
      ...prevState,
      startDate: startDate,
      endDate: endDate,
    }));
  };

  const handleCancel = (event, picker) => {
    picker.element.val('');
    setDates((prevState) => ({
      ...prevState,
      startDate: null,
      endDate: null,
    }));
    applyFilterRef.current.startDate = null;
    applyFilterRef.current.endDate = null;
  };

  const isBeforeTime = (time1, time2) => time1.minutes() + time1.hours() * 60 < time2.minutes() + time2.hours();

  const handleValueChange1 = (value) => {
    if (!value) {
      setStartTime(value);
      setEndTime(value);
      applyFilterRef.current.startTime = null;
      applyFilterRef.current.endTime = null;
      return;
    }
    setStartTime(value.clone());
    setEndTime((previousendTime) =>
      previousendTime && isBeforeTime(value, previousendTime)
        ? previousendTime.clone()
        : value.clone().add(15, 'minutes'),
    );
    // Disable past hours and times for endTime
    const hrs = value.hours();
    const mins = value.minutes();
    const disabledHrs = [];
    const disabledMins = [];
    for (let i = 0; i < 24; i++) {
      if (i < hrs) {
        disabledHrs.push(i);
      }
    }
    // Disable minutes for the selected hour if it's the current hour
    if (value.hours() === hrs) {
      for (let i = 0; i < 60; i += 15) {
        if (i <= mins) {
          disabledMins.push(i);
        }
      }
    }
    setDisabledTime((prevState) => ({
      ...prevState,
      hours: disabledHrs,
      minutes: disabledMins,
    }));
  };

  const handleValueChange2 = (value) => {
    if (!value) {
      setEndTime(value);
      applyFilterRef.current.endTime = null;
      return;
    }
    setEndTime(value.clone());
    const disabledMins = [];
    // Disable minutes for the selected hour if it's the current hour
    if (startTime.format('HH') === value.format('HH')) {
      for (let i = 0; i < 60; i += 15) {
        if (i <= startTime.format('mm')) {
          disabledMins.push(i);
        }
      }
    }
    setDisabledTime((prevState) => ({
      ...prevState,
      minutes: disabledMins,
    }));
  };

  return (
    <>
      <div>
        {isLoading ? (
          <div className="loader-warp">
            <img src={Loader} alt="loader" />
          </div>
        ) : tenants.length > 0 ? (
          <>
            <div className="filter-card mb-4">
              <div className="row">
                <div className="col d-flex justify-content-between align-items-end flex-column flex-md-row">
                  <div className="d-flex flex-wrap align-items-end mob-100 gap-2">
                    <Form.Group className="me-0 me-md-2 mb-3 mb-xxl-0 mob-100" controlId="tenantName">
                      <StyledLabel>{t('Tenant Name')}</StyledLabel>
                      <Form.Select name="status" value={selectedTenants?._id} onChange={handleTenantChange}>
                        {tenants.map((tenant) => (
                          <option key={tenant._id} value={tenant._id}>
                            {tenant.tenantName}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="me-0 me-md-1 mb-3 mb-xxl-0 mob-100" controlId="roleName">
                      <StyledLabel>{t('Zone')}</StyledLabel>
                      <Form.Select name="status" value={selectedZoneId} onChange={handleZoneChange}>
                        <option value="all">All Zones</option>
                        {tenantZones.map((zone) => (
                          <option key={zone._id} value={zone._id}>
                            {zone.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="me-0 me-md-1 mb-3 mb-xxl-0 mob-100" controlId="duration">
                      <StyledLabel>{t('Filter By Duration')}</StyledLabel>
                      <Form.Select name="status" value={duration} onChange={handleFilterByDate}>
                        <option value="">{t('Select Duration')}</option>
                        {DATE_FILTER_OPTIONS.map((date, index) => (
                          <option key={index} value={date.value}>
                            {t(date.label)}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    {showDateRange && (
                      <>
                        <Form.Group className="me-0 me-md-1 mb-3 mb-xxl-0 mob-100" controlId="date">
                          <StyledLabel>{t('Filter By Date')}</StyledLabel>
                          <DateRangePicker
                            ref={pickerRef}
                            initialSettings={{
                              autoUpdateInput: false,
                              locale: {
                                cancelLabel: 'Clear',
                              },
                              maxDate: moment(),
                              maxSpan: {months: 1},
                            }}
                            onApply={handleApply}
                            onCancel={handleCancel}
                          >
                            <input
                              type="text"
                              id="date-range"
                              autoComplete="off"
                              className="form-control col-4"
                              defaultValue=""
                            />
                          </DateRangePicker>
                        </Form.Group>
                        <Form.Group
                          className="me-0 d-grid me-md-1 mb-3 mb-xxl-0 mob-100 time-picker"
                          controlId="startTime"
                        >
                          <StyledLabel>{t('Start time')}</StyledLabel>
                          <TimePicker
                            value={startTime}
                            minuteStep={15}
                            showSecond={false}
                            onChange={handleValueChange1}
                          />
                        </Form.Group>

                        <Form.Group
                          className="me-0 d-grid me-md-1 mb-3 mb-xxl-0 mob-100 time-picker"
                          controlId="endTime"
                        >
                          <StyledLabel>{t('End time')}</StyledLabel>

                          <TimePicker
                            value={endTime}
                            disabledHours={() => (disabledTime.hours.length > 0 ? disabledTime.hours : null)}
                            disabledMinutes={() => (disabledTime.minutes.length > 0 ? disabledTime.minutes : null)}
                            minuteStep={15}
                            showSecond={false}
                            onChange={handleValueChange2}
                          />
                        </Form.Group>
                      </>
                    )}
                    <PrimaryButton
                      ref={applyFilterButtonRef}
                      variant="outline-primary"
                      className="apply me-2 mb-3 mb-xxl-0"
                      onClick={handleFilter}
                    >
                      {t('Apply')}
                    </PrimaryButton>
                    <Button
                      variant="outline-primary"
                      className="mb-3 mb-xxl-0"
                      type="button"
                      onClick={handleClearFilter}
                    >
                      <img src={resetFilterImg} alt="Filter" />
                    </Button>
                    {/* </div> */}
                    {/* <div className="d-flex flex-wrap align-items-end mob-100 gap-2"> */}
                    {isZoneVisible && (
                      <Form.Group
                        className={`me-0 ${
                          isZoneVisible && filterDate === 'custom' ? `ms-md-5` : `ms-md-2`
                        } mb-3 mb-xxl-0 mob-100`}
                        controlId="parameters"
                      >
                        <StyledLabel>{t('Filter By Parameter')}</StyledLabel>
                        <Form.Select name="status" value={parameter} onChange={handleFilterByParameter}>
                          {/* <option value="">{t('Select Parameter')}</option> */}
                          {allParameters.map((date, index) => (
                            <option key={index} value={date.name}>
                              {date.displayName}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    )}
                    <Form.Group className="me-0 mb-3 mb-xxl-0" controlId="showZones">
                      <StyledLabel>{t('Zones view')}</StyledLabel>
                      <SwitchButton
                        leftLabel={'Enable'}
                        rightLabel={'Disable'}
                        isDisableInactive={true}
                        value={isZoneVisible}
                        handleSetValue={handleSwitchToggle}
                        isDisableActive={true}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
            {/* city inside section starts */}
            <div className="city-insights-sec mb-3 mb-xl-5 shadow-none">
              <h5>{t('City Insights')}</h5>
              <div className="row">
                <div className="col-12 col-md-4 col-lg-3">
                  <div
                    className={`city-insights-card ${selectedCityInsights === ALL_IN_ONE_SENSOR_VALUE && `active`}`}
                    onClick={() => handleCityInsights(ALL_IN_ONE_SENSOR_VALUE)}
                  >
                    <div className="card-icon">
                      <img src={handmarkerImg} alt="Air Censor" />
                    </div>
                    {t(ALL_IN_ONE_SENSOR)}
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                  <div className="city-insights-card inactive">
                    <div className="card-icon">
                      <img src={tracfficIconImg} alt="Traffic" />
                    </div>
                    {t(TRAFFIC)}
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                  <div className="city-insights-card inactive">
                    <div className="card-icon">
                      <img src={parkingIconImg} alt="Parking" />
                    </div>
                    {t(PARKING)}
                  </div>
                </div>
                {PERFORMANCE_ISSUE_PERMISSION.includes(user.type) && (
                  <div className="col-12 col-md-4 col-lg-3">
                    <div
                      className={`city-insights-card ${selectedCityInsights === PERFORMANCE_ISSUE_VALUE && `active`}`}
                      onClick={() => handleCityInsights(PERFORMANCE_ISSUE_VALUE)}
                    >
                      <div className="card-icon">
                        <img src={parkingIconImg} alt="Parking" />
                      </div>
                      {t(PERFORMANCE_ISSUE)}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* city inside section ends */}
            <>
              <div className="filter-card mt-3">
                <div className="row">
                  <div style={{display: 'flex', height: '35rem'}}>
                    <DashboardMapContainer
                      devices={devices}
                      zones={zones}
                      selectedZone={selectedZone}
                      deviceId={''}
                      showZoneColor={true}
                      selectedDevice={selectedDevice}
                      selectedDeviceDetail={selectedDeviceDetail}
                      showDeviceDetailModal={showDeviceDetailModal}
                      isPolygonVisible={isZoneVisible}
                      isDeviceLoading={isDeviceLoading}
                      isZoneCluster={true}
                      isContainerLoading={isContainerLoading}
                      setShowDeviceDetailModal={setShowDeviceDetailModal}
                      handleSelectDevice={handleSelectDevice}
                      handleMapZoneClick={handleMapZoneClick}
                    />
                    <div ref={scrollTargetRef} />
                  </div>
                </div>
              </div>
              {/* Graph parameter section starts */}
              <div className="graph-parameter my-4">
                <div className="card rounded-2">
                  <div className="card-body ">
                    {isParameterLoading ? (
                      <>
                        <div className="text-center">
                          <span className="d-flex justify-content-center mt-2 mb-2" key={0}>
                            <Spinner animation="border" />
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        {parametersAverage.length > 0 ? (
                          <Carousel id={`parameters-${selectedZoneId}`} data-bs-theme="dark">
                            {parametersAverage.map((parameters, index) => (
                              <Carousel.Item key={index}>
                                <div className="row">
                                  {parameters.map((item, itemIndex) => {
                                    const img = `/parameters/${item.key.toLowerCase()}.svg`;
                                    return (
                                      <div key={itemIndex} className="col-6 col-md-3 col-lg-3 col-xl-2">
                                        <div className="device-parameter-container d-flex justify-content-center">
                                          <div className="para-icon me-2">
                                            <img className="cloud-icon" src={img} alt={item.displayName} />
                                          </div>
                                          <div className="para-value">
                                            <OverlayTrigger
                                              placement={'top-start'}
                                              overlay={<Tooltip>{item.displayName}</Tooltip>}
                                            >
                                              <h4 className="mb-1">{item.displayName}</h4>
                                            </OverlayTrigger>
                                            <div className="value-bottom d-flex">
                                              <span>
                                                {item.averageValue} {item.unit}
                                              </span>
                                              <ParameterAvgColor
                                                className="para-grade-card ms-2"
                                                backgroundColor={item.colorCode}
                                                color={item.colorCode}
                                              >
                                                <OverlayTrigger
                                                  placement={'top'}
                                                  overlay={<Tooltip>{item.condition}</Tooltip>}
                                                >
                                                  <div>{item.condition}</div>
                                                </OverlayTrigger>
                                              </ParameterAvgColor>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </Carousel.Item>
                            ))}
                          </Carousel>
                        ) : (
                          <div className="text-center">
                            <span className="d-flex justify-content-center mt-2 mb-2" key={0}>
                              {t('No Record Found!!!')}
                            </span>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              {/* Graph parameter section ends */}
            </>
          </>
        ) : (
          <>{t('There are no active Tenants/ Devices right now. Please action to view the dashboard.')}</>
        )}
      </div>
    </>
  );
};

export default OverviewDashboardContainer;
