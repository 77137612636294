import React from 'react';
import Action from 'components/datatable/Action';

export const subscriberListColumn = (filters, handleEdit, handleDelete, t) => {
  return [
    {
      name: t('Serial No'),
      selector: (row) => row.serial_no,
      cell: (row, index) => {
        return (filters.pageNumber - 1) * filters.pageSize + index + 1;
      },
    },
    {
      name: t('Name'),
      alias: 'name',
      selector: (row) => row.name,
      sortable: true,
      sortFunction: (a, b) => a.name?.toLowerCase().localeCompare(b.name?.toLowerCase()),
    },
    {
      name: t('Email'),
      alias: 'email',
      selector: (row) => row.email,
      sortable: true,
      sortFunction: (a, b) => a.email?.toLowerCase().localeCompare(b.email?.toLowerCase()),
    },
    {
      name: t('Action'),
      button: true,
      cell: (row) => <Action row={row} handleEdit={handleEdit} handleDelete={handleDelete} />,
    },
  ];
};
