import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation} from 'react-i18next';
import cancelImg from 'assets/images/svg/pop-close.svg';

const CustomToast = ({title, message, type}) => {
  const {t} = useTranslation();

  const getIconClass = () => {
    switch (type) {
      case 'success':
        return 'success-block';
      case 'warning':
        return 'warning-block';
      case 'error':
        return 'error-block';
      default:
        return 'default-block';
    }
  };

  const iconClass = getIconClass();

  return (
    <div className={`custom-toast custom-toast-${type}`}>
      <div className={`toast-icon ${iconClass}`} />
      <div className="toast-content">
        <div className="toast-title">{t(title)}</div>
        <div className="toast-message">{message}</div>
      </div>
      <div className="toast-close">
        <button className="close-button">
          <img src={cancelImg} alt="close" />
        </button>
      </div>
    </div>
  );
};

export default CustomToast;
