import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Container, Row, Col, Button} from 'react-bootstrap';
import Error404 from 'assets/images/svg/404-error.svg';
import {useTranslation} from 'react-i18next';

const PageNotFound = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const hanldGoToHomePage = () => {
    navigate(-1);
  };

  return (
    <Container className="my-3">
      <Row className="justify-content-center align-items-center text-center">
        <Col xs={10} sm={10} md={8} lg={6}>
          <div className='error-container d-flex align-items-center flex-column justify-content-center'>
            <img className="mb-3 w-80" src={Error404} alt="404-error" />
            <p className="mb-4">
              {t(`We’re sorry, the page you requested could not be found. Please go back to the homepage.`)}
            </p>
            <Button onClick={hanldGoToHomePage} variant="primary">
              {t('Go Back')}
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default PageNotFound;
