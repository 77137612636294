import moment from 'moment';
import {DAILY, REPORT_DATE_FORMATE} from 'constants/reports';

export const getStackedLineChartOptions = (data, reportType) => {
  const xAxisLabel = data.map((chart) => {
    return reportType === DAILY ? chart.name : moment(chart.name).format(REPORT_DATE_FORMATE);
  });
  return {
    dataset: [
      {
        dimensions: ['name', 'value', 'colorCode'],
        source: data,
      },
      {
        transform: {
          type: 'sort',
          config: {dimension: 'name', order: 'asc'},
        },
      },
    ],
    series: [
      {
        name: 'Average',
        type: 'line',
        data: data.map((item) => item.averageValue),
      },
      {
        name: 'Minumun',
        type: 'line',
        data: data.map((item) => item.minValue),
      },
      {
        name: 'Maximum',
        type: 'line',
        data: data.map((item) => item.maxValue),
      },
    ],
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: xAxisLabel,
    },
    yAxis: {
      type: 'value',
    },
    animation: true,
    legend: {
      data: ['Average', 'Minumun', 'Maximum'],
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'none',
      },
      // Configure tooltip appearance
      backgroundColor: 'rgba(50, 50, 50, 0.7)', // Tooltip background color
      borderColor: '#333', // Border color
      borderWidth: 1, // Border width
      textStyle: {
        color: '#fff', // Text color
        fontSize: 12, // Font size
        fontWeight: 'bold', // Font weight
        fontFamily: 'Arial, sans-serif', // Font family
      },
    },
  };
};
