import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {Link, useNavigate} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import Loader from 'assets/images/loader.gif';
import CustomToast from 'components/Toast/CustomToast';
import reportheadImg from 'assets/images/svg/report-head-icon.svg';
import typeheadImg from 'assets/images/svg/report-type-head-icon.svg';
import areaheadImg from 'assets/images/svg/area-head-icon.svg';
import leftarrowImg from 'assets/images/svg/left-arrow.svg';
import {
  AIO_SENSOR_VALUE,
  AREAS,
  DAILY_VALUE,
  DEVICE_VALUE,
  OVERALL_VALUE,
  PARKING_VALUE,
  REPORTS,
  REPORTS_TYPES,
  TRAFFIC_VALUE,
} from 'constants/reports';
import {fetchActiveDevices, fetchAllParameters} from 'features/reports/reportsApi';
import DashboardMapContainer from 'components/charts/DashboardMapContainer';
import {MANAGE_REPORTS, VIEW_REPORTS} from 'constants/routesName';
import { encryptData } from 'utils/rememberMe';
import {useTranslation} from 'react-i18next';

const GenerateReports = () => {
  const [isDeviceLoading, setIsDeviceLoading] = useState(false);
  const [report, setReport] = useState(AIO_SENSOR_VALUE);
  const [reportType, setReportType] = useState(DAILY_VALUE);
  const [area, setArea] = useState(OVERALL_VALUE);
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [selectedParameters, setSelectedParameters] = useState([]);
  const [parameters, setParameters] = useState([]);
  const [allSubDeviceTypes, setAllSubDeviceTypes] = useState([]);
  const [errors, setErrors] = useState({
    parameter: false,
    device: false,
  });

  const navigate = useNavigate();
  const {t} = useTranslation();

  useEffect(() => {
    (async () => {
      if (area === OVERALL_VALUE) {
        await getOverAllParameters();
      }
    })();
  }, [area]);

  useEffect(() => {
    handleSelectedParameters(selectedParameters);
  }, [selectedParameters]);

  useEffect(() => {
    if (selectedParameters.length > 0) {
      setErrors((prevSate) => ({
        ...prevSate,
        parameter: false,
      }));
    } else {
      setErrors((prevSate) => ({
        ...prevSate,
        parameter: true,
      }));
    }
  }, [selectedParameters]);

  const getOverAllParameters = async (deviceId = '') => {
    try {
      const {data} = await fetchAllParameters({deviceId: deviceId});
      const deviceParameter = data ? data : [];
      setAllSubDeviceTypes(deviceParameter);
      const allParameter = deviceParameter.map((parameter) => parameter._id);
      setSelectedParameters(allParameter);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    }
  };

  const handleAreaSelection = async (selectedArea) => {
    try {
      setErrors((prevSate) => ({
        ...prevSate,
        parameter: false,
        device: false,
      }));
      setArea(selectedArea);
      if (selectedArea === DEVICE_VALUE) {
        setIsDeviceLoading(true);
        setAllSubDeviceTypes([]);
        setSelectedParameters([]);
        const {data} = await fetchActiveDevices();
        setDevices(data ? data : []);
      }
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      if (selectedArea === DEVICE_VALUE) {
        setIsDeviceLoading(false);
      }
    }
  };

  const handleParameters = (parameterId) => {
    const index = selectedParameters.indexOf(parameterId);

    if (index === -1) {
      setSelectedParameters([...selectedParameters, parameterId]);
    } else {
      const updatedParameters = [...selectedParameters];
      updatedParameters.splice(index, 1);
      setSelectedParameters(updatedParameters);
    }
  };

  const handleSelectedParameters = (parameterIds) => {
    const filteredSubDeviceTypes = allSubDeviceTypes.filter((item) => parameterIds.includes(item._id));
    setParameters(filteredSubDeviceTypes);
  };

  const handleSelectDevice = (device) => {
    setSelectedDevice(device);
    getOverAllParameters(device._id);
  };

  const handleGenerateReport = () => {
    const isValid = checkReportValidation();
    if (isValid) {
      const reportDetails = {
        report: report,
        reportType: reportType,
        area: area,
        parameters: parameters.map((parameter) => parameter.name).join(','),
        device: selectedDevice,
      };
      const jsonString = JSON.stringify(reportDetails);
      const encryptedData = encryptData(jsonString);
      window.open(`${VIEW_REPORTS}?report=${encodeURIComponent(encryptedData)}`, '_blank');
    }
  };

  const handleAutomateReport = () => {
    const isValid = checkReportValidation();
    if (isValid) {
      const reportDetails = {
        report: report,
        reportType: reportType,
        area: area,
        parameters: parameters,
        device: selectedDevice,
      };
      navigate(MANAGE_REPORTS, {state: {reportDetails: reportDetails}});
    }
  };

  const checkReportValidation = () => {
    let isError = false;
    if (selectedParameters.length === 0) {
      setErrors((prevSate) => ({
        ...prevSate,
        parameter: true,
      }));
      isError = true;
    } else {
      setErrors((prevSate) => ({
        ...prevSate,
        parameter: false,
      }));
    }

    if (area === DEVICE_VALUE && !selectedDevice) {
      setErrors((prevSate) => ({
        ...prevSate,
        device: true,
      }));
      isError = true;
    } else {
      setErrors((prevSate) => ({
        ...prevSate,
        device: false,
      }));
    }

    if (!isError) {
      setErrors((prevSate) => ({
        ...prevSate,
        parameter: false,
        device: false,
      }));
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div className="breadcrum-sec align-items-center breadcrum-sec d-flex justify-content-between flex-column flex-md-row">
        <div className="mob-100">
          <ul>
            <li>
              <Link to={`/app/dashboard`} className="breadcrum-link" variant="breadcrum">
                <img className="me-2" src={leftarrowImg} alt="Add" />
                {t('Dashboard')}
              </Link>
            </li>
          </ul>
          <h2 className="page-title">{t('Reports')}</h2>
        </div>
      </div>
      {/* Report specification starts */}
      <div className="card rounded-2 mb-5">
        <div className="card-body">
          <div className="report-parameter-sec">
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-4 mb-3 mb-lg-0">
                <div className="row">
                  <div className="col-12">
                    <h4 className="report-heading d-flex align-items-center">
                      <img className="me-1" src={reportheadImg} alt="icon" />
                      {t('Reports')}
                    </h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 d-flex">
                    {REPORTS.map((reports) => {
                      const isDisabled = reports.value === TRAFFIC_VALUE || reports.value === PARKING_VALUE;
                      return (
                        <div
                          key={reports.value}
                          className={`${reports.value === report ? `active` : ``} ${
                            isDisabled ? 'button-disabled' : ''
                          } report-para-card d-flex flex-column justify-content-center align-items-center`}
                          onClick={() => (!isDisabled ? setReport(reports.value) : null)}
                        >
                          <img src={reports.icon} alt="icon" />
                          <span>{t(reports.label)}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4 mb-3 mb-lg-0">
                <div className="row">
                  <div className="col-12">
                    <h4 className="report-heading d-flex align-items-center">
                      <img className="me-1" src={typeheadImg} alt="icon" />
                      {t('Report Type')}
                    </h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 d-flex">
                    {REPORTS_TYPES.map((reportTypes) => {
                      return (
                        <div
                          key={reportTypes.value}
                          className={`${
                            reportTypes.value === reportType ? `active` : ``
                          } report-para-card d-flex flex-column justify-content-center align-items-center`}
                          onClick={() => setReportType(reportTypes.value)}
                        >
                          <img src={reportTypes.icon} alt="icon" />
                          <span>{t(reportTypes.label)}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4 mb-3 mb-lg-0">
                <div className="row">
                  <div className="col-12">
                    <h4 className="report-heading d-flex align-items-center">
                      <img className="me-1" src={areaheadImg} alt="icon" />
                      {t('Area')}
                    </h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 d-flex">
                    {AREAS.map((areas) => {
                      return (
                        <div
                          key={areas.value}
                          className={`${areas.value === area ? `active` : ``} ${
                            ['zone', 'street'].includes(areas.value) ? `button-disabled` : ``
                          } report-para-card d-flex flex-column justify-content-center align-items-center`}
                          onClick={() =>
                            [OVERALL_VALUE, DEVICE_VALUE].includes(areas.value)
                              ? handleAreaSelection(areas.value)
                              : null
                          }
                        >
                          <img src={areas.icon} alt="icon" />
                          <span>{t(areas.label)}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Report specification ends */}
      {/* Map container start */}
      {area === DEVICE_VALUE && (
        <>
          <div className="filter-card mt-3 mb-4">
            <div className="row">
              {errors.device && <span className="mb-2 text-primary text-small text-danger">{t('Please select device.')}</span>}
              <div style={{display: 'flex', height: '35rem'}}>
                {isDeviceLoading ? (
                  <div className="loader-warp">
                    <img src={Loader} alt="loader" />
                  </div>
                ) : (
                  <DashboardMapContainer
                    devices={devices}
                    zones={[]}
                    deviceId={''}
                    showZoneColor={false}
                    selectedDevice={selectedDevice}
                    isPolygonVisible={false}
                    handleSelectDevice={handleSelectDevice}
                    isZoneCluster={false}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}
      {/* Map container end */}
      {/* Parameters start */}
      {allSubDeviceTypes.length > 0 && (
        <div className="row">
          <div className="col mb-4">
            {errors.parameter && (
              <span className="mb-2 text-primary text-small text-danger">{t('Please select at least one parameter.')}</span>
            )}
            <div className="sub-tanent-card-sec mb-2">
              {allSubDeviceTypes.map((allSubDeviceType) => {
                return (
                  <div
                    key={allSubDeviceType._id}
                    className={`sub-tanent-card me-3 ${
                      selectedParameters.includes(allSubDeviceType._id) ? 'active' : ''
                    }`}
                    onClick={() => handleParameters(allSubDeviceType._id)}
                  >
                    {allSubDeviceType.displayName}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
      {/* Parameters end */}
      {/* Buttons Start */}
      <div className="row mb-4">
        <div className="d-flex justify-content-end gap-2">
          <Button
            className="btn-primary d-flex align-items-center"
            variant=""
            type="button"
            onClick={handleAutomateReport}
          >
            {t('Automate')}
          </Button>
          <Button
            className="btn-primary d-flex align-items-center"
            variant=""
            type="button"
            onClick={handleGenerateReport}
          >
            {t('Generate')}
          </Button>
        </div>
      </div>
      {/* Buttons End */}
    </>
  );
};

export default GenerateReports;
