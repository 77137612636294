import React, {useState, useRef, useMemo} from 'react';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import {PAGE_DISPLAY} from 'constants/datatable';
import CustomDataTable from 'components/datatable';
import PageInnerHeader from 'components/PageInnerHeader';
import CustomModal from 'components/shared/CustomModal';
import DeleteModal from 'components/modal/DeleteModal';
import CustomToast from 'components/Toast/CustomToast';
import {deleteTenant, fetchTenantCsvContent, fetchTenants} from 'features/tenant/tenantApi';
import {conditionalRowStyles} from 'utils/tableRowsColor';
import {downloadFile} from 'utils/downloadFile';
import {useCustomEffect} from 'hooks/useCustomEffect';
import {useStateAsync} from 'hooks/useStateAsync';
import useGetTableList from 'hooks/table/useGetTableList';
import {tenantListColumn} from 'components/datatable/columns/tenantListColumn';
import TenantListFilter from 'pages/ManageTenantsPage/TenantListFilter';
import {SOMETHING_WENT_ERROR} from 'constants/apiNetwork';
import {usePermission} from 'hooks/usePermission';
import {useTranslation} from 'react-i18next';

const ManageTenants = () => {
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [filters, setFilters] = useStateAsync({
    filterName: '',
    status: '',
    key: '',
    value: '',
    pageSize: PAGE_DISPLAY[0], // per page data
    pageNumber: 1, // current page number
  });
  const numberOfPagesRef = useRef(1);
  const applyFilterRef = useRef(false);

  const {t} = useTranslation();

  const isCreateAllow = usePermission(`app/tenants`, 'create');
  const isEditAllow = usePermission(`app/tenants`, 'edit');
  const isDeleteAllow = usePermission(`app/tenants`, 'delete');

  const navigate = useNavigate();

  const {tableData, isLoading, getTablelist, handleSort, handlePageChange, handleRowsPerPageChange} = useGetTableList(
    fetchTenants,
    numberOfPagesRef,
    setFilters,
    applyFilterRef,
  );

  useCustomEffect(() => {
    getTablelist(filters);
  }, []);

  const handleEdit = (row) => {
    setSelectedTenant(row);
    navigate(`/app/tenants/manage/${row._id}`);
  };

  const handleDelete = (row) => {
    setSelectedTenant(row);
    setShowDelete(true);
  };

  const handleDeleteCloseModal = () => {
    setShowDelete(false);
    setSelectedTenant(null);
  };

  const handleSetting = (row) => {
    navigate(`/app/tenants/setting/${row._id}`, {state: {from: '/app/tenants'}});
  };

  const handleDeleteTenant = async () => {
    try {
      const {_id} = selectedTenant;
      const {message} = await deleteTenant(_id);
      toast(<CustomToast type="success" title="Success" message={message} />);
      await getTablelist(filters);
      handleDeleteCloseModal();
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    }
  };

  const handleFilter = () => {
    applyFilterRef.current = true;
    setFilters(
      (prevFilters) => ({
        filterName: prevFilters.filterName,
        status: prevFilters.status === 'all' ? '' : prevFilters.status,
        key: '',
        value: '',
        pageSize: prevFilters.pageSize,
        pageNumber: 1,
      }),
      (newValue) => {
        getTablelist(newValue);
      },
    );
  };

  const handleFilterChange = (event) => {
    const {name, value} = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleClearFilter = () => {
    applyFilterRef.current = false;
    setFilters(
      (prevFilters) => ({
        ...prevFilters,
        filterName: '',
        status: '',
        key: '',
        value: '',
        pageSize: prevFilters.pageSize,
        pageNumber: 1,
      }),
      (newValue) => {
        getTablelist(newValue);
      },
    );
  };

  const handleDownloadCSV = async () => {
    try {
      const downloadWithFilter = {};
      if (applyFilterRef.current) {
        downloadWithFilter['filterName'] = filters.filterName;
        downloadWithFilter['key'] = filters.key;
        downloadWithFilter['value'] = filters.value;
        if (filters.status !== 'all') {
          downloadWithFilter['status'] = filters.status;
        }
      }
      const csvData = await fetchTenantCsvContent(downloadWithFilter);
      const currentDate = new Date().toLocaleString();
      downloadFile(csvData, `tsn-tenant-${currentDate}.csv`);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={t(SOMETHING_WENT_ERROR)} />);
    }
  };

  const columns = useMemo(
    () => tenantListColumn(filters, isEditAllow, isDeleteAllow, handleEdit, handleDelete, handleSetting, t),
    [filters, isEditAllow, isDeleteAllow, handleEdit, handleDelete, handleSetting, t],
  );

  return (
    <>
      <PageInnerHeader
        title={t('Manage Tenants')}
        buttonLabel={t('Add New Tenant')}
        isCreateAllow={isCreateAllow}
        handleAddButton={() => navigate('/app/tenants/manage')}
      />
      <TenantListFilter
        filters={filters}
        handleFilterChange={handleFilterChange}
        handleFilter={handleFilter}
        handleClearFilter={handleClearFilter}
        handleDownloadCSV={handleDownloadCSV}
      />
      <CustomDataTable
        columns={columns}
        data={tableData}
        loading={isLoading}
        handleSort={handleSort}
        pagination={true}
        numberOfPages={numberOfPagesRef.current}
        rowPerPage={filters.pageSize}
        conditionalRowStyles={conditionalRowStyles}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
      />
      {showDelete && (
        <CustomModal
          size="sm"
          show={showDelete}
          handleClose={handleDeleteCloseModal}
          body={<DeleteModal deletedItem={selectedTenant && selectedTenant.tenantName} />}
          buttons={[
            {label: 'Yes', type: 'primary', onClickHandler: handleDeleteTenant},
            {label: 'No', type: 'secondary', onClickHandler: handleDeleteCloseModal},
          ]}
        />
      )}
    </>
  );
};

export default ManageTenants;
