import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {NavLink, useLocation, useNavigate} from 'react-router-dom';
import 'assets/styles/layout/sidebar.scss';
import logoSrc from 'assets/images/svg/vala-logo.svg';
import expandedLogoSrc from 'assets/images/svg/vala-logo-expanded.svg';
import expandArrowSrc from 'assets/images/svg/expand-icon.svg';
import DashboardIcon from 'assets/images/svg/dashboard-icon.svg';
import ManageRoles from 'assets/images/svg/manage-roles.svg';
import ManageUsers from 'assets/images/svg/manage-users.svg';
import ManageDevices from 'assets/images/svg/manage-devices.svg';
import ManageState from 'assets/images/svg/manage-state.svg';
import DeviceReports from 'assets/images/svg/device-reports.svg';
import LogoutIcon from 'assets/images/svg/logout.svg';
import {logoutUser} from 'utils/authUtils';
import {useTranslation} from 'react-i18next';

const page_navigation_links_data = [
  {
    icon: DashboardIcon,
    label: 'Overview Dashboard',
    route: '/app/dashboard',
  },
  {
    icon: DashboardIcon,
    label: 'Detailed Dashboard',
    route: '/app/device/dashboard',
  },
  {
    icon: ManageRoles,
    label: 'Manage Roles',
    route: '/app/roles',
  },
  {
    icon: ManageUsers,
    label: 'Manage Users',
    route: '/app/users',
  },
  {
    icon: ManageState,
    label: 'Manage Tenants',
    route: '/app/tenants',
  },
  {
    icon: ManageDevices,
    label: 'Manage Notifications',
    route: '/app/notifications',
  },
  {
    icon: DeviceReports,
    label: 'Reports',
    route: '/app/reports',
  },
];

const Sidebar = (props) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const {isNotificationVisible, mainContainerRef, setIsNotificationVisible} = props;

  const {t} = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const {pathname} = location;
  const {user} = useSelector((state) => state.auth);
  const handleLogout = logoutUser();

  useEffect(() => {
    if (isNotificationVisible) {
      setIsExpanded(false);
    }
  }, [isNotificationVisible]);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
    if (!isExpanded) {
      setIsNotificationVisible(false);
    }
  };

  // Filter the navigation links based on user permissions
  let filteredNavigationLinks;
  if (user.isDefaultUser) {
    filteredNavigationLinks = page_navigation_links_data;
  } else {
    filteredNavigationLinks = page_navigation_links_data.filter((link) => {
      const allowedRoutes = user.permission.map((p) => `/${p.routeName}`);
      if (allowedRoutes.includes('/app/dashboard') && link.route === '/app/device/dashboard') {
        return true;
      }
      return allowedRoutes.includes(link.route);
    });
  }

  return (
    <>
      <aside
        className={`sidebar d-flex flex-column  align-items-center ${!isExpanded && 'sidebar__collapsed '}`}
        ref={mainContainerRef}
      >
        <img
          src={isExpanded ? expandedLogoSrc : logoSrc}
          alt="be-assess"
          className={` sidebar__logo ${isExpanded && 'sidebar__logo--expanded'}`}
          onClick={() => navigate('/app/dashboard', {replace: true})}
        />
        <div className="d-flex flex-column justify-content-between h-100 w-100">
          <ul className="p-0">
            {filteredNavigationLinks.map((each, index) => {
              const {icon: Icon, label, route} = each;
              return (
                <li
                  key={index}
                  className={`list-inline mb-3 position-relative ${
                    isExpanded
                      ? `author-sidebar ${pathname.startsWith(route) ? 'active' : 'inactive'}`
                      : 'd-flex justify-content-around'
                  }`}
                >
                  <NavLink
                    to={route}
                    className={`sidebar__options-list rounded d-flex align-items-center ${
                      route === pathname ? 'active' : 'inactive'
                    }`}
                  >
                    <img src={Icon} alt={label} />
                    {isExpanded && <p className="ms-2">{t(label)}</p>}
                  </NavLink>
                </li>
              );
            })}
          </ul>
          <ul className="p-0 mb-3 mt-5 logOut-sec">
            <li
              className="sidebar__options-list sidebar__options-bottom-list rounded d-flex align-items-center mb-0"
              onClick={handleLogout}
            >
              <img src={LogoutIcon} alt="help" />
              {isExpanded && <p className="ms-2">{t('Logout')}</p>}
            </li>
          </ul>
        </div>

        <button
          className="btn position-absolute bg-white rounded-circle d-flex justify-content-center align-items-center sidebar__expand-icon"
          onClick={toggleExpansion}
        >
          <img src={expandArrowSrc} className={`arrow__icon ${!isExpanded && 'arrow__icon--collapsed'}`} />
        </button>
      </aside>
    </>
  );
};

export default Sidebar;
