import React, {useState, useEffect} from 'react';
import moment from 'moment';
import 'assets/styles/layout/header.scss';
import {useSelector} from 'react-redux';
import {Dropdown} from 'react-bootstrap';
import imageSrc from 'assets/vala-sidebar/header-profile.svg';
import NotificationIcon from 'assets/vala-sidebar/notification.svg';
import {logoutUser} from 'utils/authUtils';
import {usePermission} from 'hooks/usePermission';
import {getInitials} from 'utils/getInitials';
import {useTranslation} from 'react-i18next';
import LanguageSelector from 'components/layout/LanguageSelector';
import { MEXICO_TIME_ZONE } from 'constants/app';

const Navbar = (props) => {
  const {handleOpen, headerRef} = props;
  const [time, setTime] = useState(moment().utcOffset(MEXICO_TIME_ZONE));
  const {user} = useSelector((state) => state.auth);

  const {t} = useTranslation();

  const isSystemNotificationAllow = usePermission(`app/system-notification`, 'read');

  useEffect(() => {
    const intervalID = setInterval(() => {
      setTime(moment().utcOffset(MEXICO_TIME_ZONE)); // UTC offset for 'America/Mexico_City'
    }, 1000);

    // Clear the interval on component unmount
    return () => clearInterval(intervalID);
  }, []);

  const handleLogout = logoutUser();

  const formatTime = (date) => {
    return date.format('hh:mm A');
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg bg-transparent navbar-dark header__nav p-0" ref={headerRef}>
        <div className="header container-fluid">
          <span className="me-auto header__title p-0">{t('Smart City')}</span>

          <button
            className="navbar-toggler border-0 shadow-none d-none"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="" id="navbarSupportedContent">
            <div className="d-md-flex justify-content-md-around justify-content-lg-end align-items-md-center w-100">
              <div className="d-flex align-items-center justify-content-end">
                <div className="mx-4 time">{formatTime(time)}</div>
                <ul className="my-2 my-lg-0 navbar-nav ">
                  <li className="px-0 navbar__options">
                    <LanguageSelector />
                  </li>
                  {!isSystemNotificationAllow && (
                    <li className="px-0 navbar__options notification-icon">
                      <a onClick={handleOpen} disabled={true}>
                        <img src={NotificationIcon} alt="" />
                      </a>
                    </li>
                  )}
                </ul>
                <Dropdown className="header__profile-drop-down">
                  <Dropdown.Toggle className="header__profile-container rounded-circle p-0 bg-transparent d-flex justify-content-between align-items-center">
                    {user && getInitials(user) ? (
                      <span className="user-initials">{getInitials(user)}</span>
                    ) : (
                      <img src={imageSrc} alt="user" />
                    )}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={handleLogout}>{t('Logout')}</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default React.memo(Navbar);
