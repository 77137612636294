import React, {useState} from 'react';
import {Form, Button} from 'react-bootstrap';
import {PrimaryButton, StyledLabel} from 'styles/styled-components/StyledComponents';
import downloadImg from 'assets/images/svg/download.svg';
import resetFilterImg from 'assets/images/svg/refresh-icon.svg';
import {useCustomEffect} from 'hooks/useCustomEffect';
import {toast} from 'react-toastify';
import CustomToast from 'components/Toast/CustomToast';
import {fetchTenantDeviceType, getTagName} from 'features/tenant/tenantApi';
import {fetchNotificationType} from 'features/notification/notificationApi';
import {useTranslation} from 'react-i18next';

const NotificationFilter = (props) => {
  const {filters, handleFilterChange, handleFilter, handleClearFilter, handleDownloadCSV} = props;

  const [tags, setTags] = useState([]);
  const [deviceTypes, setDeviceTypes] = useState([]);
  const [notificationTypes, setNotificationTypes] = useState([]);

  const {t} = useTranslation();

  useCustomEffect(() => {
    getTags();
    getDeviceTypes();
    getNotificationTypes();
  }, []);

  const getTags = async () => {
    try {
      const {data} = await getTagName();
      const tagsData = data ? data : [];
      setTags(tagsData);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    }
  };

  const getDeviceTypes = async () => {
    try {
      const {data} = await fetchTenantDeviceType();
      const deviceTypes = data ? data : [];
      setDeviceTypes(deviceTypes);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    }
  };

  const getNotificationTypes = async () => {
    try {
      const {data} = await fetchNotificationType();
      const notidicationType = data ? data : [];
      setNotificationTypes(notidicationType);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    }
  };

  return (
    <>
      <div className="filter-card">
        <div className="row">
          <div className="col d-flex justify-content-between align-items-end flex-column flex-md-row">
            <div className="d-flex flex-wrap align-items-end mob-100 w-100">
              <Form.Group className="me-0 me-md-2 mb-3 mob-100 flex-fill" controlId="notificationTitle">
                <StyledLabel>{t('Notification Title')}</StyledLabel>
                <Form.Control
                  type="text"
                  name="notificationTitle"
                  placeholder={t("Enter Notification Title")}
                  autoComplete="off"
                  value={filters.notificationTitle}
                  onChange={handleFilterChange}
                />
              </Form.Group>
              <Form.Group className="me-0 me-md-2 mb-3 mob-100 flex-fill" controlId="notificationTypeId">
                <StyledLabel>{t('Notification Type')}</StyledLabel>
                <Form.Select name="notificationTypeId" value={filters.notificationTypeId} onChange={handleFilterChange}>
                  <option value="">All</option>
                  {notificationTypes.map((notificationType) => (
                    <option key={notificationType._id} value={notificationType._id}>
                      {notificationType.displayName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="me-0 me-md-2 mb-3 mob-100 flex-fill" controlId="deviceTypeId">
                <StyledLabel>{t('Device Type')}</StyledLabel>
                <Form.Select name="deviceTypeId" value={filters.deviceTypeId} onChange={handleFilterChange}>
                  <option value="">All</option>
                  {deviceTypes.map((deviceType) => (
                    <option key={deviceType._id} value={deviceType._id}>
                      {deviceType.displayName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="me-0 me-md-2 mb-3 mob-100 flex-fill" controlId="tagId">
                <StyledLabel>{t('Tags')}</StyledLabel>
                <Form.Select name="tagId" value={filters.tagId} onChange={handleFilterChange}>
                  <option value="">All</option>
                  {tags.map((tag) => (
                    <option key={tag._id} value={tag._id}>
                      {tag.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="me-0 me-md-2 mb-3 mob-100 flex-fill" controlId="status">
                <StyledLabel>{t('Status')}</StyledLabel>
                <Form.Select name="status" value={filters.status} onChange={handleFilterChange}>
                  <option value="">All</option>
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </Form.Select>
              </Form.Group>
              <PrimaryButton variant="outline-primary" className="apply me-2 mb-3" onClick={handleFilter}>
                {t('Apply')}
              </PrimaryButton>
              <Button variant="outline-primary" className="mb-3" type="button" onClick={handleClearFilter}>
                <img src={resetFilterImg} alt="Filter" />
              </Button>
              <div className="ms-5">
                <Button variant="outline-primary" className="mb-3" type="button" onClick={handleDownloadCSV}>
                  <img src={downloadImg} alt="download" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationFilter;
