import {createSlice} from '@reduxjs/toolkit';
import {SPANISH} from 'constants/locales';

const languageSlice = createSlice({
  name: 'language',
  initialState: {
    selectedLanguage: SPANISH,
    publicSelectedLanguage: SPANISH,
  },
  reducers: {
    setLanguage: (state, action) => {
      state.selectedLanguage = action.payload;
    },
    setPublicLanguage: (state, action) => {
      state.publicSelectedLanguage = action.payload;
    },
    resetLanguage: (state) => {
      state.selectedLanguage = SPANISH;
      state.publicSelectedLanguage = SPANISH;
    }
  },
});

export const {setLanguage, setPublicLanguage, resetLanguage} = languageSlice.actions;
export const selectLanguage = (state) => state.language.selectedLanguage;

export default languageSlice.reducer;
