import React, {useState, useEffect, useCallback} from 'react';
import {toast} from 'react-toastify';
import {useForm} from 'react-hook-form';
import {Form} from 'react-bootstrap';
import CustomModal from 'components/shared/CustomModal';
import CustomDataTable from 'components/datatable';
import {conditionalRowStyles} from 'utils/tableRowsColor';
import {formatRoleFormData} from 'utils/formateRoleFormData';
import {fetchModules} from 'features/role/roleApi';
import {useCustomEffect} from 'hooks/useCustomEffect';
import {StyledErrorLabel, StyledLabel} from 'styles/styled-components/StyledComponents';
import {ROLE_ACCESS} from 'constants/role';
import SwitchButton from 'components/SwitchButton';
import CustomToast from 'components/Toast/CustomToast';
import {getCheckedElement} from 'utils/getCheckedElement';
import DiscardAlert from 'components/modal/DiscardAlert';
import {useTranslation} from 'react-i18next';
import {manageRoleColumn} from 'components/datatable/columns/manageRoleColumn';

const ModalBody = (props) => {
  const {
    data,
    setData,
    errors,
    roleFormData,
    selectedRole,
    register,
    setValue,
    setRoleFormData,
    checkboxSelected,
    setCheckboxSelected,
  } = props;

  const {t} = useTranslation();
  
  const [selectAll, setSelectAll] = useState({
    [ROLE_ACCESS.CREATE]: false,
    [ROLE_ACCESS.READ]: false,
    [ROLE_ACCESS.EDIT]: false,
    [ROLE_ACCESS.DELETE]: false,
  });

  useEffect(() => {
    if (selectedRole) {
      const roleForm = {
        roleName: selectedRole.name,
        roleDescription: selectedRole.description,
        roleStatus: selectedRole.status,
      };
      setValue('roleName', selectedRole.name);
      setValue('roleDescription', selectedRole.description);
      setValue('roleStatus', selectedRole.status);
      setRoleFormData(roleForm);
    }
  }, [selectedRole]);

  const handleSelectAllChange = useCallback(
    (columnKey) => {
      const allChecked = !data.every((row) => row[columnKey]);
      const updatedData = data.map((rowData) => {
        let updatedRow = {...rowData, [columnKey]: allChecked};

        // reset all checkboxs is read is uncheck
        if (columnKey[0] === ROLE_ACCESS.READ && !allChecked) {
          updatedRow = {
            ...updatedRow,
            [ROLE_ACCESS.CREATE]: false,
            [ROLE_ACCESS.EDIT]: false,
            [ROLE_ACCESS.DELETE]: false,
          };
        }

        return updatedRow;
      });

      setData(updatedData);

      // Check if any checkbox is selected
      handleCheckBoxValidation(updatedData);
    },
    [data, setData],
  );

  const handleCheckboxChange = (row, columnKey) => {
    const updatedData = data.map((rowData) => {
      if (rowData.id === row.id) {
        const updatedRow = {...rowData, [columnKey]: !rowData[columnKey]};
        // If "Create", "Update/Edit", and "Delete" checkboxes are selected,
        // automatically select the "Read/View" checkbox
        if (columnKey[0] === ROLE_ACCESS.READ && !updatedRow[ROLE_ACCESS.READ]) {
          updatedRow[ROLE_ACCESS.READ] = false;
          updatedRow[ROLE_ACCESS.CREATE] = false;
          updatedRow[ROLE_ACCESS.EDIT] = false;
          updatedRow[ROLE_ACCESS.DELETE] = false;
        } else if (updatedRow[ROLE_ACCESS.CREATE] || updatedRow[ROLE_ACCESS.EDIT] || updatedRow[ROLE_ACCESS.DELETE]) {
          updatedRow[ROLE_ACCESS.READ] = true;
        }
        return updatedRow;
      }
      return rowData;
    });
    setData(updatedData);

    // Check if any checkbox is selected
    handleCheckBoxValidation(updatedData);
  };

  const handleCheckBoxValidation = useCallback(
    (updatedData) => {
      const isSelected = updatedData.some(
        (rowData) =>
          rowData[ROLE_ACCESS.CREATE] ||
          rowData[ROLE_ACCESS.READ] ||
          rowData[ROLE_ACCESS.EDIT] ||
          rowData[ROLE_ACCESS.DELETE],
      );
      setCheckboxSelected(isSelected);
    },
    [setCheckboxSelected],
  );

  useEffect(() => {
    updateCheckBox();
  }, [data]);

  const updateCheckBox = useCallback(async () => {
    const assesses = [ROLE_ACCESS.READ, ROLE_ACCESS.CREATE, ROLE_ACCESS.EDIT, ROLE_ACCESS.DELETE];
    const allAccessChecked = await getCheckedElement(assesses, data);

    const updatedSelectAll = {
      [ROLE_ACCESS.CREATE]: allAccessChecked.includes(ROLE_ACCESS.CREATE),
      [ROLE_ACCESS.READ]: allAccessChecked.includes(ROLE_ACCESS.READ),
      [ROLE_ACCESS.EDIT]: allAccessChecked.includes(ROLE_ACCESS.EDIT),
      [ROLE_ACCESS.DELETE]: allAccessChecked.includes(ROLE_ACCESS.DELETE),
    };

    setSelectAll(updatedSelectAll);
  }, [data]);

  useEffect(() => {
    if (
      !selectAll[ROLE_ACCESS.READ] &&
      (selectAll[ROLE_ACCESS.CREATE] || selectAll[ROLE_ACCESS.EDIT] || selectAll[ROLE_ACCESS.DELETE])
    ) {
      handleSelectAllChange([ROLE_ACCESS.READ]);
    }
  }, [selectAll]);

  const columns = manageRoleColumn(selectAll, handleCheckboxChange, handleSelectAllChange);

  return (
    <>
      <form>
        <div className="row">
          <div className="col d-flex flex-wrap">
            <Form.Group className="mb-4 me-2 position-relative" controlId="roleName">
              <StyledLabel>
                {t('Role')}
                <span className="required" />
              </StyledLabel>
              <Form.Control
                type="text"
                placeholder={t("Enter Role")}
                autoComplete="off"
                {...register('roleName', {
                  required: t('Role name is required'),
                })}
              />
              {errors.roleName && <StyledErrorLabel>{errors.roleName.message}</StyledErrorLabel>}
            </Form.Group>
            <Form.Group className="mb-4 me-2 position-relative" controlId="roleDescription">
              <StyledLabel>
                {t('Role Description')}
                <span className="required" />
              </StyledLabel>
              <Form.Control
                type="text"
                placeholder={t("Enter Role Description")}
                autoComplete="off"
                {...register('roleDescription', {
                  required: t('Role description is required'),
                })}
              />
              {errors.roleDescription && <StyledErrorLabel>{errors.roleDescription.message}</StyledErrorLabel>}
            </Form.Group>
            <Form.Group className="mb-3 position-relative" controlId="roleStatus">
              <StyledLabel>
                {t('Role Status')}
                <span className="required" />
              </StyledLabel>
              <SwitchButton
                leftLabel={'Active'}
                rightLabel={'Inactive'}
                isDisableInactive={Boolean(roleFormData.roleName)}
                value={roleFormData.roleStatus}
                handleSetValue={(value) => setValue('roleStatus', value)}
              />
            </Form.Group>
          </div>
        </div>
      </form>

      <div className="model-subHeading mb-3">{t('Manage Permissions')}</div>
      {!checkboxSelected && (
        <span className="mb-2 text-primary text-small">{t('Please select at least one permission.')}</span>
      )}
      <CustomDataTable
        tableClass={'manage-role-table'}
        columns={columns}
        data={data}
        pagination={false}
        conditionalRowStyles={conditionalRowStyles}
      />
    </>
  );
};

const ManageRoleModal = React.memo((props) => {
  const {show, selectedRole, isSubmit, isTenantManagePage, handleAddEdit, handleClose} = props;
  const [isLoading, setIsLoading] = useState(false);
  const [checkboxSelected, setCheckboxSelected] = useState(selectedRole ? true : false);
  const [isDiscard, setIsDiscard] = useState(false);
  const [roleFormData, setRoleFormData] = useState({roleName: '', roleDescription: '', roleStatus: true});
  const [data, setData] = useState([]);

  const {t} = useTranslation();

  const {
    setValue,
    register,
    handleSubmit,
    formState: {errors, isDirty},
  } = useForm({
    mode: 'onChange',
    defaultValues: roleFormData,
  });

  const isDisabled = isLoading || isSubmit || Object.keys(errors).length > 0 || !checkboxSelected;

  const handleRole = async (roleData) => {
    let formData = await formatRoleFormData(data);
    formData['name'] = roleData.roleName;
    formData['description'] = roleData.roleDescription;
    formData['status'] = roleData.roleStatus;
    const action = selectedRole ? 'edit' : 'add';
    handleAddEdit(formData, action);
  };

  const getModules = useCallback(async () => {
    try {
      setIsLoading(true);
      let param = {
        type: ""
      }
      if(isTenantManagePage) {
        param.type = "tenant"
      }
      const {data} = await fetchModules(param);
      const tableData = formatTableData(data, selectedRole);
      setData(tableData);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useCustomEffect(() => {
    getModules();
  }, []);

  const formatTableData = (modules, edit) => {
    const result = modules.map((module) => {
      let data = {
        id: module._id,
        name: module.displayName,
        [ROLE_ACCESS.CREATE]: false,
        [ROLE_ACCESS.READ]: false,
        [ROLE_ACCESS.EDIT]: false,
        [ROLE_ACCESS.DELETE]: false,
      };

      if (edit) {
        const {permission} = edit;
        const matchingPermission = permission.find((perm) => perm.lookupRoutesResult._id === module._id);
        if (matchingPermission) {
          data[ROLE_ACCESS.CREATE] = matchingPermission.access.includes(ROLE_ACCESS.CREATE);
          data[ROLE_ACCESS.READ] = matchingPermission.access.includes(ROLE_ACCESS.READ);
          data[ROLE_ACCESS.EDIT] = matchingPermission.access.includes(ROLE_ACCESS.EDIT);
          data[ROLE_ACCESS.DELETE] = matchingPermission.access.includes(ROLE_ACCESS.DELETE);
        }
      }

      return data;
    });

    setData(result);
    return result;
  };

  const handleCloseButton = () => {
    if (isDirty) {
      setIsDiscard(true);
    } else {
      handleClose();
    }
  };

  const hanldeDiscardAllModal = () => {
    setIsDiscard(false);
    handleClose();
  };

  const hanldeDiscard = () => {
    setIsDiscard(false);
  };

  return (
    <>
      <CustomModal
        size="lg"
        show={show}
        handleClose={handleCloseButton}
        title={<h2>{`${selectedRole ? t('Edit') : t('Create')} ${t('Role & Manage Permissions')}`}</h2>}
        body={
          !isLoading && (
            <ModalBody
              data={data}
              setData={setData}
              errors={errors}
              selectedRole={selectedRole}
              register={register}
              setValue={setValue}
              setRoleFormData={setRoleFormData}
              roleFormData={roleFormData}
              checkboxSelected={checkboxSelected}
              setCheckboxSelected={setCheckboxSelected}
            />
          )
        }
        buttons={[
          {label: t('Discard'), type: 'primary', onClickHandler: handleCloseButton},
          {
            label: `${selectedRole ? t('Update') : t('Create')}`,
            type: 'primary',
            disabled: isDisabled,
            onClickHandler: handleSubmit(handleRole),
          },
        ]}
      />
      {isDiscard && (
        <CustomModal
          size="sm"
          show={isDiscard}
          handleClose={hanldeDiscard}
          body={<DiscardAlert />}
          buttons={[
            {label: 'Yes', type: 'primary', onClickHandler: hanldeDiscardAllModal},
            {label: 'No', type: 'secondary', onClickHandler: hanldeDiscard},
          ]}
        />
      )}
    </>
  );
});

export default ManageRoleModal;
