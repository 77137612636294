import React from 'react';
import {Form, Button} from 'react-bootstrap';
import {PrimaryButton, StyledLabel} from 'styles/styled-components/StyledComponents';
import downloadImg from 'assets/images/svg/download.svg';
import addImg from 'assets/images/svg/add-white.svg';
import resetFilterImg from 'assets/images/svg/refresh-icon.svg';
import { useTranslation } from 'react-i18next';

const RoleListFilter = (props) => {
  const {
    filters,
    isTenantManagePage,
    setShowRoleModal,
    handleFilterChange,
    handleFilter,
    handleClearFilter,
    handleDownloadCSV,
  } = props;
  const { t } = useTranslation();
  return (
    <>
      <div className="filter-card">
        <div className="row">
          <div className="col d-flex justify-content-between align-items-end flex-column flex-md-row">
            <div className="d-flex flex-wrap align-items-end mob-100">
              <Form.Group className="me-0 me-md-2 mb-3 mb-xxl-0 mob-100" controlId="roleName">
                <StyledLabel>{t('Role')}</StyledLabel>
                <Form.Control
                  type="text"
                  name="filterName"
                  placeholder={t("Enter Role")}
                  autoComplete="off"
                  value={filters.filterName}
                  onChange={handleFilterChange}
                />
              </Form.Group>
              <Form.Group className="me-0 me-md-2 mb-3 mb-xxl-0 mob-100" controlId="roleStatus">
                <StyledLabel>{t('Status')}</StyledLabel>
                <Form.Select name="status" value={filters.status} onChange={handleFilterChange}>
                  <option value="all">All</option>
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </Form.Select>
              </Form.Group>
              <PrimaryButton variant="outline-primary" className="apply me-2 mb-3 mb-xxl-0" onClick={handleFilter}>
                {t('Apply')}
              </PrimaryButton>
              <Button variant="outline-primary" className="mb-3 mb-xxl-0" type="button" onClick={handleClearFilter}>
                <img src={resetFilterImg} alt="Filter" />
              </Button>
            </div>
            <div className="d-flex justify-content-start mob-100">
              {isTenantManagePage && (
                <Button
                  variant="primary"
                  className="mb-3 me-2 mb-xxl-0 effect-btn"
                  type="button"
                  onClick={() => setShowRoleModal(true)}
                >
                  <img className="me-1" src={addImg} alt="plus" />
                  {t('Add Roles & Permission')}
                </Button>
              )}
              <Button variant="outline-primary" className="mb-3 mb-xxl-0" type="button" onClick={handleDownloadCSV}>
                <img src={downloadImg} alt="download" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoleListFilter;
