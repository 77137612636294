import React, {useState, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import CustomDataTable from 'components/datatable';
import CustomModal from 'components/shared/CustomModal';
import DeleteModal from 'components/modal/DeleteModal';
import {conditionalRowStyles} from 'utils/tableRowsColor';
import SubscriberFilter from 'components/reports/SubscriberFilter';
import {subscriberListColumn} from 'components/datatable/columns/subscriberListColumn';

const SubscriberList = (props) => {
  const [isDeleteing, setIsDeleteing] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [subscriber, setSubscriber] = useState(null);

  const {t} = useTranslation();

  const {
    isLoading,
    subscribers,
    filters,
    numberOfPagesRef,
    setSelectedSubscriber,
    setShowSubscriberModel,
    handleFilterChange,
    handleRowsPerPageChange,
    handleFilter,
    handleClearFilter,
    handleAddSubscriber,
    handlePageChange,
    handleSubscriptionDelete,
  } = props;

  const handleEdit = (row) => {
    setSelectedSubscriber(row);
    setShowSubscriberModel(true);
  };

  const handleDelete = (row) => {
    setSubscriber(row);
    setShowDelete(true);
  };

  const handleDeleteCloseModal = () => {
    setSubscriber(null);
    setShowDelete(false);
  };

  const handleDeleteSubscriber = async () => {
    setIsDeleteing(true);
    await handleSubscriptionDelete(subscriber);
    handleDeleteCloseModal();
    setIsDeleteing(false);
  };

  const columns = useMemo(
    () => subscriberListColumn(filters, handleEdit, handleDelete, t),
    [filters, handleEdit, handleDelete, t],
  );

  return (
    <>
      <SubscriberFilter
        filters={filters}
        handleFilterChange={handleFilterChange}
        handleFilter={handleFilter}
        handleClearFilter={handleClearFilter}
        handleAddSubscriber={handleAddSubscriber}
      />
      <CustomDataTable
        columns={columns}
        data={subscribers}
        loading={isLoading}
        pagination={true}
        numberOfPages={numberOfPagesRef.current}
        rowPerPage={filters.pageSize}
        conditionalRowStyles={conditionalRowStyles}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
      />
      {showDelete && (
        <CustomModal
          size="sm"
          show={showDelete}
          handleClose={handleDeleteCloseModal}
          body={<DeleteModal deletedItem={subscriber && subscriber.name} />}
          buttons={[
            {label: t('Yes'), type: 'primary', disabled: isDeleteing, onClickHandler: handleDeleteSubscriber},
            {label: t('No'), type: 'secondary', onClickHandler: handleDeleteCloseModal},
          ]}
        />
      )}
    </>
  );
};

export default SubscriberList;
