import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import FloatingColorIcon from 'svgs/FloatingColorIcon';

const FloatingColor = () => {
  const [toggleColorCard, setToggleColorCard] = useState('hide');
  const {t} = useTranslation();

  return (
    <>
      <div className="floating-element">
        <div className="floating-icon" onClick={() => setToggleColorCard(toggleColorCard === 'show' ? 'hide' : 'show')}>
          <FloatingColorIcon />
        </div>
        <div className={`floating-content ${toggleColorCard}`}>
          <div className="level level-concern">{t('Levels of Concern')}</div>
          <div className="level level-good">{t('Good')}</div>
          <div className="level level-moderate">{t('Moderate')}</div>
          <div className="level level-unhealthy-sensitive">{t('Unhealthy for Sensitive Groups')}</div>
          <div className="level level-unhealthy">{t('Unhealthy')}</div>
          <div className="level level-very-unhealthy">{t('Very Unhealthy')}</div>
          <div className="level level-hazardous">{t('Hazardous')}</div>
        </div>
      </div>
    </>
  );
};

export default FloatingColor;
