export const symbols = (symbol) => {
  let mathSymbol;
  switch (symbol) {
    case 'gt':
      mathSymbol = '>';
      break;
    case 'lt':
      mathSymbol = '<';
      break;
    case 'gte':
      mathSymbol = '>=';
      break;
    case 'ltq':
      mathSymbol = '=<';
      break;
    default:
      mathSymbol = '';
      break;
  }
  return mathSymbol;
};
