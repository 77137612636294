export const ADD_TENANT_TAB = 'add-tenant-tab';
export const ADD_ROLES_PERMISSIONS_TAB = 'roles-permissions-tab';
export const ADD_USERS_TAB = 'add-users-tab';
export const ADD_DEVICES_TAB = 'add-devices-tab';
export const ADD_SETTINGS_TAB = 'add-settings-tab';

export const ADD_TENANT_TITLE = 'Add Tenant';
export const ADD_ROLES_PERMISSIONS_TITLE = 'Roles & Permissions';
export const ADD_USERS_TITLE = 'Add Users';
export const ADD_DEVICES_TITLE = 'Devices';
export const ADD_SETTINGS_TITLE = 'Settings';

export const TENANT_TABS = [
  {
    tabName: ADD_TENANT_TAB,
    tabTitle: 'Add Tenant',
  },
  {
    tabName: ADD_ROLES_PERMISSIONS_TAB,
    tabTitle: 'Roles & Permissions',
  },
  {
    tabName: ADD_USERS_TAB,
    tabTitle: 'Add Users',
  },
  {
    tabName: ADD_DEVICES_TAB,
    tabTitle: 'Devices',
  },
  {
    tabName: ADD_SETTINGS_TAB,
    tabTitle: 'Settings',
  },
];

export const DEVICE_SENSORS = [
  {
    label: 'Air Sensor',
    icon: 'noice-sensor',
  },
  {
    label: 'Noise Sensor',
    icon: 'noice-sensor',
  },
];

export const DEVICE_SUB_TENANTS = ['NO2', 'CO2', 'CO', 'NO', 'O3', 'PM2.5', 'PM10'];

export const NORMAL_MARKER_WIDTH = 32;
export const NORMAL_MARKER_HEIGHT = 32;
export const SELECTED_MARKER_WIDTH = 42;
export const SELECTED_MARKER_HEIGHT = 42;