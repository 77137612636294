import React from 'react';
import {Form, Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {PrimaryButton, StyledLabel} from 'styles/styled-components/StyledComponents';
import downloadImg from 'assets/images/svg/download.svg';
import resetFilterImg from 'assets/images/svg/refresh-icon.svg';

const SubTenantListFilter = (props) => {
  const {
    filters,
    handleFilterChange,
    handleFilter,
    handleClearFilter,
    handleDownloadCSV,
  } = props;

  const {t} = useTranslation();

  return (
    <>
      <div className="filter-card">
        <div className="row">
          <div className="col d-flex justify-content-between align-items-end flex-column flex-md-row">
            <div className="d-flex flex-wrap align-items-end w-100">
              <Form.Group className="me-0 me-md-2 mb-3 mb-xxl-0 mob-100" controlId="roleName">
                <StyledLabel>{t('Sub Tenant Name')}</StyledLabel>
                <Form.Control
                  type="text"
                  name="filterName"
                  placeholder={t("Enter Sub Tenant Name")}
                  autoComplete="off"
                  value={filters.filterName}
                  onChange={handleFilterChange}
                />
              </Form.Group>
              <PrimaryButton variant="outline-primary" className="apply me-2 mb-3 mb-xxl-0" onClick={handleFilter}>
                {t('Apply')}
              </PrimaryButton>
              <Button variant="outline-primary" className="mb-3 mb-xxl-0" type="button" onClick={handleClearFilter}>
                <img src={resetFilterImg} alt="Filter" />
              </Button>
            </div>
            <div className="d-flex justify-content-start mob-100">
              <Button variant="outline-primary" className="mb-3 mb-xxl-0" type="button" onClick={handleDownloadCSV}>
                <img src={downloadImg} alt="download" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubTenantListFilter;
