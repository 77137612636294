import React from 'react';
import {Form, Row, Col} from 'react-bootstrap';
import SwitchButton from 'components/SwitchButton';
import {useTranslation} from 'react-i18next';
import {StyledLabel, StyledErrorLabel} from 'styles/styled-components/StyledComponents';
import {EMAIL_REGEX, NUMBER_ONLY_REGEX, STRING_ONLY_REGEX} from 'constants/regularExpression';

const ManageUserForm = (props) => {
  const {register, roleTypes, errors, selectedUser, setValue, setError, clearErrors} = props;
  const {t} = useTranslation();

  return (
    <div className="user-form">
      <Row className="">
        <Col md="12" lg="6" className="">
          <Row>
            <Col>
              <Form.Group className="mb-3 position-relative" controlId="name">
                <Form.Label>
                  {t('Name')}
                  <span className="required" />
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t('Enter Name')}
                  className="mb-2"
                  autoComplete="off"
                  {...register('name', {
                    required: t('Name is required'),
                  })}
                />
                {errors.name && <StyledErrorLabel>{errors.name.message}</StyledErrorLabel>}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3 position-relative" controlId="address">
                <Form.Label>
                  {t('Address')}
                  <span className="required" />
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t('Address 1')}
                  className="mb-2"
                  autoComplete="off"
                  {...register('address.addressLine1', {
                    required: t('Address is required'),
                  })}
                />
                {errors.address?.addressLine1 && (
                  <StyledErrorLabel>{errors.address?.addressLine1.message}</StyledErrorLabel>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3 position-relative" controlId="address2">
                <Form.Label>
                  {t('Address line 2')}
                  <span className="required" />
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t('Address Line 2')}
                  className="mb-2"
                  autoComplete="off"
                  {...register('address.addressLine2', {
                    required: t('Address 2 is required'),
                  })}
                />
                {errors.address?.addressLine2 && (
                  <StyledErrorLabel>{errors.address?.addressLine2.message}</StyledErrorLabel>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3 position-relative" controlId="name">
                <Form.Label>
                  {t('Country')}
                  <span className="required" />
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t('Enter Country')}
                  className="mb-2"
                  autoComplete="off"
                  {...register('country', {
                    required: t('Country is required'),
                    pattern: {
                      value: STRING_ONLY_REGEX,
                      message: t('Country should be string'),
                    },
                  })}
                />
                {errors.country && <StyledErrorLabel>{errors.country.message}</StyledErrorLabel>}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3 position-relative" controlId="city">
                <Form.Label>
                  {t('City')}
                  <span className="required" />
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t('Enter City')}
                  className="mb-2"
                  autoComplete="off"
                  {...register('city', {
                    required: t('City is required'),
                    pattern: {
                      value: STRING_ONLY_REGEX,
                      message: t('City should be string'),
                    },
                  })}
                />
                {errors.city && <StyledErrorLabel>{errors.city.message}</StyledErrorLabel>}
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col md="12" lg="6" className="">
          <Row>
            <Col>
              <Form.Group className="mb-3 position-relative" controlId="roletype">
                <StyledLabel>{t('Role Type')}</StyledLabel>
                <Form.Select
                  name="status"
                  className="mb-2"
                  {...register('roleId', {
                    required: t('Role Type is required'),
                  })}
                >
                  <option value="">{t('Select Role Type')}</option>
                  {roleTypes.map((roleType) => (
                    <option key={roleType._id} value={roleType._id}>
                      {roleType.name}
                    </option>
                  ))}
                </Form.Select>
                {errors.roleId && <StyledErrorLabel>{errors.roleId.message}</StyledErrorLabel>}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3 position-relative" controlId="email-id">
                <Form.Label>
                  {t('Email ID')}
                  <span className="required" />
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder={t('Enter Email ID')}
                  className="mb-2"
                  disabled={selectedUser ? true : false}
                  autoComplete="off"
                  {...register('email', {
                    required: t('Email is required'),
                    pattern: {
                      value: EMAIL_REGEX,
                      message: t('Invalid email address'),
                    },
                  })}
                />
                {errors.email && <StyledErrorLabel>{errors.email.message}</StyledErrorLabel>}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3 position-relative" controlId="mobile-number">
                <Form.Label>
                  {t('Mobile Number')}
                  <span className="required" />
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t('Enter Mobile Number')}
                  className="mb-2"
                  autoComplete="off"
                  {...register('contact.number', {
                    required: t('Mobile number is required'),
                  })}
                  onChange={(e) => {
                    const modifiedValue = e.target.value.replace(/^0+/, '');
                    setValue('contact.number', modifiedValue);
                    if (!NUMBER_ONLY_REGEX.test(modifiedValue)) {
                      setError('contact.number', {
                        type: 'manual',
                        message: t('Invalid mobile numer'),
                      });
                    } else {
                      clearErrors('contact.number');
                    }
                  }}
                />
                {errors.contact?.number && <StyledErrorLabel>{errors.contact?.number.message}</StyledErrorLabel>}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3 position-relative" controlId="status">
                <Form.Label>
                  {t('Status')}
                  <span className="required" />
                </Form.Label>
                <div className="d-flex">
                  <SwitchButton
                    leftLabel={'Active'}
                    rightLabel={'Inactive'}
                    isDisableInactive={Boolean(selectedUser)}
                    value={selectedUser ? selectedUser.status : true}
                    handleSetValue={(value) => setValue('status', value)}
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3 position-relative" controlId="Remark">
            <Form.Label>{t('Additional Remarks')}</Form.Label>
            <Form.Control as="textarea" placeholder={t("Enter Description")} rows={3} {...register('additionalRemark')} />
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};

export default ManageUserForm;
