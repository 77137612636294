import React from 'react';
import {useParams} from 'react-router-dom';
import ManageSettingNotificationContainer from 'components/tenantStepper/Setting/ManageSettingNotificationContainer';
import ManageSettingNotification from 'components/tenantStepper/Setting/ManageSettingNotification';

const ManageSettingTab = ({tenantId}) => {
  const {deviceId: notificationId} = useParams();

  return (
    <>
      {notificationId ? (
        <ManageSettingNotification notificationId={notificationId} tenantId={tenantId} />
      ) : (
        <div className="card">
          <div className="card-body">
            <ManageSettingNotificationContainer tenantId={tenantId} />
          </div>
        </div>
      )}
    </>
  );
};

export default ManageSettingTab;
