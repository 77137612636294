import styled from 'styled-components';
import theme from 'styles/theme';
import {Form, Button, InputGroup, ProgressBar} from 'react-bootstrap';

export const StyledErrorLabel = styled.span`
  color: ${theme.errorRed};
  font-size: 9px;
  display: block;
  // margin-top: -5px;
  line-height: 10px;
  position: absolute;
  bottom: -15px;
`;

export const StyledLabel = styled(Form.Label)`
  &&& {
    .required::after {
      content: '*';
      color: red;
      margin-left: 0.25em;
    }
  }
`;

export const StyledSecondaryButton = styled(Button)`
  background: transperent;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid $primary-2;
  height: 36px;
  transition: 0.2s ease-in-out;
  &:hover {
    background: $primary-2;
    color: #ffffff;
  }
`;

export const StyledPrimaryButton = styled(Button)`
  background: ${theme.prussianBlue};
  color: ${theme.white};
  font-size: 14px;
  font-weight: 500;
  border: 1px solid ${theme.prussianBlue};
  height: 48px;
  transition: 0.2s ease-in-out;
  cursor: ${(props) => (props.disabled ? 'no-drop' : 'pointer')};
  &:hover {
    background: transparent;
    color: ${theme.prussianBlue};
  }
  &:disabled {
    background: ${theme.prussianBlue};
    color: ${theme.white};
    opacity: 0.5;
    pointer-events: unset;
  }
`;

export const StyledOutlinePrimaryButton = styled(Button)`
  background: transparent;
  color: ${theme.prussianBlue};
  font-size: 14px;
  font-weight: 500;
  border: 1px solid ${theme.prussianBlue};
  height: 48px;
  transition: 0.2s ease-in-out;
  &:hover {
    background: ${theme.prussianBlue};
    color: ${theme.white};
  }
`;

export const StyledLinkButton = styled(Button)`
  font-size: 14px;
  color: ${theme.prussianBlue};
  font-weight: 500;
  text-decoration: none;
  float: right;
  border: none;
  padding: 0px !important;
`;

export const StyledPasswordEyeContainer = styled(InputGroup.Text)`
  cursor: pointer;
  background-color: transparent;
  border: 1px solid ${theme.lightGray};
  transition: 0.2s ease-in-out;
  border-left: 0px !important;
`;

const GeneralButton = styled(Button)`
  text-transform: none !important;
  border-radius: 4px !important;
  padding: 6px 12px !important;
  span {
    font-family: 'Nunito Sans', sans-serif !important;
    font-size: 17px;
  }
  &:disabled {
    color: white !important;
    background-color: #fff !important;
  }
`;

export const PrimaryButton = styled(Button)`
  background-color: #fff !important;
  color: ${theme.prussianBlue} !important;
  font-weight: 600 !important;
  &:hover {
    background-color: ${theme.prussianBlue} !important;
    color: ${theme.white} !important;
  }
  &:disabled {
    background-color: ${(props) => props.theme.warmGrey} !important;
  }
`;

export const SecondaryButton = styled(Button)`
  background: #FFFFFF !important
  color: #00677F !important;
  font-weight: 600 !important;
  &:hover {
    background: ${theme.prussianBlue};
    color: ${theme.white};
  }
`;

export const DashboardContainer = styled.div`
  height: calc(100vh - 100px);
  display: flex;
`;

export const MapSection = styled.div`
  height: 100%;
  width: 100%;
  position: relative;

  .map-container {
    height: 100%;
    width: 100%;
  }
`;

export const SidebarSection = styled.div`
  height: 100%;
  width: 20%;
  background: #14161a;
  color: #fff;
`;

export const ColorChartSection = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  height: auto;
  width: 8rem;
  margin-bottom: 10px;
  border-radius: 3px;
  padding: 8px;
  font-weight: 700;
  text-align: center;
  font-size: 12px;
`;

export const EChartContainer = styled.div`
  height: ${(props) => props.height};
  background: #ffffff;
`;

export const EChartTitle = styled.div`
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  padding-top: 8px;
`;

export const LoaderContainer = styled.div`
  height: 100%;
  display: flex;
`;

export const LoaderInnerContainer = styled.div`
  margin: auto;
`;

export const ParameterAvgColor = styled.div`
  color: ${(props) => (props.color == '#FFFF00' ? '#000000' : '#FFFFFF')};
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : '#9C9796')};
`;

export const StyledProgressBar = styled(ProgressBar)`
  .progress-bar {
    background-color: ${(props) => (props.color ? props.color : '#9C9796')} !important;
  }
`;

export const ParameterCircleColor = styled.div`
  background: ${(props) => (props.color ? props.color : '#9C9796')};
`;

export const AxisContainer = styled.div`
  position: relative;
`;

export const Axis = styled.svg`
  .axis {
    position: absolute;
    top: 0;
    left: 0;
    stroke: gray;
  }
`;
