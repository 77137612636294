export const GROUP_1 = ['AQI', 'NO2', 'CO', 'CO2', 'NO', 'O3', 'SO2', 'PM2_5', 'PM10', 'PM1'];
export const GROUP_2 = [
  'humidity',
  'windDirection',
  'temperature',
  'noiseLEQ',
  'pressure',
  'windSpeed',
  'rainfall',
  'humidityOB',
  'tempratureOB',
  'noise-max',
  'pressureOB',
];
