import React, {useEffect, useState} from 'react';
import ReactECharts from 'echarts-for-react'; // Please do not remove this, it has to import before ReactEChartsCore.
import * as echarts from 'echarts/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import {getStackedLineChartOptions} from 'utils/echarts/stackedLineChartOptions';
import ReportRoseGraph from 'components/reports/ReportRoseGraph';
import {GRAPH_PER_PAGE} from 'constants/reports';
import { getParameterInChunks } from 'utils/getParameterInChunks';

const ReportCharts = ({parametersChart, reportType, rose, isLoadingRose}) => {
  const [parameters, setParameters] = useState([]);
  const [isLast, setIsLast] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);

  useEffect(() => {
    (async () => {
      const data = await getParameterInChunks(parametersChart, GRAPH_PER_PAGE);
      setParameters(data);
    })();
  }, []);

  useEffect(() => {
    if (parameters.length > 0) {
      let parametersLength = parameters.length;
      let lastElementLength = parameters[parametersLength - 1].length;
      const isLast = parametersLength === 0 ? true : lastElementLength === GRAPH_PER_PAGE ? true : false;
      setIsLast(isLast);
      setIsLastPage(parametersLength + 2)
    }
  }, [parameters]);

  return (
    <>
      {parameters.length > 0 && (
        <>
          {parameters.map((parameter, i) => {
            let index = i + 2;
            return (
              <div key={`${index}-${i}`} className={`row page page-${index}`}>
                {parameter.map((item) => {
                  const parameterName = Object.keys(item)[0];
                  return (
                    <div key={parameterName.toLowerCase()} className={`col-12 col-md-6`}>
                      <div className="card rounded-1 mt-3">
                        <div className="card-body">
                          <div className="windchart-container">
                            <div className="bar-chart-sec">
                              <div className="p-2">
                                <div className="d-flex justify-content-between">
                                  <div>
                                    <h3 className="mb-4">{parameterName}</h3>
                                  </div>
                                </div>
                                {item ? (
                                  <ReactEChartsCore
                                    echarts={echarts}
                                    option={getStackedLineChartOptions(item[parameterName], reportType)}
                                    notMerge={true}
                                    lazyUpdate={true}
                                    style={{height: '350px'}}
                                  />
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {parameter.length < GRAPH_PER_PAGE && <ReportRoseGraph rose={rose} isLoadingRose={isLoadingRose} />}
              </div>
            );
          })}
        </>
      )}
      {isLast && <ReportRoseGraph rose={rose} isLoadingRose={isLoadingRose} isLast={isLast} isLastPage={isLastPage} />}
    </>
  );
};

export default React.memo(ReportCharts);
