import React, {useState, useRef, useEffect} from 'react';
import {toast} from 'react-toastify';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {Form, InputGroup, Spinner, Row, Col} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import {
  StyledErrorLabel,
  StyledLabel,
  StyledPrimaryButton,
  StyledOutlinePrimaryButton,
  StyledPasswordEyeContainer,
} from 'styles/styled-components/StyledComponents';
import CustomToast from 'components/Toast/CustomToast';
import {resetPassword, verifyPasswordToken} from 'features/auth/authApi';
import {forgotPasswordLogin} from 'features/auth/authSlice';
import {useParams, useLocation} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {AUTH_LOGIN, SET_PASSWORD} from 'constants/routesName';
import {OK} from 'constants/apiNetwork';
import {useTranslation} from 'react-i18next';

const ErrorLabel = styled(StyledErrorLabel)`
  bottom: -25px !important;
`;

const ResetPassword = () => {
  const [isTokenVerified, setIsTokenVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const effectRun = useRef(false);
  const {token} = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const {t} = useTranslation();

  const {pathname} = location;
  const page = pathname.split('/')[2];

  const {
    register,
    watch,
    setError,
    clearErrors,
    handleSubmit,
    formState: {errors},
  } = useForm({
    mode: 'onChange',
    defaultValues: {email: '', password: '', c_password: '', remember: true},
  });

  const handleResetPassword = async ({password}) => {
    try {
      const {data, message} = await resetPassword(token, {password});
      await dispatch(forgotPasswordLogin(data));
      toast(<CustomToast type="success" title="Success" message={message} />);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setIsLoading(false);
    }
  };
  const verifyToken = async (token) => {
    try {
      const {status} = await verifyPasswordToken({token});
      if (status === OK) {
        setIsTokenVerified(true);
      } else {
        setIsTokenVerified(false);
      }
    } catch (error) {
      setIsTokenVerified(false);
      toast(<CustomToast type="error" title="Error" message={error} />);
    }
  };

  useEffect(() => {
    if (!effectRun.current) {
      (async () => {
        await verifyToken(token);
      })();
    }
    return () => {
      effectRun.current = true;
    };
  }, []);

  return (
    <div className="login-form">
      <div className="w-100">
        <h2 className="mb-1">{page === SET_PASSWORD ? t(`Set Password`) : t(`Change Password`)}</h2>
        <span className="login-msg d-block mb-4">{t(`Set your New Password`)}</span>
        <form onSubmit={handleSubmit(handleResetPassword)}>
          <Form.Group className="mb-3 position-relative" controlId="password">
            <StyledLabel>
              {t('New Password')}
              <span className="required" />
            </StyledLabel>
            <InputGroup className="mb-4">
              <Form.Control
                className="border-end-0"
                type={showPassword ? 'text' : 'password'}
                autoComplete="off"
                placeholder={t('Enter new password')}
                disabled={!isTokenVerified}
                {...register('password', {
                  required: t('Password is required'),
                  validate: (val) => {
                    if (watch('c_password') != val) {
                      setError('c_password', {
                        message: t('Please make sure your passwords match.'),
                      });
                    } else {
                      clearErrors('c_password');
                    }
                  },
                  minLength: {
                    value: 6,
                    message: t('Password must be at least 6 characters'),
                  },
                  pattern: {
                    value: /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])/g,
                    message: t(
                      'Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character (#?!@$%^&*-).',
                    ),
                  },
                })}
              />
              <StyledPasswordEyeContainer onClick={() => (isTokenVerified ? setShowPassword(!showPassword) : null)}>
                <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
              </StyledPasswordEyeContainer>
            </InputGroup>
            {errors.password && <ErrorLabel>{errors.password.message}</ErrorLabel>}
          </Form.Group>

          <Form.Group className="mb-5 position-relative" controlId="cpassword">
            <StyledLabel>
              {t('Confirm Password')}
              <span className="required" />
            </StyledLabel>
            <InputGroup className="mb-3">
              <Form.Control
                className="border-end-0"
                type={showCPassword ? 'text' : 'password'}
                autoComplete="off"
                placeholder={t('Enter confirm password')}
                disabled={!isTokenVerified}
                {...register('c_password', {
                  required: t('Confirm Password is required'),
                  validate: (val) => {
                    if (watch('password') != val) {
                      return t('Please make sure your passwords match.');
                    }
                  },
                })}
              />
              <StyledPasswordEyeContainer onClick={() => (isTokenVerified ? setShowCPassword(!showCPassword) : null)}>
                <FontAwesomeIcon icon={showCPassword ? faEye : faEyeSlash} />
              </StyledPasswordEyeContainer>
            </InputGroup>
            {errors.c_password && <StyledErrorLabel>{errors.c_password.message}</StyledErrorLabel>}
          </Form.Group>
          <Row>
            <Col sm={12} md={12} lg={12} xxl={12} className="d-flex justify-content-between">
              <StyledOutlinePrimaryButton
                type="submit"
                variant="primary"
                className="flex-fill me-2"
                onClick={() => navigate(AUTH_LOGIN, {replace: true})}
              >
                {t(`Cancel`)}
              </StyledOutlinePrimaryButton>
              <StyledPrimaryButton
                type="submit"
                variant="primary"
                className="flex-fill ms-2"
                disabled={!isTokenVerified || isLoading || Object.keys(errors).length > 0}
              >
                {isLoading ? <Spinner animation="border" /> : page === SET_PASSWORD ? t(`Create`) : t(`Update`)}
              </StyledPrimaryButton>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
