import {createSlice} from '@reduxjs/toolkit';
import { DESKTOP } from 'constants/app';

const initialState = {
  deviceType: DESKTOP,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setDeviceType: (state, action) => {
      state.deviceType = action.payload;
    },
  },
});

export const {setDeviceType} = appSlice.actions;

export default appSlice.reducer;
