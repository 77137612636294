import React, {useState, useRef, useMemo} from 'react';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import {PAGE_DISPLAY} from 'constants/datatable';
import CustomDataTable from 'components/datatable';
import CustomModal from 'components/shared/CustomModal';
import DeleteModal from 'components/modal/DeleteModal';
import CustomToast from 'components/Toast/CustomToast';
import {conditionalRowStyles} from 'utils/tableRowsColor';
import {downloadFile} from 'utils/downloadFile';
import {useCustomEffect} from 'hooks/useCustomEffect';
import {useStateAsync} from 'hooks/useStateAsync';
import useGetTableList from 'hooks/table/useGetTableList';
import {SOMETHING_WENT_ERROR} from 'constants/apiNetwork';
import {notificationListColumn} from 'components/datatable/columns/notificationListColumn';
import {deleteNotification} from 'features/notification/notificationApi';
import {usePermission} from 'hooks/usePermission';
import {fetchSettingNotificationCsvContent, fetchSettingNotifications} from 'features/tenant/settingApi';
import SettingNotificationFilter from './SettingNotificationFilter';
import {useTranslation} from 'react-i18next';

const ManageSettingNotificationContainer = ({tenantId}) => {
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [filters, setFilters] = useStateAsync({
    notificationTitle: '',
    notificationTypeId: '',
    deviceTypeId: '',
    tagId: '',
    status: '',
    key: '',
    value: '',
    pageSize: PAGE_DISPLAY[0], // per page data
    pageNumber: 1, // current page number
  });
  const numberOfPagesRef = useRef(1);
  const applyFilterRef = useRef(false);

  const {t} = useTranslation();

  const isEditAllow = usePermission(`app/notifications`, 'edit');
  const isDeleteAllow = usePermission(`app/notifications`, 'delete');

  const navigate = useNavigate();

  const {tableData, isLoading, getTablelist, handleSort, handlePageChange, handleRowsPerPageChange} = useGetTableList(
    fetchSettingNotifications,
    numberOfPagesRef,
    setFilters,
    applyFilterRef,
  );

  useCustomEffect(() => {
    getTablelist(filters);
  }, []);

  const handleEdit = (row) => {
    navigate(`/app/tenants/manage/${tenantId}/${row._id}?tab=5`);
  };

  const handleDelete = (row) => {
    setSelectedNotification(row);
    setShowDelete(true);
  };

  const handleDeleteCloseModal = () => {
    setShowDelete(false);
    setSelectedNotification(null);
  };

  const handleDeleteNotification = async () => {
    try {
      const {_id} = selectedNotification;
      const {message} = await deleteNotification(_id);
      toast(<CustomToast type="success" title="Success" message={message} />);
      await getTablelist(filters);
      handleDeleteCloseModal();
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    }
  };

  const handleFilter = () => {
    applyFilterRef.current = true;
    setFilters(
      (prevFilters) => ({
        notificationTitle: prevFilters.notificationTitle,
        notificationTypeId: prevFilters.notificationTypeId,
        deviceTypeId: prevFilters.deviceTypeId,
        tagId: prevFilters.tagId,
        status: prevFilters.status === 'all' ? '' : prevFilters.status,
        key: '',
        value: '',
        pageSize: prevFilters.pageSize,
        pageNumber: 1,
      }),
      (newValue) => {
        getTablelist(newValue);
      },
    );
  };

  const handleFilterChange = (event) => {
    const {name, value} = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleClearFilter = () => {
    applyFilterRef.current = false;
    setFilters(
      (prevFilters) => ({
        ...prevFilters,
        notificationTitle: '',
        notificationTypeId: '',
        deviceTypeId: '',
        tagId: '',
        status: '',
        key: '',
        value: '',
        pageSize: prevFilters.pageSize,
        pageNumber: 1,
      }),
      (newValue) => {
        getTablelist(newValue);
      },
    );
  };

  const handleDownloadCSV = async () => {
    try {
      const downloadWithFilter = {};
      if (applyFilterRef.current) {
        downloadWithFilter['notificationTitle'] = filters.notificationTitle;
        downloadWithFilter['notificationTypeId'] = filters.notificationTypeId;
        downloadWithFilter['deviceTypeId'] = filters.deviceTypeId;
        downloadWithFilter['tagId'] = filters.tagId;
        downloadWithFilter['key'] = filters.key;
        downloadWithFilter['value'] = filters.value;
        if (filters.status !== 'all') {
          downloadWithFilter['status'] = filters.status;
        }
      }
      const csvData = await fetchSettingNotificationCsvContent(downloadWithFilter);
      const currentDate = new Date().toLocaleString();
      downloadFile(csvData, `tsn-notification-setting-${currentDate}.csv`);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={t(SOMETHING_WENT_ERROR)} />);
    }
  };

  const columns = useMemo(
    () => notificationListColumn(filters, isEditAllow, isDeleteAllow, handleEdit, handleDelete, t),
    [filters, isEditAllow, isDeleteAllow, handleEdit, handleDelete, t],
  );

  return (
    <>
      <SettingNotificationFilter
        filters={filters}
        handleFilterChange={handleFilterChange}
        handleFilter={handleFilter}
        handleClearFilter={handleClearFilter}
        handleDownloadCSV={handleDownloadCSV}
      />
      <CustomDataTable
        columns={columns}
        data={tableData}
        loading={isLoading}
        handleSort={handleSort}
        pagination={true}
        numberOfPages={numberOfPagesRef.current}
        rowPerPage={filters.pageSize}
        conditionalRowStyles={conditionalRowStyles}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
      />
      {showDelete && (
        <CustomModal
          size="sm"
          show={showDelete}
          handleClose={handleDeleteCloseModal}
          body={<DeleteModal deletedItem={selectedNotification && selectedNotification.notificationTitle} />}
          buttons={[
            {label: t('Yes'), type: 'primary', onClickHandler: handleDeleteNotification},
            {label: t('No'), type: 'secondary', onClickHandler: handleDeleteCloseModal},
          ]}
        />
      )}
    </>
  );
};

export default ManageSettingNotificationContainer;
