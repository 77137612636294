export const OVERALL_DEFAULT_DURATION = '15 minute';
export const DEVICE_DEFAULT_DURATION = '24 hour';
export const DEFAULT_MINUTES = 15;

export const DATE_FILTER_OPTIONS = [
  {
    label: 'Last 5 Minutes',
    value: '5 minute',
  },
  {
    label: 'Last 15 Minutes',
    value: '15 minute',
  },
  {
    label: 'Last 1 Hours',
    value: '1 hour',
  },
  {
    label: 'Last 12 Hours',
    value: '12 hour',
  },
  {
    label: 'Last 24 Hours',
    value: '24 hour',
  },
  {
    label: 'Last 2 Days',
    value: '2 day',
  },
  {
    label: 'Last 5 Days',
    value: '5 day',
  },
  {
    label: 'Last 7 Days',
    value: '7 day',
  },
  {
    label: 'Last 1 Months',
    value: '1 month',
  },
  {
    label: 'Custom',
    value: 'custom',
  },
];
