import React from 'react';
import {Form, Row, Col} from 'react-bootstrap';
import SwitchButton from 'components/SwitchButton';
import Loader from 'assets/images/loader.gif';
import {StyledErrorLabel} from 'styles/styled-components/StyledComponents';
import {EMAIL_REGEX, NUMBER_ONLY_REGEX} from 'constants/regularExpression';
import {useTranslation} from 'react-i18next';

const ManageTenantTab = (props) => {
  const {isLoading, selectedTenant, register, errors, setValue, setError, clearErrors} = props;
  const {t} = useTranslation();
  return (
    <>
      <div className="card">
        <div className="card-body">
          {isLoading ? (
            <>
              <div className="loader-warp">
                <img src={Loader} alt="loader" />
              </div>
            </>
          ) : (
            <>
              <div>{t('Add Tenant')}</div>
              <div className="user-form">
                <Row className="">
                  <Col md="12" lg="6" className="">
                    <Row>
                      <Col>
                        <Form.Group className="mb-3 position-relative" controlId="name">
                          <Form.Label>
                            {t('Tenant Name')}
                            <span className="required" />
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder={t("Enter Tenant Name")}
                            className="mb-2"
                            autoComplete="off"
                            {...register('tenantName', {
                              required: t('Tenant name is required'),
                            })}
                          />
                          {errors.tenantName && <StyledErrorLabel>{errors.tenantName.message}</StyledErrorLabel>}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3 position-relative" controlId="address">
                          <Form.Label>
                            {t('Contact Person')}
                            <span className="required" />
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder={t("Enter Contact Person")}
                            className="mb-2"
                            autoComplete="off"
                            {...register('name', {
                              required: t('Contact person is required'),
                            })}
                          />
                          {errors.name && <StyledErrorLabel>{errors.name.message}</StyledErrorLabel>}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3 position-relative" controlId="address2">
                          <Form.Label>
                            {t('Contact Person Email')}
                            <span className="required" />
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder={t("Enter Contact Person Email")}
                            className="mb-2"
                            autoComplete="off"
                            disabled={selectedTenant ? true : false}
                            {...register('email', {
                              required: t('Contact person email is required'),
                              pattern: {
                                value: EMAIL_REGEX,
                                message: t('Invalid email address'),
                              },
                            })}
                          />
                          {errors.email && <StyledErrorLabel>{errors.email.message}</StyledErrorLabel>}
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col md="12" lg="6" className="">
                    <Row>
                      <Col>
                        <Form.Group className="mb-3 position-relative" controlId="status">
                          <Form.Label>
                            {t('Status')}
                            <span className="required" />
                          </Form.Label>
                          <div className="d-flex">
                            <SwitchButton
                              leftLabel={'Active'}
                              rightLabel={'Inactive'}
                              isDisableInactive={Boolean(selectedTenant)}
                              value={selectedTenant ? selectedTenant.status : true}
                              handleSetValue={(value) => setValue('status', value)}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3 position-relative" controlId="mobile-number">
                          <Form.Label>
                            {t('Contact Person Mobile Number')}
                            <span className="required" />
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder={t("Enter Mobile Number")}
                            className="mb-2"
                            autoComplete="off"
                            {...register('contact.number', {
                              required: t('Mobile number is required'),
                              // pattern: {
                              //   value: NUMBER_ONLY_REGEX,
                              //   message: 'Invalid mobile number',
                              // },
                            })}
                            onChange={(e) => {
                              const modifiedValue = e.target.value.replace(/^0+/, '');
                              setValue('contact.number', modifiedValue);
                              if (!NUMBER_ONLY_REGEX.test(modifiedValue)) {
                                setError('contact.number', {
                                  type: 'manual',
                                  message: t('Invalid mobile numer'),
                                });
                              } else {
                                clearErrors('contact.number');
                              }
                            }}
                          />
                          {errors.contact?.number && (
                            <StyledErrorLabel>{errors.contact?.number.message}</StyledErrorLabel>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3 position-relative" controlId="Remark">
                      <Form.Label>{t('Additional Remarks')}</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder={t("Enter Description")}
                        rows={3}
                        {...register('additionalRemark')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ManageTenantTab;
