import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Marker, InfoWindow, MarkerClusterer} from '@react-google-maps/api';
import {CLUSTER_OPTIONS, INFOWINDOW_OFFSET, INFOWINDOW_OFFSET_SELECTED, ZONE_CLUSTER} from 'constants/map';
const geolib = require('geolib');

const DashboardMapMarkers = ({devices, zones, deviceId, selectedDevice, clusteringLevel, handleMarkerClick}) => {
  const [hoveredMarker, setHoveredMarker] = useState(null);
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [zoneClusters, setZoneClusters] = useState([]);
  const [infoWindowPosition, setInfoWindowPosition] = useState({lat: 0, lng: 0});

  const {t} = useTranslation();

  useEffect(() => {
    (async () => {
      const {clusterArray: zoneClusterArray, returnFound} = await clusterMaker();
      setZoneClusters(zoneClusterArray);
    })();
  }, [devices]);

  useEffect(() => {
    if (selectedDevice) {
      handleMouseOver(selectedDevice);
    } else {
      setHoveredMarker(null);
    }
  }, [selectedDevice]);

  const clusterMaker = () => {
    let p,
      found = [],
      returnFound = [],
      clusterArray = [];
    for (let j = 0; j < zones.length; j++) {
      p = zones[j];
      found = [];
      for (let i = 0; i < devices.length; i++) {
        if (
          geolib.isPointInPolygon(
            {latitude: devices[i].masterDevice.location.lat, longitude: devices[i].masterDevice.location.long},
            p.coordinates,
          )
        ) {
          found.push(devices[i]);
          returnFound.push(devices[i]);
        }
      }
      p.devices = found;
      clusterArray.push(p);
    }

    const outOfZones = [];
    // Create a set of _id values from the returnFoundArray for faster lookup
    const returnFoundIds = new Set(returnFound.map((device) => device._id));
    // Check each device in the deviceArray
    for (const device of devices) {
      // If the _id of the device is not found in the returnFoundIds set, add it to the unmatchedDevices array
      if (!returnFoundIds.has(device._id)) {
        outOfZones.push(device);
      }
    }

    if (outOfZones.length > 0) {
      const outOfZoneCluster = {
        _id: 'outOfZone',
        name: 'Out Of Zone',
        coordinates: [],
        device: [],
        key: 'outOfZone',
        value: null,
        condition: null,
        colorCode: null,
        devices: outOfZones,
      };
      clusterArray.push(outOfZoneCluster);
    }

    return {clusterArray, returnFound};
  };

  const getMarkerIcon = (marker, selectedDevice) => {
    const {masterDevice} = marker;
    const isSelected = selectedDevice && selectedDevice.masterDevice.deviceId === masterDevice.deviceId;

    if (masterDevice.isNew && !isSelected) {
      return '/markers/default.gif';
    } else if (masterDevice.isNew && isSelected) {
      return '/markers/default-selected.gif';
    } else if (!masterDevice.isNew && masterDevice.deviceAliasName && !isSelected) {
      return `/markers/${masterDevice.deviceAliasName}.svg`;
    } else {
      return `/markers/${masterDevice.deviceAliasName}-selected.svg`;
    }
  };

  const handleMouseOver = React.useCallback((marker) => {
    setHoveredMarker(marker);
    setInfoWindowPosition({
      lat: parseFloat(marker.masterDevice.location.lat),
      lng: parseFloat(marker.masterDevice.location.long),
    });
    setShowInfoWindow(true);
  }, []);

  const handleMouseOut = React.useCallback(() => {
    setShowInfoWindow(false);
  }, []);

  return (
    <>
      {clusteringLevel === ZONE_CLUSTER ? (
        <>
          {zoneClusters.map((p) => {
            return (
              <MarkerClusterer key={p._id} options={CLUSTER_OPTIONS} minimumClusterSize={2}>
                {(clusterer) => {
                  if (p.devices.length > 0) {
                    return p.devices.map((marker, index) => {
                      const {masterDevice} = marker;
                      const lat = masterDevice?.location?.lat;
                      const lng = masterDevice?.location?.long;
                      const icon = getMarkerIcon(marker, selectedDevice);

                      return (
                        <Marker
                          key={`${masterDevice.deviceId}-${index}`}
                          position={{lat: parseFloat(lat), lng: parseFloat(lng)}}
                          icon={{
                            url: icon,
                          }}
                          clusterer={clusterer}
                          onClick={() => (!deviceId ? handleMarkerClick(marker) : null)}
                          onMouseOver={() => handleMouseOver(marker)}
                          onMouseOut={handleMouseOut}
                        />
                      );
                    });
                  }
                }}
              </MarkerClusterer>
            );
          })}
        </>
      ) : (
        <>
          <MarkerClusterer options={CLUSTER_OPTIONS} minimumClusterSize={2}>
            {(clusterer) => {
              return (
                <>
                  {devices.map((marker, index) => {
                    const {masterDevice} = marker;
                    const lat = masterDevice?.location?.lat;
                    const lng = masterDevice?.location?.long;
                    const icon = getMarkerIcon(marker, selectedDevice);

                    return (
                      <Marker
                        key={`${masterDevice.deviceId}-${index}`}
                        position={{lat: parseFloat(lat), lng: parseFloat(lng)}}
                        icon={{
                          url: icon,
                        }}
                        clusterer={clusterer}
                        onClick={() => (!deviceId ? handleMarkerClick(marker) : null)}
                        onMouseOver={() => handleMouseOver(marker)}
                        onMouseOut={handleMouseOut}
                      />
                    );
                  })}
                </>
              );
            }}
          </MarkerClusterer>
        </>
      )}

      {/* InfoWindow for displaying marker details */}
      {hoveredMarker && (
        <InfoWindow
          position={{
            lat: infoWindowPosition.lat,
            lng: infoWindowPosition.lng,
          }}
          options={{
            pixelOffset: new window.google.maps.Size(
              0,
              selectedDevice?._id === hoveredMarker._id ? INFOWINDOW_OFFSET_SELECTED : INFOWINDOW_OFFSET,
            ),
          }}
          onCloseClick={() => setHoveredMarker(null)}
        >
          <div className="map-marker-infoWindo">
            <p className="title">{t('Device Name')}</p>
            <p className="device-name">{hoveredMarker.deviceName}</p>
          </div>
        </InfoWindow>
      )}
    </>
  );
};

export default React.memo(DashboardMapMarkers);
