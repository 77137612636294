import React, {useState} from 'react';
import PageInnerHeader from 'components/PageInnerHeader';
import ManageRoleContainer from 'pages/ManageRolesPage/ManageRoleContainer';
import { usePermission } from 'hooks/usePermission';
import {useTranslation} from 'react-i18next';

const ManageRolesPage = React.memo(() => {
  const [showRoleModal, setShowRoleModal] = useState(false);
  const isCreateAllow = usePermission(`app/roles`, 'create');

  const {t} = useTranslation();

  const handleAddRoleModal = () => {
    setShowRoleModal(true);
  };

  return (
    <>
      <PageInnerHeader
        title={t('Manage Roles')}
        buttonLabel={t('Add New Role')}
        isCreateAllow={isCreateAllow}
        handleAddButton={handleAddRoleModal}
      />
      <ManageRoleContainer
        showRoleModal={showRoleModal}
        setShowRoleModal={setShowRoleModal}
        isTenantManagePage={false}
      />
    </>
  );
});

export default ManageRolesPage;
