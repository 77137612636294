import React from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import PrivateRoutes from 'router/PrivateRoutes';
import PublicRoutes from 'router/PublicRoutes';
import GlobleHeader from 'components/layout';
import HomePage from 'pages/HomePage';
import LoginPage from 'pages/LoginPage';
import ForgotPassword from 'pages/ForgotPassword';
import {
  PRIVATE_PREFIX,
  PUBLIC_PREFIX,
  LOGIN,
  VERIFY,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  SET_PASSWORD,
  DASHBOARD,
  ROLES,
  USERS,
  TENANTS,
  MANAGE_TENANTS,
  MANAGE_TENANTS_SETTING,
  NOTIFICATIONS,
  MANAGE_NOTIFICATIONS,
  UNAUTHORIZED,
  REPORTS,
  DEVICE_DASHBOARD,
  MANAGE_REPORTS,
  GENERATE_REPORTS,
  VIEW_REPORTS,
  VIEW_PRINT_REPORTS,
  REPORTS_SUBSCRIBER,
} from 'constants/routesName';
import PageNotFound from 'components/PageNotFound';
import ResetPassword from 'pages/ForgotPassword/ResetPassword';
import NotAuthorized from 'components/NotAuthorized';
import ManageRolesPage from 'pages/ManageRolesPage';
import ManageUsersPage from 'pages/ManageUsersPage';
import RTSPStreamComponent from 'pages/CAM';
import ManageTenants from 'pages/ManageTenantsPage';
import RenderElement from 'components/RenderElement';
import ManageTenant from 'pages/ManageTenantsPage/ManageTenant';
import ManageTenantSetting from 'pages/ManageTenantsPage/ManageTenantSetting';
import ManageNotificationsPage from 'pages/ManageNotificationsPage';
import ManageNotification from 'pages/ManageNotificationsPage/ManageNotification';
import ReportsPage from 'pages/ReportsPage';
import DeviceDashboardPage from 'pages/DeviceDashboardPage';
import GenerateReports from 'components/reports/GenerateReports';
import ManageReports from 'components/reports/ManageReports';
import ViewReport from 'components/reports/ViewReport';
import Subscriber from 'pages/ReportsPage/Subscriber';
import DeviceDashboardPOCPage from 'pages/DeviceDashboardPOCPage';

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/auth" />} />
        <Route path={PUBLIC_PREFIX} element={<PublicRoutes />}>
          <Route path="" element={<RenderElement element={<LoginPage />} />} />
          <Route exact path={`${LOGIN}`} element={<RenderElement element={<LoginPage />} />} />
          <Route exact path={`${VERIFY}`} element={<RenderElement element={<LoginPage />} />} />
          <Route exact path={FORGOT_PASSWORD} element={<RenderElement element={<ForgotPassword />} />} />
          <Route exact path={`${RESET_PASSWORD}/:token`} element={<RenderElement element={<ResetPassword />} />} />
          <Route exact path={`${SET_PASSWORD}/:token`} element={<RenderElement element={<ResetPassword />} />} />
        </Route>

        <Route element={<PrivateRoutes />}>
          <Route path={PRIVATE_PREFIX} element={<GlobleHeader />}>
            <Route exact path={DASHBOARD} element={<HomePage />} />
            <Route exact path={`${DEVICE_DASHBOARD}/:viewDeviceId?`} element={<DeviceDashboardPage />} />
            <Route exact path={`/app/device/poc/dashboard`} element={<DeviceDashboardPOCPage />} />
            <Route exact path={ROLES} element={<RenderElement element={<ManageRolesPage />} />} />
            <Route exact path={USERS} element={<RenderElement element={<ManageUsersPage />} />} />
            <Route exact path={TENANTS} element={<RenderElement element={<ManageTenants />} />} />
            <Route exact path={`${MANAGE_TENANTS}/:tenantId?/:deviceId?`} element={<ManageTenant />} />
            <Route exact path={`${MANAGE_TENANTS_SETTING}/:tenantId?/:deviceId?`} element={<ManageTenantSetting />} />
            <Route exact path={'camera'} element={<RTSPStreamComponent />} />
            <Route exact path={NOTIFICATIONS} element={<ManageNotificationsPage />} />
            <Route exact path={`${MANAGE_NOTIFICATIONS}/:notificationId?`} element={<ManageNotification />} />
            <Route exact path={REPORTS} element={<ReportsPage />} />
            <Route exact path={`${GENERATE_REPORTS}`} element={<GenerateReports />} />
            <Route exact path={`${MANAGE_REPORTS}/:reportId?`} element={<ManageReports />} />
            {/* <Route exact path={`${VIEW_REPORTS}/:reportId?`} element={<ViewReport />} /> */}
            <Route exact path={`${REPORTS_SUBSCRIBER}/:reportId`} element={<Subscriber />} />
          </Route>
          <Route exact path={`${VIEW_REPORTS}/:reportId?`} element={<ViewReport />} />
        </Route>
        <Route exact path={UNAUTHORIZED} element={<NotAuthorized />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
