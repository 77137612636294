import React from 'react';
import {ColorChartSection} from 'styles/styled-components/StyledComponents';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {CHARTS_COLOR} from 'constants/ChartsColor';
import {useTranslation} from 'react-i18next';

const ChartColorContainer = () => {
  const {t} = useTranslation();
  return (
    <>
      <div className="row mb-4 mongo-chart">
        <div className="col-12 col-md-12">
          <div className="card mb-3">
            <div className="card-body">
              <h4 className="section-subheading mb-3">{t('Color Chart')}</h4>
              <div className="d-flex flex-column flex-md-row gap-3">
                {CHARTS_COLOR.map((section, index) => {
                  return (
                    <OverlayTrigger key={index} placement={'top'} overlay={<Tooltip>{section.label}</Tooltip>}>
                      <ColorChartSection className='color-chart-block' background={section.background} color={section.color}>
                        {t(section.label)}
                      </ColorChartSection>
                    </OverlayTrigger>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChartColorContainer;
