import React from 'react';

export default (props) => (
  <svg width="80" height="80" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.4286 19.375H14.5714V7.5H19.4286V19.375ZM14.5714 26.5H19.4286V21.75H14.5714V26.5ZM29.9443 21.75H24.2857L27.9286 25.3125C25.3786 28.4713 21.4443 30.5375 17 30.5375C13.3306 30.5312 9.81336 29.1029 7.21871 26.5655C4.62407 24.0281 3.16356 20.5884 3.15714 17C3.15714 16.1925 3.23 15.4087 3.37571 14.625H0.194286C0.0728571 15.4087 0 16.1925 0 17C0 26.1675 7.62571 33.625 17 33.625C22.3186 33.625 27.03 31.2025 30.1386 27.4737L34 31.25V21.75H29.9443ZM4.05571 12.25H9.71429L6.07143 8.6875C8.62143 5.52875 12.5557 3.4625 17 3.4625C24.6257 3.4625 30.8429 9.5425 30.8429 17C30.8429 17.8075 30.77 18.5912 30.6243 19.375H33.8057C33.9271 18.5912 34 17.8075 34 17C34 7.8325 26.3743 0.375 17 0.375C11.6814 0.375 6.97 2.7975 3.86143 6.52625L0 2.75V12.25H4.05571Z"
      fill="#01426A"
    />
  </svg>
);
