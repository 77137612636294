import moment from 'moment';

export const getHeatChartOptions = (heapData) => {
  const {chart: data, colors} = heapData;
  const initialValue = colors.length > 0 ? colors[0].startValue : 0;
  const lastValue = colors.length > 0 ? colors[colors.length - 1].startValue : 100;

  const heapChart = {
    dataset: [
      {
        dimensions: ['x', 'y', 'value'],
        source: data,
      },
      {
        transform: {
          type: 'sort',
          config: {dimension: 'value', order: 'desc'},
        },
      },
    ],
    grid: {
      height: '60%',
      top: '0%',
    },
    tooltip: {
      position: 'top',
      formatter: (params) => {
        if (!params.data) return '';
        const [x, y, value] = params.data;
        const tooltip = `<div className='d-flex'>
                            <div>${y} ${x}</div>
                            <div><strong>${value}</strong></div>
                        </div>`;
        return tooltip;
      },
    },
    xAxis: {
      type: 'category',
      splitArea: {
        show: true,
      },
      axisLabel: {
        rotate: 90,
        formatter: (params) => {
          const [hours] = params.split(':');
          return `${moment(hours, 'hh').format('LT')}`;
        },
      },
    },
    yAxis: {
      type: 'category',
      splitArea: {
        show: false,
      },
      axisLabel: {
        formatter: (params) => {
          return moment(params).format('MMM-DD');
        },
      },
    },
    visualMap: {
      min: initialValue,
      max: lastValue, // Set appropriate min and max values
      calculable: false,
      orient: 'horizontal',
      left: 'center',
      bottom: '15%',
    },
    series: [
      {
        type: 'heatmap',
        data: data.map((item) => [item.x, item.y, item.value]),
        label: {
          show: false,
          formatter: (params) => `${params.value[2]}`,
          fontSize: 10,
        },
        itemStyle: {
          borderWidth: 1,
          borderColor: 'rgba(0, 0, 0, 0.3)', // Border color for data cells
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
            borderColor: '#fff', // Border color for cells
            borderWidth: 1, // Border width for cells
          },
        },
      },
    ],
  };

  if (colors.length > 0) {
    const pieces = colors.map((condition) => {
      return {
        min: condition.startValue,
        max: condition.endValue,
        color: condition.colorCode,
      };
    });
    pieces[pieces.length - 1].max = Infinity;

    heapChart.visualMap.pieces = pieces;
    heapChart.visualMap.selectedMode = false;
  } else {
    heapChart.visualMap.color = ['red', 'yellow', 'green'];
  }

  return heapChart;
};
