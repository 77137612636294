import axios from 'axios';
import store from 'store';
import i18n from 'i18next';
import {logout} from 'features/auth/authSlice';
import {SOMETHING_WENT_ERROR} from 'constants/apiNetwork';
import {resetLanguage} from 'features/language/languageSlice';

// Create an instance of Axios
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor to add the Authorization header
api.interceptors.request.use(
  (config) => {
    const {user} = store.getState().auth;
    const {selectedLanguage, publicSelectedLanguage} = store.getState().language;
    const token = user?.token;
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
      config.headers['accept-language'] = selectedLanguage.value;
    } else {
      config.headers['accept-language'] = publicSelectedLanguage.value;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Add a response interceptor to handle loading state and error handling
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    let errorMessage;
    if (error.response) {
      // need to logout the user if token is expire.
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          store.dispatch(logout());
          store.dispatch(resetLanguage());
        } catch (error) {
          console.error(error);
        }
      }
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (error.response.data && error.response.data) {
        errorMessage = error.response.data?.message ? error.response.data.message : error.response.data.error;
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      errorMessage = i18n.t(SOMETHING_WENT_ERROR);
    } else {
      // Something happened in setting up the request that triggered an Error
      errorMessage = error.message;
    }

    return Promise.reject(errorMessage);
  },
);

export default api;
