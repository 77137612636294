import React from 'react';
import {Link} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import plusImg from 'assets/images/svg/plus.svg';
import leftarrowImg from 'assets/images/svg/left-arrow.svg';
import {useTranslation} from 'react-i18next';

const PageInnerHeader = (props) => {
  const {title, buttonLabel, isCreateAllow, handleAddButton} = props;
  const {t} = useTranslation();

  return (
    <>
      <div className="breadcrum-sec align-items-center breadcrum-sec d-flex justify-content-between flex-column flex-md-row">
        <div className="mob-100">
          <ul>
            <li>
              <Link to={`/app/dashboard`} className="breadcrum-link" variant="breadcrum">
                <img className="me-2" src={leftarrowImg} alt="Add" />
                {t('Dashboard')}
              </Link>
            </li>
          </ul>
          <h2 className="page-title">{title}</h2>
        </div>
        <div className="mob-100">
          <Button
            className="add-btn d-flex align-items-center"
            variant=""
            type="submit"
            disabled={isCreateAllow}
            onClick={handleAddButton}
          >
            <img className="me-2" src={plusImg} alt="Add" /> {buttonLabel}
          </Button>
        </div>
      </div>
    </>
  );
};

export default PageInnerHeader;
