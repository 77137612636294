import React from 'react';
import Loader from 'assets/images/loader.gif';
import DataTable from 'react-data-table-component';
import Pagination from 'components/datatable/Pagination';
import {PAGE_DISPLAY} from 'constants/datatable';
import {useTranslation} from 'react-i18next';

const CustomDataTable = React.memo((props) => {
  const {
    tableClass,
    columns,
    data,
    loading,
    handleSort,
    conditionalRowStyles,
    pagination,
    rowPerPage,
    numberOfPages,
    handlePageChange,
    handleRowsPerPageChange,
  } = props;

  const {t} = useTranslation();

  return (
    <DataTable
      className={tableClass}
      columns={columns}
      data={data}
      conditionalRowStyles={conditionalRowStyles}
      onSort={handleSort}
      pagination={pagination}
      progressPending={loading}
      paginationServer={true}
      paginationRowsPerPageOptions={PAGE_DISPLAY}
      paginationPerPage={rowPerPage}
      progressComponent={
        <div className='loader-warp'>
          <img src={Loader} alt="loader" />
        </div>
      }
      paginationComponent={(props) => (
        <Pagination
          {...props}
          numberOfPages={numberOfPages}
          handlePageChange={handlePageChange}
          handleRowsPerPageChanges={handleRowsPerPageChange}
        />
      )}
      style={{width: '100%'}}
      noDataComponent={t('There are no records to display')}
    />
  );
});

export default CustomDataTable;
