import React from 'react';
import {Form} from 'react-bootstrap';
import {CHECKBOX_DISABLED, ROLE_ACCESS} from 'constants/role';
import { useTranslation } from 'react-i18next';

export const manageRoleColumn = (selectAll, handleCheckboxChange, handleSelectAllChange) => {
  const { t } = useTranslation();
  return [
    {
      name: t('Modules'),
      selector: (row) => t(row.name.charAt(0).toUpperCase() + row.name.slice(1)),
    },
    {
      name: (
        <>
          <Form.Check
            className="me-2"
            type={'checkbox'}
            id={`select-all-${[ROLE_ACCESS.CREATE]}`}
            checked={selectAll[ROLE_ACCESS.CREATE]}
            onChange={() => handleSelectAllChange([ROLE_ACCESS.CREATE])}
          />
          {t('Create')}
        </>
      ),
      cell: (row) => (
        <>
          {!CHECKBOX_DISABLED.includes(row.name) && (
            <Form.Check
              className={`me-2`}
              type={'checkbox'}
              disabled={CHECKBOX_DISABLED.includes(row.name)}
              checked={row[ROLE_ACCESS.CREATE]}
              onChange={() => handleCheckboxChange(row, [ROLE_ACCESS.CREATE])}
            />
          )}
        </>
      ),
    },
    {
      name: (
        <>
          <Form.Check
            className="me-2"
            type={'checkbox'}
            id={`select-all-${[ROLE_ACCESS.READ]}`}
            checked={selectAll[ROLE_ACCESS.READ]}
            onChange={() => handleSelectAllChange([ROLE_ACCESS.READ])}
          />
          {t('Read/View')}
        </>
      ),
      cell: (row) => (
        <>
          <Form.Check
            className="me-2"
            type={'checkbox'}
            checked={row[ROLE_ACCESS.READ]}
            onChange={() => handleCheckboxChange(row, [ROLE_ACCESS.READ])}
          />
        </>
      ),
    },
    {
      name: (
        <>
          <Form.Check
            className="me-2"
            type={'checkbox'}
            id={`select-all-${[ROLE_ACCESS.EDIT]}`}
            checked={selectAll[ROLE_ACCESS.EDIT]}
            onChange={() => handleSelectAllChange([ROLE_ACCESS.EDIT])}
          />
          {t('Update/Edit')}
        </>
      ),
      cell: (row) => (
        <>
          {!CHECKBOX_DISABLED.includes(row.name) && (
            <Form.Check
              className={`me-2 ${CHECKBOX_DISABLED.includes(row.name) ? `checkbox-disabled` : ``}`}
              type={'checkbox'}
              disabled={CHECKBOX_DISABLED.includes(row.name)}
              checked={row[ROLE_ACCESS.EDIT]}
              onChange={() => handleCheckboxChange(row, [ROLE_ACCESS.EDIT])}
            />
          )}
        </>
      ),
    },
    {
      name: (
        <>
          <Form.Check
            className="me-2"
            type={'checkbox'}
            id={`select-all-${[ROLE_ACCESS.DELETE]}`}
            checked={selectAll[ROLE_ACCESS.DELETE]}
            onChange={() => handleSelectAllChange([ROLE_ACCESS.DELETE])}
          />
          {t('Delete')}
        </>
      ),
      cell: (row) => (
        <>
          {!CHECKBOX_DISABLED.includes(row.name) && (
            <Form.Check
              className={`me-2 ${CHECKBOX_DISABLED.includes(row.name) ? `checkbox-disabled` : ``}`}
              type="checkbox"
              disabled={CHECKBOX_DISABLED.includes(row.name)}
              checked={row[ROLE_ACCESS.DELETE]}
              onChange={() => handleCheckboxChange(row, [ROLE_ACCESS.DELETE])}
            />
          )}
        </>
      ),
    },
  ];
};
