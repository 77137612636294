import React from 'react';
import {useTranslation} from 'react-i18next';
import { StyledErrorLabel } from 'styles/styled-components/StyledComponents';

const DeviceSensorType = ({allSubDeviceTypes, selectedSubDeviceType, deviceId, handleSubDeviceType}) => {
  const {t} = useTranslation();
  return (
    <>
      <div className="row">
        <div className="col mb-4">
          <h4 className="tanent-label mb-3">{t('Device Parameters*')}</h4>
          <div className="sub-tanent-card-sec mb-2">
            {allSubDeviceTypes.map((allSubDeviceType) => {
              return (
                <div
                  key={allSubDeviceType._id}
                  className={`sub-tanent-card me-3 ${
                    selectedSubDeviceType.includes(allSubDeviceType._id) ? 'active' : ''
                  }`}
                  onClick={() => (!deviceId ? handleSubDeviceType(allSubDeviceType) : null)}
                >
                  {allSubDeviceType.displayName}
                </div>
              );
            })}
          </div>
          {selectedSubDeviceType.length === 0 && <StyledErrorLabel>{t('Please choose services')}</StyledErrorLabel>}
        </div>
      </div>
    </>
  );
};

export default DeviceSensorType;
