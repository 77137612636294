import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {Button, Form, Row, Col} from 'react-bootstrap';
import {StyledErrorLabel} from 'styles/styled-components/StyledComponents';
import SwitchButton from 'components/SwitchButton';
import {toast} from 'react-toastify';
import CustomToast from 'components/Toast/CustomToast';
import {createReports, editReports} from 'features/reports/reportsApi';
import {AREAS, DEVICE_VALUE, REPORTS, REPORTS_TYPES} from 'constants/reports';
import {useTranslation} from 'react-i18next';

const ManageReportForm = (props) => {
  const {isLoading, reportId, reportDetails} = props;

  const [isSubmit, setIsSubmit] = useState(false);

  const navigate = useNavigate();
  const {t} = useTranslation();

  const {
    setValue,
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: reportId ? reportDetails?.name : '',
      report: REPORTS.find((report) => report.value === reportDetails?.report)?.label,
      area: AREAS.find((area) => area.value === reportDetails?.area)?.label,
      reportType: REPORTS_TYPES.find((reportType) => reportType.value === reportDetails?.reportType)?.label,
      areaDetails: reportId
        ? reportDetails?.areaDetails
        : reportDetails?.device
        ? reportDetails?.device.deviceName
        : AREAS.find((area) => area.value === reportDetails?.area)?.label,
      status: true,
    },
  });

  const handleManageReports = async (formData) => {
    try {
      let selectedReportId;
      setIsSubmit(true);
      formData.report = reportDetails.report;
      formData.area = reportDetails.area;
      formData.reportType = reportDetails.reportType;
      formData.parameters = reportDetails?.parameters.map((parameter) => {
        return parameter._id;
      });
      if (reportDetails.area === DEVICE_VALUE) {
        formData.deviceId = reportDetails.device._id;
      }
      if (reportId) {
        const {message} = await editReports(reportId, formData);
        toast(<CustomToast type="success" title="Success" message={message} />);
        selectedReportId = reportId;
      } else {
        const {data, message} = await createReports(formData);
        toast(<CustomToast type="success" title="Success" message={message} />);
        selectedReportId = data._id;
      }
      navigate(`/app/reports/manage/${selectedReportId}`);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setIsSubmit(false);
    }
  };

  return (
    <>
      <Row className="user-form">
        <Col md="12" lg="6" className="">
          <Row>
            <Col>
              <Form.Group className="mb-3 position-relative" controlId="name">
                <Form.Label>
                  {t('Report Name')}
                  <span className="required" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder={t("Enter Report Name")}
                  className="mb-2"
                  autoComplete="off"
                  {...register('name', {
                    required: t('Report Name is required'),
                  })}
                />
                {errors.name && <StyledErrorLabel>{errors.name.message}</StyledErrorLabel>}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3 position-relative" controlId="report">
                <Form.Label>
                  {t('Report')}
                  <span className="required" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="report"
                  placeholder={t("Enter Report")}
                  className="mb-2"
                  autoComplete="off"
                  disabled={true}
                  {...register('report', {
                    required: t('Report is required'),
                  })}
                />
                {errors.report && <StyledErrorLabel>{errors.report.message}</StyledErrorLabel>}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3 position-relative" controlId="area">
                <Form.Label>
                  {t('Area')}
                  <span className="required" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="area"
                  placeholder={t("Enter Area")}
                  className="mb-2"
                  autoComplete="off"
                  disabled={true}
                  {...register('area', {
                    required: t('Area is required'),
                  })}
                />
                {errors.area && <StyledErrorLabel>{errors.area.message}</StyledErrorLabel>}
              </Form.Group>
            </Col>
          </Row>
        </Col>
        <Col md="12" lg="6" className="">
          <Row>
            <Col>
              <Form.Group className="mb-3 position-relative" controlId="status">
                <Form.Label>
                  {t('Status')}
                  <span className="required" />
                </Form.Label>
                <div className="d-flex">
                  <SwitchButton
                    leftLabel={'Active'}
                    rightLabel={'Inactive'}
                    isDisableInactive={reportId ? true : false}
                    value={reportId ? reportDetails?.status : true}
                    handleSetValue={(value) => setValue('status', value)}
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3 position-relative" controlId="reportType">
                <Form.Label>
                  {t('Report Type')}
                  <span className="required" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="area"
                  placeholder={t("Enter Report Type")}
                  className="mb-2"
                  autoComplete="off"
                  disabled={true}
                  {...register('reportType', {
                    required: t('Report Type is required'),
                  })}
                />
                {errors.reportType && <StyledErrorLabel>{errors.reportType.message}</StyledErrorLabel>}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3 position-relative" controlId="areaDetails">
                <Form.Label>
                  {t('Area Details')}
                  <span className="required" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="area"
                  placeholder={t("Enter Area Details")}
                  className="mb-2"
                  autoComplete="off"
                  disabled={true}
                  {...register('areaDetails', {
                    required: t('Area Detail is required'),
                  })}
                />
                {errors.areaDetails && <StyledErrorLabel>{errors.areaDetails.message}</StyledErrorLabel>}
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* Parameters start */}
      {reportDetails?.parameters.length > 0 && (
        <div className="row">
          <div className="col mb-4">
            <div className="sub-tanent-card-sec mb-2">
              {reportDetails.parameters.map((allSubDeviceType) => {
                return (
                  <div key={allSubDeviceType._id} className={`sub-tanent-card me-3 active`}>
                    {allSubDeviceType.displayName}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
      {/* Parameters end */}
      <Row>
        <Col className="d-flex justify-content-end">
          <Button
            className="btn-primary d-flex align-items-center"
            variant=""
            type="button"
            disabled={isSubmit}
            onClick={handleSubmit(handleManageReports)}
          >
            {reportId ? t('Update') : t('Add')}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ManageReportForm;
