import React from 'react';
import {useSelector} from 'react-redux';
import {Outlet, Navigate, Link, useLocation} from 'react-router-dom';
import logoImg from 'assets/images/logo.png';
import loginBanner from 'assets/images/svg/login-banner.svg';
import optBanner from 'assets/images/svg/otp-banner.svg';
import forgetPasswordBanner from 'assets/images/svg/forgot-password-banner.svg';
import {Container, Row, Col, Image} from 'react-bootstrap';
import {PUBLIC_PREFIX, DASHBOARD, AUTH_LOGIN, AUTH_VERIFY} from 'constants/routesName';
import PublicLanguageSelector from 'components/PublicLanguageSelector';

const PublicRoutes = () => {
  const location = useLocation();
  const {pathname} = location;
  const {isAuthorized, isVerify, user} = useSelector((state) => state.auth);

  let imgPth =
    pathname === `/${PUBLIC_PREFIX}` || pathname === AUTH_LOGIN
      ? loginBanner
      : pathname === AUTH_VERIFY
      ? optBanner
      : forgetPasswordBanner;

  const permissions = user?.permission[0].routeName;

  return isAuthorized && isVerify ? (
    <Navigate to={`/${permissions}`} />
  ) : (
    <div className="login-wrapper">
      <Container>
        <PublicLanguageSelector />
        <Row className="login-row">
          <Col sm={12} md={6} lg={6} xxl={7}>
            <div className="w-100">
              <Link to={`/${PUBLIC_PREFIX}`} className="login-link mb-4 d-inline-block logo" variant="body2">
                <Image src={logoImg} fluid />
              </Link>
            </div>
            <Image src={imgPth} fluid className={pathname === AUTH_VERIFY ? 'otp-img' : 'banner-img'} />
          </Col>
          <Col md={10} lg={6} xxl={5} className="d-flex align-items-center offset-md-1 offset-lg-0">
            <Outlet />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PublicRoutes;
