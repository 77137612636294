import React, {useState, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import {useTranslation} from 'react-i18next';

const SwitchButton = ({leftLabel, rightLabel, value, isDisableInactive, handleSetValue, isDisableActive}) => {
  const [buttonStatus, setButtonStatus] = useState('');

  const {t} = useTranslation();

  function cm(...args) {
    return args.filter((v) => v).join(' ');
  }

  useEffect(() => {
    setButtonStatus(value ? 'active' : 'inactive');
  }, [value]);

  const handleActive = () => {
    setButtonStatus('active');
    handleSetValue(true);
  };

  const handleInactive = () => {
    setButtonStatus('inactive');
    handleSetValue(false);
  };

  return (
    <div className="toggle-button-wrap">
      <Button
        variant="outline-primary"
        className={cm('toggle-button', buttonStatus === 'active' ? 'active-toggle' : 'inactive-toggle')}
        onClick={() => (isDisableActive || isDisableActive == undefined ? handleActive() : null)}
      >
        {t(leftLabel)}
      </Button>
      <Button
        variant="outline-primary"
        className={cm('toggle-button', buttonStatus === 'inactive' ? 'active-toggle' : 'inactive-toggle')}
        onClick={() => (isDisableInactive ? handleInactive() : null)}
      >
        {t(rightLabel)}
      </Button>
    </div>
  );
};

export default SwitchButton;
