import React from 'react';
import moment from 'moment';
import { DAILY, MONTHLY, REPORT_DATE_FORMATE, REPORT_DATE_TIME_FORMATE } from 'constants/reports';

export const group2ParametersColumn = (parameters, reportType) => {
  const dynamicColumns = [
    {
      name: 'Date',
      alias: 'date',
      selector: (row) => {
        return reportType === DAILY
          ? row.date
          : reportType === MONTHLY
          ? moment(row.date).format(REPORT_DATE_FORMATE)
          : moment(row.date).format(REPORT_DATE_TIME_FORMATE);
      },
    },
  ];

  parameters.forEach((param) => {
    let columnName = '';
    let unit = '';
    switch (param) {
      case 'Noise LEQ':
        columnName = 'Noise LEQ (dB)';
        unit = 'dB';
        break;
      case 'Noise Max':
        columnName = 'Noise Max (dB)';
        unit = 'dB';
        break;
      case 'Humidity':
        columnName = 'Humidity (%)';
        unit = '%';
        break;
      case 'Humidity OB':
        columnName = 'Humidity OB (%)';
        unit = '%';
        break;
      default:
        break;
    }

    dynamicColumns.push({
      name: columnName,
      alias: param.toLowerCase(),
      selector: (row) => {
        return <span>{row[`${param}_averageValue`]}</span>;
      },
    });
  });

  return dynamicColumns;
};
