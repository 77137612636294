export const getInitials = (userObject) => {
  if (userObject && userObject.email) {
    let nameInitials = '';
    let emailInitials = '';

    // Extract initials from the name, if available
    if (userObject.name && userObject.name.trim() !== '') {
      const nameParts = userObject.name.split(' ');
      nameInitials = nameParts.map((part) => part.charAt(0).toUpperCase()).join('');
    }

    // Extract initials from the email
    const emailParts = userObject.email.split('@')[0].split('.');
    emailInitials = emailParts.map((part) => part.charAt(0).toUpperCase()).join('');

    // Return name initials if available, otherwise, return email initials
    return nameInitials || emailInitials;
  } else if (userObject && userObject.name && userObject.name.trim() !== '') {
    // Extract initials from the name
    const nameParts = userObject.name.split(' ');
    const nameInitials = nameParts.map((part) => part.charAt(0).toUpperCase()).join('');

    return nameInitials;
  }

  return ''; // Return an empty string if neither email nor name is present
};
