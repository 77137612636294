// Public Routes
export const PUBLIC_PREFIX = 'auth';
export const PRIVATE_PREFIX = 'app';
export const LOGIN = 'login';
export const VERIFY = 'verify';
export const AUTH_LOGIN = `/${PUBLIC_PREFIX}/${LOGIN}`;
export const AUTH_VERIFY = `/${PUBLIC_PREFIX}/${VERIFY}`;
export const REGISTER = 'register';
export const FORGOT_PASSWORD = 'forgot-password';
export const RESET_PASSWORD = 'reset-password';
export const SET_PASSWORD = 'set-password';

// Private Routes
export const UNAUTHORIZED = `/${PRIVATE_PREFIX}/unauthorized`;
export const DASHBOARD = `/${PRIVATE_PREFIX}/dashboard`;
export const DEVICE_DASHBOARD = `/${PRIVATE_PREFIX}/device/dashboard`;
export const ROLES = `/${PRIVATE_PREFIX}/roles`;
export const USERS = `/${PRIVATE_PREFIX}/users`;
export const TENANTS = `/${PRIVATE_PREFIX}/tenants`;
export const MANAGE_TENANTS = `/${PRIVATE_PREFIX}/tenants/manage`;
export const MANAGE_TENANTS_SETTING = `/${PRIVATE_PREFIX}/tenants/setting`;
export const MAP = `/${PRIVATE_PREFIX}/map`;
export const NOTIFICATIONS = `/${PRIVATE_PREFIX}/notifications`;
export const MANAGE_NOTIFICATIONS = `/${PRIVATE_PREFIX}/notifications/manage`;
export const REPORTS = `/${PRIVATE_PREFIX}/reports`;
export const GENERATE_REPORTS = `/${PRIVATE_PREFIX}/reports/generate`;
export const MANAGE_REPORTS = `/${PRIVATE_PREFIX}/reports/manage`;
export const VIEW_REPORTS = `/${PRIVATE_PREFIX}/reports/view`;
export const VIEW_PRINT_REPORTS = `/${PRIVATE_PREFIX}/reports/print`;
export const REPORTS_SUBSCRIBER = `/${PRIVATE_PREFIX}/reports/subscriber`;
