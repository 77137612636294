import React from 'react';
import {Polygon} from '@react-google-maps/api';

const getZoneColor = (zone, selectedZone, isZoneVisible, showZoneColor) => {
  if (isZoneVisible) {
    return selectedZone[0]._id === zone._id ? '#01426A' : '#666562';
  }
  if (showZoneColor) {
    if (selectedZone) {
      return selectedZone[0]._id === zone._id ? selectedZone[0].colorCode : '#B7E6ED';
    }
    return zone.colorCode ? zone.colorCode : '#666562';
  }
  return '#666562';
};

const getZoneOpacity = (zone, selectedZone, isZoneVisible, showZoneColor) => {
  if (isZoneVisible) {
    return selectedZone[0]._id === zone._id ? 0.6 : 0.3;
  }
  if (showZoneColor) {
    if (selectedZone) {
      return selectedZone[0]._id === zone._id ? 0.6 : 0.3;
    }
    return 0.3;
  }
  return 0.3;
};



const DashboardMapZones = ({zones, selectedZone, isZoneVisible, showZoneColor, handleZoneClick}) => {
  return (
    <>
      {zones.map((zone) => {
        return (
          <Polygon
            key={zone._id}
            options={{
              fillOpacity: getZoneOpacity(zone, selectedZone, isZoneVisible, showZoneColor),
              strokeWeight: 1.5,
              fillColor: getZoneColor(zone, selectedZone, isZoneVisible, showZoneColor),
            }}
            path={zone.coordinates}
            onClick={() => handleZoneClick(zone)}
          />
        );
      })}
    </>
  );
};

export default React.memo(DashboardMapZones);
