export const RED = '#FF0000';
export const AMBER = '#FFA500';
export const GREEN = '#008000';
export const PURPLE = '#800080';
export const BROWN = '#800000';
export const GREY = '#8A8A8A';

export const SEVERITY_HASH_COLORS = {
  low: GREEN,
  medium: AMBER,
  high: RED,
};

export const SEVERITY_COLORS = {
  low: 'green',
  medium: 'amber',
  high: 'red',
};
