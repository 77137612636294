import moment from "moment";

export const formateDateTimeChart = (duration, customDate, prevFilterDuration, params) => {
if (duration) {
  const [quantity, unit] = duration.split(' ');
  if (quantity === 'custom') {
    if (!customDate.startDate && !customDate.startTime) {
      if (prevFilterDuration) {
        const [quantity, unit] = prevFilterDuration.split(' ');
        if (unit === 'month') {
          return moment(params).format('DD-MMM-YYYY');
        } else if (unit === 'day') {
          return moment(params).format('DD-MMM-YYYY');
        } else {
          return moment(params).format('hh:mm A');
        }
      }
      return moment(params).format('DD-MMM-YY hh:mm A');
    }
    if (customDate.startTime) {
      return moment(params).format('DD-MMM-YY hh:mm A');
    }
    return moment(params).format('DD-MMM-YY hh:mm A');
  }
  if (unit === 'month') {
    return moment(params).format('DD-MMM-YYYY');
  } else if (unit === 'day') {
    return moment(params).format('DD-MMM-YYYY');
  } else {
    return moment(params).format('hh:mm A');
  }
} else {
  if (moment(params).isValid()) {
    return moment(params).format('hh:mm A');
  } else {
    return moment(params, 'HH:mm:ss').format('hh:mm A');
  }
}
}