import React from 'react';
import {Form, Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import downloadImg from 'assets/images/svg/download.svg';
import resetFilterImg from 'assets/images/svg/refresh-icon.svg';
import {AREAS, REPORTS, REPORTS_TYPES} from 'constants/reports';
import {PrimaryButton, StyledLabel} from 'styles/styled-components/StyledComponents';

const ReportFilter = (props) => {
  const {filters, handleFilterChange, handleFilter, handleClearFilter, handleDownloadCSV} = props;
  const {t} = useTranslation();
  return (
    <>
      <div className="filter-card">
        <div className="row">
          <div className="col d-flex justify-content-between align-items-end flex-column flex-md-row">
            <div className="d-flex flex-wrap align-items-end mob-100 w-100">
              <Form.Group className="me-0 me-md-2 mb-3 mob-100 flex-fill" controlId="report">
                <StyledLabel>{t('Report')}</StyledLabel>
                <Form.Select name="report" value={filters.report} onChange={handleFilterChange}>
                  <option value="">All</option>
                  {REPORTS.map((report, index) => (
                    <option key={index} value={report.value}>
                      {report.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="me-0 me-md-2 mb-3 mob-100 flex-fill" controlId="reportType">
                <StyledLabel>{t('Report Type')}</StyledLabel>
                <Form.Select name="reportType" value={filters.reportType} onChange={handleFilterChange}>
                  <option value="">All</option>
                  {REPORTS_TYPES.map((reportType, index) => (
                    <option key={index} value={reportType.value}>
                      {reportType.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="me-0 me-md-2 mb-3 mob-100 flex-fill" controlId="area">
                <StyledLabel>{t('Area')}</StyledLabel>
                <Form.Select name="area" value={filters.area} onChange={handleFilterChange}>
                  <option value="">All</option>
                  {AREAS.map((area, index) => (
                    <option key={index} value={area.value}>
                      {area.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="me-0 me-md-2 mb-3 mob-100 flex-fill" controlId="name">
                <StyledLabel>{t('Report Name')}</StyledLabel>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder={t("Enter Report Name")}
                  autoComplete="off"
                  value={filters.name}
                  onChange={handleFilterChange}
                />
              </Form.Group>
              <PrimaryButton variant="outline-primary" className="apply me-2 mb-3" onClick={handleFilter}>
                {t('Apply')}
              </PrimaryButton>
              <Button variant="outline-primary" className="mb-3" type="button" onClick={handleClearFilter}>
                <img src={resetFilterImg} alt="Filter" />
              </Button>
              <div className="ms-5">
                <Button variant="outline-primary" className="mb-3" type="button" onClick={handleDownloadCSV}>
                  <img src={downloadImg} alt="download" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportFilter;
