export const calculateCenterLocation = (coordinates) => {
  let sumLat = 0;
  let sumLng = 0;

  coordinates.forEach((coord) => {
    const lat = coord.location?.lat || coord.masterDevice.location.lat;
    const lng = coord.location?.long || coord.masterDevice.location.long;
    sumLat += parseFloat(lat);
    sumLng += parseFloat(lng);
  });

  const avgLat = sumLat / coordinates.length;
  const avgLng = sumLng / coordinates.length;

  return {lat: avgLat, lng: avgLng};
};


export const calculateZoneCenterLocation = (coordinates) => {
  let sumLat = 0;
  let sumLng = 0;

  coordinates.forEach((coord) => {
    const lat = coord.lat;
    const lng = coord.lng;
    sumLat += parseFloat(lat);
    sumLng += parseFloat(lng);
  });

  const avgLat = sumLat / coordinates.length;
  const avgLng = sumLng / coordinates.length;

  return {lat: avgLat, lng: avgLng};
};
