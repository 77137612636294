import React, {useState, useEffect, useMemo, useRef} from 'react';
import {useForm} from 'react-hook-form';
import {PAGE_DISPLAY} from 'constants/datatable';
import {Button, Form, OverlayTrigger, Tooltip} from 'react-bootstrap';
import Select from 'react-select';
import {toast} from 'react-toastify';
import Loader from 'assets/images/loader.gif';
import CustomToast from 'components/Toast/CustomToast';
import {fetchTenantSubDevices} from 'features/tenant/tenantApi';
import {conditionalRowStyles} from 'utils/tableRowsColor';
import {StyledErrorLabel} from 'styles/styled-components/StyledComponents';
import {ControlStyle, MultiOptionStyle, SelectContainerStyle} from 'styles/styled-components/StyledReactSelect';
import {fetchNotificationsThresholdByFrequency} from 'features/notification/notificationApi';
import CustomDataTable from 'components/datatable';
import {notificationThresholdListColumn} from 'components/datatable/columns/notificationThresholdListColumn';
import useGetTableList from 'hooks/table/useGetTableList';
import {useStateAsync} from 'hooks/useStateAsync';
import CustomModal from 'components/shared/CustomModal';
import DiscardAlert from 'components/modal/DiscardAlert';
import {convertMinutesToHoursMinutes} from 'utils/convertMinutesToHoursMinutes';
import SwitchButton from 'components/SwitchButton';
import {
  editSettingNotificationThreshold,
  fetchSettingNotificationThreshold,
  fetchSettingNotificationsThresholdById,
} from 'features/tenant/settingApi';
import {useTranslation} from 'react-i18next';

const notificationChannelOptions = [
  {value: 'email', label: 'Email'},
  {value: 'sms', label: 'Sms'},
  {value: 'vala', label: 'Vala'},
];

const ManageNotificationThresholdSettings = ({
  tenantId,
  notificationId,
  selectedNotification,
  isNotificationUpdate,
  setIsNotificationUpdate,
}) => {
  const {
    handleSubmit,
    setError,
    setValue,
    register,
    reset,
    formState: {errors, isDirty},
  } = useForm();
  const [isDiscard, setIsDiscard] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [selectedEdit, setSelectedEdit] = useState([]);
  const [deviceParameters, setDeviceParameters] = useState([]);
  const [selectedDeviceParameter, setSelectedDeviceParameter] = useState('');
  const [dataTransmissionFrequency, setDataTransmissionFrequency] = useState('');
  const [notificationChannels, setNotificationChannels] = useState([[], [], []]);
  const [allFrequencyValues, setAllFrequencyValues] = useState([]);
  const [selectedFrequencyValues, setSelectedFrequencyValues] = useState([]);
  const [showThreshold, setShowThreshold] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [thresholdStatus, setThresholdStatus] = useState({
    low: true,
    medium: true,
    high: true,
  });
  const [filters, setFilters] = useStateAsync({
    notificationId: notificationId,
    tenantId: tenantId,
    key: '',
    value: '',
    pageSize: PAGE_DISPLAY[0], // per page data
    pageNumber: 1, // current page number
  });
  const numberOfPagesRef = useRef(1);
  const applyFilterRef = useRef(false);
  const scrollTargetRef = useRef(null);

  const {t} = useTranslation();

  const {tableData, isLoading, getTablelist, handleSort, handlePageChange, handleRowsPerPageChange} = useGetTableList(
    fetchSettingNotificationThreshold,
    numberOfPagesRef,
    setFilters,
    applyFilterRef,
  );

  useEffect(() => {
    if (notificationId && selectedNotification) {
      getDeviceParameters();
      getFrequencyValues();
      getTablelist(filters);
    }
  }, [notificationId, selectedNotification]);

  useEffect(() => {
    (async () => {
      if (isNotificationUpdate) {
        await getTablelist(filters);
        setIsNotificationUpdate(false);
      }
    })();
  }, [isNotificationUpdate]);

  const getDeviceParameters = async () => {
    try {
      const {data} = await fetchTenantSubDevices(selectedNotification.deviceTypeId._id);
      setDeviceParameters(data);
      const frequency = convertMinutesToHoursMinutes(selectedNotification.deviceTypeId.transmissionFrequency);
      setDataTransmissionFrequency(frequency);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    }
  };

  const getFrequencyValues = async () => {
    try {
      const {data} = await fetchNotificationsThresholdByFrequency(notificationId);
      setAllFrequencyValues(data);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    }
  };

  const onSubmit = async (data) => {
    try {
      setIsSubmit(true);
      const deviceSubTypeId = data.device_parameter_low;
      const thresholdData = ['low', 'medium', 'high'].map((severity) => {
        const thresholdItem = {
          notificationId,
          deviceSubTypeId: deviceSubTypeId,
          condition: data[`threshold_condition_${severity}`],
          value: data[`threshold_value_${severity}`],
          severity,
          notificationChannel: data[`notification_channel_${severity}`],
          notificationFrequencyUnit: data.notificationFrequencyUnit,
          notificationFrequencyValue: data.notificationFrequencyValue,
          status: thresholdStatus[severity],
          tenantId: tenantId,
        };

        if (selectedEdit.length > 0) {
          const existingThreshold = selectedEdit.find((item) => item.severity === severity);
          thresholdItem._id = existingThreshold._id;
          thresholdItem.deviceSubTypeId = existingThreshold.deviceSubTypeId;
        }

        return thresholdItem;
      });
      if (selectedEdit.length > 0) {
        const {message} = await editSettingNotificationThreshold(notificationId, {threshold: thresholdData});
        toast(<CustomToast type="success" title="Success" message={message} />);
      }
      resetNotificationForm();
      setShowThreshold();
      getTablelist(filters);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setIsSubmit(false);
    }
  };

  const resetNotificationForm = () => {
    reset((formValues) => ({
      device_parameter_low: '',
      threshold_condition_low: '',
      threshold_value_low: '',
      notification_channel_low: [],
      device_parameter_medium: '',
      threshold_condition_medium: '',
      threshold_value_medium: '',
      notification_channel_medium: [],
      device_parameter_high: '',
      threshold_condition_high: '',
      threshold_value_high: '',
      notification_channel_high: [],
      notificationFrequencyValue: '',
      notificationFrequencyUnit: '',
    }));
    setSelectedDeviceParameter('');
    setNotificationChannels([[], [], []]);
    setSelectedEdit([]);
    setThresholdStatus({
      low: true,
      medium: true,
      high: true,
    });
  };

  const handleChannel = (selected, event, threshold) => {
    const options = notificationChannelOptions;
    let values = [];
    if (event.action === 'select-option' && event.option.value === 'all') {
      values = options;
    } else if (
      (event.action === 'deselect-option' && event.option.value === 'all') ||
      (event.action === 'remove-value' && event.removedValue.value === 'all')
    ) {
      values = [];
    } else if (event.action === 'deselect-option' || event.action === 'remove-value') {
      values = selected ? selected.filter((select) => select.value !== 'all') : [];
    } else if (selected && selected.length === options.length) {
      values = options;
    } else {
      values = selected;
    }
    const sentToIds = values.map((selectedSent) => selectedSent.value);
    setValue(`notification_channel_${threshold}`, sentToIds);
    const errorMsg = sentToIds.length === 0 ? t('Notification Channel is required') : '';
    setError(`notification_channel_${threshold}`, {
      message: errorMsg,
    });
    const thresholdIndex = ['low', 'medium', 'high'].indexOf(threshold);
    const updatedNotificationChannels = [...notificationChannels];
    updatedNotificationChannels[thresholdIndex] = selected;
    setNotificationChannels(updatedNotificationChannels);
  };

  const handleEdit = async (row) => {
    try {
      setIsEditLoading(true);
      resetNotificationForm();
      await getFrequencyValues();
      const frequencyValues = allFrequencyValues[row.notificationFrequencyUnit];
      const values = frequencyValues ? frequencyValues : [];
      setSelectedFrequencyValues(values);
      const {data} = await fetchSettingNotificationsThresholdById(notificationId, row.perameterId, tenantId);
      setSelectedEdit(data);
      const updatedNotificationChannels = [[], [], []];
      const device_parameter = row.perameter;
      data.forEach((item) => {
        const severity = item.severity;
        setValue(`device_parameter_${severity}`, device_parameter);
        setValue(`threshold_condition_${severity}`, item.condition);
        setValue(`threshold_value_${severity}`, item.value);
        setValue(`notification_channel_${severity}`, item.notificationChannel);
        const notificationChannelValues = item.notificationChannel.map((channel) => ({
          value: channel,
          label: channel.charAt(0).toUpperCase() + channel.slice(1),
        }));
        const thresholdIndex = ['low', 'medium', 'high'].indexOf(severity);
        updatedNotificationChannels[thresholdIndex] = notificationChannelValues;
        setThresholdStatus((prevStatus) => ({
          ...prevStatus,
          [severity]: item.status,
        }));
      });
      setValue(`notificationFrequencyValue`, row.notificationFrequencyValue);
      setValue(`notificationFrequencyUnit`, row.notificationFrequencyUnit);
      setNotificationChannels(updatedNotificationChannels);
      setSelectedDeviceParameter(device_parameter);
      setShowThreshold(true);
      if (scrollTargetRef.current) {
        scrollTargetRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setIsEditLoading(false);
    }
  };

  const handleChangeStatus = async (status, severity) => {
    try {
      setValue(`status_${severity}`, severity);
      setThresholdStatus((prevStatus) => ({
        ...prevStatus,
        [severity]: status,
      }));
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    }
  };

  const handleFrequency = (event) => {
    const type = event.target.value;
    const frequencyValues = allFrequencyValues[type];
    const values = frequencyValues ? frequencyValues : [];
    setValue('notificationFrequencyValue', '');
    setSelectedFrequencyValues(values);
    const errorMsg = type.length === 0 ? t('Notification Frequency Unit is required') : '';
    setError(`notificationFrequencyUnit`, {
      message: errorMsg,
    });
  };

  const handleCloseButton = () => {
    if (isDirty) {
      setIsDiscard(true);
    } else {
      resetNotificationForm();
      setShowThreshold();
    }
  };

  const hanldeDiscardAllModal = () => {
    setIsDiscard(false);
    resetNotificationForm();
  };

  const hanldeDiscard = () => {
    setIsDiscard(false);
  };

  const memoizedTableData = useMemo(() => tableData, [tableData]);
  const columns = useMemo(() => notificationThresholdListColumn(filters, handleEdit, t), [filters, handleEdit, t]);

  const isDisabled = isSubmit;

  return (
    <>
      <h4 className="section-subheading mb-3" ref={scrollTargetRef}>
        {t('Threshold Settings')}
      </h4>
      {isEditLoading && (
        <>
          <div className="loader-warp">
            <img src={Loader} alt="loader" />
          </div>
        </>
      )}
      {showThreshold && (
        <div className="row user-form">
          <div className="col-12">
            {['low', 'medium', 'high'].map((threshold, index) => {
              const thresholdNotificationChannels = notificationChannels[index];
              return (
                <div className="row" key={index}>
                  <div className="col-12 col-md-12 col-lg-12 col-xl-6 ">
                    <Form.Group className="mb-3" controlId="thresholdCondition">
                      {index === 0 && (
                        <Form.Label>
                          {t('Threshold Condition')}
                          <span className="required" />
                        </Form.Label>
                      )}
                      <div className="row">
                        <div className="col-12 col-md-4 col-lg-4 col-xl-4">
                          <Form.Group className="position-relative">
                            <Form.Control
                              className="me-2 mb-2"
                              type="text"
                              autoComplete="off"
                              placeholder={t("Select Parameters")}
                              disabled
                              value={selectedDeviceParameter}
                              {...register(`device_parameter_${threshold}`)}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-12 col-md-4 col-lg-5 col-xl-5">
                          <Form.Group className="position-relative">
                            <Form.Select
                              className="me-2 mb-2"
                              aria-label={t("Threshold Condition")}
                              {...register(`threshold_condition_${threshold}`, {
                                required: t('Threshold Condition is required'),
                              })}
                            >
                              <option value={''}>{t('Select Condition')}</option>
                              <option value="gt">{t('is greater than')}</option>
                              <option value="lt">{t('is less than')}</option>
                              <option value="gte">{t('is greater than equal to')}</option>
                              <option value="lte">{t('is less than equal to')}</option>
                            </Form.Select>
                            {errors[`threshold_condition_${threshold}`] && (
                              <StyledErrorLabel>{errors[`threshold_condition_${threshold}`].message}</StyledErrorLabel>
                            )}
                          </Form.Group>
                        </div>
                        <div className="col-12 col-md-4 col-lg-3 col-xl-3">
                          <Form.Group className="position-relative">
                            <Form.Control
                              className="mb-2"
                              type="text"
                              autoComplete="off"
                              placeholder=""
                              {...register(`threshold_value_${threshold}`, {
                                required: t('Threshold Value is required'),
                                pattern: {
                                  value: /^\d+(\.\d{1,2})?$/, // Regular expression to match numbers with up to two decimal digits
                                  message: t('Invalid number format.'),
                                },
                              })}
                            />
                            {errors[`threshold_value_${threshold}`] && (
                              <StyledErrorLabel>{errors[`threshold_value_${threshold}`].message}</StyledErrorLabel>
                            )}
                          </Form.Group>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 col-xl-1 ">
                    <Form.Group className="mb-3 position-relative" controlId="severity">
                      {index === 0 && <Form.Label>{t('Severity')}</Form.Label>}
                      <OverlayTrigger
                        placement={'bottom'}
                        overlay={<Tooltip>{threshold.replace(/^./, threshold[0].toUpperCase())}</Tooltip>}
                      >
                        <Form.Control
                          className="mb-2 text-center"
                          aria-label="Severity"
                          type="text"
                          autoComplete="off"
                          disabled
                          value={threshold.charAt(0).toUpperCase()}
                        />
                      </OverlayTrigger>
                    </Form.Group>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 col-xl-3">
                    <Form.Group className="mb-3 position-relative" controlId="notificationChannel">
                      {index === 0 && (
                        <Form.Label>
                          {t('Notification Channel')}
                          <span className="required" />
                        </Form.Label>
                      )}
                      <Select
                        {...register(`notification_channel_${threshold}`, {
                          required: t('Notification Channel is required'),
                        })}
                        isMulti
                        classNames="mb-2"
                        name={`notification_channel_${threshold}`}
                        options={[
                          {label: `${t('Select all')} (${notificationChannelOptions.length})`, value: 'all'},
                          ...notificationChannelOptions,
                        ]}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder={t('Select Notification Channel')}
                        value={thresholdNotificationChannels}
                        styles={{
                          container: SelectContainerStyle,
                          option: MultiOptionStyle,
                          control: ControlStyle,
                        }}
                        onChange={(selected, event) => handleChannel(selected, event, threshold)}
                      />
                      {errors[`notification_channel_${threshold}`] && (
                        <StyledErrorLabel className="mt-0">
                          {errors[`notification_channel_${threshold}`].message}
                        </StyledErrorLabel>
                      )}
                    </Form.Group>
                  </div>
                  <div className="col-12 col-md-4 col-lg-2 col-xl-2 d-flex align-items-end switch-btn">
                    <SwitchButton
                      leftLabel={'Active'}
                      rightLabel={'Inactive'}
                      isDisableInactive={selectedEdit.length > 0 ? true : false}
                      value={thresholdStatus[threshold]}
                      handleSetValue={(status) => handleChangeStatus(status, threshold)}
                      isDisableActive={selectedEdit.length > 0 ? true : false}
                    />
                  </div>
                </div>
              );
            })}
            <div className="row mt-2">
              <div className="col-12 col-md-6 col-lg-3 col-xl-3">
                <Form.Group className="mb-3 position-relative" controlId="dataTransmissionFrequency">
                  <Form.Label>{t('Data Transmission Frequency')}</Form.Label>
                  <Form.Control
                    type="text"
                    className="mb-2"
                    autoComplete="off"
                    defaultValue={dataTransmissionFrequency}
                    placeholder={t("Enter Transmission Frequency")}
                    disabled
                    {...register(`dataTransmissionFrequency`)}
                  />
                  {errors.dataTransmissionFrequency && (
                    <StyledErrorLabel>{errors.dataTransmissionFrequency.message}</StyledErrorLabel>
                  )}
                </Form.Group>
              </div>
              <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                <Form.Group className="mb-3" controlId="notificationFrequency">
                  <Form.Label>
                    {t('Notification Frequency')}
                    <span className="required" />
                  </Form.Label>

                  <div className="row">
                    <div className="col-6 col-md-6 col-lg-6 col-xl-6">
                      <Form.Group className="mb-3 position-relative">
                        <Form.Select
                          {...register(`notificationFrequencyUnit`, {
                            required: t('Notification Frequency Unit is required'),
                          })}
                          className="mb-2"
                          aria-label={t("Notification Frequency Unit")}
                          onChange={handleFrequency}
                        >
                          <option value={''}>{t('Select Frequency Unit')}</option>
                          <option value="minutes">{t('Minutes')}</option>
                          <option value="hours">{t('Hours')}</option>
                          <option value="days">{t('Days')}</option>
                        </Form.Select>
                        {errors.notificationFrequencyUnit && (
                          <StyledErrorLabel className="mt-0">
                            {errors.notificationFrequencyUnit.message}
                          </StyledErrorLabel>
                        )}
                      </Form.Group>
                    </div>
                    <div className="col-6 col-md-6 col-lg-6 col-xl-6">
                      <Form.Group className="mb-3 position-relative">
                        <Form.Select
                          {...register(`notificationFrequencyValue`, {
                            required: t('Notification Frequency Value is required'),
                          })}
                          // className="mb-2"
                          aria-label={t("Notification Frequency Value")}
                        >
                          <option value={''}>{t('Select Frequency Value')}</option>
                          {selectedFrequencyValues.map((selectedFrequencyValue) => (
                            <option key={selectedFrequencyValue} value={selectedFrequencyValue}>
                              {selectedFrequencyValue}
                            </option>
                          ))}
                        </Form.Select>
                        {errors.notificationFrequencyValue && (
                          <StyledErrorLabel className="mt-0">
                            {errors.notificationFrequencyValue.message}
                          </StyledErrorLabel>
                        )}
                      </Form.Group>
                    </div>
                  </div>
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="col-12 d-flex justify-content-end">
                <Button
                  variant="outline-primary"
                  className="mb-3 me-2 effect-btn wrap-adjust"
                  type="button"
                  onClick={handleCloseButton}
                >
                  {t('Cancel')}
                </Button>
                <Button
                  variant="primary"
                  className="mb-3 me-2 effect-btn wrap-adjust"
                  type="submit"
                  disabled={isDisabled}
                  onClick={handleSubmit(onSubmit)}
                >
                  {t('Update')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="mt-4">
        <CustomDataTable
          columns={columns}
          data={memoizedTableData}
          loading={isLoading}
          handleSort={handleSort}
          pagination={true}
          numberOfPages={numberOfPagesRef.current}
          rowPerPage={filters.pageSize}
          conditionalRowStyles={conditionalRowStyles}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
        />
      </div>
      {isDiscard && (
        <CustomModal
          size="sm"
          show={isDiscard}
          handleClose={hanldeDiscard}
          body={<DiscardAlert />}
          buttons={[
            {label: t('Yes'), type: 'primary', onClickHandler: hanldeDiscardAllModal},
            {label: t('No'), type: 'secondary', onClickHandler: hanldeDiscard},
          ]}
        />
      )}
    </>
  );
};

export default ManageNotificationThresholdSettings;
