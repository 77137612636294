import React from 'react';
import DashboardMapMarkers from './DashboardMapMarkers';
import DashboardMapZones from './DashboardMapZones';
const geolib = require('geolib');

const DashboardMap = (props) => {
  const {
    devices,
    zones,
    deviceId,
    selectedDevice,
    selectedZone,
    showZoneColor,
    isZoneVisible,
    isPolygonVisible,
    clusteringLevel,
    handleSelectDevice,
    handleMapZoneClick,
  } = props;

  const handleMarkerClick = (marker) => {
    handleSelectDevice(marker);
  };

  const handleZoneClick = (p) => {
    let str = `${p.name}`;
    const found = devices
      .filter((marker) =>
        geolib.isPointInPolygon(
          {
            latitude: parseFloat(marker.masterDevice.location.lat),
            longitude: parseFloat(marker.masterDevice.location.long),
          },
          p.coordinates,
        ),
      )
      .map((marker) => marker.masterDevice.deviceId);
    // console.log("p ==> ", p._id)
    handleMapZoneClick(p);
    //     alert(`Zone: ${str}

    // Devices:
    // ------------------------------------------------
    // ${found.join('\n')}
    // ------------------------------------------------`);
  };

  return (
    <>
      <DashboardMapMarkers
        devices={devices}
        zones={zones}
        deviceId={deviceId}
        selectedDevice={selectedDevice}
        clusteringLevel={clusteringLevel}
        handleMarkerClick={handleMarkerClick}
      />
      {isPolygonVisible && (
        <DashboardMapZones
          zones={zones}
          selectedZone={selectedZone}
          isZoneVisible={isZoneVisible}
          showZoneColor={showZoneColor}
          handleZoneClick={handleZoneClick}
        />
      )}
    </>
  );
};

export default DashboardMap;
