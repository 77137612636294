import api from 'api/axios';
import {formatFilter} from 'utils/formatFilter';

export const fetchSettingNotifications = async (filters) => {
  try {
    const filterParams = formatFilter(filters);
    // Make an API request with the filter values
    const response = await api.get(`/tenant/setting`, {
      params: filterParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSettingTagName = async () => {
  try {
    const response = await api.get(`/tenant/setting/device/tag`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchSettingTenantDeviceType = async () => {
  try {
    const response = await api.get(`${process.env.REACT_APP_API_URL}/tenant/setting/device/type`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchSettingTenantSubDevices = async (deviceTypeId) => {
  try {
    const response = await api.get(`${process.env.REACT_APP_API_URL}/device/sub-type?deviceTypeId=${deviceTypeId}`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchSettingNotificationType = async () => {
  try {
    const response = await api.get(`/tenant/setting/notification/type`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchSettingNotificationThreshold = async (filters) => {
  try {
    const filterParams = formatFilter(filters);
    // Make an API request with the filter values
    const response = await api.get(`/tenant/setting/threshold`, {
      params: filterParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchSettingNotificationsThresholdById = async (notificationId, thresholdId, tenantId) => {
  try {
    const response = await api.get(
      `/tenant/setting/threshold/${notificationId}?deviceSubTypeId=${thresholdId}&tenantId=${tenantId}`,
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editSettingNotificationThreshold = async (id, payload) => {
  try {
    const response = await api.put(`/tenant/setting/threshold/${id}`, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchSettingNotificationCsvContent = async (filters) => {
  try {
    const filterParams = formatFilter(filters);

    const response = await api.get(`tenant/setting/export`, {
      params: filterParams,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
