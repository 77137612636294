import React from 'react';
import {useTranslation} from 'react-i18next';
import Loader from 'assets/images/loader.gif';
import CustomDataTable from 'components/datatable';
import {conditionalRowStyles} from 'utils/tableRowsColor';

const GroupTypeTables = (props) => {
  const {reportType, isLoading, columns, data, trend} = props;
  const {t} = useTranslation();
  return (
    <>
      <div className="card rounded-1 mt-3">
        <div className="card-body">
          <div>
            {`${reportType ? t(`${reportType} Overview`) : ''}`}:{' '}
            <span>
              {trend} {t('Trend')}
            </span>
          </div>
          {isLoading ? (
            <>
              <div className="loader-warp">
                <img src={Loader} alt="loader" />
              </div>
            </>
          ) : (
            <CustomDataTable
              columns={columns}
              data={data}
              loading={isLoading}
              pagination={false}
              conditionalRowStyles={conditionalRowStyles}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default GroupTypeTables;
