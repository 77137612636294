import React, {useEffect} from 'react';
import {toast} from 'react-toastify';
import {LANGUAGES} from 'constants/locales';
import {useDispatch, useSelector} from 'react-redux';
import CustomToast from 'components/Toast/CustomToast';
import {setPublicLanguage} from 'features/language/languageSlice';
import {useTranslation} from 'react-i18next';
import LanguageDropdown from 'components/LanguageDropdown';

const PublicLanguageSelector = () => {
  const {i18n, t} = useTranslation();
  const {publicSelectedLanguage} = useSelector((state) => state.language);

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      await initializeLanguage();
    })();
  }, []);

  const initializeLanguage = async () => {
    dispatch(setPublicLanguage(publicSelectedLanguage));
    i18n.changeLanguage(publicSelectedLanguage.value);
    document.documentElement.lang = publicSelectedLanguage.value;
  };

  const handleLanguageChange = async (selectedLanguage) => {
    try {
      const language = LANGUAGES.find((lang) => lang.value === selectedLanguage.value);
      dispatch(setPublicLanguage(language));
      i18n.changeLanguage(language.value);
      document.documentElement.lang = language.value;
      toast(<CustomToast type="success" title="Success" message={t('Language Updated Successfully')} />);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    }
  };

  return (
    <>
      <LanguageDropdown language={publicSelectedLanguage} handleLanguageChange={handleLanguageChange} />
    </>
  );
};

export default React.memo(PublicLanguageSelector);
