export const convertMinutesToHoursMinutes = (minutes) => {
  if (minutes === 'null' || minutes <= 0) {
    return '0 minutes';
  }

  const days = Math.floor(minutes / 1440); // 1440 minutes in a day
  const hours = Math.floor((minutes % 1440) / 60);
  const remainingMinutes = minutes % 60;

  let formattedTime = '';

  if (days > 0) {
    formattedTime += `${days} ${days === 1 ? 'day' : 'days'} `;
  }

  if (hours > 0) {
    formattedTime += `${hours} ${hours === 1 ? 'hour' : 'hours'} `;
  }

  if (remainingMinutes > 0) {
    formattedTime += `${remainingMinutes} ${remainingMinutes === 1 ? 'minute' : 'minutes'}`;
  }

  return formattedTime.trim();
};
