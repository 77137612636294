import React, {useState, useRef, useMemo} from 'react';
import {toast} from 'react-toastify';
import {PAGE_DISPLAY} from 'constants/datatable';
import CustomDataTable from 'components/datatable';
import ManageRoleModal from 'components/modal/ManageRoleModal';
import CustomModal from 'components/shared/CustomModal';
import DeleteModal from 'components/modal/DeleteModal';
import CustomToast from 'components/Toast/CustomToast';
import {addRole, deleteRole, editRole, fetchRoleCsvContent, fetchRoles} from 'features/role/roleApi';
import {conditionalRowStyles} from 'utils/tableRowsColor';
import {downloadFile} from 'utils/downloadFile';
import {useCustomEffect} from 'hooks/useCustomEffect';
import {useStateAsync} from 'hooks/useStateAsync';
import useGetTableList from 'hooks/table/useGetTableList';
import {roleListColumn} from 'components/datatable/columns/roleListColumn';
import RoleListFilter from 'pages/ManageRolesPage/RoleListFilter';
import {
  addTenantRole,
  deleteTenantRole,
  editTenantRole,
  fetchTenantRoleCsvContent,
  fetchTenantRoles,
} from 'features/tenant/tenantApi';
import {SOMETHING_WENT_ERROR} from 'constants/apiNetwork';
import {usePermission} from 'hooks/usePermission';
import {useTranslation} from 'react-i18next';

const ManageRoleContainer = React.memo((props) => {
  const {isTenantManagePage, tenantId, showRoleModal, setShowRoleModal} = props;
  const [selectedRole, setSelectedRole] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [filters, setFilters] = useStateAsync({
    filterName: '',
    status: '',
    key: '',
    value: '',
    pageSize: PAGE_DISPLAY[0], // per page data
    pageNumber: 1, // current page number
  });
  const numberOfPagesRef = useRef(1);
  const applyFilterRef = useRef(false);

  const {t} = useTranslation();

  const isEditAllow = isTenantManagePage ? false : usePermission(`app/roles`, 'edit');
  const isDeleteAllow = isTenantManagePage ? false : usePermission(`app/roles`, 'delete');

  const {tableData, isLoading, getTablelist, handleSort, handlePageChange, handleRowsPerPageChange} = useGetTableList(
    isTenantManagePage ? fetchTenantRoles : fetchRoles,
    numberOfPagesRef,
    setFilters,
    applyFilterRef,
    isTenantManagePage ? tenantId : null,
  );

  useCustomEffect(() => {
    getTablelist(filters);
  }, []);

  const handleEdit = (row) => {
    setSelectedRole(row);
    setShowRoleModal(true);
  };

  const handleDelete = (row) => {
    setShowDelete(true);
    setSelectedRole(row);
  };

  const handleDeleteCloseModal = () => {
    setShowDelete(false);
    setSelectedRole(null);
  };

  const handleDeleteRole = async () => {
    try {
      setIsSubmit(true);
      const {_id} = selectedRole;
      let message;
      if (isTenantManagePage) {
        const tenant = {
          data: {tenantId: tenantId},
        };
        const response = await deleteTenantRole(_id, tenant);
        message = response.message;
      } else {
        const response = await deleteRole(_id);
        message = response.message;
      }
      toast(<CustomToast type="success" title="Success" message={message} />);
      await getTablelist(filters);
      handleDeleteCloseModal();
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setIsSubmit(false);
    }
  };

  const handleAddEdit = async (formData, action) => {
    try {
      setIsSubmit(true);
      if (isTenantManagePage) {
        await handleTenantRoleAddEdit(formData, action);
        return;
      }
      if (action === 'add') {
        const {message} = await addRole(formData);
        toast(<CustomToast type="success" title="Success" message={message} />);
      } else {
        const {message} = await editRole(selectedRole._id, formData);
        toast(<CustomToast type="success" title="Success" message={message} />);
      }
      await getTablelist(filters);
      handleClose();
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setIsSubmit(false);
    }
  };

  const handleTenantRoleAddEdit = async (formData, action) => {
    try {
      formData.tenantId = tenantId;
      if (action === 'add') {
        const {message} = await addTenantRole(formData);
        toast(<CustomToast type="success" title="Success" message={message} />);
      } else {
        const {message} = await editTenantRole(selectedRole._id, formData);
        toast(<CustomToast type="success" title="Success" message={message} />);
      }
      await getTablelist(filters);
      handleClose();
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    }
  };

  const handleClose = () => {
    setSelectedRole(null);
    setShowRoleModal(false);
  };

  const handleFilter = () => {
    applyFilterRef.current = true;
    setFilters(
      (prevFilters) => ({
        filterName: prevFilters.filterName,
        status: prevFilters.status === 'all' ? '' : prevFilters.status,
        key: '',
        value: '',
        pageSize: prevFilters.pageSize,
        pageNumber: 1,
      }),
      (newValue) => {
        getTablelist(newValue);
      },
    );
  };

  const handleFilterChange = (event) => {
    const {name, value} = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleClearFilter = () => {
    applyFilterRef.current = false;
    setFilters(
      (prevFilters) => ({
        ...prevFilters,
        filterName: '',
        status: '',
        key: '',
        value: '',
        pageSize: prevFilters.pageSize,
        pageNumber: 1,
      }),
      (newValue) => {
        getTablelist(newValue);
      },
    );
  };

  const handleDownloadCSV = async () => {
    try {
      const downloadWithFilter = {};
      if (applyFilterRef.current) {
        downloadWithFilter['filterName'] = filters.filterName;
        downloadWithFilter['key'] = filters.key;
        downloadWithFilter['value'] = filters.value;
        if (filters.status !== 'all') {
          downloadWithFilter['status'] = filters.status;
        }
      }
      const currentDate = new Date().toLocaleString();

      if (isTenantManagePage) {
        const csvData = await fetchTenantRoleCsvContent(tenantId, downloadWithFilter);
        downloadFile(csvData, `tsn-tenant-role-${currentDate}.csv`);
        return;
      }
      const csvData = await fetchRoleCsvContent(downloadWithFilter);
      downloadFile(csvData, `tsn-role-${currentDate}.csv`);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={t(SOMETHING_WENT_ERROR)} />);
    }
  };

  const columns = useMemo(
    () => roleListColumn(filters, isEditAllow, isDeleteAllow, handleEdit, handleDelete, t),
    [filters, isEditAllow, isDeleteAllow, handleEdit, handleDelete, t],
  );

  return (
    <>
      <RoleListFilter
        filters={filters}
        isTenantManagePage={isTenantManagePage}
        setShowRoleModal={setShowRoleModal}
        handleFilterChange={handleFilterChange}
        handleFilter={handleFilter}
        handleClearFilter={handleClearFilter}
        handleDownloadCSV={handleDownloadCSV}
      />
      <CustomDataTable
        columns={columns}
        data={tableData}
        loading={isLoading}
        handleSort={handleSort}
        pagination={true}
        numberOfPages={numberOfPagesRef.current}
        rowPerPage={filters.pageSize}
        conditionalRowStyles={conditionalRowStyles}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
      />
      {showRoleModal && (
        <ManageRoleModal
          show={showRoleModal}
          selectedRole={selectedRole}
          isSubmit={isSubmit}
          isTenantManagePage={isTenantManagePage}
          handleAddEdit={handleAddEdit}
          handleClose={handleClose}
        />
      )}
      {showDelete && (
        <CustomModal
          size="sm"
          show={showDelete}
          handleClose={handleDeleteCloseModal}
          body={<DeleteModal deletedItem={selectedRole && selectedRole.name} />}
          buttons={[
            {label: t('Yes'), type: 'primary', onClickHandler: handleDeleteRole},
            {label: t('No'), type: 'secondary', onClickHandler: handleDeleteCloseModal},
          ]}
        />
      )}
    </>
  );
});

export default ManageRoleContainer;
