import api from 'api/axios';

export const fetchLanguage = async () => {
    try {
      const response = await api.get(`/common/user-language`);
  
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  export const updateLanguage = async (payload) => {
    try {
      const response = await api.put(`/common/user-language`, payload);
  
      return response.data;
    } catch (error) {
      throw error;
    }
  };