const errorRed = '#DE0000';
const prussianBlue = '#01426a';
const white = '#FFFFFF';
const lightGray = '#ECECEC';

export default {
  errorRed,
  prussianBlue,
  white,
  lightGray,
};
