import {
  GOOD_COLOR,
  HAZARDOUS_COLOR,
  MODERATE_COLOR,
  UNHEALTHY_COLOR,
  UNHEALTHY_FOR_SENSITIVE_GROUPS_COLOR,
  VERY_UNHEALTHY_COLOR,
} from 'constants/ChartsColor';
import React from 'react';

export default () => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.3239 0.959717C29.6209 0.959717 33.8414 2.09611 37.5575 4.25363C41.2735 6.41115 44.3529 9.51305 46.4832 13.2447L25.3239 25.3243V0.959717Z"
      fill={GOOD_COLOR}
    />
    <path
      d="M46.3832 13.1004C48.5316 16.8216 49.6578 21.0449 49.6473 25.3419C49.6369 29.6388 48.4902 33.8566 46.3237 37.5674L25.2828 25.2826L46.3832 13.1004Z"
      fill={MODERATE_COLOR}
    />
    <path
      d="M25.3239 49.689C29.6209 49.689 33.8414 48.5526 37.5575 46.3951C41.2735 44.2375 44.3529 41.1356 46.4832 37.4039L25.3239 25.3244L25.3239 49.689Z"
      fill={UNHEALTHY_FOR_SENSITIVE_GROUPS_COLOR}
    />
    <path
      d="M25.3245 49.689C21.0276 49.689 16.807 48.5526 13.091 46.3951C9.37494 44.2375 6.29557 41.1356 4.16521 37.4039L25.3245 25.3244L25.3245 49.689Z"
      fill={UNHEALTHY_COLOR}
    />
    <path
      d="M4.18228 13.1004C2.0338 16.8216 0.907673 21.0449 0.918119 25.3419C0.928566 29.6388 2.07521 33.8566 4.24176 37.5674L25.2826 25.2826L4.18228 13.1004Z"
      fill={VERY_UNHEALTHY_COLOR}
    />
    <path
      d="M25.3148 0.777344C21.0178 0.777344 16.7973 1.91373 13.0812 4.07125C9.36517 6.22877 6.28581 9.33068 4.15545 13.0624L25.3148 25.1419V0.777344Z"
      fill={HAZARDOUS_COLOR}
    />
  </svg>
);
