import {createAsyncThunk} from '@reduxjs/toolkit';
import api from 'api/axios';

export const login = createAsyncThunk('auth/login', async (payload) => {
  try {
    const response = await api.post(`${process.env.REACT_APP_API_URL}/login`, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
});

export const loginVerify = createAsyncThunk('auth/verify', async (payload) => {
  try {
    const response = await api.post(`${process.env.REACT_APP_API_URL}/login/verify-otp`, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
});

export const reportVerify = createAsyncThunk('auth/verify', async (token) => {
  try {
    const response = await api.get(`/login/verify-report-token`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
});

export const resendOtp = async (payload) => {
  try {
    const response = await api.post(`${process.env.REACT_APP_API_URL}/login/re-send-otp`, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const forgotPassword = async (payload) => {
  try {
    const response = await api.post(`${process.env.REACT_APP_API_URL}/login/forgot-password`, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (token, payload) => {
  try {
    const response = await api.post(`${process.env.REACT_APP_API_URL}/login/reset-password/${token}`, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const verifyPasswordToken = async (payload) => {
  try {
    const response = await api.post(`${process.env.REACT_APP_API_URL}/login/verify-token`, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};
