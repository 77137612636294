import React from 'react';
import {useTranslation} from 'react-i18next';
import deleteBigImg from 'assets/images/svg/delete-xl.svg';

const DeleteModal = (props) => {
  const {deletedItem} = props;
  const {t} = useTranslation();
  return (
    <>
      <div className="d-flex flex-column text-center">
        <img className="mb-2 delete-larg m-auto" src={deleteBigImg} alt="Delete" />
        <span className="sub-heading d-block mb-2">{t('Are You Sure?')}</span>
        <p>
          {t('You want to delete the')} <span className="text-primary d-inline-block text-medium">{deletedItem}</span>.
        </p>
      </div>
    </>
  );
};

export default DeleteModal;
