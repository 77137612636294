import React from 'react';
import {Link} from 'react-router-dom';
import leftarrowImg from 'assets/images/svg/left-arrow.svg';

const Breadcrumbs = ({breadcrumbs, title}) => {
  return (
    <>
      <div className="mob-100">
        <ul>
          {breadcrumbs.map((breadcrumb, index) => {
            return (
              <li key={index}>
                <Link to={breadcrumb.url} className="breadcrum-link" variant="breadcrum">
                  <img className="me-2" src={leftarrowImg} alt="Add" />
                  {breadcrumb.label}
                </Link>
              </li>
            );
          })}
        </ul>
        <h2 className="page-title">{title}</h2>
      </div>
    </>
  );
};

export default Breadcrumbs;
