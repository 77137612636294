import moment from 'moment';
import i18n from 'i18next';

export const getParameterOption = (
  chartType,
  data,
  duration = null,
  isToolbar = true,
  customDate = null,
  prevFilterDuration = null,
) => {
  return {
    dataset: [
      {
        dimensions: ['name', 'value', 'colorCode'],
        source: data,
      },
      {
        transform: {
          type: 'sort',
          config: {dimension: 'name', order: 'asc'},
        },
      },
    ],
    series: {
      itemStyle: {
        color: function (param) {
          return param.data.colorCode ? param.data.colorCode : '#9C9796';
        },
      },
      type: chartType,
      encode: {x: 'name', y: 'value'},
      datasetIndex: 1,
      smooth: false,
    },
    grid: {
      bottom: '26%',
    },
    xAxis: {
      type: 'category',
      axisLabel: {
        rotate: data.length > 10 ? 45 : 0,
        fontSize: 10,
        formatter: (params) => {
          if (duration) {
            const [quantity, unit] = duration.split(' ');
            if (quantity === 'custom') {
              if (!customDate.startDate && !customDate.startTime) {
                if(prevFilterDuration) {
                  const [quantity, unit] = prevFilterDuration.split(' ');
                  if (unit === 'month') {
                    return moment(params).format('DD-MMM-YYYY');
                  } else if (unit === 'day') {
                    return moment(params).format('DD-MMM-YYYY');
                  } else {
                    return moment(params).format('hh:mm A');
                  }
                }
                return moment(params).format('DD-MMM-YY hh:mm A');
              }
              if (customDate.startTime) {
                return moment(params).format('DD-MMM-YY hh:mm A');
              }
              return moment(params).format('DD-MMM-YY hh:mm A');
            }
            if (unit === 'month') {
              return moment(params).format('DD-MMM-YYYY');
            } else if (unit === 'day') {
              return moment(params).format('DD-MMM-YYYY');
            } else {
              return moment(params).format('hh:mm A');
            }
          } else {
            if (moment(params).isValid()) {
              return moment(params).format('hh:mm A');
            } else {
              return moment(params, 'HH:mm:ss').format('hh:mm A');
            }
          }
        },
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        color: '#333',
        fontSize: 12,
      },
    },
    animation: true,
    legend: {},
    toolbox: {
      show: isToolbar,
      feature: {
        magicType: {
          show: true,
          type: ['line', 'bar'],
          title: {
            line: i18n.t('Switch to Line Chart'),
            bar: i18n.t('Switch to Bar Chart'),
          },
        },
      },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'none',
      },
      // Configure tooltip appearance
      backgroundColor: 'rgba(50, 50, 50, 0.7)', // Tooltip background color
      borderColor: '#333', // Border color
      borderWidth: 1, // Border width
      textStyle: {
        color: '#fff', // Text color
        fontSize: 12, // Font size
        fontWeight: 'bold', // Font weight
        fontFamily: 'Arial, sans-serif', // Font family
      },
    },
  };
};
