import React from 'react';

export default (props) => (
  <svg width={props.width || "30"} height={props.height || "30"} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2474_1730)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 7.5C0 5.51088 0.790176 3.60322 2.1967 2.1967C3.60322 0.790176 5.51088 0 7.5 0L22.5 0C24.4891 0 26.3968 0.790176 27.8033 2.1967C29.2098 3.60322 30 5.51088 30 7.5V22.5C30 24.4891 29.2098 26.3968 27.8033 27.8033C26.3968 29.2098 24.4891 30 22.5 30H7.5C5.51088 30 3.60322 29.2098 2.1967 27.8033C0.790176 26.3968 0 24.4891 0 22.5V7.5ZM13.1175 7.80937C13.0773 7.53828 13.096 7.26171 13.1723 6.9985C13.2487 6.73529 13.3809 6.49163 13.5599 6.28412C13.7389 6.0766 13.9605 5.91012 14.2097 5.79599C14.4589 5.68187 14.7297 5.6228 15.0037 5.6228C15.2778 5.6228 15.5486 5.68187 15.7978 5.79599C16.047 5.91012 16.2686 6.0766 16.4476 6.28412C16.6266 6.49163 16.7588 6.73529 16.8352 6.9985C16.9115 7.26171 16.9302 7.53828 16.89 7.80937L15 20.625L13.1175 7.80937ZM15 20.625C15.4973 20.625 15.9742 20.8225 16.3258 21.1742C16.6775 21.5258 16.875 22.0027 16.875 22.5C16.875 22.9973 16.6775 23.4742 16.3258 23.8258C15.9742 24.1775 15.4973 24.375 15 24.375C14.5027 24.375 14.0258 24.1775 13.6742 23.8258C13.3225 23.4742 13.125 22.9973 13.125 22.5C13.125 22.0027 13.3225 21.5258 13.6742 21.1742C14.0258 20.8225 14.5027 20.625 15 20.625Z"
        fill={props.color || "#F70000"}
      />
    </g>
    <defs>
      <clipPath id="clip0_2474_1730">
        <rect width="30" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
