import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'assets/styles/style.scss';
import store from 'store';
import Routes from 'router';
import {Provider} from 'react-redux';
import {persistStore} from 'redux-persist';
import StyledToastContainer from 'styles/styled-components/StyledToastContainer';
import {PersistGate} from 'redux-persist/integration/react';
import ThemeProvider from 'react-bootstrap/ThemeProvider';

const persistor = persistStore(store);

function App() {
  return (
    <ThemeProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes />
          <StyledToastContainer
            toastClassName="tsn-toast-container"
            position="top-right"
            closeOnClick={true}
            autoClose={5000}
            hideProgressBar={true}
            closeButton={false}
          />
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
