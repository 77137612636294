import React, {useState, useRef, useEffect, useCallback} from 'react';
import {toast} from 'react-toastify';
import {useDispatch} from 'react-redux';
import {useNavigate, useLocation} from 'react-router-dom';
import {unwrapResult} from '@reduxjs/toolkit';
import {resetLogin} from 'features/auth/authSlice';
import {login, loginVerify} from 'features/auth/authApi';
import LoginForm from 'components/forms/LoginForm';
import VerifyForm from 'components/forms/VerifyForm';
import CustomToast from 'components/Toast/CustomToast';
import {AUTH_VERIFY, AUTH_LOGIN, PUBLIC_PREFIX} from 'constants/routesName';
import {rememberMe} from 'utils/rememberMe';

const LoginPage = () => {
  const [loginData, setLoginData] = useState(null);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const effectRun = useRef(false);
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!effectRun.current) {
      if (pathname == `/${PUBLIC_PREFIX}` || pathname == AUTH_LOGIN) {
        dispatch(resetLogin());
      }
      if (!user) {
        navigate(AUTH_LOGIN, {replace: true});
      }
    }
    return () => {
      effectRun.current = true;
    };
  }, [pathname]);

  const handleLogin = useCallback(async (formData) => {
    try {
      setIsLoading(true);
      const {email, password, remember} = formData;
      const resultAction = await dispatch(login({email, password}));
      const {data, message} = unwrapResult(resultAction);
      setLoginData(formData)
      setUser(data);
      navigate(AUTH_VERIFY, {replace: true});
      toast(<CustomToast type="success" title="Success" message={message} />);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error?.message} />);
    } finally {
      setIsLoading(false);
    }
  });

  const handleOTP = useCallback(async (otp) => {
    try {
      setIsLoading(true);
      const {password, remember} = loginData;
      const {email} = user;
      const resultAction = await dispatch(loginVerify({email, otp}));
      const {message} = unwrapResult(resultAction);
      await rememberMe(remember, {email, password});
      toast(<CustomToast type="success" title="Success" message={message} />);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error?.message} />);
    } finally {
      setIsLoading(false);
    }
  });

  return pathname == AUTH_VERIFY && user ? (
    <VerifyForm user={user} isLoading={isLoading} onSubmit={handleOTP} />
  ) : (
    <LoginForm isLoading={isLoading} onSubmit={handleLogin} />
  );
};

export default LoginPage;
