import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Marker, InfoWindow} from '@react-google-maps/api';
import {INFOWINDOW_OFFSET, INFOWINDOW_OFFSET_SELECTED} from 'constants/map';

const Map = (props) => {
  const {devices, deviceId, selectedDevice, clusterer, handleSelectDevice} = props;

  const [hoveredMarker, setHoveredMarker] = useState(null);
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [infoWindowPosition, setInfoWindowPosition] = useState({lat: 0, lng: 0});
  const {t} = useTranslation();
  const handleMarkerClick = (marker) => {
    handleSelectDevice(marker);
  };

  const getMarkerIcon = (marker, selectedDevice) => {
    const deviceType = marker.deviceAliasName;
    const isSelected = selectedDevice && selectedDevice.deviceId === marker.deviceId;
    if (marker.isNew && !isSelected) {
      return '/markers/default.gif';
    } else if (marker.isNew && isSelected) {
      return '/markers/default-selected.gif';
    } else if (!marker.isNew && deviceType && !isSelected) {
      return `/markers/${marker.deviceAliasName}.svg`;
    } else {
      return `/markers/${marker.deviceAliasName}-selected.svg`;
    }
  };

  const handleMouseOver = React.useCallback((marker) => {
    setHoveredMarker(marker);
    setInfoWindowPosition({
      lat: parseFloat(marker.location.lat),
      lng: parseFloat(marker.location.long),
    });
    setShowInfoWindow(true);
  }, []);

  const handleMouseOut = React.useCallback(() => {
    setShowInfoWindow(false);
  }, []);

  return (
    <>
      {devices.map((marker, ind) => {
        const lat = marker.location.lat;
        const lng = marker.location.long;
        const icon = getMarkerIcon(marker, selectedDevice);

        return (
          <Marker
            key={marker.deviceId}
            position={{lat: parseFloat(lat), lng: parseFloat(lng)}}
            icon={{
              url: icon,
            }}
            clusterer={clusterer}
            onClick={() => (!deviceId ? handleMarkerClick(marker) : null)}
            onMouseOver={() => handleMouseOver(marker)}
            onMouseOut={handleMouseOut}
          />
        );
      })}
      {/* InfoWindow for displaying marker details */}
      {hoveredMarker && (
        <InfoWindow
          position={{
            lat: infoWindowPosition.lat,
            lng: infoWindowPosition.lng,
          }}
          options={{
            pixelOffset: new window.google.maps.Size(
              0,
              selectedDevice?._id === hoveredMarker._id ? INFOWINDOW_OFFSET_SELECTED : INFOWINDOW_OFFSET,
            ),
          }}
          onCloseClick={() => setHoveredMarker(null)}
        >
          <div className="map-marker-infoWindo">
            <p className="title">{t('Device Name')}</p>
            <p className="device-name">{hoveredMarker.deviceId}</p>
          </div>
        </InfoWindow>
      )}
    </>
  );
};

export default React.memo(Map);
