import i18n from 'i18next';

export const filterDurationFormate = (filterDuration) => {
  if (filterDuration === 'custom') {
    // let dateRange = null;
    // if (dates.startDate && dates.endDate) {
    //   dateRange = `${moment(dates.startDate).format('YYYY-MM-DD')} - ${moment(dates.endDate).format('YYYY-MM-DD')}`
    // }
    // if (startTime && endTime) {
    //   dateRange = `${dateRange} ${startTime.format('HH:mm')} - ${endTime.format('HH:mm')}`;
    // }
    // return dateRange;
    return i18n.t('Custom');
  } else {
    const duration = i18n.t('Last') + ' ' + i18n.t(filterDuration);
    return duration;
  }
};
