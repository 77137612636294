import React, {useState, useEffect, useRef, useMemo} from 'react';
import {toast} from 'react-toastify';
import {PAGE_DISPLAY} from 'constants/datatable';
import CustomToast from 'components/Toast/CustomToast';
import useGetTableList from 'hooks/table/useGetTableList';
import {conditionalRowStyles} from 'utils/tableRowsColor';
import SubTenantListFilter from 'pages/ManageTenantsPage/SubTenants/SubTenantListFilter';
import {useStateAsync} from 'hooks/useStateAsync';
import {deleteSubTenant, fetchSubTenantDeviceCsvContent, fetchSubTenants} from 'features/tenant/tenantApi';
import CustomDataTable from 'components/datatable';
import {subTenantListColumn} from 'components/datatable/columns/subTenantListColumn';
import {SOMETHING_WENT_ERROR} from 'constants/apiNetwork';
import {downloadFile} from 'utils/downloadFile';
import CustomModal from 'components/shared/CustomModal';
import DeleteModal from 'components/modal/DeleteModal';
import {useTranslation} from 'react-i18next';

const SubTenantContainer = (props) => {
  const [selectedSubTenant, setSelectedSubTenant] = useState(null);
  const [showDelete, setShowDelete] = useState(false);

  const {t} = useTranslation();

  const {
    filters,
    setFilters,
    tenantId,
    deviceId,
    numberOfPagesRef,
    applyFilterRef,
    handleEditSubTenant,
    tableData,
    isLoading,
    getTablelist,
    handleSort,
    handlePageChange,
    handleRowsPerPageChange,
  } = props;

  useEffect(() => {
    getTablelist(filters);
  }, []);

  const handleEdit = (row) => {
    handleEditSubTenant(row)
  };

  const handleDelete = (row) => {
    setShowDelete(true);
    setSelectedSubTenant(row);
  };

  const handleDeleteCloseModal = () => {
    setShowDelete(false);
    setSelectedSubTenant(null);
  };

  const handleDeleteSubTenant = async () => {
    try {
      const {_id} = selectedSubTenant;
      const tenant = {
        data: {tenantId: tenantId},
      };
      const {message} = await deleteSubTenant(_id, tenant);
      toast(<CustomToast type="success" title="Success" message={message} />);
      await getTablelist(filters);
      handleDeleteCloseModal();
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    }
  };

  const handleFilter = () => {
    applyFilterRef.current = true;
    setFilters(
      (prevFilters) => ({
        filterName: prevFilters.filterName,
        status: prevFilters.status === 'all' ? '' : prevFilters.status,
        key: '',
        value: '',
        pageSize: prevFilters.pageSize,
        pageNumber: 1,
      }),
      (newValue) => {
        getTablelist(newValue);
      },
    );
  };

  const handleFilterChange = (event) => {
    const {name, value} = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleClearFilter = () => {
    applyFilterRef.current = false;
    setFilters(
      (prevFilters) => ({
        ...prevFilters,
        filterName: '',
        status: '',
        key: '',
        value: '',
        pageSize: prevFilters.pageSize,
        pageNumber: 1,
      }),
      (newValue) => {
        getTablelist(newValue);
      },
    );
  };

  const handleDownloadCSV = async () => {
    try {
      const downloadWithFilter = {};
      if (applyFilterRef.current) {
        downloadWithFilter['filterName'] = filters.filterName;
        downloadWithFilter['key'] = filters.key;
        downloadWithFilter['value'] = filters.value;
        if (filters.status !== 'all') {
          downloadWithFilter['status'] = filters.status;
        }
      }
      if (deviceId) {
        downloadWithFilter['deviceId'] = deviceId;
      }
      const csvData = await fetchSubTenantDeviceCsvContent(tenantId, downloadWithFilter);
      const currentDate = new Date().toLocaleString();
      downloadFile(csvData, `tsn-sub-tenant-${currentDate}.csv`);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error || t(SOMETHING_WENT_ERROR)} />);
    }
  };

  const columns = useMemo(
    () => subTenantListColumn(filters, handleEdit, handleDelete, t),
    [filters, handleEdit, handleDelete, t],
  );

  return (
    <>
      <SubTenantListFilter
        filters={filters}
        handleFilterChange={handleFilterChange}
        handleFilter={handleFilter}
        handleClearFilter={handleClearFilter}
        handleDownloadCSV={handleDownloadCSV}
      />
      <CustomDataTable
        columns={columns}
        data={tableData}
        loading={isLoading}
        handleSort={handleSort}
        pagination={true}
        numberOfPages={numberOfPagesRef.current}
        rowPerPage={filters.pageSize}
        conditionalRowStyles={conditionalRowStyles}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
      />
      {showDelete && (
        <CustomModal
          size="sm"
          show={showDelete}
          handleClose={handleDeleteCloseModal}
          body={<DeleteModal deletedItem={selectedSubTenant && selectedSubTenant.subTenantName} />}
          buttons={[
            {label: t('Yes'), type: 'primary', onClickHandler: handleDeleteSubTenant},
            {label: t('No'), type: 'secondary', onClickHandler: handleDeleteCloseModal},
          ]}
        />
      )}
    </>
  );
};

export default SubTenantContainer;
