import React, {useState} from 'react';
import PageInnerHeader from 'components/PageInnerHeader';
import ManageUserContainer from  'pages/ManageUsersPage/ManageUserContainer';
import { usePermission } from 'hooks/usePermission';
import {useTranslation} from 'react-i18next';

const ManageUsersPage = React.memo(() => {
  const [showUserModal, setShowUserModal] = useState(false);
  const isCreateAllow = usePermission(`app/users`, 'create');

  const {t} = useTranslation();

  const handleAddUserModal = () => {
    setShowUserModal(true);
  };

  return (
    <>
      <PageInnerHeader
        title={t('Manage Internal Users / Manage Employees')}
        buttonLabel={t('Add New User')}
        isCreateAllow={isCreateAllow}
        handleAddButton={handleAddUserModal}
      />
      <ManageUserContainer
        showUserModal={showUserModal}
        setShowUserModal={setShowUserModal}
        isTenantManagePage={false}
      />
    </>
  );
});

export default ManageUsersPage;
