import CryptoJS from 'crypto-js';
import {REMEMBER} from 'constants/login';

export const rememberMe = (remember, data) => {
  if (remember) {
    const encryptedData = encryptData(data);
    localStorage.setItem(REMEMBER, encryptedData);
  } else {
    localStorage.removeItem(REMEMBER);
  }
};

export const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_SECRET_PASS).toString();
};

export const decryptData = (data) => {
  const bytes = CryptoJS.AES.decrypt(data, process.env.REACT_APP_SECRET_PASS);

  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};
