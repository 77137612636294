import moment from 'moment';

export const getMinutesFromSelection = (selectedValue) => {
  const currentDate = new Date();
  let timeDifferenceInMilliseconds = 0;

  const [quantity, unit] = selectedValue.split(' ');
  const parsedQuantity = parseInt(quantity);

  switch (unit) {
    case 'minute':
      timeDifferenceInMilliseconds = parsedQuantity * 60 * 1000;
      break;
    case 'hour':
      timeDifferenceInMilliseconds = parsedQuantity * 60 * 60 * 1000;
      break;
    case 'day':
      timeDifferenceInMilliseconds = parsedQuantity * 24 * 60 * 60 * 1000;
      break;
    case 'week':
      timeDifferenceInMilliseconds = parsedQuantity * 7 * 24 * 60 * 60 * 1000;
      break;
    case 'month':
      const daysInMonth = 30; // Average days in a month
      timeDifferenceInMilliseconds = parsedQuantity * daysInMonth * 24 * 60 * 60 * 1000;
      break;
    case 'year':
      timeDifferenceInMilliseconds = parsedQuantity * 365 * 24 * 60 * 60 * 1000;
      break;
    default:
      break;
  }

  const pastDate = new Date(currentDate.getTime() - timeDifferenceInMilliseconds);
  const minutesDifference = (currentDate - pastDate) / (1000 * 60); // Difference in minutes

  return {minutes: minutesDifference, unit: unit};
};

export const getDateFromSelection = (selectedDate) => {
  let startDate, endDate;
  const [quantity, unit] = selectedDate.split(' ');

  switch (unit) {
    case 'minute':
      startDate = moment().subtract(quantity, 'minutes').utc().format('YYYY-MM-DDTHH:mm:ss').toString();
      break;
    case 'hour':
      startDate = moment().subtract(quantity, 'hours').utc().format('YYYY-MM-DDTHH:mm:ss').toString();
      break;
    case 'day':
      startDate = moment().subtract(quantity, 'days').utc().format('YYYY-MM-DDTHH:mm:ss').toString();
      break;
    case 'week':
      startDate = moment().subtract(quantity, 'weeks').utc().format('YYYY-MM-DDTHH:mm:ss').toString();
      break;
    case 'month':
      startDate = moment().subtract(quantity, 'months').utc().format('YYYY-MM-DDTHH:mm:ss').toString();
      break;
    default:
      startDate = moment().utc().format('YYYY-MM-DDTHH:mm:ss').toString(); // Default to current date/time
      break;
  }

  endDate = moment().utc().format('YYYY-MM-DDTHH:mm:ss').toString(); // End date is always current date/time

  return {startDate: `${startDate}`, endDate: `${endDate}`};
};
