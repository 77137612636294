import React from 'react';

export default (props) => (
  <svg
    width={props.width || '30'}
    height={props.height || '30'}
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width={'34'} height={'34'} rx="9" fill={props.color || '#800000'} />
    <g clipPath="url(#clip0_4658_1163)">
      <path
        d="M17 6.0003C18.3709 6.0003 19.5117 7.01722 19.6047 8.40121C19.6341 8.85758 19.5166 9.33379 19.4284 9.79016C18.7724 13.2973 18.1016 16.7994 17.4505 20.3065C17.4113 20.5099 17.3379 20.5545 17.1518 20.5545C16.5986 20.5446 16.5888 20.5545 16.486 20.0089C15.874 16.7796 15.262 13.5502 14.6549 10.3209C14.5814 9.92409 14.5276 9.52229 14.4395 9.13041C14.0625 7.46862 15.3942 5.9755 17 6.0003Z"
        fill="white"
      />
      <path
        d="M14.2534 25.2076C14.2534 23.6649 15.4872 22.4247 17.0049 22.4297C18.5325 22.4347 19.7516 23.6698 19.7467 25.2175C19.7467 26.7603 18.5178 28.0004 16.9952 27.9954C15.4774 27.9954 14.2534 26.7553 14.2534 25.2076Z"
        fill="white"
      />
      <path
        d="M13.4015 9.20508C13.4995 9.67633 13.5974 10.1277 13.7002 10.604C13.4505 10.6684 13.2106 10.7329 12.9707 10.7974C10.4542 11.4869 8.26078 12.7568 6.38073 14.5873C6.25833 14.7063 6.12614 14.8254 5.97926 14.8998C5.67571 15.0585 5.33299 14.9643 5.15184 14.7014C4.94131 14.3988 4.956 13.9672 5.23018 13.7291C5.99395 13.0644 6.73813 12.3649 7.55576 11.7746C9.26934 10.5345 11.1788 9.71105 13.2302 9.22988C13.2693 9.21996 13.3183 9.215 13.4015 9.20508Z"
        fill="white"
      />
      <path
        d="M20.3098 10.4992C20.393 10.0379 20.4763 9.60633 20.5644 9.11523C21.1911 9.29877 21.7982 9.45255 22.3857 9.65097C24.7945 10.4695 26.8949 11.7989 28.7113 13.5996C28.7211 13.6095 28.7358 13.6244 28.7456 13.6343C29.0393 13.9369 29.0834 14.3932 28.8484 14.6363C28.5595 14.9339 28.0846 14.9389 27.7762 14.6214C26.7921 13.6244 25.6954 12.7761 24.4763 12.0916C23.2376 11.3971 21.9255 10.8911 20.5497 10.5637C20.4763 10.5488 20.4077 10.524 20.3098 10.4992Z"
        fill="white"
      />
      <path
        d="M14.1849 13.1433C13.4358 13.4062 12.721 13.6245 12.0307 13.9122C10.5619 14.5273 9.27423 15.4251 8.13837 16.5512C7.95232 16.7347 7.74179 16.8488 7.4872 16.8339C7.21303 16.8191 7.0025 16.695 6.90948 16.4172C6.80177 16.1147 6.83604 15.8319 7.07105 15.5988C7.88867 14.7803 8.77974 14.061 9.75893 13.4558C10.9927 12.6968 12.3048 12.1413 13.7149 11.8188C13.7687 11.8039 13.8275 11.8039 13.9058 11.7891C13.9988 12.2554 14.0967 12.7117 14.1849 13.1433Z"
        fill="white"
      />
      <path
        d="M19.8152 13.1086C19.8935 12.687 19.9719 12.2554 20.0551 11.7842C20.2558 11.8288 20.4419 11.8635 20.6279 11.9082C23.0122 12.5431 25.093 13.7337 26.8605 15.4748C26.8898 15.5046 26.9192 15.5294 26.9486 15.5641C27.2032 15.8617 27.213 16.338 26.9682 16.581C26.694 16.8539 26.2436 16.834 25.9302 16.5612C25.3133 16.0254 24.716 15.4599 24.0551 14.9887C22.8311 14.1057 21.4553 13.5353 19.9914 13.188C19.9523 13.1781 19.9131 13.1682 19.8739 13.1582C19.8592 13.1533 19.8495 13.1384 19.8152 13.1086Z"
        fill="white"
      />
      <path
        d="M14.3906 14.3789C14.5032 14.8849 14.5962 15.3264 14.6892 15.753C14.1458 15.9514 13.6121 16.125 13.0931 16.3383C11.923 16.8294 10.89 17.5338 9.98421 18.4317C9.79816 18.6202 9.59253 18.7591 9.31836 18.7243C9.04908 18.6896 8.84345 18.5458 8.75532 18.2779C8.65251 17.9803 8.69168 17.7074 8.91689 17.4792C9.9989 16.3929 11.2425 15.5496 12.6525 14.9742C13.2106 14.7509 13.7884 14.5823 14.3906 14.3789Z"
        fill="white"
      />
      <path
        d="M19.3599 15.7185C19.4431 15.2671 19.5214 14.8256 19.6096 14.3643C20.4174 14.5428 21.1714 14.8157 21.9058 15.1629C23.0514 15.7036 24.0845 16.408 25.0196 17.2662C25.0637 17.3059 25.1077 17.3406 25.142 17.3852C25.4064 17.6729 25.4211 18.1492 25.1763 18.3972C24.9315 18.6551 24.4615 18.67 24.1726 18.422C23.8935 18.1789 23.634 17.916 23.3501 17.6878C22.2191 16.7652 20.9413 16.1352 19.5361 15.7731C19.4823 15.7582 19.4284 15.7433 19.3599 15.7185Z"
        fill="white"
      />
      <path
        d="M18.8557 18.3123C18.9438 17.846 19.0222 17.4045 19.1103 16.9531C20.6917 17.3797 22.0577 18.1437 23.2523 19.235C23.5607 19.5177 23.5852 20.0138 23.3257 20.2816C23.0662 20.5495 22.5815 20.5545 22.2878 20.2717C21.4506 19.4483 20.491 18.8282 19.3747 18.4711C19.2082 18.4115 19.0467 18.3669 18.8557 18.3123Z"
        fill="white"
      />
      <path
        d="M14.8997 16.998C14.9829 17.4643 15.0661 17.9058 15.1347 18.2828C14.4737 18.5854 13.8226 18.8235 13.235 19.1757C12.7357 19.4734 12.3146 19.9 11.8593 20.272C11.7907 20.3266 11.732 20.401 11.6585 20.4506C11.3354 20.6738 10.9633 20.644 10.7479 20.3911C10.4884 20.0785 10.4884 19.642 10.7675 19.3593C11.9229 18.2035 13.2938 17.4395 14.8997 16.998Z"
        fill="white"
      />
      <path
        d="M15.399 19.5869C15.4871 20.078 15.5655 20.5344 15.6438 20.9659C15.2227 21.1792 14.8213 21.3529 14.4443 21.5761C14.1554 21.7497 13.9057 21.9977 13.6267 22.1912C13.47 22.3003 13.2937 22.4045 13.1126 22.4392C12.8384 22.4938 12.5985 22.3946 12.4614 22.1218C12.3194 21.844 12.2998 21.5414 12.5153 21.3132C13.2986 20.4749 14.2386 19.8796 15.399 19.5869Z"
        fill="white"
      />
      <path
        d="M18.3562 20.9513C18.4394 20.49 18.5178 20.0534 18.6059 19.5723C18.885 19.6665 19.1493 19.7409 19.399 19.8451C20.1383 20.1477 20.7944 20.5892 21.377 21.1398C21.6904 21.4374 21.7295 21.9285 21.47 22.2063C21.2008 22.4841 20.7063 22.4841 20.4174 22.1815C19.8495 21.5813 19.1738 21.1696 18.3562 20.9513Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_4658_1163">
        <rect width="30" height="30" fill="white" transform="translate(5 6)" />
      </clipPath>
    </defs>
  </svg>
);
