import {useState} from 'react';

const useStateAsync = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const setValueAndCallback = (newValue, callback) => {
    setValue((prevValue) => {
      const updatedValue = typeof newValue === 'function' ? newValue(prevValue) : newValue;
      if (callback) {
        callback(updatedValue, prevValue);
      }
      return updatedValue;
    });
  };

  return [value, setValueAndCallback];
};

export {useStateAsync};
