import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import Select from 'react-select';
import Loader from 'assets/images/loader.gif';
import {useNavigate} from 'react-router-dom';
import {Button, Form, Row, Col} from 'react-bootstrap';
import {StyledErrorLabel, StyledLabel} from 'styles/styled-components/StyledComponents';
import SwitchButton from 'components/SwitchButton';
import {useCustomEffect} from 'hooks/useCustomEffect';
import {fetchTenantDeviceType, getTagName} from 'features/tenant/tenantApi';
import {toast} from 'react-toastify';
import CustomToast from 'components/Toast/CustomToast';
import {useTranslation} from 'react-i18next';
import {addNotification, editNotification, fetchNotificationType} from 'features/notification/notificationApi';
import {ControlStyle, MultiOptionStyle, SelectContainerStyle} from 'styles/styled-components/StyledReactSelect';

const sendToOptions = [
  {value: 'VALA Users', label: 'VALA Users'},
  {value: 'Tenants', label: 'Tenants'},
];

const ManageNotificationForm = ({
  isLoading,
  selectedNotification,
  setIsLoading,
  setIsNotificationUpdate,
  getNotificationById,
}) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [tags, setTags] = useState([]);
  const [deviceTypes, setDeviceTypes] = useState([]);
  const [notificationTypes, setNotificationTypes] = useState([]);
  const [selectedSentTo, setSelectedSentTo] = useState([]);

  const navigate = useNavigate();
  const {t} = useTranslation();

  const {
    setError,
    setValue,
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      notificationTitle: '',
      notificationTypeId: '',
      tagId: '',
      deviceTypeId: '',
      sentTo: '',
      status: true,
    },
  });

  useEffect(() => {
    if (selectedNotification) {
      (async () => {
        await getNotificationDetails();
        setValue('notificationTitle', selectedNotification.notificationTitle);
        setValue('notificationTypeId', selectedNotification.notificationTypeId);
        setValue('tagId', selectedNotification.tagId);
        setValue('deviceTypeId', selectedNotification.deviceTypeId._id);
        const sentTo = selectedNotification.sentTo.map((sent) => {
          return {value: sent, label: sent};
        });
        setValue('sentTo', selectedNotification.sentTo);
        setSelectedSentTo(sentTo);
      })();
    }
  }, [selectedNotification]);

  useEffect(() => {
    (async () => {
      await getNotificationDetails();
    })();
  }, []);
  

  const getNotificationDetails = async () => {
    try {
      setIsLoading(true);
      await getTags();
      await getDeviceTypes();
      await getNotificationTypes();
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setIsLoading(false);
    }
  };

  const getTags = async () => {
    try {
      const {data} = await getTagName();
      const tagsData = data ? data : [];
      setTags(tagsData);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    }
  };

  const getDeviceTypes = async () => {
    try {
      const {data} = await fetchTenantDeviceType();
      const deviceTypes = data ? data : [];
      setDeviceTypes(deviceTypes);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    }
  };

  const getNotificationTypes = async () => {
    try {
      const {data} = await fetchNotificationType();
      const notidicationType = data ? data : [];
      setNotificationTypes(notidicationType);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    }
  };

  const handleManageNotification = async (formData) => {
    try {
      setIsSubmit(true);
      let notificationId;
      if (selectedNotification) {
        const {message} = await editNotification(selectedNotification._id, formData);
        toast(<CustomToast type="success" title="Success" message={message} />);
        setIsNotificationUpdate(true);
        notificationId = selectedNotification._id;
      } else {
        const {data, message} = await addNotification(formData);
        toast(<CustomToast type="success" title="Success" message={message} />);
        notificationId = data._id;
        navigate(`/app/notifications/manage/${data._id}`);
      }
      getNotificationById(notificationId);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setIsSubmit(false);
    }
  };

  const handleSentTo = (selected, event) => {
    const options = sendToOptions;
    let values = [];
    if (event.action === 'select-option' && event.option.value === 'all') {
      values = options;
    } else if (
      (event.action === 'deselect-option' && event.option.value === 'all') ||
      (event.action === 'remove-value' && event.removedValue.value === 'all')
    ) {
      values = [];
    } else if (event.action === 'deselect-option' || event.action === 'remove-value') {
      values = selected ? selected.filter((select) => select.value !== 'all') : [];
    } else if (selected && selected.length === options.length) {
      values = options;
    } else {
      values = selected;
    }
    const sentToIds = values.map((selectedSent) => selectedSent.value);
    setValue('sentTo', sentToIds);
    const errorMsg = sentToIds.length === 0 ? t('Send To is required') : '';
    setError('sentTo', {
      message: errorMsg,
    });
    setSelectedSentTo(values);
  };

  const isDisabled = isSubmit;

  return (
    <>
      {isLoading ? (
        <div className="loader-warp">
          <img src={Loader} alt="loader" />
        </div>
      ) : (
        <>
          <Row className="user-form">
            <Col sm="12" md="6" lg="6" className="">
              <Row>
                <Col>
                  <Form.Group className="mb-3 position-relative" controlId="name">
                    <Form.Label>
                      {t('Notification Title')}
                      <span className="required" />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("Enter Notification Title")}
                      className="mb-2"
                      autoComplete="off"
                      {...register('notificationTitle', {
                        required: t('Notification Title is required'),
                      })}
                    />
                    {errors.notificationTitle && (
                      <StyledErrorLabel>{errors.notificationTitle.message}</StyledErrorLabel>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3 position-relative" controlId="tagId">
                    <Form.Label>
                      {t('Tag')}
                      <span className="required" />
                    </Form.Label>
                    <Form.Select
                      name="tagId"
                      disabled={Boolean(selectedNotification)}
                      className="mb-2"
                      {...register('tagId', {
                        required: t('Tag is required'),
                      })}
                    >
                      <option value="">{t('Select Tag')}</option>
                      {tags.map((tag) => (
                        <option key={tag._id} value={tag._id}>
                          {tag.name}
                        </option>
                      ))}
                    </Form.Select>
                    {errors.tagId && <StyledErrorLabel>{errors.tagId.message}</StyledErrorLabel>}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3 position-relative" controlId="deviceTypeId">
                    <Form.Label>
                      {t('Device Type')}
                      <span className="required" />
                    </Form.Label>
                    <Form.Select
                      name="deviceTypeId"
                      className="mb-2"
                      disabled={Boolean(selectedNotification)}
                      {...register('deviceTypeId', {
                        required: t('Device Type is required'),
                      })}
                    >
                      <option value="">{t('Select Device Type')}</option>
                      {deviceTypes.map((deviceType) => (
                        <option key={deviceType._id} value={deviceType._id}>
                          {deviceType.displayName}
                        </option>
                      ))}
                    </Form.Select>
                    {errors.deviceTypeId && <StyledErrorLabel>{errors.deviceTypeId.message}</StyledErrorLabel>}
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col sm="12" md="6" lg="6" className="">
              <Row>
                <Col>
                  <Form.Group className="mb-3 position-relative" controlId="status">
                    <Form.Label>
                      {t('Status')}
                      <span className="required" />
                    </Form.Label>
                    <div className="d-flex">
                      <SwitchButton
                        leftLabel={'Active'}
                        rightLabel={'Inactive'}
                        isDisableInactive={Boolean(selectedNotification)}
                        value={selectedNotification ? selectedNotification.status : true}
                        handleSetValue={(value) => setValue('status', value)}
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3 position-relative" controlId="notificationTypeId">
                    <Form.Label>
                      {t('Notification Type')}
                      <span className="required" />
                    </Form.Label>
                    <Form.Select
                      name="status"
                      className="mb-2"
                      {...register('notificationTypeId', {
                        required: t('Notification Type is required'),
                      })}
                    >
                      <option value="">{t('Select Notification Type')}</option>
                      {notificationTypes.map((notificationType) => (
                        <option key={notificationType._id} value={notificationType._id}>
                          {notificationType.displayName}
                        </option>
                      ))}
                    </Form.Select>
                    {errors.notificationTypeId && (
                      <StyledErrorLabel>{errors.notificationTypeId.message}</StyledErrorLabel>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3 position-relative" controlId="sentTo">
                    <Form.Label>
                      {t('Sent To')}
                      <span className="required" />
                    </Form.Label>
                    <Select
                      {...register('sentTo', {
                        required: t('Send To is required'),
                      })}
                      isMulti
                      classNames="mb-2"
                      name="sentTo"
                      options={[{label: `Select all (${sendToOptions.length})`, value: 'all'}, ...sendToOptions]}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      value={selectedSentTo}
                      styles={{
                        container: SelectContainerStyle,
                        option: MultiOptionStyle,
                        control: ControlStyle,
                      }}
                      onChange={(field, value) => handleSentTo(field, value)}
                    />
                    {errors.sentTo && <StyledErrorLabel>{errors.sentTo.message}</StyledErrorLabel>}
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-end">
              <Button
                className="btn-primary d-flex align-items-center"
                variant=""
                type="button"
                disabled={isDisabled}
                onClick={handleSubmit(handleManageNotification)}
              >
                {selectedNotification ? t('Update') : t('Save')}
              </Button>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default ManageNotificationForm;
