import React from 'react';
import styled from 'styled-components';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {StyledErrorLabel} from 'styles/styled-components/StyledComponents';

const ErrorLabel = styled(StyledErrorLabel)`
  width: 100%;
  height: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ErrorWithTooltip = ({message}) => {
  return (
    <>
      <OverlayTrigger placement={'bottom'} overlay={<Tooltip>{message}</Tooltip>}>
        <ErrorLabel>{message}</ErrorLabel>
      </OverlayTrigger>
    </>
  );
};

export default ErrorWithTooltip;
