import React from 'react';
import {Link} from 'react-router-dom';
import leftarrowImg from 'assets/images/svg/left-arrow.svg';
import DeviceDashboardContainer from 'pages/DeviceDashboardPage/DeviceDashboardContainer';
import FloatingColor from 'components/FloatingColor';
import {useTranslation} from 'react-i18next';

const DeviceDashboardPOCPage = () => {
  const {t} = useTranslation();

  return (
    <>
      <div className="breadcrum-sec align-items-center breadcrum-sec d-flex justify-content-between flex-column flex-md-row">
        <div className="mob-100">
          <ul>
            <li>
              <Link to={`/app/dashboard`} className="breadcrum-link" variant="breadcrum">
                <img className="me-2" src={leftarrowImg} alt="Add" />
                {t(`Dashboard`)}
              </Link>
            </li>
          </ul>
          <h2 className="page-title">{t(`Device Dashboard`)}</h2>
        </div>
      </div>
      <DeviceDashboardContainer theme={'dark'} />
      <FloatingColor />
    </>
  );
};

export default React.memo(DeviceDashboardPOCPage);
