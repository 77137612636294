export const ENGLISH = {
  label: 'English',
  value: 'en',
};

export const SPANISH = {
  label: 'Spanish',
  value: 'es',
};

export const LANGUAGES = [ENGLISH, SPANISH];
