import React from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

const TextTooltip = ({position, text, toolTip}) => {
  return (
    <>
      <OverlayTrigger placement={position} overlay={<Tooltip>{toolTip}</Tooltip>}>
        <span>{text}</span>
      </OverlayTrigger>
    </>
  );
};

export default TextTooltip;
