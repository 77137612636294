import {formateDateTimeChart} from 'utils/formateDateTimeChart';

export const getBarGroupChartOptions = (data, duration = null, customDate = null, prevFilterDuration = null) => {
  const deviceData = data.deviceData;
  const time = data.time;
  const legend = [];

  const chartData = [];
  for (let i = 0; i < deviceData.length; i++) {
    const label = Object.keys(deviceData[i])[0];
    legend.push(label);

    let device = {
      name: label,
      type: 'line',
      barGap: 0,
      emphasis: {
        focus: 'series',
      },
      data: deviceData[i][label].deviceData,
    };

    chartData.push(device);
  }

  return {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {
      selectedMode: false,
      data: legend,
    },
    xAxis: [
      {
        type: 'category',
        axisTick: {show: false},
        data: time,
        axisLabel: {
          rotate: data.length > 10 ? 45 : 0,
          fontSize: 10,
          formatter: (params) => {
            return formateDateTimeChart(duration, customDate, prevFilterDuration, params);
          },
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
      },
    ],
    series: chartData,
  };
};
