import React, {useState, useEffect} from 'react';
import {toast} from 'react-toastify';
import {OpenVidu} from 'openvidu-browser';
import {Form} from 'react-bootstrap';
import Loader from 'assets/images/loader.gif';
import CustomToast from 'components/Toast/CustomToast';
import UserVideoComponent from './UserVideoComponent';
import {PrimaryButton, StyledLabel} from 'styles/styled-components/StyledComponents';

const RTSPStreamComponent = () => {
  const [selectedCamera, setSelectedCamera] = useState('');
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(false);
  const [subscribers, setSubscribers] = useState([]);

  useEffect(() => {
    return () => {
      disconnectSession();
    };
  }, []);

  const handleSelectCamera = (event) => {
    setSelectedCamera(event.target.value);
  };

  const handleStartCamera = () => {
    disconnectSession();
    setSubscribers([]);
    if (selectedCamera) {
      initializeSession();
    }
  };

  const autoplayMutedVideoIfBlocked = (video) => {
    video.controls = true;
    const promise = video.play();
    if (promise !== undefined) {
      promise.catch(() => {
        video.muted = true;
        video.play();
      });
    }
  };

  const subscribe = async (token) => {
    const OV = new OpenVidu();
    const session = OV.initSession();

    session.on('streamCreated', (event) => {
      var subscriber = session.subscribe(event.stream, undefined);
      setSubscribers((prevSubscribers) => [...prevSubscribers, subscriber]);

      subscriber.on('videoElementCreated', (ev) => {
        try {
          const loader = document.createElement('div');
          loader.classList.add('loader');
          ev.element.parentNode.insertBefore(loader, ev.element.nextSibling);
        } catch (error) {
          console.log('videoElementCreated error ==> ', error);
        }
      });

      subscriber.on('streamPlaying', (ev) => {
        try {
          setLoading(false);
          const cameraVideoElement = subscriber.videos[0].video;
          cameraVideoElement.parentNode.removeChild(cameraVideoElement.nextSibling);
          autoplayMutedVideoIfBlocked(cameraVideoElement);
        } catch (error) {
          console.log('streamPlaying error ==> ', error);
          // setLoading(true);
        }
      });
    });

    session.on('exception', (exception) => {
      console.warn('exception ==> ', exception);
    });

    try {
      await session.connect(token);
      setSession(session);
    } catch (error) {
      const msg = `There was an error connecting to the session. Code: ${error.code}. Message: ${error.message}`;
      console.error('msg ==? ', msg);
      alert(msg);
    }
  };

  const initializeSession = async () => {
    const token = await fetchToken();
    if (token) {
      subscribe(token);
    }
  };

  const fetchToken = async () => {
    const apiUrl = `http://ec2-3-16-130-4.us-east-2.compute.amazonaws.com:5000/generate_token/${selectedCamera}`;

    try {
      setLoading(true);
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const token = data.token;
      return token;
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error.message} />);
      return null;
    }
  };

  const disconnectSession = () => {
    if (session) {
      session.disconnect();
    }
  };

  return (
    <>
      <div className="filter-card mb-4">
        <div className="row">
          <div className="col d-flex justify-content-between align-items-end flex-column flex-md-row">
            <div className="d-flex flex-wrap align-items-end">
              <Form.Group className="me-0 me-md-2 mb-3 mob-100 flex-fill" controlId="notificationTypeId">
                <StyledLabel>{'Camera'}</StyledLabel>
                <Form.Select name="cameras" value={selectedCamera} onChange={handleSelectCamera}>
                  <option value="">Select Camera</option>
                  <option value="2">Camera 1</option>
                  <option value="1">Camera 2</option>
                  <option value="0">Camera 3</option>
                </Form.Select>
              </Form.Group>
              <PrimaryButton variant="outline-primary" className="apply me-2 mb-3" onClick={handleStartCamera}>
                {'Stream'}
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col d-flex justify-content-between align-items-end flex-column flex-md-row">
          <div className="d-flex flex-wrap align-items-end mob-100 w-100">
            {loading && (
              <div className="loader-warp">
                <img src={Loader} alt="loader" />
              </div>
            )}
            {subscribers.map((sub, i) => (
              <div key={i} className="stream-container col-md-12 col-xs-12">
                <UserVideoComponent streamManager={sub} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default RTSPStreamComponent;
