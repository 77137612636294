import React, {useMemo} from 'react';
import {GoogleMap, MarkerClusterer, useJsApiLoader} from '@react-google-maps/api';
import Map from 'components/map/Map';
import {DashboardContainer, MapSection} from 'styles/styled-components/StyledComponents';
import {calculateCenterLocation} from 'utils/calculateCenterLocation';
import {GOOGLE_MAP_TOOL_OPTIONS} from 'constants/map';

const StreetMap = (props) => {
  const {devices, deviceId, selectedDevice, handleSelectDevice} = props;
  // const getCenterOfMap = calculateCenterLocation(devices);
  const lat = 25.6458504; //isNaN(getCenterOfMap.lat) ? '24.191836' : getCenterOfMap.lat;
  const lng = -100.477145; //isNaN(getCenterOfMap.lng) ? '-102.694752' : getCenterOfMap.lng;
  const center = useMemo(() => ({lat: parseFloat(lat), lng: parseFloat(lng)}), [lat, lng]);

  const options = {
    imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
  };

  const {isLoaded} = useJsApiLoader({
    id: 'tns-map-device',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: ['drawing'],
  });

  return (
    <>
      <DashboardContainer>
        <MapSection>
          {isLoaded && (
            <GoogleMap
              mapContainerClassName="map-container"
              center={center}
              zoom={10}
              options={GOOGLE_MAP_TOOL_OPTIONS}
            >
              <MarkerClusterer options={options} minimumClusterSize={2}>
                {(clusterer) => (
                  <Map
                    devices={devices}
                    deviceId={deviceId}
                    selectedDevice={selectedDevice}
                    clusterer={clusterer}
                    handleSelectDevice={handleSelectDevice}
                  />
                )}
              </MarkerClusterer>
            </GoogleMap>
          )}
        </MapSection>
      </DashboardContainer>
    </>
  );
};

export default StreetMap;
