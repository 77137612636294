import React from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import leftarrowImg from 'assets/images/svg/left-arrow.svg';
import OverviewDashboardContainer from 'pages/HomePage/OverviewDashboardContainer';
import FloatingColor from 'components/FloatingColor';

const HomePage = () => {
  const {t} = useTranslation();

  return (
    <>
      <div className="breadcrum-sec align-items-center breadcrum-sec d-flex justify-content-between flex-column flex-md-row">
        <div className="mob-100">
          <ul>
            <li>
              <Link to={`/app/dashboard`} className="breadcrum-link" variant="breadcrum">
                <img className="me-2" src={leftarrowImg} alt="Add" />
                {t(`Dashboard`)}
              </Link>
            </li>
          </ul>
          <h2 className="page-title">{t('Overview Dashboard')}</h2>
        </div>
      </div>
      <OverviewDashboardContainer />
      <FloatingColor />
    </>
  );
};

export default React.memo(HomePage);
