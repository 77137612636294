import React, {useState, useCallback} from 'react';
import {toast} from 'react-toastify';
import CustomToast from 'components/Toast/CustomToast';

const useGetTableList = (fetchTable, numberOfPagesRef, setFilters, applyFilterRef = null, tenantId = null) => {
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getTablelist = useCallback(async (filters) => {
    try {
      setIsLoading(true);
      let data;
      if (tenantId) {
        const response = await fetchTable(tenantId, filters);
        data = response.data;
      } else {
        const response = await fetchTable(filters);
        data = response.data;
      }

      if (data) {
        const {data: listData, numberOfPages: pageNumber} = data;
        numberOfPagesRef.current = pageNumber;
        setTableData(listData);
      } else {
        numberOfPagesRef.current = 1;
        setTableData([]);
      }
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleSort = useCallback(async (column, sortDirection) => {
    if (Object.keys(column).length) {
      const pageNumber = 1;
      if (applyFilterRef) {
        applyFilterRef.current = true;
      }
      setFilters(
        (prevFilters) => ({
          ...prevFilters,
          pageNumber: pageNumber,
          key: column.alias,
          value: sortDirection,
        }),
        (newValue) => {
          getTablelist(newValue);
        },
      );
    }
  }, []);

  const handlePageChange = useCallback((page) => {
    setFilters(
      (prevFilters) => ({
        ...prevFilters,
        pageNumber: page,
      }),
      (newValue) => {
        getTablelist(newValue);
      },
    );
  }, []);

  const handleRowsPerPageChange = useCallback((value) => {
    setFilters(
      (prevFilters) => ({
        ...prevFilters,
        pageNumber: 1,
        pageSize: value,
      }),
      (newValue) => {
        getTablelist(newValue);
      },
    );
  }, []);

  return {tableData, isLoading, getTablelist, handleSort, handlePageChange, handleRowsPerPageChange};
};

export default useGetTableList;
