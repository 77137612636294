import React from 'react';
import moment from 'moment';
import {Link} from 'react-router-dom';
import assignFooterImg from 'assets/images/svg/assign-icon.svg';
import closeFooterImg from 'assets/images/svg/close-icon-small.svg';
import Danger from 'svgs/Danger';
import SensorReadingIcon from 'svgs/SensorReadingIcon';
import DevicePerformanceIcon from 'svgs/DevicePerformanceIcon';
import TextTooltip from 'components/Tooltip';
import {useTranslation} from 'react-i18next';
import {GREY, SEVERITY_COLORS, SEVERITY_HASH_COLORS} from 'constants/notification';
import { MEXICO_TIME } from 'constants/app';

const NotificationCard = ({notification}) => {
  const {t} = useTranslation();
  const hashColor = SEVERITY_HASH_COLORS[notification.severity] || GREY;
  const color = notification.titleAlias === 'Sensor_Reading_Issue' ? SEVERITY_COLORS[notification.severity] : 'grey';

  let sortLabel = '';
  let longLabel = '';

  if (notification.isHazardous === null && notification.isDeviceWorking === null) {
    sortLabel = notification.subDeviceName;
    longLabel = notification.subDeviceName;
  } else if (notification.isHazardous === true) {
    sortLabel = `${t('DNW')} - ${notification.subDeviceName}`;
    longLabel = `${t('Device Not Working')} - ${notification.subDeviceName}`;
  } else if (notification.isHazardous === false) {
    sortLabel = `${t('PRTN')} - ${notification.subDeviceName}`;
    longLabel = `${t('Parameter Returned To Normal')} - ${notification.subDeviceName}`;
  } else if (notification.isDeviceWorking === true) {
    sortLabel = `${t('DIBO')}`;
    longLabel = `${t('Device Is Back Online')}`;
  } else if (notification.isDeviceWorking === false) {
    sortLabel = `${t('DNW')}`;
    longLabel = `${t('Device Not Working')}`;
  }

  return (
    <>
      <div className={`filter-card ${color} mb-3`} id={notification._id}>
        <div className="filter-card-wrap">
          <div className="device-status">
            {notification.titleAlias === 'Sensor_Reading_Issue' ? (
              <SensorReadingIcon height={24} width={24} color={hashColor} />
            ) : notification.subDeviceName == 'null' ? (
              <DevicePerformanceIcon height={24} width={24} color={hashColor} />
            ) : (
              <Danger height={24} width={24} color={hashColor} />
            )}
          </div>
          <div className="row mb-2">
            <div className="col-12 d-flex flex-column">
              <h4 className="device-value">
                <TextTooltip position={'top'} text={notification.tenantName} toolTip={notification.tenantName} />
              </h4>
              {/* <span className="device-label">
                <TextTooltip position={'top'} text={notification.deviceName} toolTip={notification.deviceName} />
              </span> */}
              <Link to={`/app/device/dashboard/${notification.deviceId}`} className="device-label" variant="device">
                <TextTooltip position={'top'} text={notification.deviceName} toolTip={notification.deviceName} />
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12 d-flex flex-column mb-2">
              <h3 className="device-type">
                <span className="me-1">
                  <TextTooltip position={'top'} text={sortLabel} toolTip={longLabel} />
                </span>
              </h3>
              {notification.subDeviceName && (
                <span className="device-reading d-flex">
                  <img className="me-2" src={`/icons/all-in-one-sensor.svg`} alt="Sesnsor" />
                  <TextTooltip
                    position={'top'}
                    text={`${notification.averageValue}`}
                    toolTip={notification.averageValue}
                  />
                  <span className="ms-1">
                    {notification.unit && (
                      <TextTooltip position={'top'} text={`${notification.unit}`} toolTip={notification.unit} />
                    )}
                  </span>
                </span>
              )}
            </div>
            <div className="col-12 col-md-12 d-flex justify-content-end">
              <span className="device-date">
                {/* {moment(notification.createdAtShort).subtract(MEXICO_TIME, "hours").format('DD-MMM-YYYY')} {` `}{' '}
                {moment(notification.createdAtShort).subtract(MEXICO_TIME, "hours").format('hh:mm A')} */}
                {moment(notification.createdAtShort).format('DD-MMM-YYYY')} {` `}{' '}
                {moment(notification.createdAtShort).format('hh:mm A')}
              </span>
            </div>
          </div>
        </div>
        <div className="card-footer">
          <div className="card-footer-divide">
            <a href="#" className="button-disabled">
              <img className="me-2" src={assignFooterImg} alt="Assign" />
              {t('Assign')}
            </a>
          </div>
          <div className="card-footer-divide">
            <a href="#" className="button-disabled">
              <img className="me-2" src={closeFooterImg} alt="Close" />
              {t('Close')}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationCard;
