import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {useSelector, useDispatch} from 'react-redux';
import {Outlet, Navigate, useLocation, useSearchParams, useNavigate, useParams} from 'react-router-dom';
import NotAuthorized from 'components/NotAuthorized';
import CustomToast from 'components/Toast/CustomToast';
import {reportVerify} from 'features/auth/authApi';
import {VIEW_REPORTS} from 'constants/routesName';

const PrivateRoutes = () => {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {isAuthorized, isVerify, user} = useSelector((state) => state.auth);
  // Get the route name from the current location
  const currentRoute = location.pathname.replace('/', '');
  const token = searchParams.get('token');
  const {reportId} = useParams();

  useEffect(() => {
    if (currentRoute.startsWith('app/reports/view') && token) {
      (async () => {
        await verifyReportToken(token);
      })();
    }
  }, [currentRoute, token]);

  const verifyReportToken = async (token) => {
    try {
      setIsLoading(true);
      await dispatch(reportVerify(token));
      navigate(`${VIEW_REPORTS}/${reportId}`, {replace: true});
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setIsLoading(false);
    }
  };

  // Check if the user has read permission for the current route
  const hasReadPermission =
    (user &&
      (user.isDefaultUser ||
        (currentRoute.startsWith('app/device/dashboard') &&
          user.permission.some((p) => p.routeName === 'app/dashboard' && p.access.includes('read'))) ||
        (currentRoute === 'app/tenants/manage' && user.permission.some((p) => p.access.includes('create'))) ||
        (currentRoute.startsWith('app/tenants/manage/') &&
          user.permission.some(
            (p) => (p.routeName === 'app/tenants' && p.access.includes('create')) || p.access.includes('edit'),
          )) ||
        (currentRoute.startsWith('app/tenants/setting/') &&
          user.permission.some(
            (p) => (p.routeName === 'app/tenants' && p.access.includes('edit')) || p.access.includes('delete'),
          )) ||
        (currentRoute === 'app/notifications/manage' && user.permission.some((p) => p.access.includes('create'))) ||
        (currentRoute.startsWith('app/notifications/manage/') &&
          user.permission.some(
            (p) => p.routeName === 'app/notifications' && (p.access.includes('create') || p.access.includes('edit')),
          )) ||
        user.permission.some((p) => p.routeName === currentRoute && p.access.includes('read')))) ||
    currentRoute === 'app/reports/generate' ||
    (currentRoute === 'app/reports/manage' && user.permission.some((p) => p.access.includes('create'))) ||
    ((currentRoute.startsWith('app/reports/manage/') || currentRoute.startsWith('app/reports/subscriber/')) &&
      user.permission.some(
        (p) => (p.routeName === 'app/reports' && p.access.includes('edit')) || p.access.includes('delete'),
      ))
      || currentRoute === 'app/device/poc/dashboard';

  return currentRoute.startsWith('app/reports/view') ? (
    !isLoading && isAuthorized && isVerify ? (
      <Outlet />
    ) : (
      <Navigate to="/" state={{from: location}} replace />
    )
  ) : isAuthorized && isVerify ? (
    hasReadPermission ? (
      <Outlet />
    ) : (
      <NotAuthorized />
    )
  ) : (
    <Navigate to="/" state={{from: location}} replace />
  );
};

export default PrivateRoutes;
