import React from 'react';
import {useTranslation} from 'react-i18next';

const NoRecordFound = () => {
  const {t} = useTranslation();
  return (
    <>
      <div className='no-record-found-container'>
        <div className='no-record-found dark-text'>{t('No Record Found!!!')}</div>
      </div>
    </>
  );
};

export default NoRecordFound;
