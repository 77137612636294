import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import CustomToast from 'components/Toast/CustomToast';
import {useTranslation} from 'react-i18next';
import {fetchNotificationsById} from 'features/notification/notificationApi';
import ManageSettingNotificationForm from 'components/tenantStepper/Setting/ManageSettingNotificationForm';
import ManageNotificationThresholdSettings from 'components/tenantStepper/Setting/ManageNotificationThresholdSettings';

const ManageSettingNotification = () => {
  const [isNotificationUpdate, setIsNotificationUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const {tenantId, deviceId: notificationId} = useParams();

  const {t} = useTranslation();

  useEffect(() => {
    if (notificationId) {
      getNotificationById(notificationId);
    }
  }, [notificationId]);

  const getNotificationById = async (notificationId) => {
    try {
      setIsLoading(true);
      const {data} = await fetchNotificationsById(notificationId);
      setSelectedNotification(data);
    } catch (error) {
      toast(<CustomToast type="error" title="Error" message={error} />);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <div className="card mb-3">
        <div className="card-body">
          <h4 className="section-subheading mb-3">{t('Manage Notifications')}</h4>
          <ManageSettingNotificationForm
            isLoading={isLoading}
            selectedNotification={selectedNotification}
            setIsNotificationUpdate={setIsNotificationUpdate}
            getNotificationById={getNotificationById}
          />
        </div>
      </div>
      {notificationId && (
        <div className="card">
          <div className="card-body">
            <ManageNotificationThresholdSettings
              tenantId={tenantId}
              notificationId={notificationId}
              selectedNotification={selectedNotification}
              isNotificationUpdate={isNotificationUpdate}
              setIsNotificationUpdate={setIsNotificationUpdate}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ManageSettingNotification;
