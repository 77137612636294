export const formatFilter = (filters) => {
  const filterParams = {};
  // Build the filterParams object by excluding empty or falsy filter attributes
  Object.keys(filters).forEach((key) => {
    if (filters[key]) {
      filterParams[key] = filters[key];
    }
  });

  return filterParams;
};
